import {
  UPDATE_SEGMENTS_REQUEST_PARAMS,
  UPDATE_OFFERS_REQUEST_PARAMS,
  UPDATE_MOBILE_MESSAGES_REQUEST_PARAMS,
  UPDATE_COUPONS_REQUEST_PARAMS,
  UPDATE_MEDIA_REQUEST_PARAMS,
  UPDATE_VARIANTS_REQUEST_PARAMS,
  UPDATE_OPTIONS_REQUEST_PARAMS,
  UPDATE_COMBOS_REQUEST_PARAMS,
  UPDATE_PRODUCTS_REQUEST_PARAMS,
  UPDATE_CATEGORIES_REQUEST_PARAMS,
  UPDATE_ITEMS_REQUEST_PARAMS
} from './types';

export function updateRequestParams(requestParams, type) {
  switch (type) {
    case 'segments':
      return {
        type: UPDATE_SEGMENTS_REQUEST_PARAMS,
        payload: requestParams
      };
    case 'offers':
      return {
        type: UPDATE_OFFERS_REQUEST_PARAMS,
        payload: requestParams
      };
    case 'mobile':
      return {
        type: UPDATE_MOBILE_MESSAGES_REQUEST_PARAMS,
        payload: requestParams
      };
    case 'coupons':
      return {
        type: UPDATE_COUPONS_REQUEST_PARAMS,
        payload: requestParams
      };
    case 'media':
      return {
        type: UPDATE_MEDIA_REQUEST_PARAMS,
        payload: requestParams
      };
    case 'variants':
      return {
        type: UPDATE_VARIANTS_REQUEST_PARAMS,
        payload: requestParams
      };
    case 'options':
      return {
        type: UPDATE_OPTIONS_REQUEST_PARAMS,
        payload: requestParams
      };
    case 'combos':
      return {
        type: UPDATE_COMBOS_REQUEST_PARAMS,
        payload: requestParams
      };
    case 'products':
      return {
        type: UPDATE_PRODUCTS_REQUEST_PARAMS,
        payload: requestParams
      };
    case 'categories':
      return {
        type: UPDATE_CATEGORIES_REQUEST_PARAMS,
        payload: requestParams
      };
    case 'items':
      return {
        type: UPDATE_ITEMS_REQUEST_PARAMS,
        payload: requestParams
      };
  }
}
