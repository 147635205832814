import React, { Fragment, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ViewPastOrdersNote from './ViewPastOrdersNote';
import OrderHistoryTable from './OrderHistoryTable';
import AdvancedFilter from './AdvancedFilter';
import Pagination from '../../../../components/Pagination/Pagination';
import { Button } from '../../../../components/Button/Button';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import { ComponentCard } from '../../../../components/FormElements/FormElements';

const pageName = 'Order History';

const NonTableContainer = styled.div`
  padding: 36px;
`;

const StyledCard = styled(ComponentCard)`
  padding: 0 0 0 0;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
`;

const TitleContainer = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: 4fr 1fr;
`;

const ViewPastOrdersNoteWrapper = styled.div`
  padding: 0 0 20px 0;
`;

const AdvancedFilterWrapper = styled.div``;

const ExportWrapper = styled.div`
  text-align: right;
  align-self: center;
`;

const PaginationWrapper = styled.div`
  padding: 40px 0;
`;

export const OrderHistory = props => {
  const {
    advancedFilterIsOpen,
    handleAdvancedFilterButtonClick,
    handleApplyFilters,
    amountOfFiltersApplied,
    orders,
    requestParams,
    updateRequestParams,
    ordersOfMerchantIsRequesting,
    handleActionButtonClick,
    meta,
    handleExportClick,
    requestingCSV
  } = props;

  const orderHistoryTableRef = useRef(null);

  useEffect(() => {
    window.scrollTo({
      top: orderHistoryTableRef.current.offsetTop + -400,
      behavior: 'smooth'
    });
  }, [orders]);
  return (
    <Fragment>
      <TitleContainer>
        <BreadCrumb data-test="component-BreadCrumb" pageName={pageName} />

        <ExportWrapper>
          <Button
            tertiary
            onClick={handleExportClick}
            disabled={requestingCSV || ordersOfMerchantIsRequesting}
          >
            {requestingCSV ? 'Export...' : 'Export'}
          </Button>
        </ExportWrapper>
      </TitleContainer>
      <StyledCard data-test="component-OrderHistory">
        <NonTableContainer>
          <ViewPastOrdersNoteWrapper>
            <ViewPastOrdersNote data-test="component-ViewPastOrdersNotes" />
          </ViewPastOrdersNoteWrapper>
          <AdvancedFilterWrapper>
            <AdvancedFilter
              data-test="component-AdvancedFilter"
              advancedFilterIsOpen={advancedFilterIsOpen}
              handleAdvancedFilterButtonClick={handleAdvancedFilterButtonClick}
              handleApplyFilters={handleApplyFilters}
              amountOfFiltersApplied={amountOfFiltersApplied}
            />
          </AdvancedFilterWrapper>
        </NonTableContainer>
        <OrderHistoryTable
          orderHistoryTableRef={orderHistoryTableRef}
          data-test="component-OrderHistoryTable"
          orders={orders}
          ordersOfMerchantIsRequesting={ordersOfMerchantIsRequesting}
          handleActionButtonClick={handleActionButtonClick}
        />
        {!ordersOfMerchantIsRequesting ? (
          <PaginationWrapper>
            <Pagination
              data-test="component-Pagination"
              response={{
                page_number: meta.page_number,
                page_size: meta.page_size,
                count: meta.count
              }}
              requestParams={requestParams}
              updateRequestParams={updateRequestParams}
            />
          </PaginationWrapper>
        ) : null}
      </StyledCard>
    </Fragment>
  );
};

export default OrderHistory;
