export const GET_CATEGORIES = 'GET_CATEGORIES';
export const PATCH_ACTIVE_CATEGORY = 'PATCH_ACTIVE_CATEGORY';
export const LOAD_EDIT_CATEGORY_PAGE = 'LOAD_EDIT_CATEGORY_PAGE';
export const LOAD_CREATE_CATEGORY_PAGE = 'LOAD_CREATE_CATEGORY_PAGE';
export const LOAD_LIST_CATEGORY_PAGE = 'LOAD_LIST_CATEGORY_PAGE';
export const REDIRECT_LIST_PAGE_ON_SAVE = 'REDIRECT_LIST_PAGE_ON_SAVE';
export const HANDLE_CATEGORY_SAVE_REQUEST = 'HANDLE_CATEGORY_SAVE_REQUEST';
export const HANDLE_CATEGORY_SAVE_REQUEST_FAILURE =
  'HANDLE_CATEGORY_SAVE_REQUEST_FAILURE';
export const RESET_CATEGORY_REDUX_STATE = 'RESET_CATEGORY_REDUX_STATE';
export const SORT_CATEGORY_LIST = 'SORT_CATEGORY_LIST';
export const REQUEST_SORT_CATEGORY_LIST = 'REQUEST_SORT_CATEGORY_LIST';
export const SUBCATEGORY_HOURS_VALIDATION_ERROR =
  'SUBCATEGORY_HOURS_VALIDATION_ERROR';
export const SET_PRODUCT_CATEGORY_ASSIGNMENT_MODAL_IS_VISIBLE =
  'SET_PRODUCT_CATEGORY_ASSIGNMENT_MODAL_IS_VISIBLE';
export const UPDATE_SORTED_CATEGORIES_LIST = 'UPDATE_SORTED_CATEGORIES_LIST';
