import React from "react";
import { connect } from "react-redux";
import AppFeedLayoutListContainer from "./AppFeedLayoutList/AppFeedLayoutListContainer";
import AppFeedLayoutFormContainer from "./AppFeedLayoutForm/AppFeedLayoutFormContainer";

const AppFeedLayoutContainer = ({
  appFeedLayoutPage,
  formInitialData,
  id,
  listAppFeed
}) => {
  if (appFeedLayoutPage === "list") {
    return <AppFeedLayoutListContainer />;
  } else if (appFeedLayoutPage === "edit") {
    return (
      <AppFeedLayoutFormContainer
        formInitialData={formInitialData}
        id={id}
        listAppFeed={listAppFeed}
      />
    );
  } else {
    return <AppFeedLayoutFormContainer />;
  }
};

const mapStateToProps = state => ({
  appFeedLayoutPage: state.appFeedLayoutReducer.appFeedLayoutPage,
  formInitialData: state.appFeedLayoutReducer.formInitialData,
  id: state.appFeedLayoutReducer.id,
  listAppFeed: state.appFeedLayoutReducer.listAppFeed
});

export default connect(mapStateToProps)(AppFeedLayoutContainer);
