import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  TableWithButtonRow,
  TableWrapper,
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
} from '../../../../components/Table/TableElements';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '../../../../components/Icon/Icon';
import PaginationControl from '../../../../components/Pagination/PaginationControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { LoadingBlueLarge } from '../../../../components/Loading/Loading';
import { SmallToggleSwitch } from '../../../../components/ToggleSwitch/ToggleSwitch';
import { Button } from '../../../../components/Button/Button';
import {sortByTypes} from '../../../../utils/sort/sort';
import {STRING, DATE, BOOLEAN} from '../../../../utils/sort/sort';
import Select from '../../../../components/Select/Select';

const OfferImage = styled.img`
  width: 100px;
  object-fit: contain;
  background-color: #d3d3d3;
`;

const TextSearch = styled.div`
  // padding: 20px;
`;

const PaginationWrapper = styled.div`
  // padding-top: 135px;
  // padding-bottom: 33px;
`;

const Wrapper = styled.div`
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  display: grid;
  grid-gap: 20px;
`;

const SortWrapper = styled.div`
  font-size: 12px;
  letter-spacing: -0.02px;
  color: #191818;
`;

const TopDiv = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  justify-content: space-between;
  margin: 37px;
`;

const toCurrency = num => {
  if (typeof num == 'number') {
    return num.toFixed(2);
  } else return num;
};

const transformTemplateName = templateName =>
  templateName.replace('-', ' ').toUpperCase();

const transformTemplateDiscount = offerDetails => {
  let str =
    offerDetails.template_values.discount_type === 'percent' ? '_%' : '$_';
  if (
    offerDetails.template_name === 'discounted-item' ||
    offerDetails.template_name === 'birthday-discounted-item'
  ) {
    return str.replace('_', toCurrency(offerDetails.template_values.discount));
  } else {
    return (
      str.replace(
        '_',
        toCurrency(
          offerDetails.template_values.max_discount ||
            offerDetails.template_values.total_amount
        )
      ) + ' max'
    );
  }
};

const OffersList = ({
  allOffersLength,
  toggleOfferAvailability,
  initiateCreateOffer,
  requestParams,
  handleOffersSearch,
  requestingGetOffers,
  initiateEditOffer,
  offersActiveList,
  handleSort,
}) => {

  // render headers start
  const renderHeaders = () => {
    return (
      <TrHead>
        <Th align="left" />
        <Th align="left" 
          onClick={() => {handleSort({value:'offer_details.title', sortMethod: sortByTypes, sortType: STRING}) }} 
          value='offer_details.title' 
          sortingOption= {requestParams.sorting_option} 
          isSortable='true'>
          
          Title
        </Th>
        <Th align="left" style={{ minWidth: '150px' }}
          onClick={() => {handleSort({value:'offer_details.template_name', sortMethod: sortByTypes, sortType: STRING}) }} 
          value='offer_details.template_name' 
          sortingOption= {requestParams.sorting_option} 
          isSortable='true'>
          
          Type
        </Th>
         <Th align="left" 
          onClick={() => { handleSort({value:'offer_details.template_values.expiry_date', sortMethod: sortByTypes, sortType: DATE})}} 
          value='offer_details.template_values.expiry_date' 
          sortingOption= {requestParams.sorting_option} 
          isSortable='true'>
          
          Expiry
        </Th>

        <Th align="left">Description</Th>
        {/* <Th align="left">
          Redeemed
        </Th> */}
        <Th align="left" 
          onClick={() => {handleSort({value:'offer_details.is_active', sortMethod: sortByTypes, sortType: BOOLEAN}) }} 
          value='offer_details.is_active' 
          sortingOption= {requestParams.sorting_option} 
          isSortable='true'>
          
          Active
        </Th>
      </TrHead>
    );
  };

  // render headers end

  // render rows start
  const renderRows = () => {
    if(offersActiveList.length > 0){
      return offersActiveList.map((offer, index) => (
        <Tr
          onClick={() => initiateEditOffer(offer)}
          key={index}
          style={{ cursor: 'pointer' }}
        >
          <Td>
            <OfferImage src={offer.offer_details.image_url} />
          </Td>
          <Td>{offer.offer_details.title}</Td>
          <Td>
            <div>{transformTemplateName(offer.offer_details.template_name)}</div>
            <div>{transformTemplateDiscount(offer.offer_details)}</div>
          </Td>
          <Td>
            {offer.offer_details.template_values.expiry_date || 'No Expiry'}
          </Td>
          <Td align="left">{offer.offer_details.description}</Td>
          <Td
            onClick={event => {
              event.stopPropagation();
            }}
            align="left"
            style={{ position: 'relative' }}
          >
            <SmallToggleSwitch
              checked={offer.offer_details.is_active}
              onChange={event => {
                event.stopPropagation();
                toggleOfferAvailability(offer);
              }}
            />
          </Td>
        </Tr>
      ));
    }
    else{
      return (<Tr>
                <Td colSpan='6' padding='50px 0' align='center'> No matching data </Td>
            </Tr>)
    }
  };
  // render rows end

  return (
    <Wrapper>
      <TableWithButtonRow>
        <TopDiv>
          <TextSearch>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon className="fal fa-search" />
                  </InputAdornment>
                )
              }}
              style={{ width: '100%' }}
              variant="outlined"
              type="text"
              onChange={e => handleOffersSearch(e.target.value)}
              placeholder="Search offers"
            />
          </TextSearch>
          <Button onClick={() => initiateCreateOffer()}>Create Offer</Button>
        </TopDiv>
        <TableWrapper noShadow>
          <Table noBorder>
            <thead>{renderHeaders()}</thead>
            {!requestingGetOffers && <TBody striped>{renderRows()}</TBody>}
          </Table>
        </TableWrapper>
        {requestingGetOffers && <LoadingBlueLarge />}
      </TableWithButtonRow>
      {!requestingGetOffers && (
        <PaginationWrapper>
          <PaginationControl
            response={{
              ...offersActiveList,
              page: requestParams.page_number,
              page_size: requestParams.page_size,
              count: allOffersLength
            }}
            requestParams={requestParams}
            type="offers"
          />
        </PaginationWrapper>
      )}
    </Wrapper>
  );
};

export default OffersList;
