import React from 'react';
import styled from 'styled-components';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import { Button } from '../../../../../../components/Button/Button';
import { EditIcon } from '../../../../../../components/Icon';
import { Collapse } from '@material-ui/core';
import { LoadingBlueLarge } from '../../../../../../components/Loading/Loading';
import ComboOverridesModalContainer from '../containers/ComboOverridesModalContainer';

const Card = styled.div`
  background: ${props => (props.disabled ? '#ebebeb' : 'white')};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  padding-top: 24px;
  padding-left: 48px;
  padding-right: 20px;
  color: ${props => (props.disabled ? '#959595' : 'initial')};
  label {
    z-index: 0;
  }
`;

const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
`;

const ButtonWrapper = styled(Button)`
  margin-top: 24px;
  margin-bottom: 23px;
`;

const NoOverridesText = styled.p`
  font-style: italic;
  padding-bottom: 20px;
`;

const OverridesRow = styled.div`
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 60px;
  grid-gap: 50px;
`;

const OverridenPrice = styled.div`
  color: #191818;
  margin-top: 16px;
  font-size: 14px;
  letter-spacing: 0.01px;
`;

const EditIconWrapper = styled.div`
  color: #191818;
  font-weight: 600;
  font-size: 14px;
`;

const BusinessName = styled.span`
  color: #191818;
  font-weight: 600;
`;

let ComboBusinessesOverridesForm = ({
  isExpanded,
  setIsExpanded,
  disabled,
  summaryData,
  isOverrideModal,
  setIsOverrideModal,
  formattedBusinesses,
  combo,
  isCreate,
  setIsCreate,
  isSubmitting
}) => {
  const summaryDataModified = summaryData ? summaryData : null;

  if ((isSubmitting || !summaryDataModified) && !disabled)
    return <LoadingBlueLarge />;

  return (
    <div>
      <Card
        disabled={disabled}
        onClick={() => !disabled && setIsExpanded(!isExpanded)}
      >
        <TitleAndCollapseButton>
          <Title>Business Overrides</Title>
          {!isExpanded ? (
            <ChevronDownIcon
              disabled={disabled}
              onClick={() => setIsExpanded(true)}
            />
          ) : (
            <ChevronUpIcon
              onClick={() => setIsExpanded(false)}
              disabled={disabled}
            />
          )}
        </TitleAndCollapseButton>

        <Collapse in={isExpanded}>
          <div>
            <ButtonWrapper
              onClick={e => {
                e.stopPropagation();
                setIsOverrideModal({
                  isShowing: true,
                  price: null,
                  assignedBusinesses: []
                });
                setIsCreate(true);
              }}
            >
              Add Override
            </ButtonWrapper>
            {!disabled ? (
              !summaryDataModified.length ? (
                <NoOverridesText>
                  There are no overrides for this combo
                </NoOverridesText>
              ) : (
                summaryDataModified.map((data, index) => {
                  return (
                    <OverridesRow key={index}>
                      <div>
                        {data.businesses.map((business, businessIndex) => {
                          return (
                            <BusinessName key={businessIndex}>
                              {business.name},{' '}
                            </BusinessName>
                          );
                        })}

                        <OverridenPrice>
                          Overriden price - ${data.fixed_price}{' '}
                        </OverridenPrice>
                      </div>
                      <EditIconWrapper>
                        Edit
                        <EditIcon
                          onClick={e => {
                            e.stopPropagation();
                            setIsOverrideModal({
                              isShowing: true,
                              price: summaryDataModified[index].fixed_price,
                              assignedBusinesses:
                                summaryDataModified[index].businesses,
                              initialBusinesses:
                                summaryDataModified[index].businesses,
                              index: index
                            });
                            setIsCreate(false);
                          }}
                          style={{ cursor: 'pointer', marginLeft: '5px' }}
                        />
                      </EditIconWrapper>
                    </OverridesRow>
                  );
                })
              )
            ) : null}
          </div>
        </Collapse>
      </Card>

      {isOverrideModal.isShowing && (
        <ComboOverridesModalContainer
          isOverrideModal={isOverrideModal}
          setIsOverrideModal={setIsOverrideModal}
          formattedBusinesses={formattedBusinesses}
          combo={combo}
          comboId={combo.id}
          summaryData={summaryData}
          isCreate={isCreate}
          disabled={!summaryDataModified.length}
        />
      )}
    </div>
  );
};

export default ComboBusinessesOverridesForm;
