import React from 'react';
import {
  Button,
  LoadingButton
} from '../../../../../../components/Button/Button';

const FinishButton = (props, formProps) => {
  if (props.isCreating) {
    let buttonText = props.edit ? 'Updating' : 'Creating';
    return (
      <LoadingButton
        whiteSpinner
        style={{
          width: '25%',
          margin: '0px 16px 16px 0px',
          justifySelf: 'end'
        }}
      >
        {buttonText}
      </LoadingButton>
    );
  }
  return (
    <Button
      style={{
        width: '25%',
        margin: '0px 16px 16px 0px',
        justifySelf: 'end'
      }}
      disabled={
        !formProps.isValid ||
        !formProps.dirty ||
        !formProps.values.segments.length ||
        !formProps.values.message ||
        !formProps.values.messageActions ||
        ((formProps.values.messageActions == 'products' ||
          formProps.values.messageActions == 'offers' ||
          formProps.values.messageActions == 'webpage') &&
          !formProps.values.messageSubActions)
      }
      type="submit"
      onClick={() => props.onFinishButtonClicked(props, formProps)}
    >
      {formProps.values.send_now ? 'Send Now' : 'Schedule'}
    </Button>
  );
};

export default FinishButton;
