import React, { useState, useEffect } from 'react';
import {
  getImages,
  showCreateImageForm,
  showEditImageForm,
  deleteImageRequest,
  displayConfirmModal,
} from '../Media/mediaActions';
import { connect } from 'react-redux';
import { LoadingBlueLarge } from '../../components/Loading/Loading';
import styled from 'styled-components';
import { DialogContent, Dialog } from '@material-ui/core';
import ImageGallery from './components/ImageGallery';
import { Title3 } from '../../components/Titles';
import CreateEditImageContainer from '../Media/containers/CreateEditImageContainer';
import ImagePreview from './components/ImagePreview';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '../../components/Icon/Icon';
import { Button } from '../../components/Button/Button';
import ConfirmDeleteModal from '../../components/Modal/ConfirmDeleteModal';
import { withStyles } from '@material-ui/core/styles';

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 70px 100px 350px auto;
`;

const TitleWrapper = styled.div`
  grid-column: 1 / span 3;
  grid-row: 1;
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 20px;
  border-bottom: 1px solid #ebebeb;
`;

const CancelButton = styled.div`
  text-align: center;
  cursor: pointer;
  padding-right: 10px;
`;

const ImageUploadSearchWrapper = styled.div`
  grid-column: 1 / span 2;
  grid-row: 2;
  height: 100px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
  padding: 20px;
  border-bottom: 1px solid #ebebeb;
`;

const UploadButton = styled.div`
  background-color: #e6ebff;
  border-radius: 8px;
  color: #2440ae;
  cursor: pointer;
  width: 204px;
  text-align: center;
  padding-top: 16px;
  font-weight: 600;
  &:hover {
    background-color: #d9e0ff;
  }
`;

const ChooseImageButtonWrapper = styled.div`
  grid-column: 1 / span 3;
  grid-row: 6;
  display: grid;
  box-shadow: 0 -2px 9px 0 rgba(0, 0, 0, 0.12);
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding: 15px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: #fff;
`;
const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#2440AE',
      },
    },
  },
})(TextField);

const MediaModalContainer = (props) => {
  const [selectedImage, setSelectedImage] = useState({
    alt_text: '',
    media_id: '',
    mime_type: '',
    name: '',
    url:
      'https://s3.amazonaws.com/assets.smoothpay.com/web/portal-assets/no-image.png',
  });

  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    if (!props.mediaReducer.images) {
      props.getImages();
    }
  }, []);

  useEffect(() => {
    (props.mediaReducer.images &&
    props.mediaReducer.images.length > 0) ?
      setSelectedImage({
        alt_text: props.mediaReducer.images[0].alt_text,
        media_id: props.mediaReducer.images[0].media_id,
        name: props.mediaReducer.images[0].name,
        url: props.mediaReducer.images[0].url,
        mime_type: props.mediaReducer.images[0].mime_type,
      }) :
      setSelectedImage({
        alt_text: '',
        media_id: '',
        mime_type: '',
        name: '',
        url:
          'https://s3.amazonaws.com/assets.smoothpay.com/web/portal-assets/no-image.png',
      })
  }, [props.mediaReducer.images]);

  const sliceImages = (images) => {
    return images.filter(
      (image) =>
        image.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1
    );
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      style={{ margin: 'unset', padding: 'unset' }}
      scroll="body"
    >
      <DialogContent style={{ margin: 'unset', padding: 'unset' }}>
        {!props.mediaReducer.requestingGetImages ? (
          <Wrapper>
            <TitleWrapper>
              <Title3> Choose Image</Title3>
              <CancelButton onClick={() => props.closeModal()}>
                {' '}
                X{' '}
              </CancelButton>
            </TitleWrapper>
            <ImageUploadSearchWrapper>
              <UploadButton onClick={() => props.showCreateImageForm()}>
                Upload Image
              </UploadButton>
              <CssTextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon className="fal fa-search" />
                    </InputAdornment>
                  ),
                }}
                style={{ width: '100%' }}
                variant="outlined"
                type="text"
                onChange={(e) => setSearchString(e.target.value)}
                placeholder="Search Images"
              />
            </ImageUploadSearchWrapper>
            <ImageGallery
              {...props}
              sliceImages={sliceImages}
              setSelectedImage={setSelectedImage}
              selectedImage={selectedImage}
            />
            <ImagePreview
              selectedImage={selectedImage}
              editImageFormIsVisible={props.mediaReducer.editImageFormIsVisible}
              showEditImageForm={props.showEditImageForm}
              deleteImageRequest={props.deleteImageRequest}
              setSelectedImage={setSelectedImage}
              displayConfirmModal={props.displayConfirmModal}
            />
            <ChooseImageButtonWrapper>
              <Button secondary onClick={() => props.closeModal()}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  props.selectProduct(
                    selectedImage.media_id,
                    selectedImage.url
                  );
                  props.closeModal();
                }}
                disabled={!selectedImage.media_id}
              >
                Choose Image
              </Button>
            </ChooseImageButtonWrapper>
          </Wrapper>
        ) : (
          <LoadingBlueLarge />
        )}
        {props.mediaReducer.createImageFormIsVisible && (
          <CreateEditImageContainer/>
        )}
        {props.mediaReducer.displayConfirmModal && (
          <ConfirmDeleteModal
            requestingDelete={props.mediaReducer.requestingDeleteImage}
            deleteAction={() => props.deleteImageRequest(selectedImage)}
            cancel={() => props.displayConfirmModal(false)}
            deleteSuccess={props.mediaReducer.imageDeleteSuccess}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

function mapStateToProps(state) {
  return {
    mediaReducer: state.mediaReducer,
  };
}

export default connect(mapStateToProps, {
  getImages,
  showCreateImageForm,
  showEditImageForm,
  deleteImageRequest,
  displayConfirmModal,
})(MediaModalContainer);
