import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Formik, FieldArray, Field } from 'formik';
import {
  RenderTextField,
  RenderSelect
} from '../../../../../../components/FormElements/FormikElements';
import { Button } from '../../../../../../components/Button/Button';
import Modal from '../../../../../../components/Modal/Modal';
import ConfirmCancelModal from '../../../ProductWizard/components/ConfirmCancelModal';
import ConfirmOverridesModal from '../../components/ConfirmOverridesModal';
import getExistingLocations from '../../../../utils/getExistingLocations';

const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: 10fr 1fr;
  padding: 24px;
`;

const ButtonsWrapper = styled.div`
  padding: 20px 40px;
  box-shadow: 0 -2px 9px 0 rgba(0, 0, 0, 0.12);
  display: grid;
  grid-template-columns: 3fr 1fr;
`;

const ModalBody = styled.div`
  padding: 0 24px;

  overflow-y: scroll;
  height: 60vh;
`;

const ButtonDiv = styled(Button)`
  width: 100px;
  min-width: 100px;
`;

const Title = styled.p`
  color: #191818;
  font-size: 17px;
  font-weight: 600;
  margin: 23px 0;
`;

const VariantsDiv = styled.div`
  margin-top: 40px;
`;

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 30px;
`;

const Header = styled.p`
  font-size: 15px;
  font-weight: 600;
`;

const BodyDiv = styled.div``;

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 10px;
`;

const RowCol = styled.div`
  font-size: 14px;
  align-self: center;
`;

const LineDivider = styled.hr`
  border: 1px solid rgba(0, 0, 0, 0.09);
  margin: 15px 0;
`;

const NoLocationSelectedDiv = styled.p`
  color: #959595;
  font-size: 20px;
  letter-spacing: 0.03;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`;

const ProductOverridesModal = ({
  isOverrideModal,
  setIsOverrideModal,
  formattedBusinesses,
  productId,
  summaryData,
  handleSubmitProductOverrides,
  productVariantsModified,
  productVariantsInitialValues,
  isCreate,
  disabled,
  isConfirmModal,
  setIsConfirmModal,
  isConfirmOverridesModal,
  setIsConfirmOverridesModal,
  selectedLocations,
  setSelectedLocations
}) => {
  const handleConfirmButtonClick = () => {
    if (
      (isOverrideModal.prices && isOverrideModal.assignedBusinesses.length) ||
      (!isOverrideModal.prices && !isOverrideModal.assignedBusinesses.length)
    ) {
      setIsConfirmModal(false);
      setIsOverrideModal({
        isShowing: false,
        prices: null,
        assignedBusinesses: []
      });
    } else {
      setIsConfirmModal(false);
      setIsOverrideModal({
        ...isOverrideModal,
        assignedBusinesses: []
      });
    }
  };

  const prices = isOverrideModal.prices || productVariantsModified;

  return (
    <Modal borderRadius>
      <div
        style={{
          width: '550px'
        }}
      >
        <ModalTitle>
          <div>Overrides</div>
          <div
            onClick={() =>
              setIsOverrideModal({
                isShowing: false,
                prices: null,
                assignedBusinesses: []
              })
            }
            style={{ cursor: 'pointer' }}
          >
            x
          </div>
        </ModalTitle>
        <LineDivider />

        <Formik
          initialValues={{
            assignedBusinesses: isOverrideModal.assignedBusinesses.map(
              business => business.id
            ),
            prices: isOverrideModal.prices || productVariantsModified
          }}
          enableReinitialize={true}
        >
          {({
            dirty,
            values,
            setFieldValue,
            initialValues,
            handleChange,
            touched
          }) => (
            <Fragment>
              <ModalBody>
                <Title>Select a Location</Title>
                <Field
                  name="assignedBusinesses"
                  component={RenderSelect}
                  options={formattedBusinesses || []}
                  isMulti
                  setFieldValue={setFieldValue}
                  getOptionLabel={business =>
                    business.isDisabled
                      ? `${business.display_name} - Business is not associated`
                      : business.display_name
                  }
                  getOptionValue={business => business.id}
                  valueKey="id"
                  placeholder="Search Locations"
                  onChange={e => {
                    setSelectedLocations(e);
                    // setIsOverrideModal({
                    //   ...isOverrideModal,
                    //   assignedBusinesses: e
                    // });
                  }}
                  simpleValue
                />

                {!isOverrideModal.prices && !selectedLocations.length ? (
                  //!isOverrideModal.assignedBusinesses.length ? (
                  <NoLocationSelectedDiv>
                    Select a location to add overrides
                  </NoLocationSelectedDiv>
                ) : (
                  <VariantsDiv>
                    <HeaderWrapper>
                      <Header>Variant</Header>
                      <Header>Price</Header>
                    </HeaderWrapper>

                    <FieldArray
                      name="prices"
                      render={arrayHelpers => {
                        return (
                          <Fragment>
                            <BodyDiv>
                              {prices.map((variant, priceIndex) => {
                                return (
                                  <div key={priceIndex}>
                                    <RowWrapper>
                                      <RowCol>{variant.name}</RowCol>
                                      <RowCol style={{ paddingRight: '20px' }}>
                                        <Field
                                          id="price"
                                          name={`prices[${priceIndex}].price`}
                                          component={RenderTextField}
                                          isPrice
                                          placeholder={`${initialValues.prices[priceIndex].price}`}
                                          {...(isCreate ? { value: null } : {})}
                                        />
                                      </RowCol>
                                    </RowWrapper>
                                    <LineDivider />
                                  </div>
                                );
                              })}
                            </BodyDiv>
                          </Fragment>
                        );
                      }}
                    />
                  </VariantsDiv>
                )}
              </ModalBody>
              <ButtonsWrapper>
                <ButtonDiv
                  secondary
                  onClick={() => {
                    if (dirty) setIsConfirmModal(true);
                    else {
                      if (
                        (isOverrideModal.prices &&
                          isOverrideModal.assignedBusinesses.length) ||
                        (!isOverrideModal.prices &&
                          !isOverrideModal.assignedBusinesses.length)
                      ) {
                        setIsOverrideModal({
                          isShowing: false,
                          prices: null,
                          assignedBusinesses: []
                        });
                      } else
                        setIsOverrideModal({
                          ...isOverrideModal,
                          assignedBusinesses: []
                        });
                    }
                  }}
                >
                  {isOverrideModal.assignedBusinesses.length &&
                  !isOverrideModal.prices
                    ? 'Back'
                    : 'Cancel'}
                </ButtonDiv>
                <ButtonDiv
                  onClick={() => {
                    if (
                      !isCreate &&
                      isOverrideModal.initialBusinesses.length <
                        values.assignedBusinesses.length
                    ) {
                      setIsConfirmOverridesModal(true);
                    } else {
                      setIsOverrideModal({
                        isShowing: false,
                        prices: null,
                        assignedBusinesses: []
                      });
                      handleSubmitProductOverrides(
                        productId,
                        summaryData,
                        values,
                        isCreate,
                        isOverrideModal.initialBusinesses,
                        isOverrideModal.index,
                        initialValues,
                        touched
                      );
                    }
                  }}
                  disabled={!dirty}
                >
                  Save
                </ButtonDiv>
              </ButtonsWrapper>

              {isConfirmModal && (
                <ConfirmCancelModal
                  onCancel={() => {
                    setIsConfirmModal(false);
                  }}
                  onConfirm={() => {
                    handleConfirmButtonClick();
                  }}
                />
              )}

              {isConfirmOverridesModal && (
                <ConfirmOverridesModal
                  businesses={getExistingLocations(
                    selectedLocations,
                    summaryData,
                    initialValues
                  )}
                  onCancel={() => setIsConfirmOverridesModal(false)}
                  onConfirm={() => {
                    setIsOverrideModal({
                      isShowing: false,
                      prices: null,
                      assignedBusinesses: []
                    });
                    handleSubmitProductOverrides(
                      productId,
                      summaryData,
                      values,
                      isCreate,
                      isOverrideModal.initialBusinesses,
                      isOverrideModal.index,
                      isCreate,
                      touched
                    );
                  }}
                />
              )}
            </Fragment>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ProductOverridesModal;
