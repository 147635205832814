import {
  GET_OFFERS_REQUEST,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_FAILURE,
  TOGGLE_OFFER_AVAILABILITY_SUCCESS,
  INITIATE_CREATE_OFFER,
  CREATE_OFFER_REQUEST,
  CREATE_OFFER_SUCCESS,
  CREATE_OFFER_FAILURE,
  INITIATE_EDIT_OFFER,
  EDIT_OFFER_REQUEST,
  EDIT_OFFER_SUCCESS,
  EDIT_OFFER_FAILURE,
  RESET_OFFER_STATE,
  UPDATE_SORTED_OFFERS_LIST
} from './offerTypes';
import { api } from '../../../api';
import { createAlert } from '../../Alert/actions';
import moment from 'moment';

export function getOffers(
  params = { page_number: 1, page_size: 10000, searchString: '' }
) {
  //params = { page_number: 1, page_size: 10000 }
  // if (!params) {
  //   params = {
  //     page_number: 1,
  //     page_size: 10000
  //   };
  // }
  return function(dispatch) {
    dispatch({ type: GET_OFFERS_REQUEST });

    return Promise.all([
      api.get(
        `/offers?page_number=${params.page_number}&page_size=${params.page_size}`
        // `/offers?page_number=${page_number}&page_size=${page_size}&search_string=${searchString}`
      ),
      api.get(`/offers/config`)
    ]).then(
      response => {
        dispatch({
          type: GET_OFFERS_SUCCESS,
          allOffers: response[0].data.data,
          options: response[1].data.data,
          meta: response[0].data.meta
        });
      },
      error => {
        dispatch({ type: GET_OFFERS_FAILURE, error });
      }
    );
  };
}

export function toggleOfferAvailability(offer) {
  return function(dispatch) {
    return api
      .patch(
        `/offers/${offer.offer_details.offer_id}`,
        toggleOfferFormat(offer)
      )
      .then(() => {
        dispatch({ type: TOGGLE_OFFER_AVAILABILITY_SUCCESS, offer });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Offer updated successfully.'
          })
        );
      });
  };
}

export function initiateCreateOffer() {
  return {
    type: INITIATE_CREATE_OFFER
  };
}

export function createOffer(offer) {
  return function(dispatch) {
    offer = createOfferStructure(offer);
    if (
      offer.offer_details.template_name === 'first-time' ||
      offer.offer_details.template_name === 'carryover-loyalty'
    ) {
      delete offer.conditions;
    }
    dispatch({ type: CREATE_OFFER_REQUEST });
    api
      .post(`/offers`, offer)
      .then(response => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'Offer created successfully'
          })
        );
        dispatch({ type: CREATE_OFFER_SUCCESS });
      })
      .catch(error => {
        dispatch({ type: CREATE_OFFER_FAILURE });
        Object.values(error.response.data.errors.details).forEach(message => {
          dispatch(
            createAlert({ type: 'error', message: message.replace(/_/g, ' ') })
          );
        });
      });
  };
}

export function editOffer(offer) {
  return function(dispatch, getState) {
    const { editingOfferId } = getState().offersReducer;
    if (offer.offer_details.template_name === 'first-time') {
      delete offer.conditions;
    }
    const editingOffer = createOfferStructure(offer);

    dispatch({ type: EDIT_OFFER_REQUEST });
    api
      .put(`/offers/${editingOfferId}`, editingOffer)
      .then(response => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'Offer edited successfully'
          })
        );
        dispatch({ type: EDIT_OFFER_SUCCESS });
      })
      .catch(error => {
        dispatch({ type: EDIT_OFFER_FAILURE });
        Object.values(error.response.data.errors.details).forEach(message => {
          dispatch(
            createAlert({ type: 'error', message: message.replace(/_/g, ' ') })
          );
        });
      });
  };
}

export function initiateEditOffer(offer) {
  return function(dispatch) {
    const editingOffer = createOfferStructure(offer);
    dispatch({
      type: INITIATE_EDIT_OFFER,
      editingOffer,
      editingOfferId: offer.offer_details.offer_id
    });
  };
}

export function resetOfferStates() {
  return {
    type: RESET_OFFER_STATE
  };
}

const toggleOfferFormat = offer => {
  return {
    is_active: !offer.offer_details.is_active
  };
};

const createOfferStructure = offer => {
  let newOfferStructure = {};
  switch (offer.offer_details.template_name) {
    case 'first-time': {
      newOfferStructure = {
        offer_details: {
          title: offer.offer_details.title,
          auto_redeem: offer.offer_details.auto_redeem,
          description: offer.offer_details.description,
          details: offer.offer_details.details,
          image_url: offer.offer_details.image_url,
          is_active: offer.offer_details.is_active,
          label: offer.offer_details.label,
          reusable: offer.offer_details.reusable,
          subtitle: offer.offer_details.subtitle,
          template_name: offer.offer_details.template_name,
          template_values: {
            total_amount: offer.offer_details.template_values.total_amount
          }
        },
        businesses: offer.businesses,
        customers: offer.customers,
        segments: offer.segments
      };

      return newOfferStructure;
    }
    case 'discounted-item': {
      newOfferStructure = {
        offer_details: {
          title: offer.offer_details.title,
          auto_redeem: offer.offer_details.auto_redeem,
          description: offer.offer_details.description,
          details: offer.offer_details.details,
          image_url: offer.offer_details.image_url,
          is_active: offer.offer_details.is_active,
          label: offer.offer_details.label,
          reusable: offer.offer_details.reusable,
          subtitle: offer.offer_details.subtitle,
          template_name: offer.offer_details.template_name,
          template_values: {
            //TODO loyalty tier unimplemented check spec
            apply_multiple: offer.offer_details.template_values.apply_multiple,
            discount: offer.offer_details.template_values.discount,
            discount_type: offer.offer_details.template_values.discount_type
          }
        },
        businesses: offer.businesses,
        customers: offer.customers,
        segments: offer.segments,
        // conditions: [
        //   {
        //     source: offer.conditions[0].source,
        //     is_active: offer.conditions[0].is_active,
        //     item_conditions: {
        //       sort_price: 'DESC',
        //       discounted_products:
        //         offer.conditions[0].item_conditions.discounted_products,
        //       required_products:
        //         offer.conditions[0].item_conditions.required_products,
        //       required_product_condition:
        //         offer.conditions[0].item_conditions
        //           .required_product_condition || 'AND',
        //       discounted_variants:
        //         offer.conditions[0].item_conditions.discounted_variants,
        //       required_variants:
        //         offer.conditions[0].item_conditions.required_variants || []
        //     }
        //   }
        // ]
        conditions: offer.conditions.map(condition => ({
          ...condition,
          item_conditions: { ...condition.item_conditions, sort_price: 'DESC' }
        }))
      };

      if (offer.offer_details.template_values.expiry_date) {
        newOfferStructure.offer_details.template_values.expiry_date = moment(
          offer.offer_details.template_values.expiry_date,
          'YYYY-MM-DD HH:mm:ss'
        ).format('YYYY-MM-DD');
      }

      return newOfferStructure;
    }
    case 'free-item': {
      if (
        offer.offer_details.template_values.points_cost == null ||
        Number(offer.offer_details.template_values.points_cost) == 0
      ) {
        newOfferStructure = {
          offer_details: {
            title: offer.offer_details.title,
            auto_redeem: offer.offer_details.auto_redeem,
            description: offer.offer_details.description,
            details: offer.offer_details.details,
            image_url: offer.offer_details.image_url,
            is_active: offer.offer_details.is_active,
            label: offer.offer_details.label,
            reusable: offer.offer_details.reusable,
            subtitle: offer.offer_details.subtitle,
            template_name: offer.offer_details.template_name,
            template_values: {
              //TODO loalty tier not being used
              apply_multiple:
                offer.offer_details.template_values.apply_multiple,

              max_discount: Number(
                offer.offer_details.template_values.max_discount
              )
            }
          },
          businesses: offer.businesses,
          customers: offer.customers,
          segments: offer.segments,
          // conditions: [
          //   {
          //     source: offer.conditions[0].source,
          //     is_active: offer.conditions[0].is_active,
          //     item_conditions: {
          //       // required_product_condition: // TODO why not this
          //       //   offer.conditions[0].item_conditions.required_product_condition,
          //       required_products: offer.conditions[0].item_conditions
          //         .required_products
          //         ? offer.conditions[0].item_conditions.required_products
          //         : [],
          //       required_variants: offer.conditions[0].item_conditions
          //         .required_variants
          //         ? offer.conditions[0].item_conditions.required_variants
          //         : [],
          //       eligible_products:
          //         offer.conditions[0].item_conditions.eligible_products,
          //       eligible_variants:
          //         offer.conditions[0].item_conditions.eligible_variants
          //     }
          //   }
          // ]
          conditions: offer.conditions.map((condition, index) => {
            return {
              ...condition,
              item_conditions: {
                eligible_products: condition.item_conditions.eligible_products,
                eligible_variants: condition.item_conditions.eligible_variants,
                required_products: condition.item_conditions.required_products,
                required_variants: condition.item_conditions.required_variants
              }
            };
          })
        };
        if (offer.offer_details.template_values.expiry_date) {
          newOfferStructure.offer_details.template_values.expiry_date = moment(
            offer.offer_details.template_values.expiry_date,
            'YYYY-MM-DD HH:mm:ss'
          ).format('YYYY-MM-DD');
        }
      } else {
        newOfferStructure = {
          offer_details: {
            title: offer.offer_details.title,
            auto_redeem: offer.offer_details.auto_redeem,
            description: offer.offer_details.description,
            details: offer.offer_details.details,
            image_url: offer.offer_details.image_url,
            is_active: offer.offer_details.is_active,
            label: offer.offer_details.label,
            reusable: offer.offer_details.reusable,
            subtitle: offer.offer_details.subtitle,
            template_name: offer.offer_details.template_name,
            template_values: {
              //TODO loalty tier not being used
              apply_multiple:
                offer.offer_details.template_values.apply_multiple,

              max_discount: Number(
                offer.offer_details.template_values.max_discount
              ),
              points_cost: Number(
                offer.offer_details.template_values.points_cost
              ) // set to zero if not being used
            }
          },
          businesses: offer.businesses,
          customers: offer.customers,
          segments: offer.segments,
          // conditions: [
          //   {
          //     source: offer.conditions[0].source,
          //     is_active: offer.conditions[0].is_active,

          //     item_conditions: {
          //       // required_product_condition: // TODO why not this
          //       //   offer.conditions[0].item_conditions.required_product_condition,
          //       required_products: offer.conditions[0].item_conditions
          //         .required_products
          //         ? offer.conditions[0].item_conditions.required_products
          //         : [],
          //       required_variants: offer.conditions[0].item_conditions
          //         .required_variants
          //         ? offer.conditions[0].item_conditions.required_variants
          //         : [],
          //       eligible_products:
          //         offer.conditions[0].item_conditions.eligible_products,
          //       eligible_variants:
          //         offer.conditions[0].item_conditions.eligible_variants
          //     }
          //   }
          // ]
          conditions: offer.conditions.map((condition, index) => {
            return {
              ...condition,
              item_conditions: {
                eligible_products: condition.item_conditions.eligible_products,
                eligible_variants: condition.item_conditions.eligible_variants,
                required_products: condition.item_conditions.required_products,
                required_variants: condition.item_conditions.required_variants
              }
            };
          })
        };
        if (offer.offer_details.template_values.expiry_date) {
          newOfferStructure.offer_details.template_values.expiry_date = moment(
            offer.offer_details.template_values.expiry_date,
            'YYYY-MM-DD HH:mm:ss'
          ).format('YYYY-MM-DD');
        }
      }
      return newOfferStructure;
    }
    case 'birthday-free-item': {
      newOfferStructure = {
        offer_details: {
          title: offer.offer_details.title,
          auto_redeem: offer.offer_details.auto_redeem,
          description: offer.offer_details.description,
          details: offer.offer_details.details,
          image_url: offer.offer_details.image_url,
          is_active: offer.offer_details.is_active,
          label: offer.offer_details.label,
          reusable: offer.offer_details.reusable,
          subtitle: offer.offer_details.subtitle,
          template_name: offer.offer_details.template_name,
          template_values: {
            //TODO loyalty tier
            days_available: Number(
              offer.offer_details.template_values.days_available
            ),
            max_discount: offer.offer_details.template_values.max_discount
          }
        },
        businesses: offer.businesses,
        customers: offer.customers,
        segments: offer.segments,
        // conditions: [
        //   {
        //     source: offer.conditions[0].source,
        //     is_active: offer.conditions[0].is_active,
        //     item_conditions: {
        //       eligible_products:
        //         offer.conditions[0].item_conditions.eligible_products
        //     }
        //   }
        // ]
        conditions: offer.conditions.map((condition, index) => {
          return {
            ...condition,
            item_conditions: {
              eligible_products: condition.item_conditions.eligible_products
            }
          };
        })
      };

      return newOfferStructure;
    }
    case 'birthday-discounted-item': {
      newOfferStructure = {
        offer_details: {
          title: offer.offer_details.title,
          auto_redeem: offer.offer_details.auto_redeem,
          description: offer.offer_details.description,
          details: offer.offer_details.details,
          image_url: offer.offer_details.image_url,
          is_active: offer.offer_details.is_active,
          label: offer.offer_details.label,
          reusable: offer.offer_details.reusable,
          subtitle: offer.offer_details.subtitle,
          template_name: offer.offer_details.template_name,
          template_values: {
            //TODO loyalty
            discount: offer.offer_details.template_values.discount,
            discount_type: offer.offer_details.template_values.discount_type,
            days_available: Number(
              offer.offer_details.template_values.days_available
            )
          }
        },
        businesses: offer.businesses,
        customers: offer.customers,
        segments: offer.segments,
        // conditions: [
        //   {
        //     source: offer.conditions[0].source,
        //     is_active: offer.conditions[0].is_active,
        //     // sort_order: offer.conditions[0].item_conditions.sort_order,
        //     item_conditions: {
        //       discounted_products:
        //         offer.conditions[0].item_conditions.discounted_products,
        //       discounted_variants:
        //         offer.conditions[0].item_conditions.discounted_variants,
        //       required_products:
        //         offer.conditions[0].item_conditions.required_products,
        //       required_product_condition:
        //         offer.conditions[0].item_conditions.required_product_condition,
        //       required_variants:
        //         offer.conditions[0].item_conditions.required_variants
        //     }
        //   }
        // ]
        conditions: offer.conditions.map(condition => ({
          ...condition,
          item_conditions: { ...condition.item_conditions }
        }))
      };
      return newOfferStructure;
    }
    case 'carryover-loyalty': {
      newOfferStructure = {
        offer_details: {
          title: offer.offer_details.title,
          auto_redeem: offer.offer_details.auto_redeem,
          description: offer.offer_details.description,
          details: offer.offer_details.details,
          image_url: offer.offer_details.image_url,
          is_active: offer.offer_details.is_active,
          label: offer.offer_details.label,
          reusable: offer.offer_details.reusable,
          subtitle: offer.offer_details.subtitle,
          template_name: offer.offer_details.template_name,
          template_values: {
            total_amount: offer.offer_details.template_values.total_amount,
            points_cost: offer.offer_details.template_values.points_cost
          }
        },
        businesses: offer.businesses,
        customers: offer.customers,
        segments: offer.segments
      };

      return newOfferStructure;
    }
  }
};

export function updateOffersRequestParams(requestParams) {
  return {
    type: 'UPDATE_OFFERS_REQUEST_PARAMS',
    payload: requestParams
  };
}

export function updateSortedOffersList(offersList, sortOrder) {
  return {
    type: 'UPDATE_SORTED_OFFERS_LIST',
    offersList: offersList,
    sortOrder: sortOrder
  };
}


