import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../productsActions';
import ProductOptionsForm from '../components/ProductOptionsForm';

const ProductOptionsFormContainer = props => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [productOptionsAreEditable, setProductOptionsAreEditable] = useState(
    false
  );
  useEffect(() => {
    if (!props.productOptionsInitialValues.length) {
      setProductOptionsAreEditable(true);
    }
  }, [props.productOptionsInitialValues]);
  const [
    confirmCancelModalIsVisible,
    setConfirmCancelModalIsVisible
  ] = useState(false);
  return (
    <ProductOptionsForm
      isExpanded={isExpanded}
      disabled={!props.productId}
      setIsExpanded={setIsExpanded}
      initialValues={{productOptions: props.productOptionsInitialValues}}
      allOptions={props.allOptions}
      productOptionsAreEditable={productOptionsAreEditable}
      setProductOptionsAreEditable={setProductOptionsAreEditable}
      setConfirmCancelModalIsVisible={setConfirmCancelModalIsVisible}
      confirmCancelModalIsVisible={confirmCancelModalIsVisible}
      putProductOptions={props.putProductOptions}
    />
  );
};

const mapStateToProps = state => ({
  productOptionsInitialValues:
    state.productsReducer.productOptionsInitialValues,
  allOptions: state.productsReducer.allOptions,
  productId: state.productsReducer.productId
});

export default connect(
  mapStateToProps,
  actions
)(ProductOptionsFormContainer);
