import { api } from '../../api';
import {
  GET_APP_FEED_REQUEST,
  GET_APP_FEED_SUCCESS,
  CREATE_APP_FEED_REQUEST,
  CREATE_APP_FEED_SUCCESS,
  CREATE_APP_FEED_FAILURE,
  OPEN_CREATE_FEED_ROW_FORM,
  CANCEL_CREATE_FEED_ROW_FORM,
  SET_SORT_APP_FEED,
  UNDO_APP_FEED_CHANGES,
  OPEN_EDIT_FEED_ROW_FORM,
  CANCEL_EDIT_FEED_ROW_FORM,
  UPDATE_FEED_ROW,
  DELETE_FEED_ROW,
  OPEN_CREATE_CARD_FORM,
  CLOSE_CREATE_CARD_FORM,
  CREATE_CARD,
  SET_SORT_CARDS,
  OPEN_EDIT_CARD_FORM,
  CLOSE_EDIT_CARD_FORM,
  LIVE_EDIT_CARD,
  EDIT_CARD,
  DELETE_CARD,
  HIDE_FEED_ROW_FORM,
  RESET_APP_FEED_REDUCER_STATE
} from './appFeedTypes';
import { createAlert } from '../Alert/actions';

export function fetchAppFeed(id) {
  return function(dispatch) {
    dispatch({ type: GET_APP_FEED_REQUEST });
    return api.get(`/feed-template/${id}/feed`).then(
      response => {
        dispatch({ type: GET_APP_FEED_SUCCESS, payload: response.data });
      },
      error => {
        console.log(error);
      }
    );
  };
}

export function createAppFeed(appFeed) {
  const appFeedWithoutIds = appFeed.map(row => {
    return Object.keys(row).reduce((newObject, key) => {
      if (key !== 'id') {
        return { ...newObject, [key]: row[key], name: 'name' };
      }
      return newObject;
    }, {});
  });

  return appFeedWithoutIds.map((row, rowIndex) => {
    if (row.items.length) {
      return {
        ...row,
        position: rowIndex + 1,
        segments: row.segments.map(segment => {
          if (segment.segment_id) {
            return segment.segment_id;
          }
          return segment;
        }),
        tags: row.tags.map(tag => {
          if (tag.tag_id) {
            return tag.tag_id;
          }
          return tag;
        }),
        feed_type: row.feed_type === 'web_links' ? 'custom' : row.feed_type,
        items: row.items.map(item => {
          return {
            ...Object.keys(item).reduce((newItemObject, key) => {
              if (key !== 'id' && key !== 'feed_id' && key !== 'position') {
                return { ...newItemObject, [key]: item[key], name: 'name' };
              }
              return newItemObject;
            }, {}),
            is_visible: row.is_visible
          };
        })
      };
    }
    return {
      ...row,
      position: rowIndex + 1,
      segments: row.segments.map(segment => {
        if (segment.segment_id) {
          return segment.segment_id;
        }
        return segment;
      }),
      tags: row.tags.map(tag => {
        if (tag.tag_id) {
          return tag.tag_id;
        }
        return tag;
      })
    };
  });

  return function(dispatch) {
    // dispatch({ type: CREATE_APP_FEED_REQUEST });
    // return api.post('/feed', { feed: feedToPublish }).then(
    //   response => {
    //     dispatch({ type: CREATE_APP_FEED_SUCCESS, appFeed });
    //     dispatch(
    //       createAlert({
    //         type: 'success',
    //         message: 'App Feed successfully updated.'
    //       })
    //     );
    //   },
    //   error => {
    //     dispatch({
    //       type: CREATE_APP_FEED_FAILURE,
    //       error
    //     });
    //     dispatch(
    //       createAlert({
    //         type: 'error',
    //         message: 'An error occurred trying to update App Feed.'
    //       })
    //     );
    //   }
    // );
  };
}

export function openCreateFeedRowForm() {
  return function(dispatch, getState) {
    const initialFeedRow = {
      id: Math.random().toString(10),
      title: '',
      feed_type: 'custom',
      feed_style_type: 'single_card',
      list_action: '',
      is_visible: true,
      items: [
        { id: Math.random().toString(10), is_visible: true, card_action: '' }
      ],
      position: getState().appFeed.feedRows.length + 1,
      is_required: false,
      is_pinned: false,
      segments: [],
      tags: []
    };
    dispatch({
      type: OPEN_CREATE_FEED_ROW_FORM,
      initialFeedRow
    });
  };
}

export function cancelCreateFeedRowForm(feedRowId) {
  return {
    type: CANCEL_CREATE_FEED_ROW_FORM,
    feedRowId
  };
}

export function setSortAppFeed(newSortedAppFeed) {
  return {
    type: SET_SORT_APP_FEED,
    newSortedAppFeed
  };
}

export function undoAppFeedChanges() {
  return { type: UNDO_APP_FEED_CHANGES };
}

export function openEditFeedRowForm(feedRow) {
  return {
    type: OPEN_EDIT_FEED_ROW_FORM,
    feedRow
  };
}

export function cancelEditFeedRowForm() {
  return { type: CANCEL_EDIT_FEED_ROW_FORM };
}

export function updateFeedRow(feedRow) {
  const newItems = feedRow.items.map(
    (item, index) => (item = { ...item, position: index + 1 })
  );

  feedRow = { ...feedRow, items: newItems };

  return {
    type: UPDATE_FEED_ROW,
    feedRow
  };
}

export function deleteFeedRow(feedRow) {
  return {
    type: DELETE_FEED_ROW,
    feedRow
  };
}

export function openCreateCardForm() {
  return { type: OPEN_CREATE_CARD_FORM };
}

export function closeCreateCardForm() {
  return { type: CLOSE_CREATE_CARD_FORM };
}

export function createCard(params) {
  return { type: CREATE_CARD };
}

export function setSortCards(newSortedCards) {
  return {
    type: SET_SORT_CARDS,
    newSortedCards
  };
}

export function openEditCardForm(card) {
  return {
    type: OPEN_EDIT_CARD_FORM,
    card
  };
}

export function closeEditCardForm(originalCard) {
  return {
    type: CLOSE_EDIT_CARD_FORM,
    originalCard
  };
}

export function liveEditCard(card) {
  return {
    type: LIVE_EDIT_CARD,
    card
  };
}

export function editCard() {
  return { type: EDIT_CARD };
}

export function deleteCard(card) {
  return {
    type: DELETE_CARD,
    card
  };
}

export function hideFeedRowForm() {
  return {
    type: HIDE_FEED_ROW_FORM
  };
}

export function resetAppFeedReducerState() {
  return {
    type: RESET_APP_FEED_REDUCER_STATE
  };
}
