import React from 'react';
import styled from 'styled-components';
import {
  ContainerDiv,
  StyledP,
  GridWrapper,
} from '../utils/commonStyledComponents';
import Tooltip from '@material-ui/core/Tooltip';
import { TooltipIcon } from '../../../../components/Icon';

const StyledSpan = styled.span`
  font-size: ${(props) => (props.isTitle ? '18px' : 'initial')};
  font-weight: ${(props) => (props.semiBold ? '600' : 'initial')};
  padding: ${(props) => props.padding || '0px 0px 0px 0px'};
`;

export const BasicOrderInfo = (props) => {
  const {
    customerName,
    customerEmail,
    orderDate,
    orderTime,
    orderType,
    orderingFrom,
    businessAddress,
    businessName,
    orderId,
  } = props;
  return (
    <div style={{ padding: '0 0 70px 0' }}>
      <GridWrapper
        data-test="component-BasicOrderInfo"
        padding="0 0 30px 0"
        templateColumns="repeat(3, 1fr)"
      >
        <ContainerDiv>
          <StyledP semiBold>Customer Name</StyledP>
          <ContainerDiv padding="0 0 0 0px">
            <StyledP data-test="customerName">{customerName}</StyledP>
          </ContainerDiv>
        </ContainerDiv>
        <ContainerDiv>
          <StyledP semiBold>Customer Email</StyledP>
          <ContainerDiv padding={'0 0 0 0px'}>
            <StyledP data-test="customerEmail">{customerEmail}</StyledP>
          </ContainerDiv>
        </ContainerDiv>
        <ContainerDiv right>
          <StyledP semiBold>
            {'Order placed at: '}
            <StyledP data-test="orderDateTime">
              {orderDate} {orderTime}{' '}
              <Tooltip
                title="Time shown is in the store's timezone"
                placement="top"
                arrow
              >
                <span>
                  <TooltipIcon />
                </span>
              </Tooltip>
            </StyledP>
          </StyledP>
        </ContainerDiv>
      </GridWrapper>
      <GridWrapper templateColumns="repeat(3, 1fr)" padding="10px 0 0 0">
        <ContainerDiv>
          <StyledP size="16px" semiBold style={{ textTransform: 'capitalize' }}>
            {orderType}
          </StyledP>
          <div>{orderingFrom.line1}</div>
          <div>{orderingFrom.line2}</div>
          <div>{orderingFrom.line3}</div>
          <div>{orderingFrom.line4}</div>
        </ContainerDiv>
        <ContainerDiv>
          <StyledP size="16px" semiBold style={{ textTransform: 'capitalize' }}>
            Business Address ({businessName})
          </StyledP>
          <div>{businessAddress.line1}</div>
          <div>{businessAddress.line2}</div>
          <div>{businessAddress.line3}</div>
          <div>{businessAddress.line4}</div>
        </ContainerDiv>
        <ContainerDiv right>
          <StyledP size="16px" semiBold style={{ textTransform: 'capitalize' }}>
            Order Number
          </StyledP>
          <div>{orderId}</div>
        </ContainerDiv>
      </GridWrapper>
    </div>
  );
};

export default BasicOrderInfo;
