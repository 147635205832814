import React from 'react';
import styled from 'styled-components';
import Select from '../../../../components/Select/Select';

const Card = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 48px 51px;
  display: grid;
  grid-gap: 32px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
`;

const Title = styled.div`
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 24px;
`;

const Subtitle = styled.div`
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 16px;
`;

const SelectWrapper = styled.div`
  width: 280px;
`;

const Price = ({
  selectOption,
  selectVariants,
  options,
  variants,
  optionPrices,
  isRequestingItemOverrides
}) => {
  return (
    <Card>
      <div>
        <Title>Pricing Option Items</Title>
        <Subtitle>Please choose an option to price adjust</Subtitle>
        <SelectWrapper>
          <Select
            options={options}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            onChange={option => {
              selectOption(option);
            }}
            isDisabled={isRequestingItemOverrides}
          />
        </SelectWrapper>
      </div>
      <div>
        <Subtitle>Select the Variants the Option applies to</Subtitle>
        <SelectWrapper>
          <Select
            options={variants}
            isMulti
            getOptionLabel={variant => variant.name}
            getOptionValue={variant => variant.id}
            valueKey="id"
            onChange={variants => {
              selectVariants(variants);
            }}
          />
        </SelectWrapper>
      </div>
      <div>
        <Subtitle>Option Info:</Subtitle>
        <div>Min - {optionPrices ? optionPrices.minimum_pick : null}</div>
        <div>Max - {optionPrices ? optionPrices.maximum_pick : null}</div>
        <div>included - {optionPrices ? optionPrices.included_pick : null}</div>
        {optionPrices &&
          optionPrices.maximum_pick <= optionPrices.included_pick && (
            <div style={{ marginTop: '10px' }}>
              Since Max is less than or equal to included, prices won't reflect
              on the app
            </div>
          )}
      </div>
    </Card>
  );
};

export default Price;
