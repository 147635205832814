import React, { useState } from 'react';
import styled from 'styled-components';
import {
  TableWithButtonRow,
  TableWrapper,
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
} from '../../../../../components/Table/TableElements';
import Icon from '../../../../../components/Icon/Icon';
import PaginationControl from '../../../../../components/Pagination/PaginationControl';
import { Button } from '../../../../../components/Button/Button';
import { TooltipIcon } from '../../../../../components/Icon';
import { STRING, BOOLEAN, sortByTypes } from '../../../../../utils/sort/sort';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const TextSearch = styled.div``;

const PaginationWrapper = styled.div``;

const Wrapper = styled.div`
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  display: grid;
  // grid-gap: 20px;
`;

const TopDiv = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  justify-content: space-between;
  margin: 37px;
`;

const SortDiv = styled.div`
  padding: 0 40px 25px;
  text-align: right;
`;

const SortWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
`;

const styles = {
  switchBase: {
    transition: 'none',
  },
  switchBaseDefault: {
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
};

const CategoryList = ({
  categories,
  toggleCategory,
  handleCreateButtonClick,
  initiateEditCategory,
  handleCategoriesSearch,
  requestParams,
  allCategoriesLength,
  setIsSortModalOpen,
  searchString,
  handleSort,
  classes,
}) => {
  const renderHeaders = () => {
    return (
      <TrHead>
        <Th
          align="left"
          isSortable="true"
          onClick={() => {
            handleSort({
              value: 'name',
              sortMethod: sortByTypes,
              sortType: STRING,
            });
          }}
          value="name"
          sortingOption={requestParams.sorting_option}
        >
          Name
        </Th>
        <Th
          style={{ minWidth: '150px' }}
          align="left"
          isSortable="true"
          onClick={() => {
            handleSort({
              value: 'subLength',
              sortMethod: sortByTypes,
            });
          }}
          value="subLength"
          sortingOption={requestParams.sorting_option}
        >
          SubCategories
        </Th>
        <Th
          align="left"
          isSortable="true"
          onClick={() => {
            handleSort({
              value: 'is_active',
              sortMethod: sortByTypes,
              sortType: BOOLEAN,
            });
          }}
          value="is_active"
          sortingOption={requestParams.sorting_option}
        >
          Active
        </Th>
      </TrHead>
    );
  };

  const renderSubCategoryString = (category) => {
    if (category.sub_categories.length === 0) return 'No Subcategories';
    if (category.sub_categories.length === 1) return '1 Subcategory';
    else {
      return category.sub_categories.length + ' ' + 'Subcategories';
    }
  };

  const renderRows = () => {
    return categories.map((category, index) => (
      <Tr
        onClick={() => initiateEditCategory(category)}
        key={index}
        style={{ cursor: 'pointer' }}
      >
        <Td>{category.name}</Td>
        <Td>{renderSubCategoryString(category)}</Td>
        <Td>
          <Switch
            checked={category.is_active}
            onClick={(e) => e.stopPropagation()}
            onChange={() => toggleCategory(category, !category.is_active)}
            // classes={{
            //   switchBase: stringTest
            //     ? classes.switchBase
            //     : classes.switchBaseDefault
            //   // classes.switchBase
            // }}
            value="checkedB"
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </Td>
      </Tr>
    ));
  };
  // render rows end

  return (
    <Wrapper>
      <TopDiv>
        <TextSearch>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon className="fal fa-search" />
                </InputAdornment>
              ),
            }}
            style={{ width: '100%' }}
            variant="outlined"
            type="text"
            onChange={(e) => {
              handleCategoriesSearch(e.target.value), setStringTest(true);
            }}
            placeholder="Search categories"
          />
        </TextSearch>
        <Button
          onClick={() => handleCreateButtonClick()}
          style={{ width: '100%' }}
        >
          Create a Category
        </Button>
      </TopDiv>
      {categories.length > 1 && (
        <SortDiv>
          <SortWrapper onClick={() => setIsSortModalOpen(true)}>
            <i className="far fa-sort-amount-down" /> Reorder Categories
            <Tooltip
              style={{ marginRight: 'auto' }}
              title="Edit the position of categories to change the order that they are presented in to the user"
            >
              <span>
                <TooltipIcon marginLeft />
              </span>
            </Tooltip>
          </SortWrapper>
        </SortDiv>
      )}
      <TableWithButtonRow>
        <TableWrapper noShadow>
          <Table noBorder>
            <thead>{renderHeaders()}</thead>
            <TBody striped>{renderRows()}</TBody>
          </Table>
        </TableWrapper>
      </TableWithButtonRow>
      <PaginationWrapper>
        <PaginationControl
          response={{
            ...categories,
            page: requestParams.page_number,
            page_size: requestParams.page_size,
            count: allCategoriesLength,
          }}
          requestParams={requestParams}
          type="categories"
        />
      </PaginationWrapper>
    </Wrapper>
  );
};

export default withStyles(styles)(CategoryList);
