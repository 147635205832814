import {
  REQUEST_REPORT_DATA,
  SET_REPORT_DATA,
  UPDATE_REPORT_INITIAL_PARAMS,
  CACHE_REPORT_DATA,
  SET_CACHE_DATA,
  RESET_REPORT_TABLE_REDUCER,
} from './ReportTableTypes';
import { createAlert } from '../Alert/actions';
import toPriceFormat from '../../utils/toPriceFormat';
import Moment from 'moment';
import api from '../../api';
import renderQueryString from '../../utils/renderQueryString';

async function callAPI(url, params, columnOrder) {
  return await api
    .get(`${url}${renderQueryString(params)}`)
    .then((response) => {
      const payload = {
        rows: response.data.data,
        columnNames: columnOrder ? columnOrder : [],
        columns:
          response.data.data.length > 0
            ? Object.keys(response.data.data[0])
            : [],
        meta: response.data.meta ? response.data.meta : null,
        initialParams: params,
      };
      return payload;
    })
    .catch((error) => {
      return {
        error: error,
      };
    });
}

function applyCachePolicies(dispatch, props, cacheId, payload) {
  const NUMBER_OF_ENTRIES_TO_DELETE = 5;

  const cacheLength = Object.keys(props.tableReport.cache).length;

  if (cacheLength >= 30) {
    const cache = { ...props.tableReport.cache };

    for (let i = 0; i <= NUMBER_OF_ENTRIES_TO_DELETE; i++) {
      delete cache[Object.keys(cache).slice(0)[0]];
    }

    dispatch({ type: SET_CACHE_DATA, cache });
    dispatch({ type: CACHE_REPORT_DATA, key: cacheId, payload });
  } else {
    dispatch({ type: CACHE_REPORT_DATA, key: cacheId, payload });
  }
}

function cacheFutureData(dispatch, props, report, url, params, columnOrder) {
  const FUTURE_PAGES_NUMBER = 5;

  for (let i = 1; i <= FUTURE_PAGES_NUMBER; i++) {
    const currentPage = params.page_number + i;
    const modifiedParams = {
      ...params,
      page_number: currentPage,
    };

    const cacheId = `${report}-${currentPage}-${
      params.start_date ? params.start_date : params.start_datetime
    }-${params.end_date ? params.end_date : params.end_datetime}-${
      params.sorting_option ? params.sorting_option : params.sort_by
    }-${params.sort_direction}-${params.page_size}-${
      params.business_ids ? params.business_ids.join(',') : null
    }`;

    if (!props.tableReport.cache[cacheId]) {
      callAPI(url, modifiedParams, columnOrder).then((payload) => {
        if (!Object.keys(payload).includes('error')) {
          applyCachePolicies(dispatch, props, cacheId, payload);
        }
      });
    }
  }
}

export function getAPIData(props, report, url, params, columnOrder) {
  const cacheId = `${report}-${params.page_number}-${
    params.start_date ? params.start_date : params.start_datetime
  }-${params.end_date ? params.end_date : params.end_datetime}-${
    params.sorting_option ? params.sorting_option : params.sort_by
  }-${params.sort_direction}-${params.page_size}-${
    params.business_ids ? params.business_ids.join(',') : null
  }`;

  return function (dispatch) {
    dispatch({ type: REQUEST_REPORT_DATA });

    if (props.tableReport.cache[cacheId]) {
      const payload = props.tableReport.cache[cacheId];
      dispatch({ type: SET_REPORT_DATA, payload });
    } else {
      callAPI(url, params, columnOrder).then((payload) => {
        if (!Object.keys(payload).includes('error')) {
          dispatch({ type: SET_REPORT_DATA, payload });
          applyCachePolicies(dispatch, props, cacheId, payload);
          cacheFutureData(dispatch, props, report, url, params, columnOrder);
        } else {
          dispatch(
            createAlert({
              type: 'error',
              message: 'An error occurred trying to get report data.',
            })
          );
        }
      });
    }
  };
}

export function updateParametersAndCallAPI(
  props,
  report,
  url,
  params,
  columnOrder
) {
  const cacheId = `${report}-${params.page_number}-${
    params.start_date ? params.start_date : params.start_datetime
  }-${params.end_date ? params.end_date : params.end_datetime}-${
    params.sorting_option ? params.sorting_option : params.sort_by
  }-${params.sort_direction}-${params.page_size}-${
    params.business_ids ? params.business_ids.join(',') : null
  }`;

  return function (dispatch) {
    dispatch({ type: UPDATE_REPORT_INITIAL_PARAMS, params });
    dispatch({ type: REQUEST_REPORT_DATA });

    if (props.tableReport.cache[cacheId]) {
      const payload = props.tableReport.cache[cacheId];
      dispatch({ type: SET_REPORT_DATA, payload });
    } else {
      callAPI(url, params, columnOrder).then((payload) => {
        if (!Object.keys(payload).includes('error')) {
          dispatch({ type: SET_REPORT_DATA, payload });
          applyCachePolicies(dispatch, props, cacheId, payload);
          cacheFutureData(dispatch, props, report, url, params, columnOrder);
        } else {
          dispatch(
            createAlert({
              type: 'error',
              message: 'An error occurred trying to get report data.',
            })
          );
        }
      });
    }
  };
}

export function inferTypeAndTransform(key, data) {
  if (/percent/.test(key)) {
    return `${data}%`;
  } else if (/points/.test(key)) {
    return data;
  } else if (/date/.test(key)) {
    if (/time/.test(key)) {
      const time = data.split(' ')[1];
      return `${Moment(data).format('MM/DD/YY')} ${Moment(time, 'hh:mm').format(
        'hh:mm a'
      )}`;
    }
    return `${Moment(data).format('MM/DD/YY')}`;
  } else if (/num/.test(key)) {
    return data;
  } else if (
    /subtotal|funds|tip|paid|discount|credits|fees|tax|expense|sales|gross|net|revenue|price|basket_size/.test(
      key
    )
  ) {
    return toPriceFormat(data)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return data;
}

export function resetReportTableReducer() {
  return function (dispatch) {
    dispatch({ type: RESET_REPORT_TABLE_REDUCER });
  };
}
