import React from 'react';
import styled from 'styled-components';
import {ContainerDiv, StyledP} from '../../../common/commonElements';
import SpecialHoursTable from './SpecialHoursTable';
import DeleteSpecialHoursModal from './DeleteSpecialHoursModal';
import SpecialHoursForm from './SpecialHoursForm';

const TitleAndAddButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const SubTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
`;

const AddSpecialHoursButton = styled(StyledP)`
  font-weight: 600;
  margin-right: 16px;
  cursor: pointer;
  color: #296bde;
`;

const SpecialHoursTableContainer = styled.div`
  padding: 20px 0;
`;

const SpecialHours = props => {
  const {
    formValues,
    handleSpecialHoursDeleteIconClick,
    isDeleteSpecialHoursConfirmModalOpen,
    isDeleteSpecialHoursRequesting,
    handleSpecialHoursCancelClick,
    handleSpecialHoursModalConfirmClick,
    handleOpenSpecialHoursForm,
    isCreateAndEditSpecialHoursModalOpen,
    handleCloseSpecialHoursForm,
    specialHoursToEdit,
    handleSpecialHoursFormSubmit,
    businessLocationId,
    isFormSubmitting,
    locationName
  } = props;


  return (
    formValues.specialHours.length > 0 ?
    <ContainerDiv>
      <TitleAndAddButton>
        <SubTitle>Special Hours</SubTitle>
        <AddSpecialHoursButton onClick={() => handleOpenSpecialHoursForm(null)}>
          + Create Special Hours
        </AddSpecialHoursButton>
      </TitleAndAddButton>
      <SpecialHoursTableContainer>
        <SpecialHoursTable
          formValues={formValues}
          handleSpecialHoursDeleteIconClick={handleSpecialHoursDeleteIconClick}
          handleOpenSpecialHoursForm={handleOpenSpecialHoursForm}
        />
      </SpecialHoursTableContainer>
      {isDeleteSpecialHoursConfirmModalOpen ? (
        <DeleteSpecialHoursModal
          handleSpecialHoursCancelClick={handleSpecialHoursCancelClick}
          handleSpecialHoursModalConfirmClick={
            handleSpecialHoursModalConfirmClick
          }
          isDeleteSpecialHoursRequesting={isDeleteSpecialHoursRequesting}
        />
      ) : null}
      {isCreateAndEditSpecialHoursModalOpen ? (
        <SpecialHoursForm
          handleCloseSpecialHoursForm={handleCloseSpecialHoursForm}
          specialHoursToEdit={specialHoursToEdit}
          formValues={formValues}
          specialHours={formValues.specialHours}
          handleSpecialHoursFormSubmit={handleSpecialHoursFormSubmit}
          businessLocationId={businessLocationId}
          isFormSubmitting={isFormSubmitting}
          locationName={locationName}
        />
      ) : null}
    </ContainerDiv>
    : 
    <ContainerDiv>
      <div
        style={{margin: '20px 40px'}} 
        onClick={() => handleOpenSpecialHoursForm(null)}>
        <p 
          style={{color: '#2440ae', fontSize:'15px', cursor: 'pointer', fontWeight: 600}}>
          + Add Special Hours 
        </p>
      </div>
      {isCreateAndEditSpecialHoursModalOpen ? (
        <SpecialHoursForm
          handleCloseSpecialHoursForm={handleCloseSpecialHoursForm}
          specialHoursToEdit={specialHoursToEdit}
          formValues={formValues}
          specialHours={formValues.specialHours}
          handleSpecialHoursFormSubmit={handleSpecialHoursFormSubmit}
          businessLocationId={businessLocationId}
          isFormSubmitting={isFormSubmitting}
          locationName={locationName}
        />
      ) : null}
    </ContainerDiv>  
  );
};

export default SpecialHours;
