import api from '../../../api';
import {
  GET_BUSINESS_LOCATIONS_OF_MERCHANT_REQUEST,
  GET_BUSINESS_LOCATIONS_OF_MERCHANT_SUCCESS,
  GET_BUSINESS_LOCATIONS_OF_MERCHANT_FAILURE,
  FILTER_BUSINESS_LOCATIONS_OF_MERCHANT_WITH_SELECTED_BUSINESS
} from './types';

function getBusinessLocationsOfMerchantRequest() {
  return {
    type: GET_BUSINESS_LOCATIONS_OF_MERCHANT_REQUEST
  };
}

export function getBusinessLocationsOfMerchant() {
  return function(dispatch) {
    dispatch(getBusinessLocationsOfMerchantRequest());
    return api
      .get(`/businesses`)
      .then(response => {
        dispatch({
          type: GET_BUSINESS_LOCATIONS_OF_MERCHANT_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        // console.log(error);
      });
  };
}

export function filterBusinessLocationsOfMerchantWithQuery(selectedBusiness) {
  return function(dispatch) {
    dispatch({
      type: FILTER_BUSINESS_LOCATIONS_OF_MERCHANT_WITH_SELECTED_BUSINESS,
      payload: selectedBusiness
    });
  };
}
