import React, {Fragment} from 'react';
import styled from 'styled-components';

import { Field } from 'formik';
import {
  RenderTextField,
  required,
  RenderSelect,
  RenderToggleSwitch,
  RenderDateTimePicker
} from '../../../../../components/FormElements/FormikElements';
import {StyledLabel} from '../../../../../components/FormElements/FormElements';
import CustomerSelect from '../../components/CustomerSelect';

const FieldWrapper = styled.div`
  grid-column: span ${props => props.col};
`;

const RecipientsForm = ({values, 
    setFieldValue, 
    allSegments, 
    selectedSegments, 
    setSelectedSegments,
    customersFromSelectedSegments,
    searchLoading,
    handleCustomerSearchChange,
    selectedRecipientsDetails,
    handleRemoveRecipient,
    handleAddRecipient,
    handleAddAllRecipient,
    handleRemoveAllRecipient,
    requestingGetCustomerDetail,
    filterCustomers,
    setIsEditingCustomers,
 }) => {

  return (
    <Fragment>
      
        <FieldWrapper col={4}>
            <StyledLabel>Choose Recipients</StyledLabel>
        </FieldWrapper>
        <FieldWrapper col={4}>
        <Field
            checked={values.allUsers.length > 0 ? false : true}
            label="All Users"
            component={RenderToggleSwitch}
            onChange={e => {
              if(e.target.checked){
                setFieldValue('allUsers', []);
                setFieldValue('customers', []);
                setIsEditingCustomers(true);
              }else{
                setFieldValue('allUsers', 'select users');
                setIsEditingCustomers(true);
              }
            }}
        />
        </FieldWrapper>
        { values.allUsers.length > 0 &&
        <Fragment>
            <FieldWrapper col={4}>
            <StyledLabel>
                Start typing the name of a segment or the name/email of an
                individual user to add them as recipients
            </StyledLabel>
            </FieldWrapper>
            <FieldWrapper col={1}>
            <Field
                isMulti
                name='selectedSegments'
                component={RenderSelect}
                getOptionValue={segment => segment.segment_id}
                getOptionLabel={segment => segment.name}
                valueKey='segment_id'
                placeholder='Select Segment(s)'
                options={allSegments ? allSegments : []}
                getValueOnChange={value => {setSelectedSegments(value); setIsEditingCustomers(true)} }
            />
            </FieldWrapper>
            <FieldWrapper style={{gridColumn: '1/ span 4'}}>
              <CustomerSelect
                handleCustomerSearchChange={handleCustomerSearchChange}
                handleRemoveRecipient={handleRemoveRecipient}
                handleAddRecipient={handleAddRecipient}
                handleAddAllRecipient={handleAddAllRecipient}
                selectedRecipientsDetails={selectedRecipientsDetails}
                handleRemoveAllRecipient={handleRemoveAllRecipient}
                customersFromSelectedSegments={filterCustomers(customersFromSelectedSegments).next().value}
                requestingGetCustomerDetail={requestingGetCustomerDetail}
                loading={searchLoading}
                setIsEditingCustomers={setIsEditingCustomers}
              />
            </FieldWrapper>
        </Fragment>
        }
            
    </Fragment>
  );
}

export default RecipientsForm;