import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import {
  RenderTextField,
  RenderTimePicker,
  RenderSelect,
  RenderRadioGroup,
} from '../../../../../components/FormElements/FormikElements';
import { Button, ButtonRow } from '../../../../../components/Button/Button';
import ChooseImage from '../../../../MediaModal/components/ChooseImage';
import {
  PageTitle,
  BackText,
  Card,
  MainWrapper,
  TitleDiv,
  Title,
  Description,
  MessageDiv,
  ImageDiv,
  MessageAction,
  MessageTiming,
  PreviewDiv,
  SectionTitle,
  TimeWrapper,
} from '../styles/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import TabsComponent from './TabsComponent';
import GenericDetailsTemplate from './GenericDetailsTemplate';

const AutomatedMessagesDetails = ({
  details,
  setDetailView,
  saveMessage,
  savingIds,
  appIdentifier,
}) => {
  return (
    <div>
      <BackText
        onClick={() => {
          setDetailView(null);
        }}
      >
        <i className="fa fa-chevron-left" />
        <div>Back To Automated Messages</div>
      </BackText>
      <PageTitle>{details.title}</PageTitle>
      <div>
        {details.data.map((data, i) => (
          <GenericDetailsTemplate
            key={i}
            data={data}
            saveMessage={saveMessage}
            isSavingDisabled={savingIds.some((id) => id === data.id)}
            appIdentifier={appIdentifier}
          />
        ))}
      </div>
    </div>
  );
};

export default AutomatedMessagesDetails;
