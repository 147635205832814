import React from 'react';
import styled from 'styled-components';
import TransactionDetailsTable from './TransactionDetailsTable';
import BatchReportHeader from './BatchReportHeader';
import ExportCSVButton from './ExportCSVButton';
import { SmallToggleSwitch } from '../../../../components/ToggleSwitch/ToggleSwitch';
import { BatchDetailPage } from '../../../../components/FormElements/FormElements';

const ReportBox = styled.div`
  padding: 15px;
  background: #f0f0f0;
  border: 1px solid lightgray;
  margin-bottom: 8px;
  @media print {
    margin-bottom: 4px;
    font-size: 14px;
    padding: 5px 15px;
  }
`;

const Report = styled.div`
  background: ${props => props.theme.white};
  padding: 16px 16px 8px 16px;
  font-size: 14px;
`;

const StyledP = styled.p`
  margin: 0;
  word-break: break-word;
  @media print {
    font-size: 14px;
    padding: ${props => props.printPadding || 'inherit'};
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'space-between'};
`;

const ReceiptRow = Row.extend`
  font-weight: 700;
  margin: 8px 0;
  color: ${props => (props.red ? props.theme.red : props.theme.black)};
  @media print {
    margin: 4px 0;
  }
`;

const SubReceiptRowHeader = ReceiptRow.extend`
  margin-bottom: 0;
`;

const SubReceiptRow = ReceiptRow.extend`
  padding-left: 16px;
  font-weight: 500;
  margin: 0;
`;
// This will be removed and imported once Order History branch is merged
const toPriceFormat = intPrice => {
  return new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: 'USD'
  }).format(Math.abs(intPrice));
};

const BatchDetail = ({
  batchDetail,
  requestParams,
  exportCSV,
  requestingCSV,
  detailsAreHidden,

  toggleDetailsAreHidden
}) => {
  const batch = batchDetail.summary_data;
  const transactions = batchDetail.transaction_details;
  const businessPluralOrSingular =
    batch.businesses_included.length > 1 ? 'businesses' : 'business';
  const businessString =
    batch.businesses_included.length > 1
      ? 'see next page for list of businesses'
      : 'see next page for business';

  return (
    <div>
      <BatchDetailPage>
        <div style={{ display: 'flex' }}>
          <SmallToggleSwitch
            value={detailsAreHidden}
            label="Hide Details"
            onChange={() => toggleDetailsAreHidden()}
          />
          <ExportCSVButton
            exportCSV={exportCSV}
            requestParams={requestParams}
            requestingCSV={requestingCSV}
          />
        </div>
        <Report>
          <BatchReportHeader
            detailsAreHidden={detailsAreHidden}
            title="Batch Report"
            batchDate={batch.date}
            batchReportBusinessName={batch.report_business_name}
            batchSettlementId={batch.settlement_id}
            batchReportBusinessAddress={batch.report_business_address}
            batchAmount={batch.batch_amount}
            batchBusinessesIncluded={batch.businesses_included}
            businessPluralOrSingular={businessPluralOrSingular}
            businessString={businessString}
            batchTransactionPeriodBegin={batch.transaction_period_begin}
            batchTransactionPeriodEnd={batch.transaction_period_end}
          />
          <ReportBox>
            <ReceiptRow>
              <div>Total Amount of Customer Receipts</div>
              <div>{toPriceFormat(batch.total_amount_receipts)}</div>
            </ReceiptRow>
            <ReceiptRow>
              <div>Tips</div>
              <div>{toPriceFormat(batch.tips)}</div>
            </ReceiptRow>
            <SubReceiptRowHeader>
              <div>Offers and Loyalty</div>
            </SubReceiptRowHeader>
            <SubReceiptRow>
              <div>Offers</div>
              <div>{toPriceFormat(batch.introductory_discount_spent)}</div>
            </SubReceiptRow>
            <SubReceiptRow>
              <div>Loyalty Rewards</div>
              <div>{toPriceFormat(batch.loyalty_rewards)}</div>
            </SubReceiptRow>
            <SubReceiptRow>
              <div>Head Office Credits</div>
              <div>{toPriceFormat(batch.whitelabel_credits_redeemed)}</div>
            </SubReceiptRow>
            <SubReceiptRow red>
              <div>Total Credits Applied</div>
              <div>{toPriceFormat(batch.total_credit_applied)}</div>
            </SubReceiptRow>
            <ReceiptRow>
              <div>Net Amount</div>
              <div>{toPriceFormat(batch.net_amount)}</div>
            </ReceiptRow>
          </ReportBox>

          <ReportBox>
            <SubReceiptRowHeader>
              <div>SmoothCommerce Fees</div>
            </SubReceiptRowHeader>
            <SubReceiptRow>
              <div>Processing</div>
              <div>{toPriceFormat(batch.fees_processing)}</div>
            </SubReceiptRow>
            <SubReceiptRow>
              <div>First Time User</div>
              <div>{toPriceFormat(batch.fees_first_time)}</div>
            </SubReceiptRow>
            <SubReceiptRow>
              <div>Loyalty</div>
              <div>{toPriceFormat(batch.fees_loyalty)}</div>
            </SubReceiptRow>
            <ReceiptRow red>
              <div>Total Fees</div>
              <div>{toPriceFormat(batch.total_fees)}</div>
            </ReceiptRow>
            <ReceiptRow red>
              <div>HST</div>
              <div>{toPriceFormat(batch.hst)}</div>
            </ReceiptRow>
          </ReportBox>

          <ReportBox>
            <ReceiptRow red>
              <div>Total Gift Card Transactions</div>
              <div>{toPriceFormat(batch.gift_card)}</div>
            </ReceiptRow>
            <ReceiptRow red>
              <div>Total Head Office Dollar Refunds</div>
              <div>{toPriceFormat(batch.total_amount_refund)}</div>
            </ReceiptRow>
            <ReceiptRow red>
              <div>SmoothCommerce Credit Refunds</div>
              <div>{toPriceFormat(batch.credits_refund)}</div>
            </ReceiptRow>
          </ReportBox>

          <ReportBox>
            <ReceiptRow>
              <div>Batch Amount</div>
              <div>{toPriceFormat(batch.batch_amount)}</div>
            </ReceiptRow>
          </ReportBox>

          <ReportBox>
            <SubReceiptRowHeader>
              <div>Head Office Credit Refunds</div>
            </SubReceiptRowHeader>
            <SubReceiptRow>
              <div>Offer Credits</div>
              <div>{toPriceFormat(batch.introductory_discount_refund)}</div>
            </SubReceiptRow>
            <SubReceiptRow>
              <div>Loyalty Rewards</div>
              <div>{toPriceFormat(batch.loyalty_rewards_refund)}</div>
            </SubReceiptRow>
            <SubReceiptRow>
              <div>Whitelabel Credits</div>
              <div>{toPriceFormat(batch.whitelabel_credits_refund)}</div>
            </SubReceiptRow>
            <ReceiptRow>
              <div>Total Head Office Credit Refunds</div>
              <div>{toPriceFormat(batch.total_credit_refund)}</div>
            </ReceiptRow>
            <ReceiptRow>
              <div>Total Head Office Gift Card Refunds</div>
              <div>{toPriceFormat(batch.gift_card_refund)}</div>
            </ReceiptRow>
          </ReportBox>

          <ReportBox>
            <ReceiptRow>
              <div>SmoothCommerce Credits Your Customers Redeemed</div>
              <div>{toPriceFormat(batch.credits_redeemed)}</div>
            </ReceiptRow>
          </ReportBox>
          {batch.settlement_id.length >= 94 ? (
            <StyledP printPadding="10px 0">
              Batch ID: {batch.settlement_id}
            </StyledP>
          ) : null}
        </Report>
      </BatchDetailPage>

      <TransactionDetailsTable
        transactions={transactions}
        batchDate={batch.date}
        batchSettlementId={batch.settlement_id}
        batchAmount={batch.batch_amount}
        businessesIncluded={batch.businesses_included}
        transactionPeriodBegin={batch.transaction_period_begin}
        transactionPeriodEnd={batch.transaction_period_end}
      />
    </div>
  );
};

export default BatchDetail;
