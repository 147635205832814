import {
  UPDATE_APP_DETAIL_CONFIG,
  GET_APP_DETAIL_CONFIG_SCHEMA,
  GET_APP_DETAIL,
  INITIATE_GET_APP_DETAILS
} from './AppDetailTypes';
import { api } from '../../api';
import { createAlert } from '../../modules/Alert/actions';

export function updateAppDetailConfiguration(appDetail, appDetailPatchSchema) {
  const { configuration } = appDetail;
  const appDetailconfigPatchFormat = constructAppDetailPatchData(
    appDetailPatchSchema,
    configuration
  );

  return dispatch => {
    const payload = { configuration: { ...appDetailconfigPatchFormat } };
    api
      .patch(`/app-details/${appDetail.platform_id}`, payload)
      .then(response => {
        dispatch({
          type: UPDATE_APP_DETAIL_CONFIG,
          appDetail: appDetail
        });
        dispatch(
          createAlert({
            type: 'success',
            message: `${appDetail.platform_name} configurations updated successfully`
          })
        );
      })
      .catch(error => console.log(error));
  };
}

const constructAppDetailPatchData = (patchSchema, configuration) => {
  let appDetailconfigPatchFormat = {};
  let copyConfig = { ...configuration };

  Object.keys(patchSchema).map(item => {
    const configsubObj = {};
    patchSchema[item].map(configKey => {
      configsubObj[configKey] = configuration[configKey];
      delete copyConfig[configKey];
    });
    appDetailconfigPatchFormat[item] = configsubObj;
  });

  appDetailconfigPatchFormat = { ...copyConfig, ...appDetailconfigPatchFormat };
  return appDetailconfigPatchFormat;
};

export function initiateGetAppDetails() {
  return dispatch => {
    dispatch({ type: INITIATE_GET_APP_DETAILS, requestingAppDetails: false });
  };
}

const constructAppDetailSchema = appDetailSchema => {
  let schemaReformatted = [];
  appDetailSchema.map(schemaItem => {
    switch (schemaItem.type) {
      case 'object':
        schemaItem.arguments.map(item => {
          schemaReformatted[item.name] = item.type;
        });
        break;
      default:
        schemaReformatted[schemaItem.name] = schemaItem.type;
        break;
    }
  });
  return schemaReformatted;
};

export function getAppDetailConfigSchema() {
  return function(dispatch) {
    api
      .get('/app-details/configuration')
      .then(response => {
        dispatch({
          type: GET_APP_DETAIL_CONFIG_SCHEMA,
          schema: constructAppDetailSchema(response.data.data)
        });
      })
      .catch(error => console.log(error));
  };
}

const constructAppDetails = appDatailsRaw => {
  let dataReformatted = {};
  appDatailsRaw.map(dataItem => {
    const { app_id, platform_id, platform_name, configuration } = dataItem;
    const configurationFlattened = appDetailConfigurationFlatten(configuration);
    dataReformatted[platform_name] = {
      app_id: app_id,
      platform_id: platform_id,
      platform_name: platform_name,
      configuration: configurationFlattened
    };
  });
  return dataReformatted;
};

const appDetailConfigurationFlatten = configuration => {
  let configurationFlattened = {};
  Object.keys(configuration).map(key => {
    if (
      !Array.isArray(configuration[key]) &&
      typeof configuration[key] === 'object'
    ) {
      const configurationObjectData = configuration[key];
      Object.keys(configurationObjectData).map(objectItemKey => {
        configurationFlattened[objectItemKey] =
          configurationObjectData[objectItemKey];
      });
    } else {
      configurationFlattened[key] = configuration[key];
    }
  });
  return configurationFlattened;
};

export function getAppDetails() {
  return function(dispatch) {
    api
      .get('/app-details?page_size=10&page_number=1')
      .then(response => {
        dispatch({
          type: GET_APP_DETAIL,
          appDetails: constructAppDetails(response.data.data)
        });
      })
      .catch(error => console.log(error));
  };
}
