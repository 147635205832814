export const GET_CUSTOMER_DETAILS_REQUEST = 'GET_CUSTOMER_DETAILS_REQUEST';
export const GET_CUSTOMER_DETAILS_SUCCESS = 'GET_CUSTOMER_DETAILS_SUCCESS';

export const SEND_REWARDS_MODAL_IS_OPEN = 'SEND_REWARDS_MODAL_IS_OPEN';
export const SEND_CREDITS_MODAL_IS_OPEN = 'SEND_CREDITS_MODAL_IS_OPEN';

export const POST_SEND_REWARDS_REQUEST = 'POST_SEND_REWARDS_REQUEST';
export const POST_SEND_REWARDS_SUCCESS = 'POST_SEND_REWARDS_SUCCESS';
export const POST_SEND_REWARDS_FAILURE = 'POST_SEND_REWARDS_FAILURE';

export const POST_SEND_CREDITS_REQUEST = 'POST_SEND_CREDITS_REQUEST';
export const POST_SEND_CREDITS_SUCCESS = 'POST_SEND_CREDITS_SUCCESS';
export const POST_SEND_CREDITS_FAILURE = 'POST_SEND_CREDITS_FAILURE';

export const ACCOUNT_ACTIVATION_CONFIRM_MODAL_TOGGLE_VISIBILITY =
  'ACCOUNT_ACTIVATION_CONFIRM_MODAL_TOGGLE_VISIBILITY';
export const PATCH_ACCOUNT_IS_ACTIVE_REQUEST =
  'PATCH_ACCOUNT_IS_ACTIVE_REQUEST';
export const PATCH_ACCOUNT_IS_ACTIVE_SUCCESS =
  'PATCH_ACCOUNT_IS_ACTIVE_SUCCESS';

export const ACCOUNT_EDIT_MODAL_TOGGLE_VISIBILITY =
  'ACCOUNT_EDIT_MODAL_TOGGLE_VISIBILITY';
export const PATCH_ACCOUNT_INFORMATION_REQUEST =
  'PATCH_ACCOUNT_INFORMATION_REQUEST';
export const PATCH_ACCOUNT_INFORMATION_SUCCESS =
  'PATCH_ACCOUNT_INFORMATION_SUCCESS';
export const PATCH_ACCOUNT_INFORMATION_FAILURE =
  'PATCH_ACCOUNT_INFORMATION_FAILURE';

export const GET_CUSTOMER_LOYALTY_INFORMATION_REQUEST =
  'GET_CUSTOMER_LOYALTY_INFORMATION_REQUEST';
export const GET_CUSTOMER_LOYALTY_INFORMATION_SUCCESS =
  'GET_CUSTOMER_LOYALTY_INFORMATION_SUCCESS';

export const GET_CUSTOMER_GLOBAL_CREDITS_REQUEST =
  'GET_CUSTOMER_GLOBAL_CREDITS_REQUEST';
export const GET_CUSTOMER_GLOBAL_CREDITS_SUCCESS =
  'GET_CUSTOMER_GLOBAL_CREDITS_SUCCESS';

export const RESET_CUSTOMER_DETAILS_REDUCER_STATE =
  'RESET_CUSTOMER_DETAILS_REDUCER_STATE';
