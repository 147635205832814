import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Formik, FieldArray, Field } from 'formik';
import { Button } from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import SelectLocationScreen from './SelectLocationScreen';
import LocationSummaryScreen from './LocationSummaryScreen';
import ConfirmCancelModal from '../../../MenuManagement/Products/ProductWizard/components/ConfirmCancelModal';
import { SELECT_LOCATION_SCREEN, SUMMARY_SCREEN } from '../priceTypes';
import ConfirmOverridesModal from '../../Products/ProductWizard/components/ConfirmOverridesModal';
import getExistingLocations from '../../utils/getExistingLocations';

const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: 10fr 1fr;
  padding: 24px;
`;

const LineDivider = styled.hr`
  border: solid 1px #e1e1e1;
`;

const ButtonsWrapper = styled.div`
  padding: 20px 40px;
  box-shadow: 0 -2px 9px 0 rgba(0, 0, 0, 0.12);
  display: grid;
  grid-template-columns: 3fr 1fr;
`;

const ModalBody = styled.div`
  padding: 0 24px;

  overflow-y: scroll;
  height: 60vh;
`;

const ButtonDiv = styled(Button)`
  width: 100px;
  min-width: 100px;
`;

const OptionOverridesModal = ({
  item,
  setIsOptionOverridesModal,
  formattedBusinesses,
  selectedOption,
  priceKey,
  selectedLocations,
  setSelectedLocations,
  singleItemOverrides,
  handleCreateOptionOverrides,
  page,
  setPage,
  summaryData,
  isPageEdit,
  setIsPageEdit,
  editingVariant,
  setEditingVariant,
  items,
  isConfirmModal,
  setIsConfirmModal,
  setSummaryData,
  isConfirmOverridesModal,
  setIsConfirmOverridesModal
}) => {
  const handleCancelButtonClick = (dirty, callBack) => {
    if (isPageEdit) {
      setIsConfirmModal(false);
      setPage(SUMMARY_SCREEN);
      setIsPageEdit(false);
    }
    if (!isPageEdit && page == SELECT_LOCATION_SCREEN) {
      setIsConfirmModal(false);
      setIsPageEdit(false);
      setSelectedLocations([]);
      callBack();
    }
    if (page === SUMMARY_SCREEN) {
      setIsConfirmModal(false);
      setIsOptionOverridesModal({
        isShowing: false,
        item: null,
        itemIndex: null
      });
    }
  };

  return (
    <Modal>
      <div
        style={{
          width: '550px'
        }}
      >
        <ModalTitle>
          <div>{item.name}</div>
          <div
            onClick={() =>
              setIsOptionOverridesModal({
                isShowing: false,
                item: null,
                itemIndex: null
              })
            }
            style={{ cursor: 'pointer' }}
          >
            x
          </div>
        </ModalTitle>
        <LineDivider />

        <Formik
          initialValues={{
            assignedBusinesses: editingVariant.assignedBusinesses.map(
              business => business.id
            ),
            item: editingVariant.item || {
              ...item,
              prices: item.prices.map(variant => ({
                product_variant_id: variant.variant_id,
                price: variant.price,
                is_active: true,
                is_available: true,
                additional_price: variant.additional_price
              }))
            }
          }}
          enableReinitialize={true}
        >
          {({
            dirty,
            values,
            setValues,
            setFieldValue,
            handleReset,
            initialValues,
            touched
          }) => (
            <Fragment>
              <ModalBody>
                {page === SELECT_LOCATION_SCREEN ? (
                  <SelectLocationScreen
                    formattedBusinesses={formattedBusinesses}
                    item={{
                      ...item,
                      prices: item.prices.filter(variant => variant.isSelected)
                    }}
                    selectedOption={selectedOption}
                    priceKey={priceKey}
                    selectedLocations={selectedLocations}
                    setSelectedLocations={setSelectedLocations}
                    setFieldValue={setFieldValue}
                    singleItemOverrides={singleItemOverrides}
                    isPageEdit={isPageEdit}
                    setIsPageEdit={setIsPageEdit}
                    editingVariant={editingVariant}
                    setEditingVariant={setEditingVariant}
                  />
                ) : (
                  <LocationSummaryScreen
                    setPage={setPage}
                    singleItemOverrides={singleItemOverrides}
                    formattedBusinesses={formattedBusinesses}
                    summaryData={summaryData}
                    setSummaryData={setSummaryData}
                    isPageEdit={isPageEdit}
                    setIsPageEdit={setIsPageEdit}
                    editingVariant={editingVariant}
                    setEditingVariant={setEditingVariant}
                    priceKey={priceKey}
                  />
                )}
              </ModalBody>
              <ButtonsWrapper>
                <ButtonDiv
                  secondary
                  onClick={() => {
                    if (dirty) setIsConfirmModal(true);
                    else if (isPageEdit) {
                      setPage(SUMMARY_SCREEN);
                      setIsPageEdit(false);
                    } else
                      setIsOptionOverridesModal({
                        isShowing: false,
                        item: null,
                        itemIndex: null
                      });
                  }}
                >
                  {selectedLocations.length ? 'Back' : 'Cancel'}
                </ButtonDiv>
                <ButtonDiv
                  onClick={() => {
                    if (
                      isPageEdit &&
                      editingVariant.assignedBusinesses.length <
                        values.assignedBusinesses.length
                    ) {
                      setIsConfirmOverridesModal(true);
                    } else {
                      handleCreateOptionOverrides({
                        selectedOption,
                        item,
                        values,
                        summaryData,
                        items,
                        editingVariant,
                        isPageEdit,
                        touched
                      });
                    }
                  }}
                  disabled={!dirty}
                >
                  Save
                </ButtonDiv>
              </ButtonsWrapper>

              {isConfirmModal && (
                <ConfirmCancelModal
                  onCancel={() => {
                    setIsConfirmModal(false);
                  }}
                  onConfirm={() => {
                    handleCancelButtonClick(dirty, () => handleReset());
                  }}
                />
              )}

              {isConfirmOverridesModal && (
                <ConfirmOverridesModal
                  businesses={getExistingLocations(
                    selectedLocations,
                    summaryData,
                    initialValues
                  )}
                  onCancel={() => setIsConfirmOverridesModal(false)}
                  onConfirm={() => {
                    handleCreateOptionOverrides({
                      selectedOption,
                      item,
                      values,
                      summaryData,
                      items,
                      editingVariant,
                      isPageEdit,
                      touched
                    });
                  }}
                />
              )}
            </Fragment>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default OptionOverridesModal;
