import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  initiateEditCombo,
  toggleCombo,
  initiateCreateCombo,
  updateCombosRequestParams,
  handleSortingComboSubmit,
  updateSortedCombosList,
} from '../combosAction';
import ComboList from './components/CombosList';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import { LoadingBlueLarge } from '../../../../components/Loading/Loading';
import CombosSortModal from './ComboSort/CombosSortModal';
import { sort } from '../../../../utils/sort/sort';

const Wrapper = styled.div`
  //width: 1200px;
  margin: 0 auto;
`;

let CombosListContainer = ({
  combosList,
  initiateEditCombo,
  toggleCombo,
  updateRequestParams,
  requestParams,
  businesses,
  meta,
  isRequestingGetCombos,
  initiateCreateCombo,
  updateCombosRequestParams,
  updateSortedCombosList,
  isSavingComboPositions,
  handleSortingComboSubmit,
  allInitialCombos,
}) => {
  const [searchString, setSearchString] = useState('');
  const handleCombosSearch = (searchString) => {
    updateCombosRequestParams({
      page_number: 1,
      page_size: requestParams.page_size,
    });
    setSearchString(searchString);
  };

  const handleSort = (sortArgs) => {
    let sortOrder = '';
    let order = '';
    const currentOptionAsArray = requestParams.sorting_option.split('-');

    if (currentOptionAsArray[0] === sortArgs.value) {
      order = currentOptionAsArray[1] === 'asc' ? 'desc' : 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    } else {
      order = 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    }

    if (combosList) {
      const result = sort(combosList, {
        ...sortArgs,
        sortOrder: `${order}`,
      });
      updateCombosRequestParams({
        page_number: 1,
        page_size: requestParams.page_size,
      });
      updateSortedCombosList(result, sortOrder);
    }
  };

  const sliceCombos = (combosList) => {
    const indexOfFirst =
      (requestParams.page_number - 1) * requestParams.page_size;
    const indexOfLast = requestParams.page_number * requestParams.page_size;

    return combosList
      .filter(
        (combo) =>
          combo.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1
      )
      .slice(indexOfFirst, indexOfLast);
  };

  const [combosActiveList, setcombosActiveList] = useState(
    sliceCombos(combosList)
  );

  useEffect(() => {
    setcombosActiveList(sliceCombos(combosList));
  }, [requestParams, combosList]);

  const [isSortModalOpen, setIsSortModalOpen] = useState(false);

  return (
    <Wrapper>
      <BreadCrumb pageName="Combos and Specials" />
      {isRequestingGetCombos ? (
        <LoadingBlueLarge />
      ) : (
        <Fragment>
          <ComboList
            combosActiveList={combosActiveList}
            initiateEditCombo={initiateEditCombo}
            combosLength={
              combosList.filter(
                (combo) =>
                  combo.name.toLowerCase().indexOf(searchString.toLowerCase()) >
                  -1
              ).length
            }
            combosList={combosList}
            toggleCombo={toggleCombo}
            meta={meta}
            handleCombosSearch={handleCombosSearch}
            requestParams={requestParams}
            updateRequestParams={updateRequestParams}
            businesses={businesses}
            initiateCreateCombo={initiateCreateCombo}
            setIsSortModalOpen={setIsSortModalOpen}
            handleSort={handleSort}
          />
          {isSortModalOpen && (
            <CombosSortModal
              combosList={combosList}
              setIsSortModalOpen={setIsSortModalOpen}
              isSavingComboPositions={isSavingComboPositions}
              handleSortingComboSubmit={handleSortingComboSubmit}
            />
          )}
        </Fragment>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  isRequestingGetCombos: state.combosReducer.isRequestingGetCombos,
  combosList: state.combosReducer.combosList,
  meta: state.combosReducer.meta,
  requestParams: state.combosReducer.requestParams,
  isSavingComboPositions: state.combosReducer.isSavingComboPositions,
  allInitialCombos: state.combosReducer.allInitialCombos,
});

export default connect(mapStateToProps, {
  initiateEditCombo,
  toggleCombo,
  initiateCreateCombo,
  updateCombosRequestParams,
  handleSortingComboSubmit,
  updateSortedCombosList,
})(CombosListContainer);
