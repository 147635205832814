import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';

import { Field, Formik } from 'formik';
import { Button } from '../../../../../../components/Button/Button';
import Card from '../../../../../../components/Card/Card';
import {
  RenderTextField,
  RenderDateTimePicker,
  RenderSelect,
  required,
  bulkValidator,
  urlValidator,
  requiredSelect
} from '../../../../../../components/FormElements/FormikElements';
import {
  FormGrid,
  FormRow
} from '../../../../../../components/FormElements/FormElements';

import moment from 'moment';
import BreadCrumb from '../../../../../../components/BreadCrumb/BreadCrumb';
import { SmallToggleSwitch } from '../../../../../../components/ToggleSwitch/ToggleSwitch';

import SendTestMessageModal from './SendTestMessageModal';
import BackButton from '../../../../../../components/BackButton';
import FinishButton from './FinishButton';
import ConfirmModal from '../../../../../../components/Modal/ConfirmModal';

const ReducedGapFormGrid = styled(FormGrid)`
  grid-row-gap: 30px;
`;
const BreadCrumbWrapper = styled.div`
  color: ${props => props.theme.darkBlue};
  margin: 30px;
  margin-left: 0;
  @media print {
    display: none;
  }
`;

const TimeWrapper = styled.div`
  display: grid;
  grid-template-columns: 300px;

  align-items: center;
  font-weight: 600;
  .rw-widget-input {
    border: 0.5px solid rgba(0, 0, 0, 0.24);
  }
  .rw-input {
    background-color: #fff;
  }
  .rw-select-bordered {
    background-color: #fff;
    border: 0.5px solid rgba(0, 0, 0, 0.24);
    border-left: none;
  }
`;
const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const MessageStats = styled.div`
  font-weight: 600;
`;

const actionUrlTypeOptions = [
  { value: 'webpage', label: 'Show a link' },
  { value: 'products', label: 'Show a product' },
  { value: 'offers', label: 'Show an offer' },
  { value: 'combos', label: 'Show a combo' },
  { value: 'open_app', label: 'Open app' }
];

const getMinDateTime = () => {
  const start = moment();
  const remainder = 15 - (start.minute() % 15);

  const dateTime = moment(start).add(remainder, 'minutes');

  return dateTime;
};

const mobileMessageDetails = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [testModalOpen, setTestModalOpen] = useState(false);
  const [isConfirmSendModalOpen, setIsConfirmSendModalOpen] = useState(false);
  const {
    initialFormValues,
    appIdentifier,
    allProducts,
    allSegments,
    allCombos,
    allOfferDetails
  } = props;

  const closeModal = () => {
    setTestModalOpen(false);
  };
  const handleSendTestMessage = (msg, userId) => {
    props.createTestMessage(msg, userId);
  };
  const renderTestButton = formProps => {
    const testButtonIsDisabled = !(
      formProps.values.message && formProps.values.name
    );
    return (
      <Button
        style={{
          width: '25%',
          margin: '0px 16px 16px 0px',
          justifySelf: 'end'
        }}
        type="button"
        tertiary
        disabled={testButtonIsDisabled}
        onClick={() => setTestModalOpen(true)}
      >
        Test
      </Button>
    );
  };

  const onFinishButtonClicked = (otherProps, formProps) => {
    if (formProps.values.send_now && !isConfirmSendModalOpen) {
      setIsConfirmSendModalOpen(true);
    } else {
      props.onhandleSubmit(otherProps, formProps);
      setIsConfirmSendModalOpen(false);
    }
  };

  const title = props.edit
    ? 'Edit Mobile Message'
    : props.read
    ? 'Read Only Mobile Message'
    : 'Create Mobile Message';

  return (
    <div className="animated fadeIn">
      <BreadCrumbWrapper>
        <BackButton
          pageName="Mobile Messages"
          onClick={() => props.cancelMobileMessage()}
        />
        <BreadCrumb
          style={{ margin: ' 0px' }}
          pageName={title} /* rootItem={rootItem} onClick={onClick} */
        />
      </BreadCrumbWrapper>

      <Formik initialValues={initialFormValues} enableReinitialize={true}>
        {formProps => (
          <Card>
            <ReducedGapFormGrid>
              <FormRow>
                <Field
                  name="name"
                  label="Internal Name*"
                  component={RenderTextField}
                  disabled={props.read}
                  validate={required}
                />
              </FormRow>
              <FormRow>
                <Field
                  name="segments"
                  component={RenderSelect}
                  isDisabled={props.read}
                  validate={requiredSelect}
                  isMulti
                  options={allSegments ? allSegments : []}
                  getOptionValue={segments => segments.segment_id}
                  getOptionLabel={segments => segments.name}
                  valueKey="segment_id"
                  placeholder="Select segments"
                />
              </FormRow>
              <Field
                label={`Enter your message below (160 characters or less)*`}
                name="message"
                component={RenderTextField}
                disabled={props.read}
                validate={required}
                rows={3}
                type="text"
              />
              <FormRow>
                <Field
                  name="messageActions"
                  component={RenderSelect}
                  options={actionUrlTypeOptions ? actionUrlTypeOptions : []}
                  isDisabled={props.read}
                  validate={requiredSelect}
                  getOptionValue={actions => actions.value}
                  getOptionLabel={actions => actions.label}
                  onChange={e => {
                    formProps.setFieldValue('messageSubActions', null);
                    formProps.setFieldValue('action_url', null);
                  }}
                  valueKey="value"
                  value={formProps.values.messageActions}
                  placeholder="Message Action"
                />
              </FormRow>
              <FormRow>
                {formProps.values.messageActions &&
                  formProps.values.messageActions == 'products' && (
                    <Field
                      name="messageSubActions"
                      component={RenderSelect}
                      isDisabled={props.read}
                      validate={requiredSelect}
                      options={allProducts ? allProducts : []}
                      getOptionValue={products => products.name}
                      getOptionLabel={products => products.name}
                      valueKey="name"
                      onChange={e => (
                        allProducts.find(product => product == e.value),
                        formProps.setFieldValue(
                          'action_url',
                          `${appIdentifier}://${formProps.values.messageActions}/${e.id}`
                        )
                      )}
                      placeholder="Search products by name..."
                    />
                  )}
                {formProps.values.messageActions &&
                  formProps.values.messageActions == 'combos' && (
                    <Field
                      name="messageSubActions"
                      component={RenderSelect}
                      isDisabled={props.read}
                      validate={requiredSelect}
                      options={allCombos ? allCombos : []}
                      getOptionValue={combo => combo.id}
                      getOptionLabel={combo => combo.name}
                      valueKey="name"
                      onChange={e => (
                        allCombos.find(combo => combo == e.value),
                        formProps.setFieldValue(
                          'action_url',
                          `${appIdentifier}://${formProps.values.messageActions}/${e.id}`
                        )
                      )}
                      placeholder="Search combos by name..."
                    />
                  )}
                {formProps.values.messageActions &&
                  formProps.values.messageActions == 'offers' && (
                    <Field
                      name="messageSubActions"
                      component={RenderSelect}
                      isDisabled={props.read}
                      validate={requiredSelect}
                      options={allOfferDetails ? allOfferDetails : []}
                      getOptionValue={offer => offer.offer_id}
                      getOptionLabel={offer => offer.title}
                      valueKey="title"
                      onChange={e => (
                        allOfferDetails.find(offer => offer == e.value),
                        formProps.setFieldValue(
                          'action_url',
                          `${appIdentifier}://${formProps.values.messageActions}/${e.offer_id}`
                        )
                      )}
                      placeholder="Search offers by name..."
                    />
                  )}
                {formProps.values.messageActions &&
                  formProps.values.messageActions == 'webpage' && (
                    <Field
                      name="messageSubActions"
                      component={RenderTextField}
                      disabled={props.read}
                      // validate={bulkValidator(required, urlValidator)}
                      validate={required}
                      type="url"
                      label="URL"
                      onChange={e =>
                        formProps.setFieldValue(
                          'action_url',
                          `${e.target.value}`
                        )
                      }
                    />
                  )}
              </FormRow>
              {props.read && (
                <Fragment>
                  <FormRow>
                    <div
                      style={{
                        fontSize: '1.4em',
                        fontWeight: '800',
                        textAlign: 'left',
                        margin: '0.2em 0.5em 0px 0px'
                      }}
                    >
                      Sent On :
                    </div>
                  </FormRow>
                  <FormRow>
                    <TimeWrapper>
                      <Field
                        disabled
                        name="send_at"
                        component={RenderDateTimePicker}
                        min={new Date(moment())}
                      />
                    </TimeWrapper>
                  </FormRow>
                </Fragment>
              )}
              {!props.read && (
                <div>
                  <FormRow>
                    <SmallToggleSwitch
                      name="send_now"
                      onChange={e => {
                        formProps.setFieldValue(
                          'send_now',
                          !formProps.values.send_now
                        );
                        formProps.setFieldValue(
                          'send_at',
                          getMinDateTime().format('YYYY-MM-DD HH:mm:ss')
                        );
                      }}
                      value="checkedB"
                      checked={formProps.values.send_now}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      label={formProps.values.send_now ? 'Send Now' : 'Send At'}
                    />
                  </FormRow>
                  <FormRow>
                    {!formProps.values.send_now && (
                      <TimeWrapper style={{ marginTop: '20px' }}>
                        <Field
                          name="send_at"
                          // defaultValue={getMinDateTime()}
                          component={RenderDateTimePicker}
                          min={new Date(getMinDateTime())}
                        />
                      </TimeWrapper>
                    )}
                  </FormRow>
                </div>
              )}
              <ButtonWrapper>
                <Button
                  style={{ textAlign: 'left' }}
                  type="button"
                  secondary
                  onClick={() => props.cancelMobileMessage()}
                >
                  {props.read ? 'Go Back' : 'Cancel'}
                </Button>
                {!props.read && renderTestButton(formProps)}
                {!props.read &&
                  FinishButton({ ...props, onFinishButtonClicked }, formProps)}
              </ButtonWrapper>
              {testModalOpen && (
                <SendTestMessageModal
                  closeModal={closeModal}
                  message={formProps.values}
                  handleSendTestMessage={handleSendTestMessage}
                />
              )}
            </ReducedGapFormGrid>
            {isConfirmSendModalOpen && (
              <ConfirmModal
                actionLabel="Send"
                confirmMessage="Your Mobile Message will go live 5 minutes from the moment you send it out."
                onClick={() => onFinishButtonClicked(props, formProps)}
                onClose={() => setIsConfirmSendModalOpen(false)}
              />
            )}
          </Card>
        )}
      </Formik>
    </div>
  );
};

export default mobileMessageDetails;
