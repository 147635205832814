import { injectGlobal } from 'styled-components';

const blue = '#2440ae';
//const darkBlue = '#223A5E';
const darkBlue = '#191818';
const checkBlue = '#3b70d6';
const lightGray = '#F8F8FA';
const blueGray = '#979eaa';
const gray = '#898E8C';
const darkGray = '#3A3D47';
//const paleGray = '#f3f7ff';
const paleGray = '#F1F1FA';
const offerBorder = '#545f72'; // colors for toppers '#15663D';
const offerPointsBox = '#40454f'; // colors for toppers '#314A44';
const offerAutoBox = '#AE0D1F';
const cerulean = '#0075db';
const brownGrey = '#959595';
const white = '#fff';
const black = '#000000';
const lightBlue = '#296bde';
const red = '#ff0000';
const tertiaryBlue = '#243167';
const theme = feedStyles => {
  return {
    tertiaryBlue,
    blueGray,
    checkBlue,
    red,
    lightBlue,
    white,
    black,
    brownGrey,
    cerulean,
    paleGray,
    offerBorder,
    offerPointsBox,
    offerAutoBox,
    blue,
    darkBlue,
    lightGray,
    gray,
    darkGray,
    gradient: 'linear-gradient(to right, #3D50DF, #00A5DB)',
    border: `1px solid lightgray`,
    borderBlack: `1px solid black`,
    borderBlue: `1px solid ${blue}`,
    tertiaryBorder: `2px solid ${blue}`,
    tertiaryBorderDisabled: `2px solid ${gray}`,
    feedStyles,
    emergBorder: `0px 0px 1px 0px solid ${blue}`
  };
};

injectGlobal`
  html, body {
    font-size: 16px;
    color: #191818;
    font-family: 'Montserrat', sans-serif;
  }

.ant-btn {
  background-color: white;
  color: black;
  :focus{
      background-color: white;
      color: darkBlue;
    }
  :hover{
    border: 1px solid darkBlue;
    color: darkBlue;
  }
}

/* this is the select */
.ant-select-selection.ant-select-selection--single{
  border: 1px solid lightGray;
  :hover{
    border: 1px solid darkBlue;
  }
  :focus{
    border: 1px solid darkBlue;
  }
}

/* Trash icon */
.ant-btn.ant-btn-danger.ant-btn-sm.ant-btn-icon-only{
color: black;
border: 1px solid lightGray;
  :hover{
    background-color: white;
    color: red;
  }
}

.ant-select {
  color: black;
}


.ant-select-focused{
  color: black;
}

/* Number input*/
.ant-input-number-sm{
  border: 1px solid lightGrey;
  :hover{
    border: 1px solid darkBlue;
  }
}

.ant-selection-selection {
  background-color: white;
  color: black;
  :focus{
      background-color: black;
    }
  :hover{
    border: 1px solid darkBlue;
    color: darkBlue;
  }

.ant-btn-primary{
  color: black; 
  :active{
    background-color: purple;
  }
}

  p {
    font-size: 16px;
  }
`;

export default theme;
