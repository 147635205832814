import React, { Fragment } from "react";
import styled from "styled-components";
import CustomerCreditsTable from "../components/CustomerCreditsTable";
import toPriceFormat from "../../../../utils/toPriceFormat";
import { Wrapper } from "../../../../components/FormElements/FormElements";

const Title = styled.div`
  font-weight: 600;
  color: ${props => props.theme.darkBlue};
  padding: 10px 0;
`;

const BalanceContainerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "a b c";
  grid-gap: 75px;
  @media screen and (max-width: 1232px) {
    grid-template-columns: 1fr;
    grid-template-areas: "a c";
  }
`;

const BalanceContainer = styled.div`
  grid-area: a;
`;

const SendReward = styled.div`
  color: ${props => props.theme.blue};
  font-weight: 600;
  padding: 10px 0;
  grid-area: c;
  cursor: pointer;
  margin-right: auto;
`;

const BalanceWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 10px;
  padding: 5px;
`;

const BalanceName = styled.div`
  font-size: 14px;
`;

const BalanceValue = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

const CustomerCredits = props => {
  const {
    toggleSendCreditsModal,
    currentCreditsBalance,
    lifetimeCreditsBalance,
    customerGlobalCredits
  } = props;
  return (
    <Fragment>
      <Wrapper>
        <Title data-test="title">Customer Credits</Title>
        <BalanceContainerWrapper>
          <BalanceContainer>
            <BalanceWrapper>
              <BalanceName data-test="label-currentCreditBalance">
                Current Credit Balance
              </BalanceName>
              <BalanceValue data-test="value-currentCreditBalance">
                {toPriceFormat(currentCreditsBalance)}
              </BalanceValue>
            </BalanceWrapper>
            <BalanceWrapper>
              <BalanceName data-test="label-lifetimeCreditAccured">
                Lifetime Credit Accrued
              </BalanceName>
              <BalanceValue data-test="value-lifetimeCreditAccured">
                {toPriceFormat(lifetimeCreditsBalance)}
              </BalanceValue>
            </BalanceWrapper>
          </BalanceContainer>
          <SendReward
            data-test="button-sendRewards"
            onClick={toggleSendCreditsModal}
          >
            + SEND CREDITS
          </SendReward>
        </BalanceContainerWrapper>
      </Wrapper>
      <CustomerCreditsTable
        customerGlobalCredits={customerGlobalCredits
          .slice(0, 10)
          .map(credit => {
            return {
              ...credit,
              initial_amount: toPriceFormat(credit.initial_amount)
            };
          })}
      />
    </Fragment>
  );
};

export default CustomerCredits;
