import React from 'react';
import styled from 'styled-components';
import {
  ContainerDiv,
  StyledP,
  GridWrapper
} from '../utils/commonStyledComponents';

import {selectedItemsToString} from '../utils/formatTransactionDetails';

const ProductImg = styled.img`
  width: 61px;
  height: 61px;
  background-color: #d3d3d3;
`;
const OptionWrapper = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.isForPreview ? '2fr 5fr' : '2fr 5fr'};
  grid-gap: 24px;
  padding: 5px 0;
  border-bottom: ${props =>
    props.isLastOption ? 'none' : `1px solid ${props.theme.lightGray}`};
  @media (max-width: 768px) {
    grid-template-columns: 64px 1fr;
  }
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.3px;
  text-align: right;
  line-height: 1.5;
  color: ${props => props.theme.black};
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;

  span {
    font-size: 13px;
    font-weight: 500;
  }
`;

export const ProductsOrdered = props => {
  const {products} = props;

  return (
    <ContainerDiv
      data-test="component-ProductsOrdered"
      padding={'15px 0px 0px 0px'}
    >
      <StyledP semiBold>{'Products Ordered'}</StyledP>
      {displayProducts(products)}
    </ContainerDiv>
  );
};

const displayProducts = products => {
  return products.map((product, index) => (
    <ContainerDiv
      data-test="component-product"
      margin="12px 0px 0px 0px"
      width="40%"
      key={index}
    >
      <GridWrapper margin="0 0 18px 0" templateColumns="2fr 5fr" gap="24px">
        <ContainerDiv right>
          <ProductImg src={product.imageUrl} />
        </ContainerDiv>
        <ContainerDiv margin="0px 0 0 0">
          <StyledP semiBold>{product.name}</StyledP>
          <GridWrapper templateColumns="repeat(2, 1fr)" margin="10px 0 0 0">
            {product.calories ? (
              <StyledP size="14px" semiBold>
                {product.calories} CALS
              </StyledP>
            ) : (
              <div />
            )}
            <StyledP size="14px" semiBold right>
              {product.price}
            </StyledP>
          </GridWrapper>
        </ContainerDiv>
      </GridWrapper>
      {product.options.map((option, index1) => {
        const isLastOption = product.options.length === index1 + 1;
        if (option.sub_options.length) {
          return option.sub_options.map((subOption, index) => {
            return (
              <OptionWrapper
                isLastOption={isLastOption}
                key={index}
                isForPreview={true}
              >
                <Label>
                  {subOption.name
                    .toLowerCase()
                    .replace(/\b\w/g, letter => letter.toUpperCase())
                    .replace(/X[0-9][0-9]*/g, str => str.toLowerCase())}
                </Label>
                <div>
                  {selectedItemsToString(subOption)
                    .split(', ')
                    .map((text, index) => {
                      return (
                        <Text key={index}>
                          {text
                            .toLowerCase()
                            .replace(/\b\w/g, letter => letter.toUpperCase())
                            .replace(/X[0-9][0-9]*/g, str => str.toLowerCase())}
                        </Text>
                      );
                    })}
                </div>
              </OptionWrapper>
            );
          });
        } else {
          return (
            <OptionWrapper
              isLastOption={isLastOption}
              key={index}
              isForPreview={true}
            >
              <Label>
                {option.name
                  .toLowerCase()
                  .replace(/\b\w/g, letter => letter.toUpperCase())
                  .replace(/X[0-9][0-9]*/g, str => str.toLowerCase())}
              </Label>
              <div>
                {selectedItemsToString(option)
                  .split(', ')
                  .map((text, index) => {
                    return (
                      <Text key={index}>
                        {text
                          .toLowerCase()
                          .replace(/\b\w/g, letter => letter.toUpperCase())
                          .replace(/X[0-9][0-9]*/g, str => str.toLowerCase())}
                      </Text>
                    );
                  })}
              </div>
            </OptionWrapper>
          );
        }
      })}
    </ContainerDiv>
  ));
};

export default ProductsOrdered;
