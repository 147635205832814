import React from 'react';
import styled from 'styled-components';
import CustomerNotes from './CustomerNotes';
import CustomerListTable from './CustomerListTable';
import Pagination from '../../../../components/Pagination/Pagination';
import { SearchInputBar } from '../../../../components/Table/TableElements';
import { ComponentCard } from '../../../../components/FormElements/FormElements';

const NonTableElements = styled.div`
  padding: 20px;
`;

const CustomerSearchWrapper = styled.div`
  padding: 40px 0 0 0;
  width: 100%;
`;

const StyledCard = styled(ComponentCard)`
  padding: 0 0 0 0;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
`;

/**
 * Functional react component to list all customers
 * @param {Object} props - React props.
 */
const CustomerList = props => {
  const {
    getCustomersIsRequesting,
    customers,
    handleSearchInput,
    searchInput,
    meta,
    requestParams,
    updateRequestParams,
    handleEditIconClick
  } = props;

  return (
    <StyledCard data-test="component-CustomerList">
      <NonTableElements>
        <CustomerNotes data-test="component-CustomerNotes" />
        <CustomerSearchWrapper>
          <SearchInputBar
            data-test="input-searchInput"
            placeholder="Search Customers by name or email"
            value={searchInput}
            onChange={e => handleSearchInput(e.target.value)}
          />
        </CustomerSearchWrapper>
      </NonTableElements>
      <CustomerListTable
        customers={customers}
        getCustomersIsRequesting={getCustomersIsRequesting}
        meta={meta}
        requestParams={requestParams}
        updateRequestParams={updateRequestParams}
        handleEditIconClick={handleEditIconClick}
      />
      {!getCustomersIsRequesting ? (
        <Pagination
          data-test="component-Pagination"
          response={{
            page_number: meta.page_number,
            page_size: meta.page_size
            // count: meta.count
          }}
          requestParams={requestParams}
          updateRequestParams={updateRequestParams}
        />
      ) : null}
    </StyledCard>
  );
};

export default CustomerList;
