import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../combosAction';
import ComboBusinessesOverridesForm from '../components/ComboBusinessesOverridesForm';
import {
  formatProductBusinessesAssigned,
  finalStructureSummaryData
} from '../utils/helper';

const ComboBusinessesOverridesContainer = ({
  combo,
  comboOverrides,
  getComboOverrides,
  comboBusinesses,
  allBusinesses,
  isSubmitting
}) => {
  const formattedBusinesses = formatProductBusinessesAssigned(
    comboBusinesses,
    allBusinesses
  );

  const [isCreate, setIsCreate] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [summaryData, setSummaryData] = useState(null);

  const [isOverrideModal, setIsOverrideModal] = useState({
    isShowing: false,
    price: null,
    assignedBusinesses: []
  });

  useEffect(() => {
    if (combo.id) {
      getComboOverrides(combo.id);
    }
  }, [combo]);

  useEffect(() => {
    if (comboOverrides && combo) {
      setSummaryData(
        finalStructureSummaryData(comboOverrides, formattedBusinesses)
      );
    }
  }, [comboOverrides, combo]);

  return (
    <ComboBusinessesOverridesForm
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      disabled={!combo.id}
      summaryData={summaryData}
      isOverrideModal={isOverrideModal}
      setIsOverrideModal={setIsOverrideModal}
      combo={combo}
      formattedBusinesses={formattedBusinesses}
      isCreate={isCreate}
      setIsCreate={setIsCreate}
      isSubmitting={isSubmitting}
    />
  );
};

const mapStateToProps = state => ({
  comboBusinesses: state.combosReducer.comboBusinesses,
  comboOverrides: state.combosReducer.comboOverrides,
  allBusinesses: state.businessLocationsList.businessLocationsOfMerchant,
  isSubmitting: state.combosReducer.isSubmitting
});

export default connect(
  mapStateToProps,
  actions
)(ComboBusinessesOverridesContainer);
