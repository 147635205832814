import React from 'react';
import { PushDiv, PushTitle, PushDivHeader, SmsDiv } from '../styles/styles';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'} variant={'body2'}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: '20px',
    minWidth: '320px',
  },
}));

const TabsComponent = ({ values }) => {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      {/* <AppBar
        position="static"
        color="transparent"
        style={{ boxShadow: 'none' }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="simple tabs example"
        >
          <Tab label="Push" {...a11yProps(0)} />
          <Tab label="Sms" {...a11yProps(1)} />
        </Tabs>
      </AppBar> */}
      <TabPanel value={value} index={0}>
        <PushDiv>
          <PushDivHeader>
            <span>APP NAME</span>
            <span>now</span>
          </PushDivHeader>
          <PushTitle>
            {values.messages.default.title &&
            values.messages.default.title.length > 75
              ? `${values.messages.default.title.slice(0, 72)}...`
              : values.messages.default.title}
          </PushTitle>
          <p>{values.messages.default.message}</p>
        </PushDiv>
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        <SmsDiv>
          <p>{values.messages.default.message}</p>
        </SmsDiv>
      </TabPanel> */}
    </div>
  );
};

export default TabsComponent;
