import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Field, FastField } from 'formik';
import {
  RenderTextField,
  required
} from '../../../../components/FormElements/FormikElements';

const CustomField = styled.div``;

const styles = theme => ({
  cssLabel: {
    color: '#2949cb'
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`
    }
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#2949cb !important'
  }
});

const TextFieldPOS = ({ value, name, classes, styles, maxLength }) => {
  const [error, setError] = useState(null);

  const validate = (value, maxLength) => {
    if (
      maxLength !== undefined &&
      value.trim() !== '' &&
      value.length > maxLength
    ) {
      setError(`Max ${maxLength} chars`);
      return `Max ${maxLength} chars`;
    }
    setError();
  };

  return (
    <CustomField>
      <FastField
        name={name}
        component={RenderTextField}
        label="POS Value"
        style={{
          borderRadius: '6px',
          backgroundColor: '#fefeff',
          ...styles
        }}
        error={error}
        variant="standard"
        validate={value => validate(value, maxLength)}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline
          },
          inputMode: 'numeric'
        }}
      />
    </CustomField>
  );
};

TextFieldPOS.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TextFieldPOS);
