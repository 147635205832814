// used for actions
import Moment from 'moment';

const dayNames = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const getCloseDayOfWeek = (hour) => {
  const openTime = Moment(hour.openTime);
  const closeTime = Moment(hour.closeTime);
  let closeDayOfWeek = hour.open_day_of_week;
  if (openTime.isAfter(closeTime)) {
    closeDayOfWeek = closeDayOfWeek + 1 > 7 ? 1 : closeDayOfWeek + 1;
  }
  return closeDayOfWeek;
};

export const formatValuesForPatch = (values, initialValues) => {
  let businessLocationPatchFormat = {};

  Object.entries(values).map((pair) => {
    const [key, value] = pair;
    if (typeof value == 'object') {
      Object.keys(value).map((item) => {
        if (value[item] != initialValues[key][item]) {
          businessLocationPatchFormat[key] = {
            ...businessLocationPatchFormat[key],
            [item]: values[key][item],
          };
        }
      });
    } else {
      Object.keys(values).map((item) => {
        if (values[item] != initialValues[item]) {
          businessLocationPatchFormat['businessInformation'] = {
            ...businessLocationPatchFormat['businessInformation'],
            [item]: values[item],
          };
        }
      });
    }
  });
  return businessLocationPatchFormat;
};

export const formatPostSpecialHour = (specialHour) => {
  let formattedSpecialHour = {
    open_day_of_week: specialHour.open_day_of_week,
    close_day_of_week: getCloseDayOfWeek(specialHour),
    open_time: Moment(specialHour.openTime).format('HH:mm:ss'),
    close_time: Moment(specialHour.closeTime).format('HH:mm:ss'),
    is_closed: !specialHour.isOpen,
    start_date: specialHour.startDate,
    end_date: specialHour.endDate,
  };
  if (specialHour.orderAheadIsOpen) {
    formattedSpecialHour = {
      ...formattedSpecialHour,
      is_order_ahead_closed: false,
      order_start_time: Moment(specialHour.orderAheadOpenTime).format(
        'HH:mm:ss'
      ),
      order_end_time: Moment(specialHour.orderAheadCloseTime).format(
        'HH:mm:ss'
      ),
    };
  } else {
    formattedSpecialHour = {
      ...formattedSpecialHour,
      is_order_ahead_closed: true,
      order_start_time: '',
      order_end_time: '',
    };
  }
  return formattedSpecialHour;
};

export const formatPatchSpecialHour = (specialHour) => {
  let formattedSpecialHour = {
    open_day_of_week: specialHour.open_day_of_week,
    close_day_of_week: getCloseDayOfWeek(specialHour),
    open_time: Moment(specialHour.openTime).format('HH:mm:ss'),
    close_time: Moment(specialHour.closeTime).format('HH:mm:ss'),
    is_closed: !specialHour.isOpen,
    start_date: specialHour.startDate,
    end_date: specialHour.endDate,
  };
  if (specialHour.orderAheadIsOpen) {
    formattedSpecialHour = {
      ...formattedSpecialHour,
      is_order_ahead_closed: false,
      order_start_time: Moment(specialHour.orderAheadOpenTime).format(
        'HH:mm:ss'
      ),
      order_end_time: Moment(specialHour.orderAheadCloseTime).format(
        'HH:mm:ss'
      ),
    };
  } else {
    formattedSpecialHour = {
      ...formattedSpecialHour,
      is_order_ahead_closed: true,
      order_start_time: '',
      order_end_time: '',
    };
  }
  return formattedSpecialHour;
};

export const constructBusinessLocationFormat = (businessInfo) => {
  let data = {};
  data['businessInformationInitialValues'] = {
    name: businessInfo ? businessInfo.name : '',
    currency: businessInfo ? businessInfo.currency : '',
    transaction_amount_limit: businessInfo
      ? businessInfo.transaction_amount_limit
      : '',
    is_order_ahead_enabled: businessInfo
      ? businessInfo.is_order_ahead_enabled
      : false,
    is_delivery_enabled: businessInfo
      ? businessInfo.is_delivery_enabled
      : false,
    is_active: businessInfo ? businessInfo.is_active : true,
    is_visible: businessInfo ? true : true,
    external_id: businessInfo ? businessInfo.external_id : '',
  };
  return (addressInfo, detailsInfo) => {
    data['addressInformationInitialValues'] = {
      address: {
        line1: addressInfo ? addressInfo.line1 : '',
        line2: addressInfo ? addressInfo.line2 : '',
        line3: addressInfo ? addressInfo.line3 : '',
        city: addressInfo ? addressInfo.city : '',
        state: addressInfo ? addressInfo.state : '',
        country: addressInfo
          ? addressInfo.country == 'CANADA'
            ? 'CA'
            : addressInfo.country
          : '',
        zip: addressInfo ? addressInfo.zip : '',
        deliveryzone: [],
        details: addressInfo ? addressInfo.details : '',
        latitude: addressInfo ? addressInfo.latitude : '',
        longitude: addressInfo ? addressInfo.longitude : '',
      },
    };
    data['addressInformationInitialValues'] = {
      ...data['addressInformationInitialValues'],
      details: {
        display_name: detailsInfo ? detailsInfo.display_name : '',
        description: detailsInfo ? detailsInfo.description : '',
        is_phone_visible: detailsInfo ? detailsInfo.is_phone_visible : false,
        timezone: detailsInfo ? detailsInfo.timezone : '',
        phone: detailsInfo ? detailsInfo.phone : '',
        is_phone_visible: detailsInfo ? detailsInfo.is_phone_visible : false,
        tax_number: detailsInfo ? detailsInfo.tax_number : '',
        website_url: detailsInfo ? detailsInfo.website_url : '',
        image_url: detailsInfo ? detailsInfo.image_url : '',
        language_code: detailsInfo ? detailsInfo.language_code : '',
      },
    };
    return (regularHours) => {
      data['regularHours'] = regularHours
        ? formatRegularHours(regularHours)
        : [];
      return (specialHours) => {
        data['specialHours'] = specialHours
          ? formatSpecialHours(specialHours)
          : [];
        return data;
      };
    };
  };
};

const formatSpecialHours = (specialHours) => {
  let formattedSpecialHours = [];
  specialHours.forEach((specialHour) => {
    let startEndDate = {
      startDate: specialHour.start_date,
      endDate: specialHour.end_date,
    };

    if (
      specialHour.is_order_ahead_closed ||
      specialHour.order_start_time === '' ||
      specialHour.order_end_time === ''
    ) {
      specialHour = {
        ...specialHour,
        order_start_time: '00:00:00',
        order_end_time: '00:00:00',
      };
    }

    let found = false;
    formattedSpecialHours.forEach((formattedSpecialHour) => {
      if (
        formattedSpecialHour.startEndDate.startDate == startEndDate.startDate &&
        formattedSpecialHour.startEndDate.endDate == startEndDate.endDate
      ) {
        found = true;
        formattedSpecialHour.hours.push(specialHour);
      }
    });
    if (!found) {
      // console.log(specialHour);
      formattedSpecialHours.push({
        startEndDate: startEndDate,
        hours: [specialHour],
      });
    } else {
    }
  });

  // console.log(formattedSpecialHours);
  return formattedSpecialHours;
};

export const formatPutRegularHours = (values) => {
  let result = [];
  let hoursError = new Set();
  values.forEach((regularHour) => {
    if (regularHour.isOpen) {
      let regularHours = {
        open_day_of_week: regularHour.open_day_of_week,
        close_day_of_week: getCloseDayOfWeek(regularHour),
        open_time: Moment(regularHour.openTime).format('HH:mm:ss'),
        close_time: Moment(regularHour.closeTime).format('HH:mm:ss'),
      };
      if (regularHour.orderAheadIsOpen) {
        if (
          Moment(regularHour.orderAheadOpenTime).isBefore(
            regularHour.openTime
          ) ||
          Moment(regularHour.orderAheadCloseTime).isAfter(regularHour.closeTime)
        ) {
          hoursError.add(regularHour.dayName);
        }
        regularHours = {
          ...regularHours,
          order_start_time: Moment(regularHour.orderAheadOpenTime).format(
            'HH:mm:ss'
          ),
          order_end_time: Moment(regularHour.orderAheadCloseTime).format(
            'HH:mm:ss'
          ),
        };
      } else {
        regularHours = {
          ...regularHours,
          order_start_time: null,
          order_end_time: null,
        };
      }

      result.push(regularHours);
    }
  });
  return [result, Array.from(hoursError)];
};

export const formatRegularHours = (regularHours) => {
  let result = [];
  for (let i = 1; i < 8; i++) {
    let foundDayHours = null;
    regularHours.forEach((regularHoursByDay) => {
      if (regularHoursByDay.open_day_of_week == i) {
        foundDayHours = regularHoursByDay;
      }
    });
    if (foundDayHours) {
      foundDayHours = {
        open_day_of_week: foundDayHours.open_day_of_week,
        dayName: dayNames[i - 1],
        openTime: Moment(foundDayHours.open_time, 'HH:mm:ss'),
        closeTime: Moment(foundDayHours.close_time, 'HH:mm:ss'),
        orderAheadOpenTime:
          foundDayHours.order_start_time == ''
            ? Moment('09:00:00', 'HH:mm:ss')
            : Moment(foundDayHours.order_start_time, 'HH:mm:ss'),
        orderAheadCloseTime:
          foundDayHours.order_end_time == ''
            ? Moment('17:00:00', 'HH:mm:ss')
            : Moment(foundDayHours.order_end_time, 'HH:mm:ss'),
        isOpen: true,
        orderAheadIsOpen: foundDayHours.order_start_time != '',
      };

      if (foundDayHours) result.push(foundDayHours);
    } else {
      result.push({
        open_day_of_week: i,
        dayName: dayNames[i - 1],
        openTime: Moment('09:00:00', 'HH:mm:ss'),
        closeTime: Moment('17:00:00', 'HH:mm:ss'),
        orderAheadOpenTime: Moment('09:00:00', 'HH:mm:ss'),
        orderAheadCloseTime: Moment('17:00:00', 'HH:mm:ss'),
        isOpen: false,
        orderAheadIsOpen: false,
      });
    }
  }
  return result;
};
