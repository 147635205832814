import * as React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import styled from 'styled-components';
import Icon from '../../../components/Icon/Icon';
import PopupState, {
  anchorRef,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state';
import EditImageContainer from '../../Media/containers/EditImageContainer';

const IconEllipsis = styled(Icon)`
    border: 1px solid #2440ae;
    border-radius: 50%;
    padding: 5px;
    margin: 5px;
    color: ${props => props.clicked ? '#fff': '#2440AE'}; ;
    opacity: ${props => props.disabled ? '0.5': '1'}; 
    cursor: ${props => props.disabled ? 'none': 'pointer'};
    background: ${props => props.clicked ? '#2440AE': '#fff'}; 

    &:hover{
      background: #2440AE;
      color: #fff;
    }
`;


const ImagePreviewPopUp = ({selectedImage, 
    editImageFormIsVisible,
    showEditImageForm,
    displayConfirmModal
 }) => {


  return (
    <PopupState variant="popover" popupId="imageActions">
    {popupState => (
      <span>
        <div  containerprops={{ ref: anchorRef(popupState) }}>
         <IconEllipsis
            className="fas fa-ellipsis-h"
            clicked={popupState.isOpen}
            disabled={!selectedImage.media_id}
            style={{ fontSize: '24px', marginLeft: '15px' }}
            {...bindTrigger(popupState)}
          />
        </div>
        <Menu
          disableAutoFocusItem={true}
          {...bindMenu(popupState)} 
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <MenuItem 
              onClick={() => {
                showEditImageForm(selectedImage)
                popupState.close(); 
              }} 
              style={{fontSize:'14px'}}>
            Rename
          </MenuItem>
          <MenuItem 
              onClick={() => {
                window.open(selectedImage.url, '_blank');
                popupState.close(); 
              }} 
              style={{fontSize:'14px'}}>
            Preview
          </MenuItem>
          {/* <MenuItem 
              onClick={() => {  
                popupState.close(); 
              }} 
              style={{fontSize:'14px'}}>
            <a href='https://s3.ca-central-1.amazonaws.com/assets.dev.smoothpay.com/toppers/dennys_1600226175.jpeg'
            download> Download</a>
          </MenuItem> */}
          <MenuItem 
              onClick={() => {
                displayConfirmModal(true);
                // deleteImageRequest(selectedImage);
                popupState.close(); 
              }} 
              style={{fontSize:'14px'}}>
            Delete
          </MenuItem>
        </Menu>
        {editImageFormIsVisible && <EditImageContainer />}
      </span>
    )}
  </PopupState>
  )
}

export default ImagePreviewPopUp