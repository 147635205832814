import React from 'react';
import OffersListContainer from './OffersList/OffersListContainer';
import OfferDetailsContainer from './OfferDetails/OfferDetailsContainer';
import { connect } from 'react-redux';

const OffersContainer = ({ isCreatingOffer, isEditingOffer }) => {
  if (isCreatingOffer || isEditingOffer) {
    return <OfferDetailsContainer />;
  } else {
    return <OffersListContainer />;
  }
};

const mapStateToProps = state => ({
  isCreatingOffer: state.offersReducer.isCreatingOffer,
  isEditingOffer: state.offersReducer.isEditingOffer
});

export default connect(mapStateToProps)(OffersContainer);
