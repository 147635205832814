import React from 'react';
import { Dialog } from '@material-ui/core';
import './Modal.scss'

const Modal = (props) => {
  return (
    <Dialog
      open
      maxWidth={props.maxWidth || 'md'}
      fullWidth={props.fullWidth || false}
      scroll={props.bodyScroll ? 'body' : props.scroll}
    >
      {props.children}
    </Dialog>
  );
}

export default Modal;
