export const NO_IMAGE_CLIENT = 'NoImageClient';
export const NO_MOBILE_MESSAGES_CLIENT = 'NoMobileMessagesClient';
export const NO_POS_MAPPING_CLIENT = 'NoPOSMappingClient';
export const NO_SEGMENTATION_CLIENT = 'NoSegmentationClient';
export const NO_LOYALTY_CLIENT = 'NoLoyaltyClient';
export const NO_COMBOS_CLIENT = 'NoCombosClient';

export const WEB_ORDERING_ESSENTIALS = 'WebOrderingEssentials';
export const WEB_ORDERING_RAPID_DEPLOYMENT = 'WebOrderingRapidDeployment';
export const WEB_ORDERING_PLUS = 'WebOrderingPlus';
export const MOBILE_APPS_ESSENTIALS = 'MobileAppEssentials';
export const MOBILE_APPS_PLUS = 'MobileAppsPlus';

export const allTiers = [
  WEB_ORDERING_RAPID_DEPLOYMENT,
  WEB_ORDERING_ESSENTIALS,
  WEB_ORDERING_PLUS,
  MOBILE_APPS_ESSENTIALS,
  MOBILE_APPS_PLUS,
  NO_COMBOS_CLIENT,
];

const clientSubscriptions = {
  yumson: [WEB_ORDERING_ESSENTIALS, MOBILE_APPS_ESSENTIALS],
  cantina: [WEB_ORDERING_ESSENTIALS, MOBILE_APPS_ESSENTIALS],
  amano: [WEB_ORDERING_RAPID_DEPLOYMENT],
  dennys: [WEB_ORDERING_PLUS],
  tropical_treats: [WEB_ORDERING_RAPID_DEPLOYMENT],
  butchies: [WEB_ORDERING_RAPID_DEPLOYMENT],
  opus_restaurant: [WEB_ORDERING_RAPID_DEPLOYMENT],
  moxies: [WEB_ORDERING_RAPID_DEPLOYMENT],
  shark: [WEB_ORDERING_PLUS],
  rock_creek: [WEB_ORDERING_PLUS],
  chop: [WEB_ORDERING_PLUS],
  mercato: [WEB_ORDERING_RAPID_DEPLOYMENT],
  smokys: [WEB_ORDERING_RAPID_DEPLOYMENT],
  wingsnob: [WEB_ORDERING_RAPID_DEPLOYMENT],
  dixielee: [WEB_ORDERING_RAPID_DEPLOYMENT],
  twinfish: [WEB_ORDERING_RAPID_DEPLOYMENT],
  ground_burger: [WEB_ORDERING_ESSENTIALS],
  pinksburgers: [WEB_ORDERING_RAPID_DEPLOYMENT],
  maestros: [MOBILE_APPS_ESSENTIALS],
  souvlakihouse: [WEB_ORDERING_PLUS],
  stockyardssmokehouse: [WEB_ORDERING_RAPID_DEPLOYMENT],
  ambiyan: [WEB_ORDERING_PLUS],
  slater: [WEB_ORDERING_RAPID_DEPLOYMENT],
  pronto: [WEB_ORDERING_RAPID_DEPLOYMENT],
  mcmurray: [WEB_ORDERING_ESSENTIALS],
  yumson: [MOBILE_APPS_ESSENTIALS],
  mevame: [MOBILE_APPS_PLUS],
};

export const pageLevelExemptions = {
  maestros: ['/mobile-messages', '/coupon-management'],
  rock_creek: ['/loyalty-report'],
  shark: ['/loyalty-report'],
  yumson: ['/coupon-management'],
  libretto: ['/mobile-messages'],
  chop: ['/loyalty-report'],
  shark: ['/loyalty-report'],
  souvlakihouse: ['/loyalty-report', '/offers', '/upsell', '/segments'],
};

export const isNoImageClient = (appIdentifier) => [].includes(appIdentifier);

export const isPOSMappingClient = (appConfig) => {
  return appConfig && appConfig.pos_integration_enabled;
};

export const isLoyaltyClient = (appConfig) => {
  return appConfig && appConfig.program_type;
};

export const isNotComboClient = (appIdentifier) => {
  return ['dennys', 'yumson'].includes(appIdentifier);
};

export const isMobileMessageClient = (appIdentifier) =>
  [
    'toppers',
    'cf',
    'cpl',
    'balzacs',
    'wilsons',
    'teriyaki',
    'chopped',
    'sodexo',
    'smokes',
    'grounds',
    'maker',
    'ticats',
    'bombers',
    'basilbox',
    'oliva',
    'mary_browns',
    'fresh',
    'pizzanova',
    'caffeartigiano',
    'maestros',
    'libretto',
    'picassos',
    'yumson',
    'iqfood',
    'mevame',
    'iq',
  ].includes(appIdentifier);

export const getClientTiers = (appIdentifier, appConfig) => {
  let clientTiers = new Set(clientSubscriptions[appIdentifier]);
  if (!clientSubscriptions[appIdentifier]) {
    // clientTiers = new Set(allTiers);
    clientTiers = new Set([MOBILE_APPS_PLUS]);
  }
  if (!isMobileMessageClient(appIdentifier)) {
    clientTiers.add(NO_MOBILE_MESSAGES_CLIENT);
  }
  if (isNoImageClient(appIdentifier)) {
    clientTiers.add(NO_IMAGE_CLIENT);
  }
  if (!isPOSMappingClient(appConfig)) {
    clientTiers.add(NO_POS_MAPPING_CLIENT);
  }
  if (!isLoyaltyClient(appConfig)) {
    clientTiers.add(NO_LOYALTY_CLIENT);
  }
  if (isNotComboClient(appIdentifier)) {
    clientTiers.add(NO_COMBOS_CLIENT);
  }

  return Array.from(clientTiers);
};
