import {
  GET_PRODUCTS,
  GET_PRODUCTS_MAPPING_FIELDS,
  GET_PRODUCTS_CATEGORIES,
  GET_PRODUCTS_FILTERED_CATEGORY,
  SET_SELECTED_VALUE_POS,
  GET_CURRENT_PRODUCT_POS_MAPPING_VALUES,
  SET_IS_REQUESTING_PRODUCT_POS_MAPPING_VALUES,
  GET_POS_COMBOS,
  GET_POS_OFFERS,
  GET_POS_COUPONS,
  GET_ALL_COUPONS_POS,
  GET_DISCOUNT_MAPPING_FIELDS,
  RESET_SEARCH_VALUE,
  SET_SEARCH_VALUE,
  POS_DISCOUNT_MAPPING_FIELDS_NOT_AVAILABLE,
  GET_DISCOUNT_MAPPING_FIELDS_REQUEST,
  GET_ITEMS_POS_VALUES_REQUEST,
  GET_ITEMS_POS_VALUES_SUCCESS,
  GET_ITEMS_MAPPING_FIELDS_SUCCESS,
  GET_ITEMS_MAPPING_FIELDS_REQUEST
} from './posTypes';
import { api } from '../../../api';
import { createAlert } from '../../Alert/actions';
import { renderItemsModified } from './ItemsMapping/helper/helper';
import axios from 'axios';
import _ from 'lodash';

const CancelToken = axios.CancelToken;
let source;

export function getProducts({ searchString, page_number = 1, page_size = 50 }) {
  return function(dispatch) {
    if (source) {
      source.cancel('Operation canceled by the user.');
    }

    source = CancelToken.source();

    let url = `/menu/products?include=prices&page_number=${page_number}&page_size=${page_size}`;
    if (searchString && searchString.length > 2) {
      url += `&search_string=${searchString}`;
    }

    return api
      .get(url, {
        cancelToken: source.token
      })
      .then(response => {
        const allProducts = response.data.data.map(product => {
          const productVariants = product.prices.map(variant => ({
            variantId: variant.product_variant_id,
            variantName: variant.name
          }));

          return {
            productId: product.id,
            productName: product.name,
            productImageUrl: product.image_url,
            productIsActive: product.is_active,
            productVariants
          };
        });

        dispatch({
          type: GET_PRODUCTS,
          payload: allProducts,
          meta: response.data.meta
        });
      })
      .catch();
  };
}

export const setIsRequestingProductPosMappings = () => {
  return dispatch => {
    dispatch({
      type: SET_IS_REQUESTING_PRODUCT_POS_MAPPING_VALUES
    });
  };
};

export function getProductsMappingFields(productId) {
  return function(dispatch) {
    return api
      .get(`/pos-mapping-fields/product?page_number=1&page_size=100`)
      .then(response => {
        const fields = response.data.data.reduce((acc, cur, i) => {
          acc[cur.key] = cur;
          return acc;
        }, {});
        dispatch({
          type: GET_PRODUCTS_MAPPING_FIELDS,
          payload: fields
        });
        if (productId) {
          dispatch(getProductPosMappingValues(productId, fields));
        }
      })
      .catch(err => {
        dispatch(
          createAlert({
            type: 'error',
            message: 'An error occurred trying to get POS fields'
          })
        );
      });
  };
}

export function getProductPosMappingValues(productId, productsMappingFields) {
  return function(dispatch) {
    return api
      .get(`/menu/products/${productId}?include=prices,pos_mappings`)
      .then(response => {
        const default_values = {};
        for (let [key, field] of Object.entries(productsMappingFields)) {
          default_values[key] = field.type === 'boolean' ? false : '';
        }

        let mofifiedMappings = { mappings: {} };
        const returnedMappings = response.data.data.pos_mappings.reduce(
          (acc, item) => {
            acc[item['product_variant_id']] = item;
            return acc;
          },
          {}
        );

        response.data.data.prices.map(variant => {
          if (returnedMappings[variant.product_variant_id]) {
            mofifiedMappings['mappings'][variant.product_variant_id] =
              returnedMappings[variant.product_variant_id];
          } else {
            mofifiedMappings['mappings'][
              variant.product_variant_id
            ] = default_values;
          }
        });

        dispatch({
          type: GET_CURRENT_PRODUCT_POS_MAPPING_VALUES,
          mappings: mofifiedMappings
        });
      })
      .catch(err => {
        dispatch(
          createAlert({
            type: 'error',
            message: 'An error occurred trying to get POS values'
          })
        );
      });
  };
}

export function submitProductPosMappingValues(
  product,
  fields,
  mappings,
  callback
) {
  return function(dispatch) {
    const productId = product.productId;

    let data = [];
    const reqFields = [];
    const whiteFields = [];
    mappingsLoop: for (let [key, values] of Object.entries(
      mappings['mappings']
    )) {
      if (values === undefined) continue;

      Object.keys(fields).map(key => {
        const value = values[key];

        if (
          typeof value !== 'boolean' &&
          value == '' &&
          !fields[key].optional &&
          !reqFields.includes(fields[key].label)
        ) {
          reqFields.push(fields[key].label);
        }
        if (typeof value !== 'boolean' && value && value.trim() == '')
          whiteFields.push('White Space');
      });

      let item = {
        product_variant_id: parseInt(key)
      };
      for (let [field, field_data] of Object.entries(fields)) {
        if (
          field_data.type !== 'boolean' &&
          (values[field] == undefined || values[field] == '')
        ) {
          delete item[field];
        } else item[field] = values[field];
      }

      data.push(item);
      continue mappingsLoop;
    }

    if (reqFields.length) {
      const message =
        reqFields.length == 1 ? ' is required!' : ' are required!';
      dispatch(
        createAlert({
          type: 'error',
          message: reqFields.toString() + message
        })
      );
      return;
    }

    if (whiteFields.length) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'Empty spaces are not allowed!'
        })
      );
      return;
    }

    return api
      .put(`/menu/products/${productId}/pos-mapping`, { mappings: data })
      .then(response => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'POS values were saved successfully.'
          })
        );

        const selectedValue = callback();
        if (selectedValue) {
          dispatch(getProductPosMappingValues(selectedValue, fields));
        }
      })
      .catch(err => {
        dispatch(
          createAlert({
            type: 'error',
            message: 'An error occurred trying to saving POS values.'
          })
        );
      });
  };
}

export function getProductsCategories() {
  return function(dispatch) {
    return api
      .get('/menu/categories?page_number=1&page_size=10000')
      .then(response => {
        dispatch({
          type: GET_PRODUCTS_CATEGORIES,
          payload: response.data.data
        });
      })
      .catch(error => {
        dispatch(
          createAlert({
            type: 'error',
            message: 'An error occurred trying to get categories'
          })
        );
      });
  };
}

export function getProductsFilteredCategory(category) {
  return function(dispatch) {
    return api
      .get(
        `/menu/categories/${category.id}?include=products.prices,sub_categories.products.prices`
      )
      .then(response => {
        const finalProductsList = response.data.data.products.map(
          (product, index) => {
            const productVariants = product.prices.map(variant => ({
              variantId: variant.product_variant_id,
              variantName: variant.name,
              variantIsActive: variant.is_active
            }));

            return {
              productId: product.id,
              productName: product.name,
              productImageUrl: product.image_url,
              productIsActive: product.is_active,
              productVariants
            };
          }
        );
        dispatch({
          type: GET_PRODUCTS_FILTERED_CATEGORY,
          payload: finalProductsList
        });
      })
      .catch(error => {
        dispatch(
          createAlert({
            type: 'error',
            message: 'An error occurred getting product variants'
          })
        );
      });
  };
}

export function getAllProducts() {
  return function(dispatch) {
    return (
      api
        .get(
          `/menu/products?include=prices&page_number=1&page_size=10000&search_string=`
        )
        // .get(`/menu/products?page_number=1&page_size=10000&include=prices`)
        .then(response => {
          dispatch({
            type: 'GET_ALL_PRODUCTS',
            payload: response.data.data
          });
        })
    );
  };
}

// POS fields for discounts
export function getPOSDiscountsMappingFields() {
  return function(dispatch) {
    dispatch({
      type: GET_DISCOUNT_MAPPING_FIELDS_REQUEST
    });
    return api
      .get('/pos-mapping-fields/discount?page_number=1&page_size=10')
      .then(response => {
        const fields = response.data.data.reduce((acc, cur, i) => {
          acc[cur.key] = cur;
          return acc;
        }, {});
        dispatch({
          type: GET_DISCOUNT_MAPPING_FIELDS,
          payload: fields
        });
      })
      .catch(err => {
        if (err.response.data.errors.http_code === 400) {
          dispatch({
            type: POS_DISCOUNT_MAPPING_FIELDS_NOT_AVAILABLE
          });
        } else {
          dispatch(
            createAlert({
              type: 'error',
              message:
                'An error occurred trying to get POS Mapping Discounts fields'
            })
          );
        }
      });
  };
}

export function validatePOSFields(
  valuesModified,
  posDiscountsMappingFields,
  type
) {
  // let valuesModified = '';
  // if (type === 'combo') {
  //   valuesModified = values.combos;
  // }
  // if (type === 'offer') {
  //   valuesModified = values;
  // }
  // if (type === 'coupon') {
  //   valuesModified = values.coupons;
  // }
  let notAllFilled = true;
  for (var i = 0; i < valuesModified.length; i++) {
    let data = [];
    Object.keys(posDiscountsMappingFields).map(val => {
      if (
        !valuesModified[i].pos_mapping[val] ||
        valuesModified[i].pos_mapping[val] == ''
      ) {
        data.push('Empty');
      }
    });

    if (
      data.length > 0 &&
      data.length < Object.keys(posDiscountsMappingFields).length
    ) {
      notAllFilled = false;
      break;
    }
  }
  return notAllFilled;
}

export function validateWhiteSpaces(valuesModified, posDiscountsMappingFields) {
  let whiteSpaces = false;
  for (var i = 0; i < valuesModified.length; i++) {
    let data = [];
    Object.keys(posDiscountsMappingFields).map(val => {
      if (
        !valuesModified[i].pos_mapping[val] == '' &&
        (!valuesModified[i].pos_mapping[val] ||
          valuesModified[i].pos_mapping[val].trim() == '')
      ) {
        data.push('Empty');
      }
    });

    if (data.length) {
      whiteSpaces = true;
      break;
    }
  }
  return whiteSpaces;
}

//Actions for combos

export function getCombos(
  { searchString, page_number, page_size },
  search = false,
  newValue
) {
  const searchStringWrapped = encodeURIComponent(searchString);
  return function(dispatch) {
    return api
      .get(
        `/combos?page_number=${page_number}&include=businesses&page_size=${page_size}&search_string=${searchStringWrapped}`
      )
      .then(response => {
        if (newValue) {
          if (response.data.data.length > 1) {
            dispatch({
              type: GET_POS_COMBOS,
              payload: response.data.data.filter(
                response => response.name === newValue.label
              ),
              meta: response.data.meta
            });
          } else
            dispatch({
              type: GET_POS_COMBOS,
              payload: response.data.data,
              meta: response.data.meta
            });
        } else
          dispatch({
            type: GET_POS_COMBOS,
            payload: response.data.data,
            meta: response.data.meta
          });
        // if (search)
        //   dispatch(
        //     setSelectedValuePOS(
        //       response.data.data.map((combo, index) => ({ ...combo, index }))
        //     )
        //   );
      });
  };
}

export function submitPOSDiscountValuesCombos(
  values,
  type,
  initialValues,
  selectedValue,
  newValue,
  newSearch,
  page_size,
  page_number,
  posDiscountsMappingFields
) {
  return function(dispatch, getState) {
    const appId = getState().accountReducer.appMetaData.app_id;

    values = values.combos;

    const valuesModified = formValuesModified(
      posDiscountsMappingFields,
      values
    );

    const initialValuesModified = formValuesModified(
      posDiscountsMappingFields,
      initialValues.combos
    );

    if (!validatePOSFields(valuesModified, posDiscountsMappingFields, type)) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'Please fill in all fields of a variant or leave them empty.'
        })
      );
      return;
    }

    if (validateWhiteSpaces(valuesModified, posDiscountsMappingFields)) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'Empty spaces are not allowed!'
        })
      );
      return;
    }

    return Promise.all(
      promiseArrays(
        initialValuesModified,
        valuesModified,
        type,
        posDiscountsMappingFields,
        appId
      )
    )
      .then(response => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'The POS values are updated successfully'
          })
        );

        if (newValue)
          dispatch(
            getCombos(
              {
                searchString: newValue.label,
                page_number: 1,
                page_size: page_size
              },
              true
            )
          );
        else if (newSearch) dispatch(getCombos(newSearch, true));
        else {
          dispatch(
            getCombos({
              searchString: newSearch ? newSearch.label : '',
              page_number,
              page_size
            })
          );
        }
      })
      .catch(err => {
        dispatch(
          createAlert({
            type: 'error',
            message: 'An error occurred updating POS values'
          })
        );
      });
  };
}

export function promiseArrays(
  initialValuesModified,
  valuesModified,
  type,
  posDiscountsMappingFields,
  appId
) {
  let initialValueId = '';
  let valueId = '';

  const idPaths = {
    combo: 'id',
    coupon: 'coupon_details.coupon_id',
    offer: 'offer_details.offer_id'
  };
  const idPath = idPaths[type];

  // new
  const oldArray = initialValuesModified;
  const newArray = valuesModified;
  const attributes = Object.keys(posDiscountsMappingFields);
  const finalValues = [];

  for (let i = 0; i < oldArray.length; i++) {
    const oldObj = oldArray[i];
    initialValueId = _.get(oldObj, idPath);
    const newObj = newArray.find(el => _.get(el, idPath) === initialValueId);
    if (!newObj) continue;
    valueId = _.get(newObj, idPath);

    const oldValues = Object.keys(oldObj.pos_mapping).reduce((acc, key) => {
      if (attributes.includes(key) /*&& oldObj.pos_mapping[key]*/)
        acc.push(oldObj.pos_mapping[key]);

      return acc;
    }, []);
    const newValues = Object.keys(newObj.pos_mapping).reduce((acc, key) => {
      if (attributes.includes(key) && newObj.pos_mapping[key])
        acc.push(newObj.pos_mapping[key]);

      return acc;
    }, []);

    let reqType;

    if (!oldValues.length && newValues.length) reqType = 'post';
    else if (oldValues.length && !newValues.length) reqType = 'delete';
    else if (!oldValues.length && !newValues.length) reqType = 'do not send';
    else reqType = 'patch';

    if (reqType === 'post') {
      const discountData = {
        ...newObj.pos_mapping,
        type: type,
        discount_id: valueId,
        app_id: appId
      };
      finalValues.push(api.post(`/discount-mapping`, discountData));
    } else if (reqType === 'patch') {
      const discountData = newObj.pos_mapping;
      delete discountData.app_id;
      delete discountData.type;
      finalValues.push(
        api.patch(`/discount-mapping/${valueId}/${type}`, discountData)
      );
    } else if (reqType === 'delete') {
      finalValues.push(api.delete(`/discount-mapping/${valueId}/${type}`));
    }
  }
  return finalValues;
}

//Actions for coupons
export function getCoupons(
  { searchString, page_number, page_size },
  search = false
) {
  const searchStringWrapped = encodeURIComponent(searchString);
  return function(dispatch) {
    return api
      .get(
        `/coupons?page_number=${page_number}&page_size=${page_size}&search_string=${searchStringWrapped}`
      )
      .then(response => {
        dispatch({
          type: GET_POS_COUPONS,
          payload: response.data.data,
          meta: response.data.meta
        });
        if (!search)
          dispatch({
            type: RESET_SEARCH_VALUE
          });
        if (search)
          dispatch(
            setSelectedValuePOS(
              response.data.data.map((coupon, index) => ({ ...coupon, index }))
            )
          );
      });
  };
}

export function formValuesModified(posDiscountsMappingFields, values) {
  const finalFields = [
    ...Object.keys(posDiscountsMappingFields),
    'type',
    'app_id',
    'discount_id'
  ];

  let valuesModified = values.map((el, index) => ({
    ...el,
    pos_mapping: Object.keys(el.pos_mapping).reduce((acc, key) => {
      if (finalFields.includes(key)) {
        acc[key] = el.pos_mapping[key];
      }

      return acc;
    }, {})
  }));

  return valuesModified;
}

export function submitPOSDiscountValuesCoupons(
  values,
  type,
  initialValues,
  selectedValue,
  newValue,
  newSearch,
  page_size,
  page_number,
  posDiscountsMappingFields
) {
  return function(dispatch, getState) {
    const appId = getState().accountReducer.appMetaData.app_id;

    values = values.coupons;

    const valuesModified = formValuesModified(
      posDiscountsMappingFields,
      values
    );

    const initialValuesModified = formValuesModified(
      posDiscountsMappingFields,
      initialValues.coupons
    );
    if (!validatePOSFields(valuesModified, posDiscountsMappingFields, type)) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'Please fill in all fields of a variant or leave them empty.'
        })
      );
      return;
    }

    if (validateWhiteSpaces(valuesModified, posDiscountsMappingFields)) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'Empty spaces are not allowed!'
        })
      );
      return;
    }

    return Promise.all(
      promiseArrays(
        initialValuesModified,
        valuesModified,
        type,
        posDiscountsMappingFields,
        appId
      )
    )
      .then(response => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'The POS values are updated successfully'
          })
        );
        if (newValue)
          dispatch(
            getCoupons(
              {
                searchString: newValue.coupon_details.label,
                page_number: 1,
                page_size: page_size
              },
              true
            )
          );
        else if (newSearch) dispatch(getCoupons(newSearch, true));
        else {
          dispatch(
            getCoupons({
              searchString: newSearch ? newSearch.label : '',
              page_number,
              page_size
            })
          );
        }
      })
      .catch(err => {
        dispatch(
          createAlert({
            type: 'error',
            message: 'An error occurred updating POS values'
          })
        );
      });
  };
}

//Actions for offers
export function getOffers(
  { searchString, page_number, page_size },
  search = false
) {
  let searchStringWrapped = '';
  if (searchString === 'ALL OFFERS') searchStringWrapped = '';
  searchStringWrapped = encodeURIComponent(searchString);
  return function(dispatch) {
    return api
      .get(
        `/offers?page_number=${page_number}&page_size=${page_size}&search_string=${searchStringWrapped}`
      )
      .then(response => {
        dispatch({
          type: GET_POS_OFFERS,
          payload: response.data.data,
          meta: response.data.meta
        });
        // if (search)
        //   dispatch(
        //     setSelectedValuePOS(
        //       response.data.data.map((offer, index) => ({ ...offer, index }))
        //     )
        //   );
      });
  };
}

export function submitPOSDiscountValuesOffers(
  values,
  type,
  initialValues,
  selectedValue,
  newValue,
  newSearch,
  page_size,
  page_number,
  posDiscountsMappingFields
) {
  return function(dispatch, getState) {
    const appId = getState().accountReducer.appMetaData.app_id;

    values = values.offers;

    const initialValuesModified = formValuesModified(
      posDiscountsMappingFields,
      initialValues.offers
    );

    const valuesModified = formValuesModified(
      posDiscountsMappingFields,
      values
    );

    if (!validatePOSFields(valuesModified, posDiscountsMappingFields, type)) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'Please fill in all fields of a variant or leave them empty.'
        })
      );
      return;
    }

    if (validateWhiteSpaces(valuesModified, posDiscountsMappingFields)) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'Empty spaces are not allowed!'
        })
      );
      return;
    }

    return Promise.all(
      promiseArrays(
        initialValuesModified,
        valuesModified,
        type,
        posDiscountsMappingFields,
        appId
      )
    )
      .then(response => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'The POS values are updated successfully'
          })
        );

        if (newValue)
          dispatch(
            getOffers(
              {
                searchString: newValue.offer_details.label,
                page_number: 1,
                page_size: page_size
                // page_number: meta.page_number,
                // page_size: meta.page_size
              },
              true
            )
          );
        // dispatch(getOffers(newValue));//setSelectedValuePOS(newValue));
        else if (newSearch) {
          dispatch(getOffers(newSearch, true));
        } else {
          dispatch(
            getOffers({
              searchString: newSearch ? newSearch.label : '',
              page_number,
              page_size
            })
          );
        }
      })
      .catch(err => {
        dispatch(
          createAlert({
            type: 'error',
            message: 'An error occurred updating POS values'
          })
        );
      });
  };
}

export function getAllCoupons() {
  return function(dispatch) {
    return api
      .get(`/coupons?page_number=1&page_size=10000&search_string=`)
      .then(response => {
        dispatch({
          type: GET_ALL_COUPONS_POS,
          payload: response.data.data
        });
      });
  };
}

export function setSelectedValuePOS(value) {
  return {
    type: SET_SELECTED_VALUE_POS,
    payload: value
  };
}

export function setSearchValue(value) {
  return {
    type: SET_SEARCH_VALUE,
    payload: value
  };
}

export function getItemsMappingFields() {
  return function(dispatch) {
    dispatch({
      type: GET_ITEMS_MAPPING_FIELDS_REQUEST
    });
    return api
      .get('/pos-mapping-fields/item?page_number=1&page_size=100')
      .then(response => {
        const fields = response.data.data.reduce((acc, cur, i) => {
          acc[cur.key] = cur;
          return acc;
        }, {});
        dispatch({
          type: GET_ITEMS_MAPPING_FIELDS_SUCCESS,
          payload: fields
        });
      })
      .catch(err => {
        dispatch(
          createAlert({
            type: 'error',
            message:
              'An error occurred trying to get POS Mapping Discounts fields'
          })
        );
        //}
      });
  };
}

export function getItemsPOSValues({ selectedOption, variants, optionPrices }) {
  return function(dispatch) {
    if (selectedOption) {
      dispatch({
        type: GET_ITEMS_POS_VALUES_REQUEST
      });
      api
        .get(
          `/menu/options/${selectedOption.id}/items?include=pos_mappings,sub_items.pos_mappings&page_size=1000&page_number=1&search_string=`
        )
        .then(response => {
          dispatch({
            type: GET_ITEMS_POS_VALUES_SUCCESS,
            payload: renderItemsModified(
              response.data.data,
              variants,
              optionPrices
            ),
            meta: response.data.meta
          });
        });
    }
  };
}

export function submitPOSValuesItems(
  values,
  itemsMappingFields,
  selectedOption,
  variants,
  optionPrices
) {
  return function(dispatch) {
    let finalArr = [];
    let reqFields = [];
    let whiteFields = [];

    values.items.map((item, index) => {
      const variantIds = item.prices.map(variant => variant.variant_id);

      Object.keys(item.pos_mappings).map(key => {
        if (!variantIds.includes(parseInt(key))) {
          delete item.pos_mappings[key];
        }
      });

      let data = [];
      mappingsLoop: for (let [key, values] of Object.entries(
        item.pos_mappings
      )) {
        if (values === undefined) continue;

        Object.keys(itemsMappingFields).map(key => {
          const value = values[key];

          if (
            typeof value !== 'boolean' &&
            (value == '' || value == null) &&
            !itemsMappingFields[key].optional &&
            !reqFields.includes(itemsMappingFields[key].label)
          ) {
            reqFields.push(itemsMappingFields[key].label);
          }
          if (typeof value !== 'boolean' && value && value.trim() == '')
            whiteFields.push('White Space');
        });

        let item = {
          product_variant_id: parseInt(key)
        };
        for (let [field, field_data] of Object.entries(itemsMappingFields)) {
          if (
            field_data.type !== 'boolean' &&
            (values[field] == undefined || values[field] == '')
          ) {
            delete item[field];
          } else item[field] = values[field];
        }

        data.push(item);
        continue mappingsLoop;
      }

      data = data.filter(d => variantIds.includes(d.product_variant_id));

      if (reqFields.length) {
        const message =
          reqFields.length == 1 ? ' is required!' : ' are required!';
        dispatch(
          createAlert({
            type: 'error',
            message: reqFields.toString() + message
          })
        );
        return;
      }

      if (whiteFields.length) {
        dispatch(
          createAlert({
            type: 'error',
            message: 'Empty spaces are not allowed!'
          })
        );
        return;
      }

      finalArr.push(
        api.put(
          `/menu/options/${item.product_option_id}/items/${item.id}/pos-mapping`,
          { mappings: data }
        )
      );
    });

    if (reqFields.length) return;
    if (whiteFields.length) return;

    Promise.all(finalArr)
      .then(responses => {
        dispatch(
          getItemsPOSValues({
            selectedOption,
            variants,
            optionPrices
          })
        );
        dispatch(
          createAlert({
            type: 'success',
            message: 'POS values were saved successfully.'
          })
        );
      })
      .catch(err => {
        dispatch(
          createAlert({
            type: 'error',
            message: 'An error occurred trying to update POS values.'
          })
        );
      });
  };
}

export function updateItemsRequestParams(requestParams) {
  return {
    type: 'UPDATE_ITEMS_REQUEST_PARAMS',
    payload: requestParams
  };
}
