import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import fetchOnParamChange from '../HOCs/fetchOnParamChange';
import exportCSVWrapper from '../HOCs/exportCSVWrapper';
import * as settlementsActions from './actions';
import * as batchDetailActions from './BatchDetail/actions';
import SettlementsIndex from './components/SettlementsIndex';
import moment from 'moment';

class Settlements extends Component {
  state = {
    checkedIds: [],
    allCheckboxesAreSelected: false,
    typeOfReportChecked: null,
    startDate: null,
    endDate: null,
    focusedInput: null,
    leftInvalid: null,
    rightInvalid: null,
    filteredSettlements: null
  };

  componentDidMount() {
    track.page('finance-settlement-list');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.requestParams !== this.props.requestParams) {
      this.setState({
        checkedIds: [],
        allCheckboxesAreSelected: false,
        typeOfReportChecked: null
      });
    }
    if (prevState.checkedIds !== this.state.checkedIds) {
      if (!this.state.checkedIds.length) {
        this.setState({ typeOfReportChecked: null });
      }
    }

    if (this.props.settlements.response !== prevProps.settlements.response) {
      if (this.state.startDate && this.state.endDate) {
        this.filterByDate(this.state.startDate, this.state.endDate);
      } else {
        this.setFilterSettlements(this.props.settlements.response.data);
      }
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      (this.state.startDate !== prevState.startDate ||
        this.state.endDate !== prevState.endDate)
    ) {
      this.filterByDate(this.state.startDate, this.state.endDate);
    }
  }

  filterByDate = (startDate, endDate) => {
    this.setFilterSettlements(
      this.props.settlements.response.data.filter(settlement => {
        return (
          moment(settlement.period_begin, 'YYYY-MM-DD').isSameOrAfter(
            startDate,
            'day'
          ) &&
          moment(settlement.period_end, 'YYYY-MM-DD').isSameOrBefore(
            endDate,
            'day'
          )
        );
      })
    );
  };

  clearSelectedDates = () => {
    this.setState({ startDate: null, endDate: null });
    this.setFilterSettlements(this.props.settlements.response.data);
  };

  setStartDate = startDate => {
    this.setState({ startDate });
  };

  setEndDate = endDate => {
    this.setState({ endDate });
  };

  setFocusedInput = focusedInput => {
    this.setState({ focusedInput });
  };

  setLeftInvalid = leftInvalid => {
    this.setState({ leftInvalid });
  };

  setRightInvalid = rightInvalid => {
    this.setState({ rightInvalid });
  };

  setFilterSettlements = filteredSettlements => {
    this.setState({ filteredSettlements });
  };

  handleToggleCheckbox = settlement => {
    if (!this.state.checkedIds.length) {
      this.setState({
        typeOfReportChecked: settlement.settlement_report_version
      });
    }
    if (this.state.checkedIds.includes(settlement.batch_id)) {
      this.setState({
        checkedIds: this.state.checkedIds.filter(
          id => id !== settlement.batch_id
        )
      });
    } else {
      this.setState({
        checkedIds: [...this.state.checkedIds, settlement.batch_id]
      });
    }
  };

  toggleAllCheckboxes = () => {
    if (this.state.filteredSettlements) {
      if (this.state.allCheckboxesAreSelected) {
        this.setState({ checkedIds: [], allCheckboxesAreSelected: false });
      } else {
        if (
          this.state.filteredSettlements.find(
            settlement => settlement.settlement_report_version == 'v2'
          )
        ) {
          this.setState({
            checkedIds: this.state.filteredSettlements
              .filter(
                settlement => settlement.settlement_report_version == 'v2'
              )
              .map(settlement => settlement.batch_id)
          });
          this.setState({
            typeOfReportChecked: 'v2',
            allCheckboxesAreSelected: true
          });
        } else {
          this.setState({
            checkedIds: this.state.filteredSettlements.map(
              settlement => settlement.batch_id
            ),
            allCheckboxesAreSelected: true
          });
        }
      }
    }
  };

  viewBatchDetail = batch_ids => {
    const params = {
      batch_ids: batch_ids,
      export: false
    };
    this.props.fetchBatchDetail(params);
  };

  render() {
    return (
      <div>
        <SettlementsIndex
          {...this.props}
          {...this.state}
          allCheckboxesAreSelected={this.state.allCheckboxesAreSelected}
          noCheckboxesAreSelected={!this.state.checkedIds.length}
          handleToggleCheckbox={this.handleToggleCheckbox}
          toggleAllCheckboxes={this.toggleAllCheckboxes}
          checkedIds={this.state.checkedIds}
          viewBatchDetail={this.viewBatchDetail}
          typeOfReportChecked={this.state.typeOfReportChecked}
          setStartDate={this.setStartDate}
          setEndDate={this.setEndDate}
          setFocusedInput={this.setFocusedInput}
          setLeftInvalid={this.setLeftInvalid}
          setRightInvalid={this.setRightInvalid}
          setFilterSettlements={this.setFilterSettlements}
          clearSelectedDates={this.clearSelectedDates}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    settlements: state.settlements
  };
}

const initialRequestParams = {
  page_number: 1,
  page_size: 10,
  export: false,
  sorting_option: 'batch_id-desc'
};

Settlements = exportCSVWrapper(Settlements);

Settlements = fetchOnParamChange(
  Settlements,
  settlementsActions.fetchSettlements,
  initialRequestParams
);

export default connect(mapStateToProps, {
  ...settlementsActions,
  ...batchDetailActions
})(Settlements);
