import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './actions';
import {
  initiateCreateLocation,
  initiateEditLocation
} from '../LocationDetailsManager/actions';
import BusinessLocationsList from './components/BusinessLocationsList';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import { formatLocations } from '../../../utils/childLocations/formatLocations';

class BusinessLocationsListContainer extends Component {
  componentDidMount() {
    track.page('app-settings-locations-list');
    this.props.getBusinessLocationsOfMerchant();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.businessSearchManager.selectedBusiness !=
      prevProps.businessSearchManager.selectedBusiness
    ) {
      if (!this.props.businessSearchManager.selectedBusiness) {
        this.props.getBusinessLocationsOfMerchant();
      } else {
        this.props.filterBusinessLocationsOfMerchantWithQuery(
          this.props.businessSearchManager.selectedBusiness
        );
      }
    }
  }

  handleBusinessLocationAction = businessId => {
    // this.props.history.push(`/locations/${businessId}`);
    this.props.initiateEditLocation(businessId);
  };

  render() {
    const pageName = 'Locations List';
    const {
      requestingGetBusinessLocationsOfMerchant,
      selectedBusinessLocationsOfMerchant
    } = this.props.businessLocationsList;
    return (
      <div>
        <BreadCrumb pageName={pageName} />
        <BusinessLocationsList
          requestingGetBusinessLocationsOfMerchant={
            requestingGetBusinessLocationsOfMerchant
          }
          businessLocationsOfMerchant={formatLocations(
            selectedBusinessLocationsOfMerchant
          )}
          handleBusinessLocationAction={this.handleBusinessLocationAction}
          {...this.props}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    businessLocationsList: state.businessLocationsList,
    businessSearchManager: state.businessSearchManager
  };
}

export default connect(mapStateToProps, {
  ...actions,
  initiateCreateLocation,
  initiateEditLocation
})(BusinessLocationsListContainer);
