import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { submitUser, toggleCreateUserModal } from '../../rolesActions';
import CreateEditUserModal from './CreateEditUserModal';

const CreateEditUserModalContainer = ({
  roles,
  toggleCreateUserModal,
  submitUser,
  businesses,
  account,
  isModal
}) => {
  return (
    <div>
      <CreateEditUserModal
        roles={roles}
        businesses={businesses}
        submitUser={submitUser}
        toggleCreateUserModal={toggleCreateUserModal}
        account={account}
        isModal={isModal}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  account: state.rolesReducer.account
});

export default connect(mapStateToProps, { submitUser })(
  CreateEditUserModalContainer
);
