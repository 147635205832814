import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import { RenderSelect } from '../../../../../components/FormElements/FormikElements';

const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-rows: 120px auto;
`;

const Subtitle = styled.div`
  font-weight: 600;
`;

const ConditionWrapper = styled.div`
  display: grid;
  grid-gap: 15px;
`;

const Conditions = ({ allProducts, allVariants, conditions, templateName }) => {
  let prefix = '';
  if (templateName === 'free-item') {
    prefix = 'eligible';
  } else {
    prefix = 'discounted';
  }
  return conditions.map((condition, index) => {
    return (
      <React.Fragment key={index}>
        <Subtitle style={{ margin: '10px 0', textTransform: 'capitalize' }}>
          {condition.source}
        </Subtitle>
        <ProductWrapper>
          <FieldWrapper>
            <div>
              <Subtitle>Pre-Required Products</Subtitle>
              <div>
                These are Products that the customer would have to have in their
                cart for this offer to be applicable. If you want to offer a
                discount without Pre-Requisite Products, continue to Discounted
                Products
              </div>
            </div>
            <div>
              <Field
                name={`conditions[${index}].item_conditions.required_products`}
                component={RenderSelect}
                placeholder="Products"
                isMulti
                options={allProducts ? allProducts : []}
                getOptionLabel={product => product.name}
                getOptionValue={product => product.id}
                simpleValue
                valueKey="id"
              />
            </div>
          </FieldWrapper>
          <FieldWrapper>
            <div>
              <Subtitle>*Eligible Products</Subtitle>
              <div>
                These are Products that can be free. If there are no
                Pre-Requisite Products, the free products will be available to
                all customers that can view it on their app.
              </div>
            </div>
            <div>
              <Field
                name={`conditions[${index}].item_conditions.${prefix}_products`}
                component={RenderSelect}
                placeholder="Products"
                isMulti
                options={allProducts ? allProducts : []}
                getOptionLabel={product => product.name}
                getOptionValue={product => product.id}
                simpleValue
                valueKey="id"
              />
            </div>
          </FieldWrapper>
        </ProductWrapper>

        <ProductWrapper>
          <FieldWrapper>
            <div>
              <Subtitle>Pre-Required Variants</Subtitle>
              <div>
                These are Variants that the customer would have to have in their
                cart for this offer to be applicable. If you want to offer a
                discount without Pre-Requisite Variants, continue to Discounted
                Variants
              </div>
            </div>
            <div>
              <Field
                name={`conditions[${index}].item_conditions.required_variants`}
                component={RenderSelect}
                placeholder="Variants"
                isMulti
                options={allVariants ? allVariants : []}
                getOptionLabel={variant => variant.name}
                getOptionValue={variant => variant.id}
                simpleValue
                valueKey="id"
              />
            </div>
          </FieldWrapper>
          <FieldWrapper>
            <div>
              <Subtitle>*Eligible Variants</Subtitle>
              <div>
                These are Variants that can be free. If there are no
                Pre-Requisite Variants, the free Variants will be available to
                all customers that can view it on their app.
              </div>
            </div>
            <div>
              <Field
                name={`conditions[${index}].item_conditions.${prefix}_variants`}
                component={RenderSelect}
                placeholder="Variants"
                isMulti
                options={allVariants ? allVariants : []}
                getOptionLabel={variant => variant.name}
                getOptionValue={variant => variant.id}
                simpleValue
                valueKey="id"
              />
            </div>
          </FieldWrapper>
        </ProductWrapper>

        {templateName === 'discounted-item' ||
        templateName === 'birthday-discounted-item' ? (
          <ConditionWrapper>
            <div>
              Do all required products need to be in cart for this offer to be
              active?*
            </div>
            <Field
              name={`conditions[${index}].item_conditions.required_product_condition`}
              component={RenderSelect}
              options={[
                { value: 'AND', label: 'Yes' },
                { value: 'OR', label: 'No' }
              ]}
            />
          </ConditionWrapper>
        ) : null}
        <hr />
      </React.Fragment>
    );
  });
};

export default Conditions;
