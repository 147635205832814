import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../appFeedLayoutActions';
import AppFeedLayoutForm from './AppFeedLayoutForm';

const AppFeedLayoutFormContainer = ({
  initiateCreateLayout,
  createAppFeedLayout,
  setPageType,
  formInitialData,
  id,
  updateAppFeed,
  listAppFeed,
  resetAppFeedLayoutReducer
}) => {
  useEffect(() => {
    return () => {
      resetAppFeedLayoutReducer();
    };
  }, []);
  return (
    <AppFeedLayoutForm
      initiateCreateLayout={initiateCreateLayout}
      createAppFeedLayout={createAppFeedLayout}
      setPageType={() => setPageType()}
      formInitialData={formInitialData}
      id={id}
      updateAppFeed={updateAppFeed}
      listAppFeed={listAppFeed}
    />
  );
};

export default connect(null, actions)(AppFeedLayoutFormContainer);
