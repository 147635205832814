import React from 'react';
import styled from 'styled-components';
import ChooseOptionVariants from './ChooseOptionVariants';
import NoOptionVariantsSelected from './NoOptionVariantsSelected';
import OptionPricesForm from './OptionPricesForm';
import { LoadingBlueLarge } from '../../../../components/Loading/Loading';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
`;

const Price = ({
  selectOption,
  selectVariants,
  options,
  variants,
  optionPrices,
  selectedVariants,
  items,
  handleSave,
  isRequesting,
  itemOverrides,
  selectedOption,
  isRequestingItemOverrides,
  formattedBusinesses,
  isOptionOverridesModal,
  setIsOptionOverridesModal,
  page,
  setPage
}) => {
  return (
    <Wrapper>
      <ChooseOptionVariants
        selectOption={selectOption}
        selectVariants={selectVariants}
        options={options}
        variants={variants}
        optionPrices={optionPrices}
        isRequestingItemOverrides={isRequestingItemOverrides}
      />
      {items ? (
        <OptionPricesForm
          includedPick={optionPrices.included_pick}
          items={items}
          handleSave={handleSave}
          isRequesting={isRequesting}
          selectOption={selectOption}
          itemOverrides={itemOverrides}
          selectedOption={selectedOption}
          optionPrices={optionPrices}
          isRequestingItemOverrides={isRequestingItemOverrides}
          selectedVariants={selectedVariants}
          formattedBusinesses={formattedBusinesses}
          isOptionOverridesModal={isOptionOverridesModal}
          setIsOptionOverridesModal={setIsOptionOverridesModal}
          page={page}
          setPage={setPage}
        />
      ) : (
        <NoOptionVariantsSelected
          text={`To price Items, select the option and variant combinations above`}
        />
      )}
    </Wrapper>
  );
};

export default Price;
