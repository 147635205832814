import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled, { withTheme } from 'styled-components';
import { ErrorMessage } from '../FormElements/FormElements';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  makeStyles,
} from '@material-ui/core';

import Checkbox from '@material-ui/core/Checkbox';

const CategorySelectionWrapper = styled.div`
  margin-bottom: ${(props) => (props.inLine ? '12px' : '')};
`;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minWidth: '100%',
  },
  selectRoot: (props) =>
    props.small
      ? {
          paddingBottom: 10,
          paddingTop: 10,
        }
      : {},
  autocompleteRoot: (props) => ({
    backgroundColor: props.theme.white,
  }),
}));

const CustomSelect = ({
  error,
  label,
  placeholder,
  inLine,
  touched,
  whiteBackground,
  small,
  topMod,
  pagination,
  onChange,
  isMulti,
  getOptionValue,
  getOptionLabel,
  value = null,
  isClearable,
  isSearchable = true,
  isDisabled,
  options,
  noOptionsMessage,
  onInputChange,
  onBlur,
  theme,
  setTouched,
  name,
  checkbox,
  ...rest
}) => {
  value = !isMulti && Array.isArray(value) ? value[0] : value;

  const [internalValue, setInternalValue] = useState(isMulti ? [] : '');

  useEffect(() => {
    if (!isSearchable && !isMulti) {
      const index = options.findIndex((o) => o === value);
      setInternalValue(index !== -1 ? index : '');
    } else {
      setInternalValue(value || (isMulti ? [] : ''));
    }
  }, [value]);

  const classes = useStyles({ small, theme });
  const hasError = touched && error && error != '';
  const events = Object.keys(rest)
    .filter((key) => key.startsWith('on'))
    .reduce((acc, key) => {
      acc[key] = rest[key];
      return acc;
    }, {});

  const onInputChangeModified = (e, inputValue) =>
    onInputChange && onInputChange(inputValue);
  const resolveOptionLabel = (option) => {
    return !option
      ? ''
      : getOptionLabel
      ? getOptionLabel(option)
      : option.label || option.name;
  };
  const onValueChange = (selectedValue) => {
    setInternalValue(selectedValue);
    onChange && onChange(selectedValue);
  };
  const onValueChangeSelect = (selectedIndex) => {
    setInternalValue(selectedIndex);
    onChange && onChange(options[selectedIndex]);
  };
  const onBlurModified = () => {
    setTouched && name && setTouched(_.set(touched, name, true));
    onBlur && onBlur();
  };

  return (
    <CategorySelectionWrapper pagination={pagination} inLine={inLine}>
      <FormControl variant="outlined" className={classes.wrapper}>
        {!isSearchable && !isMulti && (
          <Select
            {...events}
            classes={{ root: classes.selectRoot }}
            value={internalValue}
            displayEmpty
            onChange={(e) => onValueChangeSelect(e.target.value)}
            onBlur={onBlurModified}
            disabled={new Boolean(isDisabled).valueOf()}
          >
            {placeholder && (
              <MenuItem value="" disabled>
                {placeholder}
              </MenuItem>
            )}
            {options &&
              options.map((option, index) => {
                return (
                  <MenuItem key={index} value={index}>
                    {resolveOptionLabel(option)}
                  </MenuItem>
                );
              })}
          </Select>
        )}
        {(isSearchable || isMulti) && (
          <Autocomplete
            {...events}
            classes={{ root: classes.autocompleteRoot }}
            disableClearable={!isClearable}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={label}
                  error={hasError}
                  placeholder={placeholder}
                  variant="outlined"
                />
              );
            }}
            renderOption={(option, { selected }) =>
              checkbox ? (
                <React.Fragment>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                    color="primary"
                  />
                  {resolveOptionLabel(option)}
                </React.Fragment>
              ) : (
                resolveOptionLabel(option)
              )
            }
            getOptionLabel={resolveOptionLabel}
            value={internalValue}
            onBlur={onBlurModified}
            multiple={isMulti}
            onChange={(e, selectedValue) => onValueChange(selectedValue)}
            onInputChange={onInputChangeModified}
            options={options}
            getOptionDisabled={(option) => option.isDisabled}
            getOptionSelected={(option, value) => _.isEqual(option, value)}
            noOptionsText={noOptionsMessage}
            disabled={new Boolean(isDisabled).valueOf()}
          />
        )}
        {hasError && <ErrorMessage>{error}</ErrorMessage>}
      </FormControl>
    </CategorySelectionWrapper>
  );
};

export default withTheme(CustomSelect);
