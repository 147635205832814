import React, { useEffect } from 'react';
import ReportTable from '../ReportTable/ReportTable';
import moment from 'moment';
import { BatchDetailPage } from '../../components/FormElements/FormElements';

function LoyaltyReport(props) {
  useEffect(() => {
    track.page('loyalty-report');
  }, []);

  const columnNames = [
    'Location',
    'Revenue',
    'Loyalty Points Earned',
    'Loyalty Points Redeemed',
    'Loyalty Funds Redeemed',
    'Offer Funds Redeemed',
    'Coupon Funds Redeemed',
    'Head Office Funds Redeemed',
    'Total Loyalty Program Expense',
  ];

  const initialParams = {
    page_number: 1,
    page_size: '10',
    start_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    sort_by: 'business_name',
    sort_direction: 'asc',
  };

  const nestedFields = [
    {
      leftField: 'loyalty_funds_redeemed',
      rightField: 'loyalty_funds_percent',
    },
    { leftField: 'offer_funds_redeemed', rightField: 'offer_funds_percent' },
    { leftField: 'coupon_funds_redeemed', rightField: 'coupon_funds_percent' },
    {
      leftField: 'global_credit_funds_redeemed',
      rightField: 'global_credit_funds_percent',
    },
    { leftField: 'total_expense', rightField: 'total_expense_percent' },
  ];

  const columnSort = [
    'business_name',
    'revenue',
    'points_earned',
    'points_redeemed',
    'loyalty_funds_redeemed',
    // 'loyalty_funds_percent',
    'offer_funds_redeemed',
    // 'offer_funds_percent',
    'coupon_funds_redeemed',
    // 'coupon_funds_percent',
    'global_credit_funds_redeemed',
    // 'global_credit_funds_percent',
    'total_expense',
    // 'total_expense_percent',
  ];

  return (
    <BatchDetailPage>
      <ReportTable
        title="Loyalty Program Financial Summary"
        url="/report/loyalty"
        csvRequestPath="report/loyalty"
        columnNames={columnNames}
        filters={['date']}
        initialParams={initialParams}
        emptyMessage={
          'There Are No Loyalty Reports To Show Between The Currently Selected Dates'
        }
        nestedTable
        nestedFields={nestedFields}
        boldFields={['revenue', 'total_expense', 'total_expense_percent']}
        ignoreColumns={[
          'loyalty_funds_percent',
          'offer_funds_percent',
          'global_credit_funds_percent',
          'total_expense_percent',
          'coupon_funds_percent',
        ]}
        columnSort={columnSort}
      />
    </BatchDetailPage>
  );
}

export default LoyaltyReport;
