import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import { RenderTextField } from '../../../../components/FormElements/FormikElements';
import { SmallToggleSwitch } from '../../../../components/ToggleSwitch/ToggleSwitch';
import StickyButtons from '../../../../components/StickyButtons';
import Icon from '../../../../components/Icon/Icon';
import OptionOverridesModalContainer from '../OptionOverridesModalContainer';
import { LoadingBlueLarge } from '../../../../components/Loading/Loading';
import { SELECT_LOCATION_SCREEN, SUMMARY_SCREEN } from '../priceTypes';
import { finalStructureSummaryData } from '../utils/helper';

const Card = styled.div`
  background-color: #fff;
  border-radius: 5px;

  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
`;

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 48px 51px;
  grid-gap: 10px;
`;
const Header = styled.div`
  font-weight: 600;
`;

const RowWrapper = styled.div`
  padding: 24px 32px;
  display: grid;
  grid-gap: 10px;
  border: 1px solid #e1e1e1;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
`;

const VariantName = styled.div`
  color: ${props => (props.isSelected ? null : 'red')};
`;

const VariantWrapper = styled.div`
  display: grid;
  grid-gap: 15px;
  align-items: center;
`;

const OverridesWrapper = styled.div`
  text-align: center;
`;

const IconWrapper = styled(Icon)`
  margin-left: 6px;
  font-size: 14px;
`;

const validatePrices = (items, priceKey) => {
  let errors = [];

  items.forEach(item => {
    if (item.sub_items) {
      item.sub_items.forEach(subItem => {
        subItem.prices.forEach(price => {
          if (
            price.isSelected &&
            (price.price === null || price.price === '')
          ) {
            errors = [
              ...errors,
              {
                id: item.id,
                sub_item_id: subItem.id,
                variant_id: price.variant_id,
                error: 'Required'
              }
            ];
          }
          if (
            price.isSelected &&
            (price.additional_price === null || price.additional_price === '')
          ) {
            errors = [
              ...errors,
              {
                id: item.id,
                sub_item_id: subItem.id,
                variant_id: price.variant_id,
                error: 'Required'
              }
            ];
          }
        });
      });
    } else {
      item.prices.forEach(price => {
        if (price.isSelected && (price.price === null || price.price === '')) {
          errors = [
            ...errors,
            { id: item.id, variant_id: price.variant_id, error: 'Required' }
          ];
        }
        if (
          price.isSelected &&
          (price.additional_price === null || price.additional_price === '')
        ) {
          errors = [
            ...errors,
            { id: item.id, variant_id: price.variant_id, error: 'Required' }
          ];
        }
      });
    }
  });

  return errors;
};

const OptionPricesForm = ({
  items,
  includedPick,
  handleSave,
  isRequesting,
  itemOverrides,
  selectedOption,
  isRequestingItemOverrides,
  formattedBusinesses,
  isOptionOverridesModal,
  setIsOptionOverridesModal,
  page,
  setPage
}) => {
  if (!itemOverrides || isRequestingItemOverrides) return <LoadingBlueLarge />;
  const priceKey = includedPick === 0 ? 'price' : 'additional_price';

  if (selectedOption && itemOverrides.length) {
    itemOverrides = itemOverrides.map(itemOverride => itemOverride.data);

    let totSubItems = 0;

    items = items.map((item, index) => {
      let subItemsModified = [];

      if (index != 0) {
        const addition = items[index - 1].sub_items
          ? items[index - 1].sub_items.length
          : 1;
        totSubItems = totSubItems + addition;
      }

      if (item.sub_items) {
        item.sub_items.map((subItem, subItemIndex) => {
          const overridesData = itemOverrides[subItemIndex + totSubItems];

          if (overridesData && !overridesData.length) {
            subItemsModified.push({ ...subItem, overrides: 0 });
          } else {
            const summaryData = finalStructureSummaryData(
              itemOverrides.find(overrides =>
                overrides.some(
                  override => override.product_option_item_id === subItem.id
                )
              ) || [],
              subItem,
              formattedBusinesses,
              priceKey
            );

            let summaryDataModified;
            summaryDataModified = summaryData.map(data => ({
              ...data,
              item: {
                prices: data.item.prices.filter(
                  variant => variant.name && variant.isSelected
                )
              }
            }));

            summaryDataModified = summaryDataModified.filter(
              data => data.item.prices.length > 0
            );

            subItemsModified.push({
              ...subItem,
              overrides: summaryDataModified.length
            });
          }
        });
        return {
          ...item,
          sub_items: subItemsModified
        };
      } else {
        const overridesData = itemOverrides[totSubItems];
        if (!overridesData.length) {
          return {
            ...item,
            overrides: 0
          };
        }

        const summaryData = finalStructureSummaryData(
          itemOverrides.find(overrides =>
            overrides.some(
              override => override.product_option_item_id === item.id
            )
          ) || [],
          item,
          formattedBusinesses,
          priceKey
        );

        let summaryDataModified;
        summaryDataModified = summaryData.map(data => ({
          ...data,
          item: {
            prices: data.item.prices.filter(
              variant => variant.name && variant.isSelected
            )
          }
        }));

        summaryDataModified = summaryDataModified.filter(
          data => data.item.prices.length > 0
        );

        return {
          ...item,
          overrides: summaryDataModified.length
        };
      }
    });
  }

  let totSubItem = 0;

  return (
    <div>
      <Card>
        <Formik initialValues={{ items }} enableReinitialize={true}>
          {({ dirty, values, setValues, setFieldValue, handleReset }) => (
            <Fragment>
              <HeaderWrapper>
                <Header>Items</Header>
                <Header>Price Equal Across Variants?</Header>
                <Header>Variants</Header>
                <Header>Price</Header>
                <Header>Additional Price</Header>
                <Header style={{ textAlign: 'center' }}>Overrides</Header>
              </HeaderWrapper>

              {values.items.map((item, index) => {
                if (item.sub_items && index != 0) {
                  const addition = items[index - 1].sub_items
                    ? items[index - 1].sub_items.length
                    : 1;
                  totSubItem = totSubItem + addition;
                  // totSubItem = totSubItem + items[index - 1].sub_items.length;
                }
                return (
                  <Fragment>
                    <RowWrapper key={index}>
                      <div>{item.name}</div>
                      {item.sub_items && (
                        <Fragment>
                          <div />
                          <div />
                          <div />
                        </Fragment>
                      )}
                      {!item.sub_items && (
                        <Fragment>
                          <div>
                            <SmallToggleSwitch
                              checked={item.variantsAreEqual}
                              onChange={e => {
                                setFieldValue(
                                  `items[${index}].variantsAreEqual`,
                                  e.target.checked
                                );

                                item.prices.forEach((price, priceIndex) => {
                                  priceIndex !== 0 &&
                                    setFieldValue(
                                      `items[${index}].prices[${priceIndex}].price`,
                                      item.prices[0].price
                                    );
                                  priceIndex !== 0 &&
                                    setFieldValue(
                                      `items[${index}].prices[${priceIndex}].additional_price`,
                                      item.prices[0].additional_price
                                    );
                                });
                              }}
                            />
                          </div>
                          <VariantWrapper>
                            {!item.variantsAreEqual ? (
                              item.prices.map((price, priceIndex) => (
                                <VariantName
                                  isSelected={price.isSelected}
                                  key={priceIndex}
                                >
                                  {price.variantName}
                                </VariantName>
                              ))
                            ) : (
                              <div>
                                {
                                  item.prices.filter(price => price.isSelected)
                                    .length
                                }{' '}
                                Variants
                              </div>
                            )}
                          </VariantWrapper>
                          <VariantWrapper>
                            {!item.variantsAreEqual ? (
                              item.prices.map((price, priceIndex) =>
                                price.isSelected ? (
                                  <Field
                                    key={priceIndex}
                                    name={`items[${index}].prices[${priceIndex}].price`}
                                    component={RenderTextField}
                                    required
                                    label="Price"
                                    isPrice={true}
                                  />
                                ) : (
                                  <div style={{ height: '55px' }} />
                                )
                              )
                            ) : (
                              <Field
                                name={`items[${index}].prices[0].price`}
                                component={RenderTextField}
                                required
                                label="Price"
                                isPrice={true}
                                onChange={e =>
                                  item.prices.forEach((price, priceIndex) => {
                                    priceIndex !== 0 &&
                                      setFieldValue(
                                        `items[${index}].prices[${priceIndex}].price`,
                                        e.target.value
                                      );
                                  })
                                }
                              />
                            )}
                          </VariantWrapper>
                          <VariantWrapper>
                            {!item.variantsAreEqual ? (
                              item.prices.map((price, priceIndex) =>
                                price.isSelected ? (
                                  <Field
                                    key={priceIndex}
                                    name={`items[${index}].prices[${priceIndex}].additional_price`}
                                    component={RenderTextField}
                                    required
                                    label="Additional Price"
                                    isPrice={true}
                                  />
                                ) : (
                                  <div style={{ height: '55px' }} />
                                )
                              )
                            ) : (
                              <Field
                                name={`items[${index}].prices[0].additional_price`}
                                component={RenderTextField}
                                required
                                label="Additional Price"
                                isPrice={true}
                                onChange={e =>
                                  item.prices.forEach((price, priceIndex) => {
                                    priceIndex !== 0 &&
                                      setFieldValue(
                                        `items[${index}].prices[${priceIndex}].additional_price`,
                                        e.target.value
                                      );
                                  })
                                }
                              />
                            )}
                          </VariantWrapper>
                          <OverridesWrapper>
                            {item.overrides === 0
                              ? 'No Overrides'
                              : `${item.overrides} Overrides`}
                            {item.overrides === 0 ? (
                              <IconWrapper
                                className="fal fa-plus"
                                onClick={() => {
                                  setIsOptionOverridesModal({
                                    isShowing: true,
                                    item,
                                    itemIndex: index
                                  });
                                  setPage(SELECT_LOCATION_SCREEN);
                                }}
                              />
                            ) : (
                              <IconWrapper
                                className="fas fa-pencil"
                                onClick={() => {
                                  setIsOptionOverridesModal({
                                    isShowing: true,
                                    item,
                                    itemIndex: index
                                  });
                                  setPage(SUMMARY_SCREEN);
                                }}
                              />
                            )}
                          </OverridesWrapper>
                        </Fragment>
                      )}
                    </RowWrapper>
                    {item.sub_items &&
                      item.sub_items.map((subItem, subItemIndex) => (
                        <RowWrapper key={subItemIndex}>
                          <div style={{ paddingLeft: '20px' }}>
                            {subItem.name}
                          </div>
                          <div>
                            <SmallToggleSwitch
                              checked={subItem.variantsAreEqual}
                              onChange={e =>
                                setFieldValue(
                                  `items[${index}].sub_items[${subItemIndex}].variantsAreEqual`,
                                  e.target.checked
                                )
                              }
                            />
                          </div>
                          <VariantWrapper>
                            {!subItem.variantsAreEqual ? (
                              subItem.prices.map((price, priceIndex) => (
                                <VariantName
                                  isSelected={price.isSelected}
                                  key={priceIndex}
                                >
                                  {price.variantName}
                                </VariantName>
                              ))
                            ) : (
                              <div>
                                {
                                  subItem.prices.filter(
                                    price => price.isSelected
                                  ).length
                                }{' '}
                                Variants
                              </div>
                            )}
                          </VariantWrapper>
                          <VariantWrapper>
                            {!subItem.variantsAreEqual ? (
                              subItem.prices.map((price, priceIndex) =>
                                price.isSelected ? (
                                  <Field
                                    key={priceIndex}
                                    name={`items[${index}].sub_items[${subItemIndex}].prices[${priceIndex}].price`}
                                    component={RenderTextField}
                                    required
                                    label="Price"
                                    isPrice={true}
                                  />
                                ) : (
                                  <div style={{ height: '55px' }} />
                                )
                              )
                            ) : (
                              <Field
                                name={`items[${index}].sub_items[${subItemIndex}].prices[0].price`}
                                component={RenderTextField}
                                required
                                label="Price"
                                isPrice={true}
                                onChange={e =>
                                  subItem.prices.forEach(
                                    (price, priceIndex) => {
                                      priceIndex !== 0 &&
                                        setFieldValue(
                                          `items[${index}].sub_items[${subItemIndex}].prices[${priceIndex}].price`,
                                          e.target.value
                                        );
                                    }
                                  )
                                }
                              />
                            )}
                          </VariantWrapper>
                          <VariantWrapper>
                            {!subItem.variantsAreEqual ? (
                              subItem.prices.map((price, priceIndex) =>
                                price.isSelected ? (
                                  <Field
                                    key={priceIndex}
                                    name={`items[${index}].sub_items[${subItemIndex}].prices[${priceIndex}].additional_price`}
                                    component={RenderTextField}
                                    required
                                    label="Additional Price"
                                    isPrice={true}
                                  />
                                ) : (
                                  <div style={{ height: '55px' }} />
                                )
                              )
                            ) : (
                              <Field
                                name={`items[${index}].sub_items[${subItemIndex}].prices[0].additional_price`}
                                component={RenderTextField}
                                required
                                label="Additional Price"
                                isPrice={true}
                                onChange={e =>
                                  subItem.prices.forEach(
                                    (price, priceIndex) => {
                                      priceIndex !== 0 &&
                                        setFieldValue(
                                          `items[${index}].sub_items[${subItemIndex}].prices[${priceIndex}].additional_price`,
                                          e.target.value
                                        );
                                    }
                                  )
                                }
                              />
                            )}
                          </VariantWrapper>
                          <OverridesWrapper>
                            {subItem.overrides === 0
                              ? 'No Overrides'
                              : `${subItem.overrides} Overrides`}
                            {subItem.overrides === 0 ? (
                              <IconWrapper
                                className="fal fa-plus"
                                onClick={() => {
                                  setIsOptionOverridesModal({
                                    isShowing: true,
                                    item: subItem,
                                    itemIndex: subItemIndex + totSubItem
                                  });
                                  setPage(SELECT_LOCATION_SCREEN);
                                }}
                              />
                            ) : (
                              <IconWrapper
                                className="fas fa-pencil"
                                onClick={() => {
                                  setIsOptionOverridesModal({
                                    isShowing: true,
                                    item: subItem,
                                    itemIndex: subItemIndex + totSubItem
                                  });
                                  setPage(SUMMARY_SCREEN);
                                }}
                              />
                            )}
                          </OverridesWrapper>
                        </RowWrapper>
                      ))}
                    <StickyButtons
                      isVisible={dirty}
                      handleDiscard={() => handleReset()}
                      saveIsDisabled={
                        validatePrices(values.items, priceKey).length ||
                        isRequesting
                      }
                      handleSave={() => handleSave(values.items)}
                      title="Price Management"
                    />
                  </Fragment>
                );
              })}
            </Fragment>
          )}
        </Formik>
      </Card>

      {isOptionOverridesModal.isShowing && (
        <Fragment>
          <OptionOverridesModalContainer
            item={isOptionOverridesModal.item}
            setIsOptionOverridesModal={setIsOptionOverridesModal}
            selectedOption={selectedOption}
            priceKey={priceKey}
            singleItemOverrides={
              itemOverrides.find(overrides =>
                overrides.some(
                  override =>
                    override.product_option_item_id ===
                    isOptionOverridesModal.item.id
                )
              ) || []
            }
            page={page}
            setPage={setPage}
            items={items}
            formattedBusinesses={formattedBusinesses}
          />
        </Fragment>
      )}
    </div>
  );
};

export default OptionPricesForm;
