import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../productsActions';
import * as selectors from '../productsSelectors';
import ProductIndex from './components/ProductIndex';
import { LoadingBlueLarge } from '../../../../components/Loading/Loading';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import fetchOnParamChange from '../../../HOCs/fetchOnParamChange';
import { sort } from '../../../../utils/sort/sort';

export class ProductManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayedProducts: [],
      searchQuery: '',
    };
  }

  componentDidMount() {
    track.page('menu-management-product-list');
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.allProducts) {
      this.props.getProducts();
    } else {
      if (
        prevProps.requestingGetProducts !== this.props.requestingGetProducts
      ) {
        this.setState({
          displayedProducts: this.sliceProducts(this.props.allProducts),
        });
      }

      if (prevProps.allProducts !== this.props.allProducts) {
        this.setState(
          { displayedProducts: this.sliceProducts(this.props.allProducts) },
          () => {
            if (this.state.searchQuery) {
              this.applySearch();
            }
          }
        );
      }
      if (prevState.searchQuery !== this.state.searchQuery) {
        this.applySearch();
      }
      if (prevProps.requestParams !== this.props.requestParams) {
        this.setState({
          displayedProducts: this.sliceProducts(this.props.allProducts),
        });
      }
    }
  }

  handleSort = (sortArgs) => {
    let sortOrder = '';
    let order = '';
    const currentOptionAsArray = this.props.requestParams.sorting_option.split(
      '-'
    );
    if (currentOptionAsArray[0] === sortArgs.value) {
      order = currentOptionAsArray[1] === 'asc' ? 'desc' : 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    } else {
      order = 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    }
    if (this.props.allProducts) {
      const result = sort(this.props.allProducts, {
        ...sortArgs,
        sortOrder: `${order}`,
      });
      this.props.updateProductsRequestParams({
        page_number: 1,
        page_size: this.props.requestParams.page_size,
      });
      this.props.updateSortedProductsList(result, sortOrder);
    }
  };

  setSearchQuery = (event) => {
    this.setState({
      searchQuery: event.target.value.toLowerCase(),
    });
  };

  sliceProducts = (products) => {
    const indexOfFirst =
      (this.props.requestParams.page_number - 1) *
      this.props.requestParams.page_size;
    const indexOfLast =
      this.props.requestParams.page_number * this.props.requestParams.page_size;

    return products
      .filter(
        (product) =>
          product.name
            .toLowerCase()
            .indexOf(this.state.searchQuery.toLowerCase()) > -1
      )
      .slice(indexOfFirst, indexOfLast);
  };

  applySearch = () => {
    const { searchQuery } = this.state;
    const displayedProducts = this.sliceProducts(this.props.allProducts).filter(
      (product) => {
        const name = product.name.toLowerCase();
        // const categories = product.categories.filter(category => {
        //   let subCategories;
        //   if (category.sub_categories) {
        //     subCategories = category.sub_categories.filter(subCategory => {
        //       return subCategory.name.toLowerCase().indexOf(searchQuery) !== -1;
        //     });
        //   }
        //   return (
        //     category.name.toLowerCase().indexOf(searchQuery) !== -1 ||
        //     subCategories.length
        //   );
        // });
        return name.indexOf(searchQuery) !== -1;
      }
    );
    this.setState({ displayedProducts });
    this.props.updateProductsRequestParams({
      page_number: 1,
      page_size: this.props.requestParams.page_size,
    });
  };

  toggleProductAvailability = (product) => {
    this.props.toggleProduct(product.id, !product.is_active);
  };

  render() {
    const {
      initiateCreateProduct,
      initiateEditProductWizard,
      requestingToggleProductIds,
      requestParams,
      allProducts,
    } = this.props;

    let allProductsLength;
    if (allProducts) {
      allProductsLength = allProducts.filter(
        (allProducts) =>
          allProducts.name
            .toLowerCase()
            .indexOf(this.state.searchQuery.toLowerCase()) > -1
      ).length;
    }

    if (!allProducts) return <LoadingBlueLarge />;
    return (
      <div>
        <BreadCrumb />
        <ProductIndex
          products={this.state.displayedProducts}
          handleSearch={this.setSearchQuery}
          toggleProductAvailability={this.toggleProductAvailability}
          initiateEditProductWizard={initiateEditProductWizard}
          initiateCreateProduct={initiateCreateProduct}
          requestingToggleProductIds={requestingToggleProductIds}
          requestParams={requestParams}
          allProductsLength={allProductsLength}
          handleSort={this.handleSort}
        />
      </div>
    );
  }
}

const initialRequestParams = {
  page_number: 1,
  page_size: '10000',
};

ProductManager = fetchOnParamChange(
  ProductManager,
  actions.getProducts,
  initialRequestParams
);

function mapStateToProps(state) {
  return {
    allProducts: selectors.selectAllProducts(state),
    requestingToggleProductIds: selectors.selectRequestingToggleProductIds(
      state
    ),
    requestingGetProducts: state.productsReducer.requestingGetProducts,
    requestParams: state.productsReducer.requestParams,
  };
}

export default connect(mapStateToProps, actions)(ProductManager);
