import React, { Component } from 'react';
import { connect } from 'react-redux';
import fetchOnParamChange from '../HOCs/fetchOnParamChange';
import * as mediaActions from './mediaActions';
import { createAlert } from '../Alert/actions';
import MediaIndex from './components/MediaIndex';
import CreateImageContainer from './containers/CreateImageContainer';
import EditImageContainer from './containers/EditImageContainer';
import { LoadingBlueLarge } from '../../components/Loading/Loading';
import { sort } from '../../utils/sort/sort';
import CreateEditImageContainer from './containers/CreateEditImageContainer';

class MediaContainer extends Component {
  state = {
    imagesActiveList: null,
    searchString: '',
  };
  componentDidMount() {
    track.page('engagement-media');
    this.props.getImages();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.mediaReducer.images &&
      prevProps.mediaReducer.images != this.props.mediaReducer.images
    ) {
      this.setState({
        imagesActiveList: this.sliceImages(this.props.mediaReducer.images),
      });
    }
    if (prevState.searchString != this.state.searchString) {
      this.setState({
        imagesActiveList: this.sliceImages(this.props.mediaReducer.images),
      });
    }
    if (
      this.props.mediaReducer.requestParams.page_number !==
        prevProps.mediaReducer.requestParams.page_number ||
      this.props.mediaReducer.requestParams.page_size !==
        prevProps.mediaReducer.requestParams.page_size
    ) {
      this.setState({
        imagesActiveList: this.sliceImages(this.props.mediaReducer.images),
      });
    }
  }

  componentWillUnmount() {
    this.props.resetMediaReducerState();
  }

  filterImagesBySearch = (images) => {
    return images.filter(
      (image) =>
        image.name
          .toLowerCase()
          .indexOf(this.state.searchString.toLowerCase()) > -1
    );
  };

  sliceImages = (images) => {
    const indexOfFirst =
      (this.props.mediaReducer.requestParams.page_number - 1) *
      this.props.mediaReducer.requestParams.page_size;
    const indexOfLast =
      this.props.mediaReducer.requestParams.page_number *
      this.props.mediaReducer.requestParams.page_size;
    return images
      .filter(
        (image) =>
          image.name
            .toLowerCase()
            .indexOf(this.state.searchString.toLowerCase()) > -1
      )
      .slice(indexOfFirst, indexOfLast);
  };

  handleSort = (sortArgs) => {
    let sortOrder = '';
    let order = '';
    const currentOptionAsArray = this.props.mediaReducer.requestParams.sorting_option.split(
      '-'
    );
    if (currentOptionAsArray[0] === sortArgs.value) {
      order = currentOptionAsArray[1] === 'asc' ? 'desc' : 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    } else {
      order = 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    }
    if (this.props.mediaReducer.images) {
      const result = sort(this.props.mediaReducer.images, {
        ...sortArgs,
        sortOrder: `${order}`,
      });
      this.props.updateMediaRequestParams({
        page_number: 1,
        page_size: this.props.mediaReducer.requestParams.page_size,
      });
      this.props.updateSortedImagesList(result, sortOrder);
    }
  };

  handleImagesSearch = (searchString) => {
    this.props.updateMediaRequestParams({
      page_number: 1,
      page_size: this.props.mediaReducer.requestParams.page_size,
    });
    this.setState({ searchString: searchString });
  };

  render() {
    const {
      mediaReducer,
      updateRequestParams,
      showCreateImageForm,
      showEditImageForm,
      createAlert,
      deleteImageRequest,
    } = this.props;
    const {
      createImageFormIsVisible,
      editImageFormIsVisible,
      requestParams,
    } = mediaReducer;
    return (
      <div>
        {createImageFormIsVisible && <CreateEditImageContainer/>}
        {editImageFormIsVisible && <EditImageContainer />}
        {this.state.imagesActiveList ? (
          <MediaIndex
            mediaReducer={mediaReducer}
            requestParams={requestParams}
            updateRequestParams={updateRequestParams}
            showCreateImageForm={showCreateImageForm}
            showEditImageForm={showEditImageForm}
            createAlert={createAlert}
            deleteImageRequest={deleteImageRequest}
            imagesActiveList={this.state.imagesActiveList}
            handleImagesSearch={this.handleImagesSearch}
            searchString={this.state.searchString}
            handleSort={this.handleSort}
            filterImagesBySearch={this.filterImagesBySearch}
          />
        ) : (
          <LoadingBlueLarge />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mediaReducer: state.mediaReducer,
  };
}

export default connect(mapStateToProps, { ...mediaActions, createAlert })(
  MediaContainer
);
