import React from 'react';
import styled from 'styled-components';
import {
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td
} from '../../../components/Table/TableElements';
import Icon from '../../../components/Icon/Icon';
import { getPagesWithPermissions } from '../configs/pagesConfig';

const CheckMarkIcon = styled(Icon)`
  cursor: default;
  font-size: 16px;
  color: ${props => props.theme.cerulean};
`;

const XMarkIcon = styled(Icon)`
  cursor: default;
  font-size: 19px;
  font-weight: 400;
  color: ${props => props.theme.gray};
`;

const PageName = styled.span`
  font-weight: 600;
  color: ${props => props.theme.black};
`;

const CheckMark = props => {
  return <CheckMarkIcon className="fas fa-check" />;
};

const XMark = props => {
  return <XMarkIcon className="fal fa-times" />;
};

const RolesTable = props => {
  const data = getPagesWithPermissions();

  return (
    <div style={{ overflow: 'scroll' }}>
      <Table style={{ borderLeft: 'none', borderRight: 'none' }}>
        <thead>
          <TrHead>
            {data.headers.map((item, index) => (
              <Th key={index}>
                {item
                  .toLowerCase()
                  .replace(/_/g, ' ')
                  .replace(/role/g, '')
                  .split(' ')
                  .map(item => item.charAt(0).toUpperCase() + item.slice(1))
                  .join(' ')}
              </Th>
            ))}
          </TrHead>
        </thead>
        <TBody>
          {data.rows.map((item, index) => (
            <Tr key={index}>
              <Td key={index} style={{ fontWeight: '600' }}>
                {item.name}
              </Td>
              {item.authorization.map((value, index) => (
                <Td key={index} align="center">
                  {value ? <CheckMark /> : <XMark />}
                </Td>
              ))}
            </Tr>
          ))}
        </TBody>
      </Table>
    </div>
  );
};

export default RolesTable;
