import React, {useState} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import Select from '../../../../../components/Select/Select';

const Card = styled.div`
  background-color: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  //   padding: 48px;
  border-radius: 6px;
  display: grid;
  grid-gap: 32px;
`;

const NonTableElements = styled.div`
  display: grid;
  grid-gap: 24px;
  padding: 48px;
`;

const Title = styled.div`
  font-size: 21px;
  font-weight: 600;
`;

const AddProductWrapper = styled.div`
  width: 50%;
  display: grid;
  grid-gap: 24px 48px;
  grid-template-columns: auto 1fr;
`;

const Subtitle = styled.div`
  font-weight: 600;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`;

const SelectWrapper = styled.div`
  div:nth-child(3n) {
    align-self: flex-start;
  }

  i {
    align-self: center;
  }

  .react-select__control {
    background-color: white;
    border-radius: 6px;
  }

  .react-select__single-value {
    font-weight: 600;
    font-size: 14px;
  }

  .react-select__multi-value {
    background-color: #e6e6e6;
    border-radius: 15px;
    padding: 1px 3px;
    font-weight: 400;
    font-size: 14px;
  }
`;

const LinkProduct = ({}) => {
  const [linkProductIsActive, setLinkProductIsActive] = useState(false);
  return (
    <Card>
      <NonTableElements>
        <Title>Products Associated</Title>
        <AddProductWrapper>
          <Subtitle
            onClick={() => setLinkProductIsActive(!linkProductIsActive)}
          >
            + Link a Product
          </Subtitle>
          <SelectWrapper>
            <Select
              options={[{name: 'name', id: 1}]}
              getOptionLabel={product => product.name}
              getOptionValue={product => product}
              onChange={e => console.log(e)}
              placeholder="Search Product"
            />
          </SelectWrapper>
        </AddProductWrapper>
      </NonTableElements>
    </Card>
  );
};

export default LinkProduct;
