import React, { Fragment } from 'react';
import styled from 'styled-components';
import {
  TableWrapper,
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
  TdFlex
} from '../../../../../../components/Table/TableElements';
import Icon, { EditIcon, DeleteIcon } from '../../../../../../components/Icon';

const Badge = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 25px;
  text-align: center;
  background: #becfed;
`;

const NoBadge = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 25px;
  text-align: center;
`;

const TdPadding = styled(Td)`
  padding: 5px 100px;
  @media screen and (max-width: 1333px) {
    padding: 5px 0;
  }
`;

const ActionsGrid = styled.div`
  display: grid;
  margin: 0 10px;
  grid-template-columns: 1fr 1fr;
`;

const EditDeliveryContainer = styled.div``;
const DeleteDeliveryContainer = styled.div``;

const DayAbbrevGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

const EmptyStateMessage = styled.p`
  margin: 100px 60px 0px 60px;
  text-align: center;
  color: #c9cbcf;
  font-weight: 600;
  font-size: 32px;
`;

const SpecialHoursTable = props => {
  const {
    formValues,
    handleSpecialHoursDeleteIconClick,
    handleOpenSpecialHoursForm
  } = props;
  return (
    <Fragment>
      <Table>
        <TBody striped>
          {renderHeaders()}

          {renderRows(
            formValues.specialHours,
            handleSpecialHoursDeleteIconClick,
            handleOpenSpecialHoursForm
          )}
        </TBody>
      </Table>
      {/* {!formValues.specialHours.length ? (
        <EmptyStateMessage>
          No Upcoming Special Hours for this Location
        </EmptyStateMessage>
      ) : null} */}
    </Fragment>
  );
};

const renderHeaders = () => {
  return (
    <TrHead>
      <Th align="left">Start Date</Th>
      <Th align="center">End Date</Th>
      <Th align="center">Day of Week</Th>
      <Th align="center">Actions</Th>
    </TrHead>
  );
};

const renderRows = (
  specialHours,
  handleSpecialHoursDeleteIconClick,
  handleOpenSpecialHoursForm
) => {
  return specialHours.map((specialHour, index) => {
    let specialDaysByNumber = specialHour.hours.map(
      hour => hour.open_day_of_week
    );
    return (
      <Tr key={index}>
        <Td align="center">{specialHour.startEndDate.startDate}</Td>
        <Td align="center">{specialHour.startEndDate.endDate}</Td>
        <TdPadding align="left">
          <DayAbbrevGrid>
            {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, i) => {
              if (i == 0) {
                i = 7;
              }
              if (specialDaysByNumber.includes(i)) {
                return <Badge key={i}>{day}</Badge>;
              } else {
                return <NoBadge key={i}>{day}</NoBadge>;
              }
            })}
          </DayAbbrevGrid>
        </TdPadding>
        <TdPadding align="center">
          <ActionsGrid>
            <EditDeliveryContainer>
              <EditIcon
                onClick={() => handleOpenSpecialHoursForm(specialHour)}
              />
            </EditDeliveryContainer>
            <DeleteDeliveryContainer>
              <DeleteIcon
                onClick={() =>
                  handleSpecialHoursDeleteIconClick(specialHour.hours)
                }
              />
            </DeleteDeliveryContainer>
          </ActionsGrid>
        </TdPadding>
      </Tr>
    );
  });
};
export default SpecialHoursTable;
