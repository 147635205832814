import React from 'react';
import styled from 'styled-components';
import {
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
} from '../../../../../components/Table/TableElements';
import { Button } from '../../../../../components/Button/Button';
import Icon from '../../../../../components/Icon/Icon';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';
import PaginationControl from '../../../../../components/Pagination/PaginationControl';
import { STRING, BOOLEAN, sortByTypes } from '../../../../../utils/sort/sort';
import { TooltipIcon } from '../../../../../components/Icon';
import Tooltip from '@material-ui/core/Tooltip';

const Wrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
`;

const ComboImage = styled.img`
  width: 50px;
  background-color: #d3d3d3;
`;

const TextSearch = styled.div``;

const PaginationWrapper = styled.div`
  padding-top: 20px;
`;

const TopDiv = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  justify-content: space-between;
  padding: 37px;
`;

const SortDiv = styled.div`
  padding: 0 40px 25px;
  text-align: right;
`;

const SortWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
`;

const ComboList = ({
  combosActiveList,
  initiateEditCombo,
  toggleCombo,
  meta,
  requestParams,
  updateRequestParams,
  handleCombosSearch,
  businesses,
  initiateCreateCombo,
  combosLength,
  combosList,
  setIsSortModalOpen,
  handleSort,
}) => {
  const renderLocatiosNumberString = (combo) => {
    if (combo.businesses.length === 0) return 'No Locations';
    if (combo.businesses.length === 1) return '1 Location';
    if (combo.businesses.length == businesses.length) return 'All Locations';
    else {
      return combo.businesses.length + ' ' + 'Locations';
    }
  };

  return (
    <Wrapper>
      <TopDiv>
        <TextSearch>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon className="fal fa-search" />
                </InputAdornment>
              ),
            }}
            style={{ width: '100%' }}
            variant="outlined"
            type="text"
            onChange={(e) => handleCombosSearch(e.target.value)}
            placeholder="Search combos"
          />
        </TextSearch>
        <Button onClick={() => initiateCreateCombo()}>Create a Combo</Button>
      </TopDiv>
      {combosList.length > 1 && (
        <SortDiv>
          <SortWrapper onClick={() => setIsSortModalOpen(true)}>
            <i className="far fa-sort-amount-down" /> Reorder Combos
            <Tooltip
              style={{ marginRight: 'auto' }}
              title="Edit the position of combos to change the order that they are presented in to the user"
            >
              <span>
                <TooltipIcon marginLeft />
              </span>
            </Tooltip>
          </SortWrapper>
        </SortDiv>
      )}
      <Table>
        <TBody>
          <TrHead>
            <Th align="left"></Th>
            <Th
              align="left"
              isSortable="true"
              onClick={() => {
                handleSort({
                  value: 'name',
                  sortMethod: sortByTypes,
                  sortType: STRING,
                });
              }}
              value="name"
              sortingOption={requestParams.sorting_option}
            >
              Name
            </Th>
            <Th
              align="left"
              isSortable="true"
              onClick={() => {
                handleSort({
                  value: 'locationsNumber',
                  sortMethod: sortByTypes,
                });
              }}
              value="locationsNumber"
              sortingOption={requestParams.sorting_option}
            >
              Locations
            </Th>
            <Th
              align="left"
              isSortable="true"
              onClick={() => {
                handleSort({
                  value: 'is_active',
                  sortMethod: sortByTypes,
                  sortType: BOOLEAN,
                });
              }}
              value="is_active"
              sortingOption={requestParams.sorting_option}
            >
              Active
            </Th>
          </TrHead>

          {combosActiveList.map((combo) => {
            return (
              <Tr
                key={combo.id}
                onClick={() => initiateEditCombo(combo)}
                style={{ cursor: 'pointer' }}
              >
                <Td width="50px">
                  {combo.image_url ? (
                    <ComboImage src={combo.image_url} alt={combo.name} />
                  ) : (
                    <div />
                  )}
                </Td>
                <Td>{combo.name}</Td>

                <Td>{renderLocatiosNumberString(combo)}</Td>
                <Td>
                  <Switch
                    checked={combo.is_active}
                    onChange={() => toggleCombo(combo, !combo.is_active)}
                    onClick={(e) => e.stopPropagation()}
                    value="checkedB"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Td>
              </Tr>
            );
          })}
        </TBody>
      </Table>
      <PaginationWrapper>
        <PaginationControl
          response={{
            ...combosActiveList,
            page: requestParams.page_number,
            page_size: requestParams.page_size,
            count: combosLength,
          }}
          requestParams={requestParams}
          type="combos"
        />
      </PaginationWrapper>
    </Wrapper>
  );
};

export default ComboList;
