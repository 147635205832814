import React, {Component} from 'react';
import {Bar} from 'react-chartjs-2';
import {
  NoData,
  ChartCard,
  TitleWrapper,
  Title,
  TotalWrapper,
  TitleSpan
} from '../../components/DashboardElements';
import {findMaxValue, findStepSize} from '../../utils/chart';
import Tooltip from '@material-ui/core/Tooltip';
import { TooltipIcon } from '../../../../components/Icon';

export default class TransactionsVsTotal extends Component {
  render() {
    const {locations, purchases, percentage_refunds, refunds} = this.props.data;
    const label = locations; //.slice(0, 10);
    const successfulTransactions = purchases;
    const percentageRefund = percentage_refunds;
    const barThickness = label.length < 7 ? 35 : 15;

    var suggestedMaxCurrent = findMaxValue(refunds);
    var stepSize = findStepSize(refunds);

    if (stepSize <= 1 && suggestedMaxCurrent <= 7) {
      stepSize = 1;
      suggestedMaxCurrent = 7;
    } else {
      stepSize = '';
    }

    const data = {
      labels: label,
      datasets: [
        {
          label: 'Percentage',
          type: 'line',
          data: percentageRefund,
          fill: false,
          borderColor: '#f39c12',
          backgroundColor: '#f39c12',
          pointBorderColor: '#f39c12',
          pointBackgroundColor: '#f39c12',
          pointHoverBackgroundColor: '#f39c12',
          pointHoverBorderColor: '#f39c12',
          yAxisID: 'y-axis-2',
          borderWidth: 0,
          borderColor: 'transparent',
          pointRadius: 6,
          pointHoverRadius: 6
        },
        {
          type: 'bar',
          label: 'Refunds',
          data: refunds,
          fill: false,
          backgroundColor: '#00a5db',
          borderColor: '#00a5db',
          hoverBackgroundColor: '#00a5db',
          hoverBorderColor: '#00a5db',
          yAxisID: 'y-axis-1'
        }
      ]
    };

    const options = {
      responsive: true,
      tooltips: {
        mode: 'label',
        callbacks: {
          title: function(t, d) {
            return d.labels[t[0].index];
          }
        }
      },
      legend: {
        display: false
      },
      elements: {
        line: {
          fill: false
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            ticks: {
              callback: function(t) {
                var maxLabelLength = 14;
                if (t.length > maxLabelLength)
                  return t.substr(0, maxLabelLength) + '...';
                else return t;
              },
              autoSkip: false
            },
            scaleLabel: {
              display: false
            }
          }
        ],
        yAxes: [
          {
            type: 'linear',
            display: true,
            position: 'left',
            barThickness: barThickness,
            id: 'y-axis-1',
            ticks: {
              fontColor: '#00a5db',
              fontStyle: '500',
              suggestedMin: 0,
              beginAtZero: true,
              stepSize: stepSize,
              suggestedMax: suggestedMaxCurrent
            }
          },

          {
            type: 'linear',
            display: true,
            position: 'right',
            barThickness: barThickness,
            id: 'y-axis-2',
            gridLines: {
              display: false
            },
            ticks: {
              fontColor: '#f39c12',
              suggestedMax: 15,
              callback: function(value, index, values) {
                return `${value.toLocaleString()}%`;
              }
            }
          }
        ]
      }
    };

    return (
      <ChartCard>
        <Title style={{paddingBottom: '10px'}}>
          <TitleSpan>
            Refunds by Location {' '}
            <Tooltip title="The total number of refunds happened in top locations" placement="top" arrow>
              <span> <TooltipIcon/> </span>
            </Tooltip>
          </TitleSpan> <br />
          <svg height="10" width="17.7">
            <line
              x1="0"
              y1="7"
              x2="17.7"
              y2="7"
              style={{stroke: '#00a5db', strokeWidth: '8'}}
            />
          </svg>{' '}
          <span style={{color: '#00a5db'}}> refunds </span>
          &nbsp;
          <svg height="10" width="17.7">
            <line
              x1="0"
              y1="7"
              x2="17.7"
              y2="7"
              style={{stroke: '#f39c12', strokeWidth: '8'}}
            />
          </svg>{' '}
          <span style={{color: '#f39c12'}}> % of all transactions </span>
        </Title>
        {(!label || label.length == 0) && <NoData>No Data available</NoData>}
        <Bar data={data} options={options} height={200} />
      </ChartCard>
    );
  }
}
