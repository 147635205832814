import React, { Fragment, useState} from 'react';
import styled from 'styled-components';
import { connect , reset} from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { Button, ButtonRow } from '../../../../../components/Button/Button';

import {formatRegularHours} from '../../utils/actionsFormatters';
import HoursForm from './components/HoursForm';
import * as actions from '../../actions';
import { Collapse } from '@material-ui/core';
import ChevronUpIcon from '../../../../../components/Icon/ChevronUpIcon';
import ChevronDownIcon from '../../../../../components/Icon/ChevronDownIcon';
import ConfirmCancelModal from '../../../../MenuManagement/Products/ProductWizard/components/ConfirmCancelModal';

const Card = styled.div`
    background-color: ${props => (props.disabled ? '#ebebeb' : 'white')};
    color: ${props => (props.disabled ? '#191818' : 'inherit')};
    border-radius: 20px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
    margin-bottom: 24px;
    padding-right: 20px;
`;

const ContainerDiv = styled.div`
  padding: ${props => props.padding || '0 0 0 0'};
  margin: ${props => props.margin || '0 0 0 0'};
  width: ${props => props.width || 'inherit'};
  text-align: ${props => props.align || null};
`;

const ComponentContainerDiv = styled.div`
  padding: 0 20px 36px 20px;
`;

const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 20px 30px 20px 40px;
  margin: 10px;
`;

const Title = styled.div`
  opacity: ${props => (props.disabled ? '0.5' : 'none')};
  font-weight: 600;
  font-size: 21px;
`;

const required = value => (value ? undefined : 'Required');

let HoursContainer = props => {
  const {
    initialValues,
    formValues,
    dirty
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const [confirmCancelModalIsVisible,setConfirmCancelModalIsVisible] = useState(false);

  // const timezone = initialValues.timezone;

  const handleOpenSpecialHoursForm = specialHoursToEdit => {
    props.isCreateAndEditSpecialHoursModalOpen(true);
    props.specialHoursToEdit(specialHoursToEdit);
  };

  const handleCloseSpecialHoursForm = () => {
    props.isCreateAndEditSpecialHoursModalOpen(false);
  };

  const handleSpecialHoursDeleteIconClick = specialHours => {
    props.isDeleteSpecialHoursConfirmModalOpen(true);
    props.specialHoursToDelete(specialHours);
  };

  const handleSpecialHoursCancelClick = () => {
    props.isDeleteSpecialHoursConfirmModalOpen(false);
    props.specialHoursToDelete([]);
  };

  const handleSpecialHoursModalConfirmClick = () => {
    const { specialHoursToDelete } = initialValues;

    props.initiateDeleteSpecialHours(
      initialValues.businessId,
      specialHoursToDelete
    );
  };

  const handleSpecialHoursFormSubmit = (
    businessId,
    postSpecialHours,
    patchSpecialHours,
    deleteSpecialHours
  ) => {
    props.initiateUpdateSpecialHours(
      businessId,
      postSpecialHours,
      patchSpecialHours,
      deleteSpecialHours
    );
  };

  const handleRegularHoursFormSubmit = (businessId, regularHours) => {
    props.putRegularHours(businessId, regularHours);
  }


  return (
      <Fragment>
        <Card disabled={initialValues.businessId ? false : 'true'}>
        <TitleAndCollapseButton>
            <Title style={{ textTransform: 'capitalize'}} disabled={initialValues.businessId ? false : 'true'}>
                Hours
            </Title>
            {!isExpanded ? (
            <ChevronDownIcon
                disabled={initialValues.businessId ? false : true}
                style={{
                    opacity: initialValues.businessId ? 'none' : '0.5'
                  }}
                onClick={() => {
                setIsExpanded(true);
                }}
            />
            ) : (
            <ChevronUpIcon
                onClick={() => {
                setIsExpanded(false);
                }}
            />
            )}
        </TitleAndCollapseButton>
        <Collapse in={isExpanded}>
          {formValues && 
              (<HoursForm 
                  handleCloseSpecialHoursForm={handleCloseSpecialHoursForm}
                  handleSpecialHoursDeleteIconClick={handleSpecialHoursDeleteIconClick}
                  handleSpecialHoursCancelClick={handleSpecialHoursCancelClick}
                  handleOpenSpecialHoursForm={handleOpenSpecialHoursForm}
                  handleSpecialHoursModalConfirmClick={handleSpecialHoursModalConfirmClick}
                  handleSpecialHoursFormSubmit={handleSpecialHoursFormSubmit}
                  formValues={formValues} 
                  handleHourIsOpenChange={index =>
                      props.change(`regularHours[${index}].orderAheadIsOpen`, false)
                      }
              />)}
           <ButtonRow
                justifyContent="space-between"
                style={{
                margin: '24px 40px 24px 40px',
                width: 'inherit'
                }}
                >
                <Button
                secondary
                onClick={() => {
                    props.initialize(initialValues);
                    dirty
                    ? setConfirmCancelModalIsVisible(true)
                    : setIsExpanded(false);
                }}
                >
                Cancel
                </Button>
                <Button
                disabled={!dirty}
                onClick={() => {
                  handleRegularHoursFormSubmit(formValues.businessId, formValues.regularHours);
                  // setIsExpanded(false);

                }}
                >
                Save
                </Button>
            </ButtonRow>
            {confirmCancelModalIsVisible && (
                <ConfirmCancelModal
                onCancel={() => setConfirmCancelModalIsVisible(false)}
                onConfirm={() => {
                    setConfirmCancelModalIsVisible(false);
                    setIsExpanded(false);

                }}
                />
            )}
          </Collapse>
        </Card>
      </Fragment>
  );
};

HoursContainer = reduxForm({
  form: 'HoursForm',
  enableReinitialize: true,
  persistentSubmitErrors: true
})(HoursContainer);

HoursContainer = connect(state => {
  const businessLocationDetailsReducer = state.businessLocationDetails;
  const handleOpenSpecialHoursForm = handleOpenSpecialHoursForm;
  const initialValues = {
    ...businessLocationDetailsReducer,
    regularHours : businessLocationDetailsReducer.regularHours.length > 0 
                  ? businessLocationDetailsReducer.regularHours : formatRegularHours([])
  }
  const formValues = getFormValues('HoursForm')(state)
  return {
    initialValues: initialValues,
    formValues:formValues,
    dirty: initialValues != formValues
  };
}, actions)(HoursContainer);

export default HoursContainer;
