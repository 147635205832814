import React, { Component, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { renderSelect } from '../../../../components/FormElements/ReduxForm';
import SegmentCard, { ButtonRow } from '../SegmentCard/SegmentCard';
import { Button } from '../../../../components/Button/Button';
import QueryBuilder from './QueryBuilder/QueryBuilder';
import LocationsTargeted from '../LocationsTargeted/LocationsTargeted';
import { SmallToggleSwitch } from '../../../../components/ToggleSwitch/ToggleSwitch';
import { BoldCapitalizedLabel } from '../../../../components/FormElements/FormElements';

const ErrorMessage = styled.div`
  color: red;
  margin-left: 15px;
`;

const SelectWrapper = styled.div`
  display: grid;
  grid-gap: 50px;
  margin-bottom: 20px;
  width: 80%;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
  align-items: center;
  margin-top: 25px;
`;

class FilterCriteria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emptyFieldsError: false,
      isChecked: this.props.businesses.length === 0
    };
    this.nextPage = this.nextPage.bind(this);
  }

  validateEmptyFields() {
    let emptyFieldsError = false;

    const iterateGroupRules = rules => {
      return Object.keys(rules).map((rule, index) => {
        if (
          Object.keys(this.props.criteria.filter_json.children1).length === 1 &&
          !rules[rule].properties.field
        ) {
          this.nextPage();
        }
        if (rules[rule].type === 'group') {
          iterateGroupRules(rules[rule].children1);
        } else if (
          rules[rule].properties.value == false &&
          rules[rule].properties.value != '0'
        ) {
          emptyFieldsError = true;
        }
        if (index === Object.keys(rules).length - 1) {
          this.setState({ emptyFieldsError }, () => this.nextPage());
        }
      });
    };

    if (this.props.criteria) {
      const rules = this.props.criteria.filter_json.children1;
      iterateGroupRules(rules);
    } else {
      this.nextPage();
    }
  }

  nextPage() {
    if (!this.state.emptyFieldsError) {
      this.props.fetchTargetAudienceData(
        this.props.audience,
        this.props.criteria
      );
      this.props.onSubmit();
    }
  }

  render() {
    const title = 'filter criteria';
    const instructions = (
      <div>
        A <em>Group</em> is a collection of app users, categorized by their
        purchase history such as transaction dates, points earned, total
        transactions made, etc. A <em>Group</em> is the basis for building a
        targeted audience. <em>Groups</em> are made of <em>Rules</em> that
        dictate the parameters of the
        <em>Group</em>. All <em>Groups</em> must have at least one <em>Rule</em>
        . <br />
        <br />
        You may add more <em>Rules</em> to a <em>Group</em> or add more{' '}
        <em>Groups</em> to further customise any Segment based on provided
        criteria.
      </div>
    );

    return (
      <SegmentCard
        title={title}
        instructions={instructions}
        cancelEditSegment={this.props.cancelEditSegment}
      >
        <div>
          <QueryBuilder
            updateCriteria={this.props.updateCriteria}
            criteria={this.props.criteria}
            allTags={this.props.allTags}
            allProducts={this.props.allProducts}
          />
          {this.state.emptyFieldsError && (
            <ErrorMessage>Fill out blank field(s).</ErrorMessage>
          )}
        </div>

        {/* <LocationsTargeted
          selectedLocations={this.props.selectedLocations}
          drawnOverlayZones={
            this.props.overlayWithMap ? [this.props.overlayWithMap] : null
          }
        /> */}

        <div style={{ width: '50%' }}>
          <BoldCapitalizedLabel>Choose Targeted Locations</BoldCapitalizedLabel>
          <Wrapper>
            <SmallToggleSwitch
              checked={this.state.isChecked}
              label="All Locations"
              onChange={e => {
                if (e.target.checked) {
                  this.props.change('businesses', []);
                }
                this.setState({ isChecked: e.target.checked });
              }}
            />

            {!this.state.isChecked && (
              <Field
                isMulti
                // topMod={-16}
                // zIndex={9}
                placeholder="Select locations..."
                component={renderSelect}
                getOptionValue={business => business.id}
                getOptionLabel={business => business.display_name}
                name="businesses"
                options={this.props.businessLocationsOfMerchant}
                valueKey="id"
                simpleValue
              />
            )}
          </Wrapper>
        </div>

        <ButtonRow>
          <Button
            type="button"
            onClick={() => this.props.previousPage()}
            secondary
          >
            Back
          </Button>

          <Button type="button" onClick={() => this.validateEmptyFields()}>
            Next
          </Button>
        </ButtonRow>
      </SegmentCard>
    );
  }
}

const mapStateToProps = state => ({
  businessLocationsOfMerchant:
    state.businessLocationsList.businessLocationsOfMerchant
});

export default connect(mapStateToProps)(FilterCriteria);
