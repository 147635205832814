import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../../productsActions";
import ProductTogglesForm from "../components/ProductTogglesForm";
import { selectProductTogglesIsDisabled } from "../../../productsSelectors";

const ProductTogglesFormContainer = ({
  productToggles,
  patchProductIsFeatured,
  patchProductIsAvailable,
  patchProductIsActive,
  disabled
}) => {
  return (
    <div>
      <ProductTogglesForm
        {...{ ...productToggles }}
        disabled={disabled}
        patchProductIsFeatured={patchProductIsFeatured}
        patchProductIsAvailable={patchProductIsAvailable}
        patchProductIsActive={patchProductIsActive}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  disabled: selectProductTogglesIsDisabled(state),
  productToggles: state.productsReducer.productToggles
});

export default connect(
  mapStateToProps,
  actions
)(ProductTogglesFormContainer);
