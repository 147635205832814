import {
  GET_ORDER_HISTORY_OF_MERCHANT_REQUEST,
  GET_ORDER_HISTORY_OF_MERCHANT_SUCCESS,
  GET_ORDER_HISTORY_OF_MERCHANT_FAILURE
} from './types';
import api from '../../../api';

import renderQueryString from '../../../utils/renderQueryString';

export function getOrderHistoryOfMerchant(params) {
  return function(dispatch) {
    dispatch({
      type: GET_ORDER_HISTORY_OF_MERCHANT_REQUEST
    });
    api
      .get(`/orders${renderQueryString(params)}`)
      .then(response => {
        dispatch({
          type: GET_ORDER_HISTORY_OF_MERCHANT_SUCCESS,
          orders: response.data.data,
          meta: response.data.meta
        });
      })
      .catch(error => {
        dispatch({
          type: GET_ORDER_HISTORY_OF_MERCHANT_FAILURE
        });
      });
  };
}
