import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import Card from '../../../../components/Card/Card';
import { SmallToggleSwitch } from '../../../../components/ToggleSwitch/ToggleSwitch';
import StickyButtons from '../../../../components/StickyButtons';
import OfferBasicForm from './components/OfferBasicForm';
import OfferImageForm from './components/OfferImageForm';
import LocationSelection from './components/LocationSelection';
import SegmentSelection from './components/SegmentSelection';
import FirstTimeOffer from './components/FirstTimeOffer';
import FreeItemOffer from './components/FreeItemOffer';
import DiscountOffer from './components/DiscountOffer';
import BirthdayFreeItemOffer from './components/BirthdayFreeItemOffer';
import BirthdayDiscountOffer from './components/BirthdayDiscountOffer';
import CarryOverOffer from './components/CarryOverOffer';
import SourceOptions from './components/SourceOptions';

const SubTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const InfoImageWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 7.5%;
`;

const LocationSegmentGrid = styled.div`
  display: grid;
  width: 42.5%;
  grid-gap: 50px;
  margin-top: 50px;
`;

const HrLine = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 2.5em 0;
  padding: 0;
`;

const OfferDetails = ({
  handleSubmit,
  isRequesting,
  editingOffer,
  isPointsEnabled
}) => {
  const [isFormReset, setIsFormReset] = useState(false);
  useEffect(() => {
    if (isFormReset) {
      setIsFormReset(false);
    }
  }, [isFormReset]);
  return (
    <Formik
      initialValues={
        editingOffer || {
          offer_details: {
            template_name: null,
            title: '',
            subtitle: '',
            description: '',
            label: '',
            image_url: '',
            is_active: true,
            auto_redeem: false,
            reusable: false,
            template_values: {}
          },
          businesses: [],
          segments: [],
          customers: [],
          conditions: [
            // {
            //   is_active: true,
            //   source: "mobile",
            //   item_conditions: {
            //     eligible_products: [],
            //     required_products: [],
            //     eligible_variants: [],
            //     required_variants: []
            //   }
            // }
          ]
        }
      }
      enableReinitialize={true}
    >
      {({ values, handleReset, dirty, errors, setFieldValue }) => (
        <Card>
          <SubTitleWrapper>
            <Subtitle>Offer Information</Subtitle>
            <SmallToggleSwitch
              checked={values.offer_details.is_active}
              label="Active"
              onChange={e =>
                setFieldValue('offer_details.is_active', e.target.checked)
              }
            />
          </SubTitleWrapper>
          <InfoImageWrapper>
            <OfferBasicForm
              templateName={values.offer_details.template_name}
              setFieldValue={setFieldValue}
              source={
                (values.conditions &&
                  values.conditions.length &&
                  values.conditions[0].source) ||
                ''
              }
              isPointsEnabled={isPointsEnabled}
              editingOffer={editingOffer}
            />
            <OfferImageForm
              imageUrl={values.offer_details.image_url}
              setFieldValue={setFieldValue}
            />
          </InfoImageWrapper>
          <LocationSegmentGrid>
            <LocationSelection
              setFieldValue={setFieldValue}
              businesses={values.businesses}
            />
            {/* <SegmentSelection
              setFieldValue={setFieldValue}
              segments={values.segments}
            /> */}
            {values.offer_details.template_name !== 'first-time' &&
              values.offer_details.template_name !== 'carryover-loyalty' && (
                <SourceOptions
                  templateName={values.offer_details.template_name}
                  setFieldValue={setFieldValue}
                  conditions={values.conditions}
                  isFormReset={isFormReset}
                />
              )}
          </LocationSegmentGrid>

          {values.offer_details.template_name && <HrLine />}
          {values.offer_details.template_name === 'first-time' ? (
            <FirstTimeOffer
              setFieldValue={setFieldValue}
              auto_redeem={values.offer_details.auto_redeem}
            />
          ) : values.offer_details.template_name === 'free-item' ? (
            <FreeItemOffer
              templateName={values.offer_details.template_name}
              setFieldValue={setFieldValue}
              conditions={values.conditions}
              auto_redeem={values.offer_details.auto_redeem}
              reusable={values.offer_details.reusable}
              apply_multiple={
                values.offer_details.template_values.apply_multiple
              }
              expiry_date={values.offer_details.template_values.expiry_date}
            />
          ) : values.offer_details.template_name === 'discounted-item' ? (
            <DiscountOffer
              templateName={values.offer_details.template_name}
              setFieldValue={setFieldValue}
              conditions={values.conditions}
              auto_redeem={values.offer_details.auto_redeem}
              reusable={values.offer_details.reusable}
              apply_multiple={
                values.offer_details.template_values.apply_multiple
              }
              expiry_date={values.offer_details.template_values.expiry_date}
            />
          ) : values.offer_details.template_name === 'birthday-free-item' ? (
            <BirthdayFreeItemOffer
              templateName={values.offer_details.template_name}
              setFieldValue={setFieldValue}
              conditions={values.conditions}
              auto_redeem={values.offer_details.auto_redeem}
              reusable={values.offer_details.reusable}
              apply_multiple={
                values.offer_details.template_values.apply_multiple
              }
            />
          ) : values.offer_details.template_name ===
            'birthday-discounted-item' ? (
            <BirthdayDiscountOffer
              templateName={values.offer_details.template_name}
              setFieldValue={setFieldValue}
              conditions={values.conditions}
              auto_redeem={values.offer_details.auto_redeem}
              reusable={values.offer_details.reusable}
              apply_multiple={
                values.offer_details.template_values.apply_multiple
              }
            />
          ) : values.offer_details.template_name === 'carryover-loyalty' ? (
            <CarryOverOffer setFieldValue={setFieldValue}></CarryOverOffer>
          ) : null}
          <StickyButtons
            isVisible={dirty}
            handleDiscard={() => {
              handleReset();
              setIsFormReset(true);
            }}
            handleSave={() => handleSubmit(values)}
            title="Offer Management"
            saveIsDisabled={
              Object.keys(errors).length ||
              !values.offer_details.image_url ||
              isRequesting
            }
          />
        </Card>
      )}
    </Formik>
  );
};

export default OfferDetails;
