import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  color: grey;
`;
const ContentWrapper = styled.div`
  text-align: center;
  display: grid;
  grid-gap: 10px;
  align-items: center;
`;

const ErrorLabel = styled.h1`
  font-size: 400%;
  color: #5b5a63;
`;
const ErrorHead = styled.h1`
  font-size: 150%;
  color: #5b5a63;

  @media (max-width: 750px) {
    font-size: 130%;
  }
`;
const ErrorBody = styled.div`
  font-size: 100%;

  text-align: center;
  @media (max-width: 720px) {
    display: none;
  }
`;
const PageNotFound = props => (
  <Wrapper>
    <ContentWrapper>
      <i style={{ fontSize: '1300%' }} className="fal fa-frown" />
      <ErrorLabel>404</ErrorLabel>
      <ErrorHead>Page Not Found</ErrorHead>
      <ErrorBody>
        This is almost always what happens when you try to load some random page
        that doesn’t exist.
      </ErrorBody>
    </ContentWrapper>
  </Wrapper>
);

export default PageNotFound;
