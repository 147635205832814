import React from 'react';
import styled from 'styled-components';
import {
  TableWithButtonRow,
  TableWrapper,
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td
} from '../../../../../components/Table/TableElements';
import { SmallToggleSwitch } from '../../../../../components/ToggleSwitch/ToggleSwitch';
import { LoadingBlueLarge } from '../../../../../components/Loading/Loading';
import Icon from '../../../../../components/Icon/Icon';
import { Button } from '../../../../../components/Button/Button';
import { STRING, BOOLEAN, sortByTypes } from '../../../../../utils/sort/sort';
import PaginationControl from '../../../../../components/Pagination/PaginationControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';

const Wrapper = styled.div`
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  display: grid;
  grid-gap: 20px;
`;

const TopRowTableCard = styled.div`
  margin: 15px 15px 0px;
`;

const ProductImage = styled.img`
  width: 100px;
  margin: 5px 0px;
  vertical-align: middle;
  object-fit: contain;
  background-color: #d3d3d3;
`;

const ProductIndex = ({
  products,
  initiateEditProductWizard,
  initiateCreateProduct,
  handleSearch,
  toggleProductAvailability,
  requestingToggleProductIds,
  requestParams,
  allProductsLength,
  handleSort
}) => {
  const renderRows = () => {
    return products.map((product, index) => {
      return (
        <Tr
          style={{ cursor: 'pointer' }}
          onClick={() => initiateEditProductWizard(product)}
          key={index}
          key={product.id}
        >
          <Td padding={'0px 0px 0px 0px'} align="left" width="10%">
            <ProductImage src={product.image_url} alt={product.name} />
          </Td>
          <Td padding={'5px 0px 5px 20px'} align="left" bold width="70%">
            {product.name}
          </Td>
          {/* <Td align="left">
            {product.categories.map(category => {
              const subCategoryName =
                category.sub_categories &&
                category.sub_categories.length > 0 &&
                category.sub_categories[0].name;
              return <div>{`${category.name} - ${subCategoryName}`}</div>;
            })}
          </Td> */}
          {/*<Td align="center">{product.quantity} </Td>*/}
          {/*<Td align="center">
            <EditIcon
              onClick={() => showEditProductForm(product.product_id)}
            />
          </Td>
          <Td align="center">
            <Icon className="fa fa-trash" />
          </Td>*/}
          <Td
            align="center"
            onClick={event => {
              event.stopPropagation();
            }}
            width="20%"
            // style={{
            //   position: 'relative',
            //   display: 'flex',
            //   justifyContent: 'space-around',
            //   alignItems: 'center'
            // }}
          >
            <div
              style={{
                display: 'flex',
                //justifyContent: 'space-evenly',
                alignItems: 'center'
              }}
            >
              <SmallToggleSwitch
                onChange={() => toggleProductAvailability(product)}
                checked={product.is_active}
                disabled={
                  product.is_pos_mapping_required && !product.is_pos_mapped
                }
              />

              {/* {product.is_pos_mapping_required && !product.is_pos_mapped && (
                <Tooltip title="Cannot make the product active if it is not POS mapped">
                  <Icon
                    className="fal fa-info-circle"
                    style={{ marginLeft: '10px' }}
                  />
                </Tooltip>
              )} */}
            </div>
          </Td>
        </Tr>
      );
    });
  };

  const renderHeaders = () => {
    return (
      <TrHead>
        <Th align="left" width="50" />
        <Th
          align="left"
          onClick={() => {
            handleSort({
              value: 'name',
              sortMethod: sortByTypes,
              sortType: STRING
            });
          }}
          value="name"
          sortingOption={requestParams.sorting_option}
          isSortable="true"
        >
          Product Name
        </Th>
        {/*<Th align="right">Price</Th>*/}
        {/* <Th align="left">Category</Th> */}
        {/*<Th align="center">Times Purchased</Th>*/}
        {/*<Th align="center" />
        <Th align="center" />*/}
        <Th
          align="left"
          onClick={() => {
            handleSort({
              value: 'is_active',
              sortMethod: sortByTypes,
              sortType: BOOLEAN
            });
          }}
          value="is_active"
          sortingOption={requestParams.sorting_option}
          isSortable="true"
        >
          Active
        </Th>
      </TrHead>
    );
  };

  return (
    <Wrapper>
      <TableWithButtonRow>
        <TableWrapper>
          <TopRowTableCard>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '4fr 1fr',
                gridGap: '20px',
                justifyContent: 'space-between',
                margin: '37px'
              }}
            >
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon className="fal fa-search" />
                    </InputAdornment>
                  )
                }}
                style={{ width: '100%' }}
                variant="outlined"
                type="text"
                onChange={handleSearch}
                placeholder="Search by product name"
                disabled={!products}
              />

              <Button
                style={{ fontSize: '16px', width: '100%' }}
                onClick={() => initiateCreateProduct()}
              >
                Create a Product
              </Button>
            </div>
          </TopRowTableCard>
          <Table noBorder>
            <thead>{renderHeaders()}</thead>
            {products && <TBody striped>{renderRows()}</TBody>}
          </Table>
        </TableWrapper>
        {!products && <LoadingBlueLarge />}
      </TableWithButtonRow>
      <PaginationControl
        response={{
          ...products,
          page: requestParams.page_number,
          page_size: requestParams.page_size,
          count: allProductsLength
        }}
        requestParams={requestParams}
        type="products"
      />
    </Wrapper>
  );
};

export default ProductIndex;
