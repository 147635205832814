import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateRequestParams } from '../../modules/Pagination/actions';
import Select from '../Select/Select';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

const PaginationRow = styled.div`
  display: grid;
  justify-content: flex-end;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 60px;
  font-size: 16px;
  .pagination {
    margin: 0;
  }
  margin-right: 15px;
  padding: 10px;
`;

const DropDownWrapper = styled.div`
  display: grid;
  grid-template: 40px / auto auto;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
`;

const ChevronWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 60px;
  align-items: center;
`;

const Chevron = styled.i`
  cursor: pointer;
  color: ${props => (props.disabled ? 'lightgray' : 'dimgray')};
`;

export class PaginationControl extends Component {
  constructor(props) {
    super(props);
    this.updatePagination = this.updatePagination.bind(this);
  }

  updatePagination(value) {
    this.props.updateRequestParams(
      {
        ...this.props.requestParams,
        page_size: value,
        page_number: 1
      },
      this.props.type
    );
  }

  updatePageNumber(number) {
    this.props.updateRequestParams(
      {
        ...this.props.requestParams,
        page_number: number
      },
      this.props.type
    );
  }

  changePage(action) {
    const currentPageNumber = this.props.requestParams.page_number;
    let nextPageNumber =
      action === 'next' ? currentPageNumber + 1 : currentPageNumber - 1;
    this.updatePageNumber(nextPageNumber);
  }

  lastNumberOnPage() {
    const { page, page_size, count } = this.props.response;
    const number = page * page_size;
    return number > count ? count : number;
  }

  render() {
    const { page, page_size, count } = this.props.response;
    const isFirstPage = page == 1;
    const isLastPage = page * page_size >= count;

    const pageIntervalOptions = [
      { value: 5, label: '5' },
      { value: 10, label: '10' },
      { value: 25, label: '25' },
      { value: 50, label: '50' },
      { value: 100, label: '100' }
    ];

    return (
      <PaginationRow>
        <DropDownWrapper>
          Results Per Page
          <div style={{ width: '90px' }}>
            <Select
              small
              pagination
              isSearchable={false}
              options={pageIntervalOptions}
              value={pageIntervalOptions.filter(
                ({ value }) => value == this.props.requestParams.page_size
              )}
              onChange={option => this.updatePagination(option.value)}
              isDisabled={this.props.isResultsPerPageDisabled}
            />
          </div>
        </DropDownWrapper>

        <div>
          {(page - 1) * page_size + 1}-{this.lastNumberOnPage()} of {count}
        </div>
        <ChevronWrapper>
          {isFirstPage ? (
            <Chevron className="fa fa-chevron-left" disabled />
          ) : (
            <Tooltip
              title={
                'Please save the changes before searching for a new value!'
              }
              disableHoverListener={!this.props.dirty}
              disableTouchListener={!this.props.dirty}
              disableFocusListener={!this.props.dirty}
              placement="top"
            >
              <Chevron
                className="fa fa-chevron-left"
                onClick={() => this.changePage('prev')}
                disabled={this.props.dirty}
              />
            </Tooltip>
          )}
          {isLastPage ? (
            <Chevron className="fa fa-chevron-right" disabled />
          ) : (
            <Tooltip
              title={
                'Please save the changes before searching for a new value!'
              }
              disableHoverListener={!this.props.dirty}
              disableTouchListener={!this.props.dirty}
              disableFocusListener={!this.props.dirty}
              placement="top"
            >
              <Chevron
                className="fa fa-chevron-right"
                onClick={() => this.changePage('next')}
                disabled={this.props.dirty}
              />
            </Tooltip>
          )}
        </ChevronWrapper>
      </PaginationRow>
    );
  }
}

PaginationControl.propTypes = {
  response: PropTypes.object.isRequired,
  requestParams: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};
// response object must contain:
// {
//   "count": "0",
//   "page": 1,
//   "page_size": 10
// }

export default connect(null, { updateRequestParams })(PaginationControl);
