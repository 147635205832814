import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Formik, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import { Collapse } from '@material-ui/core';
import { RenderSelect } from '../../../../../../components/FormElements/FormikElements';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import { Button, ButtonRow } from '../../../../../../components/Button/Button';
import EditIcon from '../../../../../../components/Icon/EditIcon';
import ConfirmCancelModal from '../../components/ConfirmCancelModal';
import ProductTagsSummary from './ProductTagsSummary';

const Card = styled.div`
  background: ${props => (props.disabled ? '#ebebeb' : 'white')};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 32px 48px;
  color: ${props => (props.disabled ? '#959595' : 'initial')};
  label {
    z-index: 0;
  }
`;

const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
`;

const ProductTagsWrapper = styled.div`
  display: grid;
  grid-gap: 24px;

  .react-select__control {
    background-color: white;
    border-radius: 6px;
  }

  .react-select__single-value {
    font-weight: 600;
    font-size: 14px;
  }

  .react-select__multi-value {
    background-color: #e6e6e6;
    border-radius: 15px;
    padding: 1px 3px;
    font-weight: 400;
    font-size: 14px;
  }
`;

let ProductTagsForm = ({
  disabled,
  isExpanded,
  setIsExpanded,
  initialValues,
  allProductTags,
  setConfirmCancelModalIsVisible,
  confirmCancelModalIsVisible,
  putProductTags
}) => {
  return (
    <Card
      disabled={disabled}
      onClick={() => !disabled && setIsExpanded(!isExpanded)}
    >
      <Formik initialValues={initialValues} enableReinitialize={true}>
        {({ dirty, values, setValues, setFieldValue }) => (
          <Fragment>
            <TitleAndCollapseButton>
              <Title>Tags - Optional</Title>
              {!isExpanded ? (
                <ChevronDownIcon
                  disabled={disabled}
                  onClick={() => setIsExpanded(true)}
                />
              ) : (
                <ChevronUpIcon
                  disabled={dirty}
                  onClick={() => setIsExpanded(false)}
                />
              )}
            </TitleAndCollapseButton>
            {!isExpanded && (
              <ProductTagsSummary
                productTagsInitialValues={initialValues.productTags}
                allProductTags={allProductTags}
              />
            )}
            {isExpanded && (
              <Fragment>
                <ProductTagsWrapper onClick={e => e.stopPropagation()}>
                  <Field
                    name="productTags"
                    component={RenderSelect}
                    options={allProductTags}
                    isMulti
                    setFieldValue={setFieldValue}
                    getOptionLabel={tag => tag.name}
                    getOptionValue={tag => tag.tag_id}
                    valueKey="tag_id"
                    placeholder="Select Tags*"
                    required
                  />
                </ProductTagsWrapper>
                <ButtonRow
                  justifyContent="space-between"
                  style={{
                    marginTop: '24px'
                  }}
                >
                  <Button
                    secondary
                    onClick={e => {
                      e.stopPropagation();
                      dirty
                        ? setConfirmCancelModalIsVisible(true)
                        : setIsExpanded(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!dirty}
                    onClick={e => {
                      e.stopPropagation();
                      putProductTags(values.productTags);
                      setIsExpanded(false);
                    }}
                  >
                    Save
                  </Button>
                </ButtonRow>
                {confirmCancelModalIsVisible && (
                  <ConfirmCancelModal
                    onCancel={() => setConfirmCancelModalIsVisible(false)}
                    onConfirm={() => {
                      setValues(initialValues);
                      setConfirmCancelModalIsVisible(false);
                      setIsExpanded(false);
                    }}
                  />
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </Formik>
    </Card>
  );
};

export default ProductTagsForm;
