import React, { Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Formik, Field, FieldArray } from 'formik';
import DeleteIcon from '../../../../../../components/Icon/DeleteIcon';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import { Button, ButtonRow } from '../../../../../../components/Button/Button';
import { RenderSelect } from '../../../../../../components/FormElements/FormikElements';
import ConfirmCancelModal from '../../components/ConfirmCancelModal';
import { Collapse } from '@material-ui/core';

const Card = styled.div`
  background: ${props => (props.disabled ? '#ebebeb' : 'white')};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 32px 48px;
  color: ${props => (props.disabled ? '#959595' : 'initial')};
`;

const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 18px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
`;

const Description = styled.div`
  font-size: 14px;
`;

const CategorySelectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 16px;
  grid-gap: 24px;

  div:nth-child(3n) {
    align-self: flex-start;
  }

  i {
    align-self: center;
  }

  .react-select__control {
    background-color: white;
    border-radius: 6px;
  }

  .react-select__single-value {
    font-weight: 600;
    font-size: 14px;
  }

  .react-select__multi-value {
    background-color: #e6e6e6;
    border-radius: 15px;
    padding: 1px 3px;
    font-weight: 400;
    font-size: 14px;
  }
`;

const AddNewCategory = styled.div`
  font-weight: 600;
  color: ${props => props.disabled && '#6f6f76'};
  margin-right: auto;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  cursor: pointer;
`;

let ProductCategoriesForm = props => {
  const {
    categories,
    isExpanded,
    setIsExpanded,
    putProductCategories,
    initialValues,
    disabled,
    confirmCancelModalIsVisible,
    setConfirmCancelModalIsVisible
  } = props;

  const renderSummary = () => (
    <div>
      {initialValues.categories.map((category, index) => {
        let foundCategory = categories.find(
          globalCategory => category.id == globalCategory.id
        );
        let subCategoriesNames = [];
        if (foundCategory && category.subCategoriesId) {
          category.subCategoriesId.forEach(subCategoryId => {
            let foundSubCategory = foundCategory.sub_categories.find(
              subCategory => subCategory.id == subCategoryId
            );
            subCategoriesNames.push(foundSubCategory.name);
          });
          return (
            <div key={index}>
              {foundCategory.name} - {subCategoriesNames.join(', ')}
            </div>
          );
        } else if (foundCategory) {
          return <div>{foundCategory.name}</div>;
        }
      })}
    </div>
  );
  return (
    <Card
      disabled={disabled}
      onClick={() => !disabled && setIsExpanded(!isExpanded)}
    >
      <Formik initialValues={initialValues} enableReinitialize={true}>
        {({ dirty, values, setValues }) => (
          <Fragment>
            <TitleAndCollapseButton>
              <Title>Categories</Title>

              {!isExpanded ? (
                <ChevronDownIcon
                  disabled={disabled}
                  onClick={() => setIsExpanded(true)}
                />
              ) : (
                <ChevronUpIcon
                  disabled={dirty}
                  onClick={() => setIsExpanded(false)}
                />
              )}
            </TitleAndCollapseButton>
            {!isExpanded && renderSummary()}

            <Collapse in={isExpanded}>
              <div style={{ display: 'grid', gridGap: '24px' }}>
                {/* <Description>Category Description and example.</Description> */}

                <FieldArray
                  name="categories"
                  component={arrayHelpers => (
                    <Fragment>
                      {arrayHelpers.form.values.categories.map(
                        (category, index) => (
                          <CategorySelectionWrapper
                            key={index}
                            onClick={e => e.stopPropagation()}
                          >
                            <Field
                              name={`categories[${index}].id`}
                              component={RenderSelect}
                              setFieldValue={arrayHelpers.form.setFieldValue}
                              options={categories
                                .filter(
                                  globalCategory =>
                                    !arrayHelpers.form.values.categories
                                      .map(field => field.id)
                                      .includes(globalCategory.id) ||
                                    category.id === globalCategory.id
                                )
                                .map(globalCategory =>
                                  globalCategory.is_active
                                    ? globalCategory
                                    : {
                                        ...globalCategory,
                                        name: `${globalCategory.name} - INACTIVE`
                                      }
                                )}
                              getOptionLabel={category => category.name}
                              getOptionValue={category => category.id}
                              valueKey="id"
                              placeholder="Select Category*"
                              getValueOnChange={selectedId => {
                                selectedId &&
                                  arrayHelpers.form.setFieldValue(
                                    `categories[${index}].subCategoriesId`,
                                    categories.find(
                                      category => category.id === selectedId
                                    ).sub_categories &&
                                      categories.find(
                                        category => category.id === selectedId
                                      ).sub_categories.length > 0
                                      ? []
                                      : null
                                  );
                              }}
                            />

                            {categories.find(item => item.id === category.id) &&
                            categories.find(item => item.id === category.id)
                              .sub_categories &&
                            categories.find(item => item.id === category.id)
                              .sub_categories.length > 0 ? (
                              <Field
                                name={`categories[${index}].subCategoriesId`}
                                component={RenderSelect}
                                isMulti
                                options={categories
                                  .find(
                                    globalCategory =>
                                      globalCategory.id === category.id
                                  )
                                  .sub_categories.map(subCategory =>
                                    subCategory.is_active
                                      ? subCategory
                                      : {
                                          ...subCategory,
                                          name: `${subCategory.name} - INACTIVE`
                                        }
                                  )}
                                getOptionLabel={category => category.name}
                                getOptionValue={category => category.id}
                                valueKey="id"
                                placeholder="Select Sub-Category*"
                                setFieldValue={arrayHelpers.form.setFieldValue}
                              />
                            ) : (
                              <div />
                            )}
                            <DeleteIcon
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          </CategorySelectionWrapper>
                        )
                      )}

                      <AddNewCategory
                        disabled={
                          (arrayHelpers.form.values.categories.length &&
                            (!arrayHelpers.form.values.categories[
                              arrayHelpers.form.values.categories.length - 1
                            ].subCategoriesId == null ||
                              (arrayHelpers.form.values.categories[
                                arrayHelpers.form.values.categories.length - 1
                              ].subCategoriesId !== null &&
                                !arrayHelpers.form.values.categories[
                                  arrayHelpers.form.values.categories.length - 1
                                ].subCategoriesId.length))) ||
                          categories.length ==
                            arrayHelpers.form.values.categories.length
                        }
                        onClick={e => {
                          e.stopPropagation();
                          arrayHelpers.push({
                            id: null,
                            subCategoriesId: []
                          });
                        }}
                      >
                        + Add New Category
                      </AddNewCategory>
                    </Fragment>
                  )}
                />

                <ButtonRow
                  justifyContent="space-between"
                  style={{
                    marginTop: '24px'
                  }}
                >
                  <Button
                    secondary
                    onClick={e => {
                      e.stopPropagation();
                      dirty
                        ? setConfirmCancelModalIsVisible(true)
                        : setIsExpanded(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      !dirty ||
                      !values.categories.length ||
                      !values.categories[values.categories.length - 1]
                        .subCategoriesId == null ||
                      (values.categories[values.categories.length - 1]
                        .subCategoriesId !== null &&
                        !values.categories[values.categories.length - 1]
                          .subCategoriesId.length)
                    }
                    onClick={e => {
                      e.stopPropagation();
                      props.putProductCategories(values.categories);
                      setIsExpanded(false);
                    }}
                  >
                    Save
                  </Button>
                </ButtonRow>
                {confirmCancelModalIsVisible && (
                  <ConfirmCancelModal
                    onCancel={() => setConfirmCancelModalIsVisible(false)}
                    onConfirm={() => {
                      setValues(initialValues);
                      setConfirmCancelModalIsVisible(false);
                      setIsExpanded(false);
                    }}
                  />
                )}
              </div>
            </Collapse>
          </Fragment>
        )}
      </Formik>
    </Card>
  );
};

export default ProductCategoriesForm;
