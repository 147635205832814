import React, {useState, useCallback} from 'react';
import styled from 'styled-components';
import Modal from '../../../components/Modal/Modal';
import { ButtonRow, Button } from '../../../components/Button/Button';
import {Title3} from '../../../components/Titles';
import ReactCrop from "react-image-crop";

const Wrapper = styled.div`
    display: grid;
    grid-gap: 20px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4);
    background: white;
    padding: 24px;
    max-width: 800px;
    width: 800px;
    min-height: 500px;
`;

const  ImageRatioWrapper = styled.div`
    display: grid;
    grid-gap: 10px;
    width:200px;
`;

const AspectRatioWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    text-align: center;
`;

const AspectRatioSquare = styled.div`
    border: 2px solid black;
    height: ${props => props.height};
    width: ${props => props.width};
    background-color: #fff;
    border-radius: 2px;
`;

const AspectRatio = styled.div`
    display: grid;
    cursor: pointer;
`;

const CropDiv = styled.div`
    .ReactCrop__image{
        background-color: #d3d3d3;
    }
`;


const ImageCropModal = ({src, 
    crop, 
    onImageLoaded, 
    onCropComplete, 
    onChangeToIncompleteCropClick, 
    onCropChange, 
    handleCropSubmit,
    handleCropCancel}) =>  {

    const aspectRatios = [
        {aspectX: 1, aspectY: 1, width: '25px', height:'25px'},
        {aspectX: 3, aspectY: 2, width: '37px', height:'25px'},
        {aspectX: 4, aspectY: 3, width: '34px', height:'25px'},
        {aspectX: 16, aspectY: 9, width: '44px', height:'25px'}
        ]

    const renderAspectRatio = aspectRatios.map( aspectRatio => {
        return (
            <AspectRatio onClick={() => onChangeToIncompleteCropClick(aspectRatio.aspectX / aspectRatio.aspectY)}>
                <AspectRatioSquare width={aspectRatio.width} height={aspectRatio.height}> </AspectRatioSquare>
                <div>{aspectRatio.aspectX} : {aspectRatio.aspectY}</div>
            </AspectRatio>
        )
    })
    return (
      <Modal bodyScroll>
          <Wrapper>
          <Title3>Import Image</Title3>
          <CropDiv>
            <ReactCrop
                src={src}
                crop={crop}
                ruleOfThirds
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}  
            />
          </CropDiv>
          <ImageRatioWrapper>
              <div>Image ratio</div>
              <AspectRatioWrapper> 
                {renderAspectRatio}
              </AspectRatioWrapper>
          </ImageRatioWrapper>
          <div style={{display:'grid'}}>
            <ButtonRow
                style={{ marginTop: '6px'}}
                justifyContent="space-between"
            >
                <Button secondary onClick={handleCropCancel}>
                Cancel
                </Button>
                <Button onClick={handleCropSubmit}>
                Submit
                </Button>
            </ButtonRow>
          </div>
        </Wrapper>
      </Modal>
    );
  }


export default ImageCropModal;
