import {
  GET_PRODUCTS,
  GET_PRODUCTS_MAPPING_FIELDS,
  GET_CURRENT_PRODUCT_POS_MAPPING_VALUES,
  GET_PRODUCTS_CATEGORIES,
  GET_PRODUCTS_FILTERED_CATEGORY,
  SET_SELECTED_VALUE_POS,
  SET_IS_REQUESTING_PRODUCT_POS_MAPPING_VALUES,
  GET_POS_COMBOS,
  GET_POS_OFFERS,
  GET_POS_COUPONS,
  GET_ALL_COUPONS_POS,
  GET_DISCOUNT_MAPPING_FIELDS,
  RESET_SEARCH_VALUE,
  SET_SEARCH_VALUE,
  POS_DISCOUNT_MAPPING_FIELDS_NOT_AVAILABLE,
  GET_DISCOUNT_MAPPING_FIELDS_REQUEST,
  GET_ITEMS_MAPPING_FIELDS_REQUEST,
  GET_ITEMS_MAPPING_FIELDS_SUCCESS,
  GET_ITEMS_POS_VALUES_REQUEST,
  GET_ITEMS_POS_VALUES_SUCCESS
} from './posTypes';

const initialState = {
  isRequestingGetProducts: true,
  products: [],
  meta: { page_number: 1, page_size: 1000 },
  productsMappingFields: [],
  productsCategories: [],
  isRequestingGetProductsFilteredCategory: true,
  productsFilteredCategory: [],
  selectedProduct: [],
  combos: [],
  isRequestingGetCombos: true,
  coupons: [],
  isRequestingGetCoupons: true,
  offers: [],
  isRequestingGetOffers: true,
  selectedValuePOS: [],
  currentProductMappingValues: {},
  isRequestingProductPosMappings: true,
  allCoupons: [],
  isRequestingGetAllCoupons: true,
  posDiscountsMappingFields: [],
  isRequestingPosMappingFields: false,
  isShowingPosDiscount: true,
  isRequestingItemsPosValues: false,
  itemsPosValues: [],
  itemsMappingFields: [],
  isRequestingGetItemsMappingFields: false,
  searchValue: '',
  requestParams: {
    page_number: 1,
    page_size: '5',
    sorting_option: 'title-asc'
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        isRequestingGetProducts: false,
        products: action.payload,
        meta: action.meta
      };
    case GET_PRODUCTS_MAPPING_FIELDS:
      return {
        ...state,
        productsMappingFields: action.payload
      };
    case GET_ITEMS_MAPPING_FIELDS_REQUEST:
      return {
        ...state,
        isRequestingGetItemsMappingFields: true
      };
    case GET_ITEMS_MAPPING_FIELDS_SUCCESS:
      return {
        ...state,
        itemsMappingFields: action.payload,
        isRequestingGetItemsMappingFields: false
      };
    case SET_IS_REQUESTING_PRODUCT_POS_MAPPING_VALUES:
      return {
        ...state,
        isRequestingProductPosMappings: true
      };
    case GET_CURRENT_PRODUCT_POS_MAPPING_VALUES:
      return {
        ...state,
        isRequestingProductPosMappings: false,
        currentProductMappingValues: action.mappings
      };
    case GET_PRODUCTS_CATEGORIES:
      return {
        ...state,
        productsCategories: action.payload
      };
    case GET_PRODUCTS_FILTERED_CATEGORY:
      return {
        ...state,
        isRequestingGetProductsFilteredCategory: false,
        products: action.payload
      };
    case 'GET_SELECTED_PRODUCT':
      return {
        ...state,
        selectedProduct: action.payload
      };
    case GET_POS_COMBOS:
      return {
        ...state,
        combos: action.payload,
        isRequestingGetCombos: false,
        meta: action.meta
      };
    case GET_POS_COUPONS:
      return {
        ...state,
        isRequestingGetCoupons: false,
        coupons: action.payload,
        meta: action.meta
      };
    case RESET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: ''
      };
    case GET_POS_OFFERS:
      return {
        ...state,
        isRequestingGetOffers: false,
        offers: action.payload,
        meta: action.meta
      };
    case SET_SELECTED_VALUE_POS:
      return {
        ...state,
        selectedValuePOS: action.payload
      };
    case GET_ALL_COUPONS_POS:
      return {
        ...state,
        isRequestingGetAllCoupons: false,
        allCoupons: action.payload
      };
    case GET_DISCOUNT_MAPPING_FIELDS:
      return {
        ...state,
        posDiscountsMappingFields: action.payload,
        isRequestingPosMappingFields: false
      };
    case POS_DISCOUNT_MAPPING_FIELDS_NOT_AVAILABLE:
      return {
        ...state,
        isShowingPosDiscount: false,
        isRequestingPosMappingFields: false
      };
    case 'UPDATE_ITEMS_REQUEST_PARAMS':
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload
        }
      };
    case GET_ITEMS_POS_VALUES_REQUEST:
      return {
        ...state,
        isRequestingItemsPosValues: true
      };
    case GET_ITEMS_POS_VALUES_SUCCESS:
      return {
        ...state,
        isRequestingItemsPosValues: false,
        itemsPosValues: action.payload,
        meta: action.meta
      };
    case GET_DISCOUNT_MAPPING_FIELDS_REQUEST:
      return {
        ...state,
        isRequestingPosMappingFields: true
      };
    default:
      return state;
  }
}
