import React from 'react';
import Modal from '../../../components/Modal/Modal';
import { renderTextField } from '../../../components/FormElements/ReduxForm';
import { reduxForm, Field, change } from 'redux-form';
import * as actions from '../actions';
import { connect } from 'react-redux';
import { Button, ButtonRow } from '../../../components/Button/Button';
import styled from 'styled-components';
import Alert from '../../Alert/Alert';

const Wrapper = styled.div`
  width: 400px;
  background: white;
  padding: 16px;
`;

const StyledForm = styled.form`
  display: grid;
  grid-gap: 10px;
`;

let AuthenticateModal = props => {
  const { accountReducer, handleSubmit } = props;
  const isButtonLoading = props.accountReducer.isSigningIn;

  // reset password
  if (accountReducer.shouldClearOnError) {
    props.dispatch(change('reAuthenticate', 'password', ''));
  }

  const handleFormSubmit = formProps => {
    props.signinUser(formProps);
  };

  return (
    <Modal>
      <Alert />
      <Wrapper>
        <p style={{ fontWeight: '600', marginBottom: '24px' }}>
          Session expired. Please Re-Authenticate.
        </p>
        <StyledForm name="signin" onSubmit={handleSubmit(handleFormSubmit)}>
          <Field
            name="email"
            label="Username"
            component="input"
            type="text"
            component={renderTextField}
            placeholder="Username"
            icon="fa fa-user"
            className="form-control"
          />
          <Field
            name="password"
            label="Password"
            component="input"
            type="password"
            password
            className="form-control"
            component={renderTextField}
            icon="fa fa-key"
            placeholder="Password"
          />
          <ButtonRow justifyContent="flex-end">
            <Button type="submit" disabled={isButtonLoading}>
              Login
            </Button>
          </ButtonRow>
        </StyledForm>
      </Wrapper>
    </Modal>
  );
};

function validate(formProps) {
  const errors = {};
  if (!formProps.email) {
    errors.email = 'Required Username';
  }
  if (!formProps.password) {
    errors.password = 'Required Password';
  }
  return errors;
}

function mapStateToProps(state) {
  return {
    accountReducer: state.accountReducer
  };
}

AuthenticateModal = reduxForm({
  form: 'reAuthenticate',
  validate
})(AuthenticateModal);

AuthenticateModal = connect(mapStateToProps, actions)(AuthenticateModal);

export default AuthenticateModal;
