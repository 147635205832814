import React from 'react';
import {connect} from 'react-redux';
import BusinessLocationsListContainer from './LocationListManager/BusinessLocationsListContainer';
import BusinessWizardContainer from './LocationDetailsManager/BusinessWizard/BusinessWizardContainer';
import { withRouter } from 'react-router';

let BusinessLocationsContainer = (props) => {
  
    return !props.isCreatingLocation && !props.isEditingLocation ? (
      <BusinessLocationsListContainer {...props} />
    ) : (
      <BusinessWizardContainer />
    );
}

const mapStateToProps = state => ({
  isCreatingLocation: state.businessLocationDetails.isCreatingLocation,
  isEditingLocation: state.businessLocationDetails.isEditingLocation
});

BusinessLocationsContainer = withRouter(BusinessLocationsContainer);
export default connect(mapStateToProps)(BusinessLocationsContainer);
