import React, { Fragment, useState, useEffect, useRef } from 'react';
import NumberFormat from 'react-number-format';
import {
  RenderTextField,
  RenderToggleSwitch,
  required,
} from '../../../../../../components/FormElements/FormikElements';
import { Formik, Form, Field } from 'formik';
import { Collapse } from '@material-ui/core';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import { Button, ButtonRow } from '../../../../../../components/Button/Button';
import ConfirmCancelModal from '../../../../../MenuManagement/Products/ProductWizard/components/ConfirmCancelModal';
import MediaModalContainer from '../../../../../MediaModal/MediaModalContainer';
import {
  Title,
  TitleAndCollapseButton,
  Card,
  FormWrapper,
  FieldWrapper,
  Headline,
  Error,
} from './styles';
import { searchAddress } from './helper';
import {
  getCountries,
  getStates,
} from '../../../../../../utils/constants/constantOptions';
import AddressForm from './AddressForm';
import DetailsForm from './DetailsForm';

const AddressAndDetailsForm = ({
  businessId,
  initialValues,
  handleSubmit,
  languagesConfig,
}) => {
  const [
    mediaSelectionModalIsVisible,
    setMediaSelectionModalIsVisible,
  ] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [
    confirmCancelModalIsVisible,
    setConfirmCancelModalIsVisible,
  ] = useState(false);
  const states = getStates();
  const countries = getCountries();

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedDetails, setDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const form = useRef();

  useEffect(() => {
    let input = document.getElementById('address-input');
    if (initialValues.address) {
      input.value = `${initialValues.address.line1}`;
      setSelectedAddress({
        address: { ...initialValues.address },
      });
      setDetails({
        details: { ...initialValues.details },
      });
    }
    let searchBox = new window.google.maps.places.Autocomplete(input, {
      componentRestrictions: { country: ['ca', 'usa'] },
    });

    let searchBoxListener = searchBox.addListener('place_changed', () => {
      let place = searchBox.getPlace();
      if (!place) {
        setSelectedAddress(null);
        return;
      }

      const searchedAddress = searchAddress(place, states, countries);
      //   input.value = searchedAddress ? searchedAddress.line1
      if (searchedAddress) {
        setSelectedAddress({ address: searchedAddress });
        searchedAddress && setIsEditing(true);
        form.current.getFormikActions().validateForm();
      }
    });
    return () => {
      window.google.maps.event.removeListener(searchBoxListener);
    };
  }, []);

  useEffect(() => {
    if (form.current) {
      form.current.getFormikActions().validateForm();
    }
  });

  const formValues = selectedAddress
    ? {
        details: { ...selectedDetails.details },
        address: { ...selectedAddress.address },
      }
    : initialValues;
  return (
    <Card disabled={businessId ? false : 'true'}>
      <Formik initialValues={formValues} ref={form} enableReinitialize={true}>
        {({
          values,
          handleReset,
          dirty,
          setFieldValue,
          errors,
          setFieldError,
        }) => (
          <Fragment>
            <TitleAndCollapseButton>
              <Title
                style={{ textTransform: 'capitalize' }}
                disabled={businessId ? false : 'true'}
              >
                Address & Details
              </Title>
              {!isExpanded ? (
                <ChevronDownIcon
                  disabled={businessId ? false : true}
                  style={{
                    opacity: businessId ? 'none' : '0.5',
                  }}
                  onClick={() => {
                    setIsExpanded(true);
                  }}
                />
              ) : (
                <ChevronUpIcon
                  disabled={dirty}
                  onClick={() => {
                    setIsExpanded(false);
                  }}
                />
              )}
            </TitleAndCollapseButton>
            <Collapse in={isExpanded}>
              <Form autoComplete="off">
                <FormWrapper>
                  <FieldWrapper grid="2fr 1fr">
                    <Field
                      name="details.display_name"
                      component={RenderTextField}
                      onChange={(e) => {
                        setDetails({
                          details: {
                            ...selectedDetails.details,
                            display_name: e.target.value,
                          },
                        });
                        // setFieldValue('details.display_name', e.target.value)
                        setIsEditing(true);
                      }}
                      error={
                        isEditing &&
                        errors.details &&
                        errors.details.display_name
                      }
                      label="Internal Location Name *"
                      validate={required}
                    />
                  </FieldWrapper>
                  <FieldWrapper grid="1fr 100px">
                    <Field
                      name="details.description"
                      component={RenderTextField}
                      onChange={(e) => {
                        setDetails({
                          details: {
                            ...selectedDetails.details,
                            description: e.target.value,
                          },
                        });
                        setIsEditing(true);
                      }}
                      label="Description (ex. Food Truck, Kiosk)"
                    />
                  </FieldWrapper>
                  <Headline>Address</Headline>
                  <AddressForm
                    setSelectedAddress={setSelectedAddress}
                    setIsEditing={setIsEditing}
                    isEditing={isEditing}
                    countries={countries}
                    states={states}
                    values={values}
                    selectedAddress={selectedAddress}
                    errors={errors}
                  />
                  <FieldWrapper grid="1fr 1fr 1fr 100px">
                    <NumberFormat
                      name="details.phone"
                      value={values.details.phone}
                      onValueChange={(values) => {
                        setFieldValue('details.phone', values.value);
                        setIsEditing(true);
                        setDetails({
                          details: {
                            ...selectedDetails.details,
                            phone: values.value,
                          },
                        });
                      }}
                      onBlur={() => {
                        values.details.phone.length < 10 &&
                          setFieldError('details.phone', 'Must be 10 numbers');
                      }}
                      label="Phone Number"
                      component={RenderTextField}
                      format="+1 (###) ###-####"
                      mask="_"
                      allowEmptyFormatting
                      style={{
                        padding: '15px',
                        border: '1px solid lightgray',
                        borderRadius: '4px',
                      }}
                    />
                    <Field
                      name="details.is_phone_visible"
                      component={RenderToggleSwitch}
                      label="Visible"
                      onChange={(e) => {
                        setFieldValue(
                          'details.is_phone_visible',
                          e.target.checked
                        );
                        setDetails({
                          details: {
                            ...selectedDetails.details,
                            is_phone_visible: e.target.checked,
                          },
                        });
                        setIsEditing(true);
                      }}
                    />
                  </FieldWrapper>
                  {errors.details && errors.details.phone && (
                    <Error>{errors.details.phone}</Error>
                  )}
                  <Headline>Location Details</Headline>
                  <DetailsForm
                    selectedDetails={selectedDetails}
                    setIsEditing={setIsEditing}
                    isEditing={isEditing}
                    values={values}
                    setDetails={setDetails}
                    setMediaSelectionModalIsVisible={
                      setMediaSelectionModalIsVisible
                    }
                    errors={errors}
                    languagesConfig={languagesConfig}
                  />
                  <ButtonRow
                    justifyContent="space-between"
                    style={{
                      margin: '24px 40px 24px 40px',
                      width: 'inherit',
                    }}
                  >
                    <Button
                      secondary
                      onClick={() => {
                        dirty
                          ? setConfirmCancelModalIsVisible(true)
                          : setIsExpanded(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={
                        !isEditing ||
                        Object.keys(errors).length ||
                        !values.details.timezone ||
                        !values.details.language_code
                      }
                      onClick={() => {
                        setIsEditing(false);
                        setIsExpanded(false);
                        handleSubmit(values, businessId);
                      }}
                    >
                      Save
                    </Button>
                  </ButtonRow>
                  {mediaSelectionModalIsVisible && (
                    <MediaModalContainer
                      closeModal={() => setMediaSelectionModalIsVisible(false)}
                      selectProduct={(id, url) => {
                        setFieldValue('details.image_url', url);
                        setDetails({
                          details: {
                            ...selectedDetails.details,
                            image_url: url,
                          },
                        });
                        setIsEditing(true);
                      }}
                    />
                  )}
                  {confirmCancelModalIsVisible && (
                    <ConfirmCancelModal
                      onCancel={() => setConfirmCancelModalIsVisible(false)}
                      onConfirm={() => {
                        handleReset();
                        setConfirmCancelModalIsVisible(false);
                        setIsExpanded(false);
                      }}
                    />
                  )}
                </FormWrapper>
              </Form>
            </Collapse>
          </Fragment>
        )}
      </Formik>
    </Card>
  );
};

export default AddressAndDetailsForm;
