import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from './priceActions';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import Price from './components/Price';
import { SELECT_LOCATION_SCREEN } from './priceTypes';

const PriceContainer = ({
  options,
  variants,
  getOptionPrices,
  filterOptionsByVariants,
  putOptionPrices,
  priceReducer,
  resetPriceReduxState,
  getItemsOverrides,
  getProductAssignedBusinesses,
  setIsOptionOverridesModal
}) => {
  const {
    optionPrices,
    items,
    isRequesting,
    itemOverrides,
    isRequestingItemOverrides,
    formattedBusinesses,
    isOptionOverridesModal
  } = priceReducer;
  const [selectedOption, selectOption] = useState(null);
  const [selectedVariants, selectVariants] = useState(null);
  // const [isOptionOverridesModal, setIsOptionOverridesModal] = useState({
  //   isShowing: false,
  //   item: null,
  //   itemIndex: null
  // });
  const [page, setPage] = useState(SELECT_LOCATION_SCREEN);

  useEffect(() => {
    track.page('menu-management-option-price');
    return () => {
      resetPriceReduxState();
    };
  }, []);

  useEffect(() => {
    if (selectedOption !== null) {
      getProductAssignedBusinesses(selectedOption);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (selectedOption !== null) {
      getOptionPrices(selectedOption, selectedVariants);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (selectedVariants !== null && optionPrices !== null) {
      filterOptionsByVariants(selectedVariants);
    }
  }, [selectedVariants]);

  useEffect(() => {
    if (selectedOption) {
      getItemsOverrides(selectedOption, items);
    }
  }, [items]);

  // useEffect(() => {
  //   if (!isRequesting && selectedOption !== null) {
  //     getOptionPrices(selectedOption, selectedVariants);
  //   }
  // }, [isRequesting]);

  const handleSave = items => {
    putOptionPrices(items);
  };

  return (
    <Fragment>
      <BreadCrumb />
      {options && variants && (
        <Price
          isRequesting={isRequesting}
          items={items}
          selectOption={selectOption}
          selectVariants={selectVariants}
          options={options}
          variants={variants}
          selectedVariants={selectedVariants}
          optionPrices={optionPrices}
          handleSave={handleSave}
          itemOverrides={itemOverrides}
          selectedOption={selectedOption}
          isRequestingItemOverrides={isRequestingItemOverrides}
          formattedBusinesses={formattedBusinesses}
          isOptionOverridesModal={isOptionOverridesModal}
          setIsOptionOverridesModal={setIsOptionOverridesModal}
          page={page}
          setPage={setPage}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  options: state.optionReducer.options,
  variants: state.variantsReducer.allVariants,
  priceReducer: state.priceReducer
});

export default connect(mapStateToProps, actions)(PriceContainer);
