import React from "react";
import Modal from "../../../../../../components/Modal/Modal";
import styled from "styled-components";
import { Button } from "../../../../../../components/Button/Button";

export const ModalBody = styled.div`
  width: 800px;
  background: white;
  display: grid;
  border-radius: 2px;
  padding: 0 0 30px 0;
`;

export const ContainerDiv = styled.div`
  text-align: center;
  padding: ${props => props.padding || "0"};
`;

export const StyledP = styled.p`
  font-size: ${props => props.size || "inherit"};
  color: ${props => (props.lightBlue ? "#296bde" : props.theme.darkBlue)};
  font-weight: ${props => (props.bold ? "bold" : "inherit")};
`;

export const HorizontalLine = styled.hr`
  width: 100%;
  margin: 0 auto;
  border: none;
  border-top: 1px solid #c9cbcf;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin: 15px 15% 15px 15%;
`;

const DeleteSpecialHoursModal = props => {
  const {
    handleSpecialHoursCancelClick,
    handleSpecialHoursModalConfirmClick,
    isDeleteSpecialHoursRequesting
  } = props;
  return (
    <Modal>
      <ModalBody>
        <ContainerDiv padding="15px">
          <StyledP bold size="36px">
            ...are you sure?
          </StyledP>
        </ContainerDiv>
        <ContainerDiv>
          <HorizontalLine />
        </ContainerDiv>
        <ContainerDiv padding="30px 15px 10px 15px">
          <StyledP size="18px">
            You are about to delete upcoming Special Hours
          </StyledP>
        </ContainerDiv>
        <ContainerDiv padding="10px 15px 30px 15px">
          <StyledP size="16px" lightBlue>
            This action cannot be undone
          </StyledP>
        </ContainerDiv>
        <ContainerDiv padding="10px 15px 30px 15px">
          <StyledP size="18px">Are you sure you want to Delete?</StyledP>
        </ContainerDiv>
        <GridWrapper>
          <Button primary onClick={() => handleSpecialHoursCancelClick()}>
            CANCEL
          </Button>
          <Button
            secondary
            disabled={isDeleteSpecialHoursRequesting}
            onClick={() => handleSpecialHoursModalConfirmClick()}
          >
            {isDeleteSpecialHoursRequesting ? "DELETING..." : "YES, DELETE"}
          </Button>
        </GridWrapper>
      </ModalBody>
    </Modal>
  );
};

export default DeleteSpecialHoursModal;
