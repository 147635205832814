import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  getServingSize,
  getNutritionFacts,
  postNutritionFact,
  sortNutritionFacts
} from './nutritionActions';
import NutritionalInformationList from './components/NutritionalInformationList';

const Title = styled.p`
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.04px;
  color: #191818;
  width: 800px;
  margin: 64px auto 24px auto;
`;

const InformationText = styled.p`
  font-size: 15px;
  line-height: 1.67;
  color: #191818;
`;

const Wrapper = styled.div`
  width: 800px;
  margin: 0 auto;
  background: white;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
`;

const NutritionTemplateContainer = ({
  getServingSize,
  getNutritionFacts,
  nutritionTemplatePage,
  postNutritionFact,
  sortNutritionFacts
}) => {
  useEffect(() => {
    getServingSize();
    getNutritionFacts();
  }, []);
  return (
    <div>
      <Title>Nutritional Information Table</Title>
      <Wrapper>
        <InformationText>
          You can edit the Nutritional Information that will display to the
          users. You can add, delete or sort the Nutritional Facts in the table.
          Keep in mind that this table will apply to products in the menu.
        </InformationText>

        <NutritionalInformationList
          nutritionTemplatePage={nutritionTemplatePage}
          handleNutritionFactSave={postNutritionFact}
          sortNutritionFacts={sortNutritionFacts}
        />
      </Wrapper>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    nutritionTemplatePage: state.nutritionReducer
  };
};

export default connect(mapStateToProps, {
  getServingSize,
  getNutritionFacts,
  postNutritionFact,
  sortNutritionFacts
})(NutritionTemplateContainer);
