import React, { Component } from 'react';
import * as actions from './actions';
import { connect } from 'react-redux';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import Select from '../../../components/Select/Select';
import { Charts, TimePeriod } from '../components/DashboardElements';
import { LoadingBlueLarge } from '../../../components/Loading/Loading';
import Transactions from './components/Transactions';
import Sales from './components/Sales';
import NewUserTransactions from './components/NewUserTransactions';
import TransactionsVsTotal from './components/TransactionsVsTotal';
import intervalOptions from '../utils/intervalOptions';
import { TitleArea } from '../../../components/Table/TableElements';

class TopLocationsDashboard extends Component {
  state = {
    intervalTypeAndValue: intervalOptions[1]
  };

  componentDidMount() {
    this.getAllTopLocationData();
    track.page('dashboard-top-locations');
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.token && this.props.token) {
      this.getAllTopLocationData();
    }
    if (prevState.intervalTypeAndValue !== this.state.intervalTypeAndValue) {
      this.getAllTopLocationData();
    }
  }

  getAllTopLocationData = () => {
    const { intervalTypeAndValue } = this.state;
    this.props.getTopLocationTransactions(intervalTypeAndValue.value);
    this.props.getTopLocationSales(intervalTypeAndValue.value);
    this.props.getTopLocationRefund(intervalTypeAndValue.value);
    this.props.getTopLocationNewUserTransactions(intervalTypeAndValue.value);
  };

  handleTimeIntervalChange = value => {
    this.setState({ intervalTypeAndValue: value });
  };

  render() {
    const {
      transactionsData,
      salesData,
      refundData,
      newUserTransactionsData,
      loading
    } = this.props.topLocationsDashboard;
    return (
      <div>
        <TitleArea
          pageName="Top Locations"
          selectName="time_period"
          selectLabel="Time Period"
          selectValue={this.state.intervalTypeAndValue}
          onChange={this.handleTimeIntervalChange}
          options={intervalOptions}
        />

        {transactionsData && loading ? (
          <div>
            <Charts>
              {transactionsData ? (
                <Transactions data={transactionsData} />
              ) : (
                <LoadingBlueLarge />
              )}

              {salesData ? <Sales data={salesData} /> : <LoadingBlueLarge />}
            </Charts>
            <Charts>
              {refundData ? (
                <TransactionsVsTotal data={refundData} />
              ) : (
                <LoadingBlueLarge />
              )}

              {newUserTransactionsData ? (
                <NewUserTransactions data={newUserTransactionsData} />
              ) : (
                <LoadingBlueLarge />
              )}
            </Charts>{' '}
          </div>
        ) : (
          <LoadingBlueLarge />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    topLocationsDashboard: state.topLocationsDashboard,
    token: state.accountReducer.token
  };
}

export default connect(mapStateToProps, actions)(TopLocationsDashboard);
