import React, { Fragment} from 'react';
import {
  RenderTextField,
  required,
  RenderSelect,
  requiredSelect,
  isAlphabetValidation,
  isValidPostalCode,
  isLatLng
} from '../../../../../../components/FormElements/FormikElements';
import { Field } from 'formik';
import {
    FieldWrapper,
} from './styles';

const AddressForm = ({
    setSelectedAddress,
    selectedAddress,
    isEditing,
    setIsEditing,
    countries,
    states,
    values,
    errors
}) => {

  return (
    <Fragment>
        <FieldWrapper grid='1fr 100px'>
                            <Field
                                id="address-input"
                                name="address.line1"
                                inputProps={{
                                    autocomplete: 'off',
                                    form: {
                                      autocomplete: 'off',
                                    },
                                  }}
                                onChange={(e) => {
                                    setSelectedAddress({address: {line1: e.target.value}});
                                    setIsEditing(true);
                                }}
                                error={isEditing && errors.address && errors.address.line1}
                                component={RenderTextField}
                                label="Street Address *"
                                validate={required}
                            />
                        </FieldWrapper>
                        <FieldWrapper grid='1fr 100px'>
                            <Field
                                name="address.line2"
                                onChange={(e) => {
                                    setSelectedAddress({address: {...selectedAddress.address, line2: e.target.value}});
                                    setIsEditing(true);
                                }}
                                component={RenderTextField}
                                label="Unit, Suite, etc."
                            />
                        </FieldWrapper>
                        <FieldWrapper grid='1fr 100px'>
                            <Field
                                    name="address.line3"
                                    onChange={(e) => {
                                        setSelectedAddress({address: {...selectedAddress.address, line3: e.target.value}});
                                        setIsEditing(true);
                                    }}
                                    component={RenderTextField}
                                    label="Neighbourhood"
                            />
                        </FieldWrapper>
                        <FieldWrapper grid='1fr 100px'>
                            <Field
                                    name="address.city"
                                    component={RenderTextField}
                                    onChange={(e) => {
                                        setSelectedAddress({address: {...selectedAddress.address, city: e.target.value}});
                                        setIsEditing(true);
                                    }}
                                    error={isEditing && errors.address && errors.address.city}
                                    label="City *"
                                    validate={isAlphabetValidation}
                            />
                        </FieldWrapper>
                        <FieldWrapper grid='1fr 1fr 1fr 100px'>
                            <Field 
                                name="address.country"
                                component={RenderSelect}
                                style={{position: 'relative', top:'-4px'}}
                                onChange={(e) => {
                                    setIsEditing(true);
                                    setSelectedAddress({address: {...selectedAddress.address, country: e.abbreviation, state: ''}})
                                }}
                                options={countries ? countries : []}
                                getOptionValue={country => country.abbreviation}
                                getOptionLabel={country => country.name}
                                valueKey="abbreviation"
                                placeholder="Country/Region *"
                                error={isEditing && errors.address && errors.address.country}
                                validate={required}
                            />
                            <Field 
                                name="address.state"
                                isDisabled={!values.address.country}
                                component={RenderSelect}
                                style={{position: 'relative', top:'-4px'}}
                                options={states ? states.filter(state => state.country == values.address.country) : []}
                                onChange={(e) => {
                                    setSelectedAddress({address: {...selectedAddress.address, state: e.abbreviation}});
                                    setIsEditing(true);
                                }}
                                getOptionValue={state => state.abbreviation}
                                getOptionLabel={state => state.name}
                                valueKey="abbreviation"
                                placeholder="Province/State *"
                                // error={isEditing && errors.address && errors.address.state}
                                validate={required}
                            />
                            <Field
                                name="address.zip"
                                onChange={(e) => {
                                    setSelectedAddress({address: {...selectedAddress.address, zip: e.target.value}});
                                    setIsEditing(true);
                                }}
                                error={isEditing && errors.address && errors.address.zip}
                                component={RenderTextField}
                                label="Postal/Zip Code *"
                                validate={isValidPostalCode(values.address.country)}
                            />
                        </FieldWrapper>
                        <FieldWrapper grid='1fr 1fr 1fr 100px'>
                            <Field
                                name="address.latitude"
                                onChange={(e) => {
                                    setSelectedAddress({address: {...selectedAddress.address, latitude: e.target.value}});
                                    setIsEditing(true);
                                }}
                                error={isEditing && errors.address && errors.address.latitude}
                                component={RenderTextField}
                                label="Latitude *"
                                validate={isLatLng}
                            />
                           <Field
                                name="address.longitude"
                                onChange={(e) => {
                                    setSelectedAddress({address: {...selectedAddress.address, longitude: e.target.value}});
                                    setIsEditing(true);
                                }}
                                error={isEditing && errors.address && errors.address.longitude}
                                component={RenderTextField}
                                label="Longitude *"
                                validate={isLatLng}
                            />
                        </FieldWrapper>                
    </Fragment>
  );
};

export default AddressForm;
