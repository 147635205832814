import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getProductNutritionValues,
  getProductServingSize,
  getNutritionFacts,
  getNutritionUnits,
  putProductNutritionValues
} from '../nutritionActions';
import NutritionInfoModal from './NutritionInfoModal';
import { createAlert } from '../../../Alert/actions';

const NutritionInfoModalContainer = ({
  toggleNutritionInfoModal,
  getProductNutritionValues,
  getProductServingSize,
  getNutritionUnits,
  getNutritionFacts,
  putProductNutritionValues,
  productId,
  productVariantId,
  nutritionTemplatePage,
  createAlert
}) => {
  useEffect(() => {
    getProductServingSize(productId);
    getNutritionFacts();
    getProductNutritionValues(productId, productVariantId);
    getNutritionUnits();
  }, []);

  return (
    <NutritionInfoModal
      toggleNutritionInfoModal={toggleNutritionInfoModal}
      nutritionTemplatePage={nutritionTemplatePage}
      putProductNutritionValues={putProductNutritionValues}
      productId={productId}
      productVariantId={productVariantId}
      createAlert={createAlert}
    />
  );
};

const mapStateToProps = state => {
  return {
    nutritionTemplatePage: state.nutritionReducer
  };
};

export default connect(mapStateToProps, {
  getProductNutritionValues,
  getProductServingSize,
  getNutritionFacts,
  getNutritionUnits,
  putProductNutritionValues,
  createAlert
})(NutritionInfoModalContainer);
