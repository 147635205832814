import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BusinessWizardIndex from './components/BusinessWizardIndex';
import * as actions from '../actions';
import { connect } from 'react-redux';
import { resetDeliveryZoneReducerState } from './DeliveryZoneInformation/actions';

const Wrapper = styled.div`
  // width: 1000px;
  // margin: 0 auto;
`;

const BusinessWizardContainer = (props) => {
  const {
    isCreatingLocation,
    shouldComponentBeLoading,
    isEditingLocation,
    businessId,
  } = props.businessLocationDetailsReducer;

  useEffect(() => {
    track.page('app-settings-locations-detail');
    if (isEditingLocation)
      props.getBusinessLocationDetailsByBusinessId(businessId);
  }, []);

  return (
    <Wrapper>
      <BusinessWizardIndex
        isCreatingLocation={isCreatingLocation}
        resetBusinessLocationDetailsReducerState={
          props.resetBusinessLocationDetailsReducerState
        }
        shouldComponentBeLoading={shouldComponentBeLoading}
        resetDeliveryZoneReducerState={props.resetDeliveryZoneReducerState}
        is_delivery_enabled={props.is_delivery_enabled}
      />
    </Wrapper>
  );
};

function mapStateToProps(state) {
  return {
    businessLocationDetailsReducer: state.businessLocationDetails,
    is_delivery_enabled:
      state.accountReducer.appMetaData.configuration.is_delivery_enabled,
  };
}
export default connect(mapStateToProps, {
  ...actions,
  resetDeliveryZoneReducerState,
})(BusinessWizardContainer);
