import moment from 'moment';

//formatting of get api to show all week days
export const hoursFormatter = (combosAvailableHours, editingCombo) => {
  let formatComboAvailableHours = [];
  for (let i = 1; i < 8; i++) {
    const found = combosAvailableHours.filter(comboAvailableHours => {
      if (comboAvailableHours.start_day_of_week === i) return combosAvailableHours;
    });

    if (!found.length) {
      formatComboAvailableHours.push({
        combo_id: editingCombo.id,
        start_day_of_week: i,
        end_time: null,
        //id: ,
        start_time: null,
        isAvailableAllDay: false
      });
    } else {
      const newHoursObj = {
        combo_id: found[0].combo_id,
        start_day_of_week: found[0].start_day_of_week,
        end_time:
          found[0].end_time === '00:00:00'
            ? null
            : moment(found[0].end_time, 'HH:mm:ss'),
        id: found[0].id,
        start_time:
          found[0].start_time === '00:00:00'
            ? null
            : moment(found[0].start_time, 'HH:mm:ss'),
        isAvailableAllDay: false
      };

      if (
        found[0].start_time === '00:00:00' &&
        found[0].end_time === '23:59:59'
      ) {
        newHoursObj.isAvailableAllDay = true;
      }

      formatComboAvailableHours.push(newHoursObj);
    }
  }
  return formatComboAvailableHours;
};

// appear days of week with names
const days = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday'
};

export const dayToString = dayNumber => days[dayNumber];

//logic of select options
export const setAvailabilityOption = (
  comboAvailability,
  option,
  index,
  setFieldValue
) => {
  let newArray = [];

  if (option.id === 1) {
    newArray = comboAvailability.map((comboAvailableHours, i) => {
      if (index === i)
        return {
          ...comboAvailableHours,
          isAvailableAllDay: true,
          start_time: moment('00:00:00', 'HH:mm:ss'),
          end_time: moment('23:59:59', 'HH:mm:ss')
        };

      return comboAvailableHours;
    });
  } else if (option.id === 2) {
    newArray = comboAvailability.map((comboAvailableHours, i) => {
      if (index === i)
        return {
          ...comboAvailableHours,
          isAvailableAllDay: false,
          start_time: null,
          end_time: null
        };

      return comboAvailableHours;
    });
  } else if (option.id === 3) {
    newArray = comboAvailability.map((comboAvailableHours, i) => {
      if (index === i)
        return {
          ...comboAvailableHours,
          isAvailableAllDay: false,
          start_time: moment('09:00:00', 'HH:mm:ss'),
          end_time: moment('17:00:00', 'HH:mm:ss')
        };

      return comboAvailableHours;
    });
  }

  //setCombosAvailableHours(newArray);
  setFieldValue('comboAvailability', newArray);
};

// Dropdown options
export const renderAvailabilityOptions = () => {
  return [
    {
      id: 1,
      name: 'Available All Day'
    },
    {
      id: 2,
      name: 'Not Available All Day'
    },
    {
      id: 3,
      name: 'Select Available Hours'
    }
  ];
};

// check if combo is available all time
export const comboAvailableAllTime = () => {
  const comboAllTime = [];
  for (let i = 1; i <= 7; i++) {
    comboAllTime.push({
      start_day_of_week: i,
      start_time: moment('00:00:00', 'HH:mm:ss'),
      end_time: moment('23:59:59', 'HH:mm:ss')
    });
  }
  return comboAllTime;
};

// set combo availabilities for all time toggle
export const isComboAvailableAllTimeFunction = comboAvailabilities => {
  let counter = 0;
  if (comboAvailabilities.length === 7) {
    comboAvailabilities.map(comboAvailability => {
      if (
        comboAvailability.start_time === '00:00:00' &&
        comboAvailability.end_time === '23:59:59'
      ) {
        counter++;
      }
    });
  }
  return counter === 7;
};
