import React, { Fragment } from "react";
import styled from "styled-components";
import PermissionsTable from "./PermissionsTable";
import { Wrapper } from "../../../components/FormElements/FormElements";

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const Notes = styled.div``;

const Roles = props => {
  const { accounts } = props;
  return (
    <div>
      <Wrapper>
        <Title>Permissions</Title>
        <Notes>View the different permission levels for each page</Notes>
      </Wrapper>
      <PermissionsTable accounts={accounts} />
    </div>
  );
};
export default Roles;
