import React, { useState } from "react";
import { Formik, Field } from 'formik';
import {
    RenderTextField,
    required
} from '../../components/FormElements/FormikElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '../../components/Icon/Icon';
import Paper from "@material-ui/core/Paper";
import { Button, ButtonRow } from '../../components/Button/Button';
import styled from "styled-components";
import Alert from '../Alert/Alert';

import PasswordVerifier from '../PasswordVerifier/PasswordVerifier';
import { validateChangePasswordFields, resetUserPassword } from './actions'
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

import {
    PageWrapper,
    StyledForm,
    SectionWrapper,
    Section,
    ImageWrapper,
} from './styles';

const MessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    margin-bottom: 5%;
    font-weight: 500;
`;

function ResetPassword(props) {

    const [state, setState] = useState({
        passwordMaskState: true,
        passwordConfirmMaskState: true
    });

    const { token } = props.match.params;

    return (
        <Formik
            initialValues={{
                password: '',
                passwordConfirm: ''
            }}
            enableReinitialize={true}
            validate={values => validateChangePasswordFields(values)}
            onSubmit={values => {
                props.resetUserPassword({ token: token, password: values.password });
                setTimeout(() => props.history.push("/"), 3000);
            }
            }
        >
            {
                ({
                    handleSubmit,
                    values
                }) => (
                        <PageWrapper>
                            <SectionWrapper>
                                <Alert />
                                <Paper component={Section}>
                                    <ImageWrapper>
                                        <img src="https://s3.amazonaws.com/assets.smoothpay.com/web/portal-assets/smooth-commerce-new-logo.png" width="40%" />
                                    </ImageWrapper>
                                    <MessageWrapper>
                                        Change Password
                                    </MessageWrapper>
                                    <StyledForm onSubmit={handleSubmit}>
                                        <Field
                                            id="ChangePassword-newPasswordField"
                                            type={state.passwordMaskState ? "password" : "text"}
                                            name="password"
                                            placeholder="Create New Password"
                                            component={RenderTextField}
                                            validate={required}
                                            size="small"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Icon
                                                            className={state.passwordMaskState ? "fas fa-eye-slash" : "fas fa-eye"}
                                                            style={{color: "grey"}}
                                                            onClick={() => setState({
                                                                ...state,
                                                                passwordMaskState: !state.passwordMaskState
                                                            })}
                                                        />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <PasswordVerifier
                                            password={values.password}
                                            passwordConfirm={values.passwordConfirm}
                                        />
                                        <Field
                                            id="ChangePassword-passwordConfirm"
                                            type={state.passwordConfirmMaskState ? 'password' : 'text'}
                                            name="passwordConfirm"
                                            placeholder="Confirm New Password"
                                            component={RenderTextField}
                                            size="small"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Icon
                                                            className={state.passwordConfirmMaskState ? "fas fa-eye-slash" : "fas fa-eye"}
                                                            style={{color: "grey"}}
                                                            onClick={() => setState({
                                                                ...state,
                                                                passwordConfirmMaskState: !state.passwordConfirmMaskState
                                                            })}
                                                        />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <ButtonRow style={{ justifyContent: "center" }}>
                                            <Button id="ChangePassword-submitButton" type="submit">
                                                Submit
                                            </Button>
                                        </ButtonRow>
                                    </StyledForm>
                                </Paper>
                            </SectionWrapper>
                        </PageWrapper>

                    )}
        </Formik>
    )
}


export default connect(null, { resetUserPassword })(ResetPassword);