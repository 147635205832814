import React, { useEffect } from 'react';
import Moment from 'moment';
import ReportTable from '../ReportTable/ReportTable';
import { BatchDetailPage } from '../../components/FormElements/FormElements';

function TransactionReportContainer(props) {
  useEffect(() => {
    track.page('finance-transaction-report');
  }, []);

  const columnNames = [
    'ID order',
    'Date',
    'Source',
    'Customer',
    'Location',
    'Status',
    'Subtotal',
    'Tip($)',
    'Fees($)',
    'Tax($)',
    'Customer Paid($)',
    'Points Earned',
    'Points Redeemed',
    'Total Credits Applied($)',
    'Offer Credits($)',
    'Loyalty Credits($)',
    'Head Office Credits($)',
    'Global Store Credits($)',
    'Head Office Coupon Credits($)',
    'Store Coupon Credits($)',
    'Combo Discount($)',
    'Payment Type',
    'Payment Tender',
    'Order type',
  ];

  const initialParams = {
    page_number: 1,
    page_size: 10,
    export: false,
    start_datetime: `${Moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD')} 00:00:00`,
    end_datetime: `${Moment().format('YYYY-MM-DD')} 23:59:59`,
  };

  const notSortColumns = ['status'];

  const columnSort = [
    'order_id',
    'datetime',
    'source',
    'customer',
    'location',
    'status',
    'subtotal',
    'tip',
    'fees',
    'tax',
    'customer_paid',
    'points_earned',
    'points_redeemed',
    'total_credits_applied',
    'offer_credits_applied',
    'loyalty_credits_applied',
    'global_credits_applied_smooth',
    'global_credits_applied_store',
    'coupon_credits_applied_smooth',
    'coupon_credits_applied_store',
    'combo_discount',
    'payment_type',
    'payment_tender',
    'order_type',
  ];

  return (
    <BatchDetailPage>
      <ReportTable
        title="Transaction Report"
        url="/transactions"
        columnNames={columnNames}
        csvRequestPath="transactions"
        filters={['date']}
        initialParams={initialParams}
        emptyMessage={
          'There Are No Transactions Between The Currently Selected Dates'
        }
        useOldSort={true}
        notSortColumns={notSortColumns}
        columnSort={columnSort}
      />
    </BatchDetailPage>
  );
}

export default TransactionReportContainer;
