import React, {Component} from 'react';
import * as actions from './actions';
import * as selectors from './selectors';
import {connect} from 'react-redux';

function getOffers(WrappedComponent) {
  class Container extends Component {
    componentDidMount() {
      if (!this.props.allOffers) {
        this.props.getOffers();
      }
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.token && this.props.token) {
        this.props.getOffers();
      }
    }

    render() {
      return (
        <WrappedComponent
          requestingOffers={this.props.requestingOffers}
          allOffers={this.props.allOffers}
          {...this.props}
        />
      );
    }
  }

  function mapStateToProps(state) {
    return {
      allOffers: selectors.getAllOffers(state),
      requestingOffers: state.offers.requestingOffers,
      token: state.accountReducer.token
    };
  }

  return connect(
    mapStateToProps,
    actions
  )(Container);
}

export default getOffers;
