import api from '../../api';
import {REQUEST_SETTLEMENTS, RECEIVE_SETTLEMENTS} from './types';
import renderQueryString from '../../utils/renderQueryString';

function requestSettlements() {
  return {
    type: REQUEST_SETTLEMENTS
  };
}

export function fetchSettlements(params) {
  return function(dispatch) {
    dispatch(requestSettlements());
    return api
      .get(`/settlements${renderQueryString(params)}`)
      .then(response => {
        dispatch({type: RECEIVE_SETTLEMENTS, payload: response.data});
      })
      .catch(error => {
        // console.log(error);
      });
  };
}
