import React from 'react';
import {
  TableWrapper,
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
  // StyledTh
} from '../../../../components/Table/TableElements';
import Tooltip from '@material-ui/core/Tooltip';
import PaginationControls from '../../../../components/Pagination/PaginationControl';
import { LoadingBlueLarge } from '../../../../components/Loading/Loading';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '../../../../components/Icon/Icon';
import { Button } from '../../../../components/Button/Button';
import { SmallToggleSwitch } from '../../../../components/ToggleSwitch/ToggleSwitch';
import { STRING, BOOLEAN, sortByTypes } from '../../../../utils/sort/sort';


const EmptyStateMessage = styled.p`
  margin-top: 60px;
  text-align: center;
  color: ${props => props.theme.gray};
`;

const TopDiv = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  justify-content: space-between;
  margin: 37px;
`;
const TextSearch = styled.div`
  // padding: 20px;
`;


export class CouponPromoIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuOpen: null
    };
  }

  renderHeaders = () => {
    return (
      <TrHead style={{ background: 'none' }}>
        <Th align="left" 
          onClick={() => {this.props.handleSort({value:'coupon_details.label', sortMethod: sortByTypes, sortType: STRING}) }} 
          value='coupon_details.label' 
          sortingOption= {this.props.requestParams.sorting_option} 
          isSortable='true'>
          
          Name
        </Th>
        <Th align="left" 
          onClick={() => {this.props.handleSort({value:'coupon_details.coupon_code', sortMethod: sortByTypes, sortType: STRING}) }} 
          value='coupon_details.coupon_code' 
          sortingOption= {this.props.requestParams.sorting_option} 
          isSortable='true'>
          
          Code
        </Th>
        <Th align="left">
          Reward Type
        </Th>
        <Th align="left">
          Recipients
        </Th>
        <Th align="left" 
          onClick={() => {this.props.handleSort({value:'coupon_details.is_active', sortMethod: sortByTypes, sortType: BOOLEAN}) }} 
          value='coupon_details.is_active' 
          sortingOption= {this.props.requestParams.sorting_option} 
          isSortable='true'>
          
          Active
        </Th>
      </TrHead>
    );
  };

  renderRows = ({ coupons, props }) => {
    const { requestParams } = props;
    const indexOfFirst =
      (requestParams.page_number - 1) * requestParams.page_size;
    const indexOfLast = requestParams.page_number * requestParams.page_size;
    const couponsData = coupons.response.data.filter(
              coupon =>
                coupon.coupon_details.label
                  .toLowerCase()
                  .indexOf(this.props.searchString.toLowerCase()) > -1
            ).slice(indexOfFirst, indexOfLast);
    if(couponsData.length > 0){
      return couponsData.map((coupon, index) => {
        return (
          <Tr
            onClick={() => props.initiateEditCoupon({ coupon })}
            key={index}
            style={{ cursor: 'pointer', height: '75px' }}
          >
            <Td align="left">{coupon.coupon_details.label}</Td>
            <Td align="left">{coupon.coupon_details.coupon_code}</Td>
            <Td align="left">
              {coupon.coupon_details.template_values.type == 'money'
                ? 'Dollar Credit'
                : 'Loyalty Credit'}
            </Td>
            {/* <Td align="left">
              {coupon.coupon_details.template_values.type == 'money' && '$'}
              {coupon.coupon_details.template_values.type == 'money' ? (
                <FormattedNumber
                  value={coupon.coupon_details.template_values.total_amount}
                  minimumFractionDigits={2}
                />
              ) : (
                `${coupon.coupon_details.template_values.total_amount} pts`
              )}
            </Td> */}
            <Td align="left">
              {coupon.customers.length == 0 ? 'All Users' : 'Targeted'}
            </Td>
             <Td
                onClick={event => {
                  event.stopPropagation();
                }}
                align="left"
                style={{ position: 'relative' }}
              >
                <SmallToggleSwitch
                  checked={coupon.coupon_details.is_active}
                  onChange={event => {
                    event.stopPropagation();
                    props.toggleIsActiveCoupon(coupon);
                  }}
                />
              </Td>
          </Tr>
        );
      });
    }else{
        return (
          <Tr  style={{width: '100%'}}>
            <Td colSpan="5" align="center" padding="50px 0">No matching data</Td>
          </Tr>
        );
    }
  };

  render() {
    const { coupons, requestParams } = this.props;
    const { requestingGetCoupons, response } = this.props.coupons;
    const props = this.props;
    return (
      <React.Fragment>
        <TableWrapper>
          <TopDiv>
            <TextSearch>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon className="fal fa-search" />
                    </InputAdornment>
                  )
                }}
                style={{ width: '100%' }}
                variant="outlined"
                type="text"
                onChange={e => props.handleCouponsSearch(e.target.value)}
                placeholder="Search coupons"
              />
            </TextSearch>
            <Button style={{fontSize:'16px', fontWeight: '600'}} 
              onClick={() => props.initiateCreateCoupon()}>Create Coupon</Button>
          </TopDiv>
          <Table noBorder>
            <thead>{this.renderHeaders()}</thead>
            <TBody striped>
              {response && this.renderRows({ coupons, props })}
            </TBody>
          </Table>

          {coupons && coupons.response && (
            <PaginationControls
              response={{
                ...coupons.response,
                page: requestParams.page_number,
                page_size: requestParams.page_size,
                count: coupons.response.data.length
              }}
              requestParams={requestParams}
              type="coupons"
            />
          )}
        </TableWrapper>
        {!response && <LoadingBlueLarge />}
        {!requestingGetCoupons && response && response.meta.count == 0 && (
          <EmptyStateMessage>There are no coupons available.</EmptyStateMessage>
        )}
      </React.Fragment>
    );
  }
}
