import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  assignTemplatesBusinesses,
  bulkAssignTemplateBusinesses,
  setPageType,
  getListAppFeedLayout
} from '../../appFeedLayoutActions';
import Icon from '../../../../components/Icon/Icon';
import Select from '../../../../components/Select/Select';
import StickyButtons from '../../../../components/StickyButtons';
import BulkAssignmentModal from './BulkAssignmentModal';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import { renderData } from './utils';

const Wrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  padding: 24px;
  margin-bottom: 24px;
  font-size: 15px;
  line-height: 1.67;
  color: #191818;
`;

const BusinessLayoutsDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 3fr;
  margin-top: 34px;
  vertical-align: middle;

  & p {
    margin-top: 28px;
    margin-bottom: 28px;
  }
`;

const Divider = styled.hr`
  border: 1px solid rgba(0, 0, 0, 0.14);
  margin-top: 16px;
  margin-bottom: 16px;
`;

const HeaderDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 3fr;
  font-size: 15px;
  font-weight: 600;
  color: #191818;
  margin-top: 35px;
`;

const BusinessLayoutAssigned = ({
  layoutPage,
  businessesList,
  assignTemplatesBusinesses,
  bulkAssignTemplateBusinesses,
  setPageType,
  getListAppFeedLayout
}) => {
  const templateList = layoutPage.listAppFeed;
  const businessList = businessesList.businessLocationsOfMerchant;
  const [modalState, setModalState] = useState(false);
  // const [businessList, setBusinessList] = useState(
  //   businessesList.businessLocationsOfMerchant
  // );
  const [searchState, setSearchState] = useState({
    search: '',
    businesses: []
  });

  // const [
  //   businessTemplateModifiedList,
  //   setBusinessTemplateModifiedList
  // ] = useState(renderData(templateList, businessList));

  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const [
    businessTemplateModifiedList,
    setBusinessTemplateModifiedList
  ] = useState(renderData(templateList, businessList));
  const [stickyButtons, setStickyButtons] = useState(false);

  useEffect(() => {
    setBusinessTemplateModifiedList(templateList, businessList);
  }, [businessList]);

  useEffect(() => {
    setSearchState({
      search: searchState.search,
      businesses: businessTemplateModifiedList //businessesList.businessLocationsOfMerchant
    });
  }, []);

  // const businessTemplateModifiedList = renderData(templateList, businessList);
  useEffect(() => {
    setBusinessTemplateModifiedList(renderData(templateList, businessList));
  }, [templateList]);
  useEffect(() => {
    setTemplatesSelected(
      businessTemplateModifiedList.map(business => business.selectedTemplate)
    );
  }, [businessTemplateModifiedList]);

  const [templatesSelected, setTemplatesSelected] = useState(
    businessTemplateModifiedList.map(business => business.selectedTemplate)
  );

  const actionSelectOptions = [
    { value: 'bulk-assign-layout', label: 'Bulk Assign a Layout' }
    // { value: 'schedule-layout', label: 'Schedule a Layout' }
  ];

  const handleSearch = e => {
    setSearchState({
      search: e.target.value,
      //businessesList.businessLocationsOfMerchant.
      businesses: businessTemplateModifiedList.filter(business =>
        business.display_name
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
    });
  };

  const handleSelect = (value, index) => {
    setStickyButtons(true);
    let newTemplatesSelected = [...templatesSelected];
    newTemplatesSelected[index] = value;
    setTemplatesSelected(newTemplatesSelected);
  };

  const handleActionSelect = value => {
    if (value === 'bulk-assign-layout') {
      setModalState(true);
    }
  };

  const handleSave = () => {
    const updatedBusinesses = businessTemplateModifiedList.map(
      (business, index) => {
        return {
          ...business,
          selectedTemplate: templatesSelected[index]
        };
      }
    );
    setStickyButtons(false);

    assignTemplatesBusinesses(updatedBusinesses);

    setPageType();
  };
  const handleReset = () => {
    getListAppFeedLayout();
    setSelectedBusinesses([]);
    setStickyButtons(false);

    setPageType();
  };

  const onToggleBusiness = id => {
    if (selectedBusinesses.includes(id)) {
      const newSelectedBusinesses = selectedBusinesses.filter(
        businessId => businessId !== id
      );
      setSelectedBusinesses(newSelectedBusinesses);
    } else {
      const newSelectedBusinesses = [...selectedBusinesses, id];
      setSelectedBusinesses(newSelectedBusinesses);
    }
  };

  const onBulkSave = templateId => {
    bulkAssignTemplateBusinesses(templateId, selectedBusinesses);
    setSelectedBusinesses([]);
    setModalState(false);
  };

  return (
    <Wrapper>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon className="fal fa-search" />
            </InputAdornment>
          )
        }}
        variant="outlined"
        style={{ width: '100%' }}
        onChange={e => handleSearch(e)}
        placeholder="Search business"
        value={searchState.search}
      />

      <HeaderDiv>
        <div style={{ margin: '0', padding: '0', textAlign: 'left' }}>
          <Checkbox
            onChange={e =>
              e.target.checked
                ? setSelectedBusinesses(businessList.map(item => item.id))
                : setSelectedBusinesses([])
            }
            checked={selectedBusinesses.length === businessList.length}
            inputProps={{
              'aria-label': 'primary checkbox'
            }}
          />
        </div>

        <div style={{ padding: '8px 0 0 0' }}>Business Name</div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr' }}>
          Assigned Layout
          {selectedBusinesses.length ? (
            <Select
              bulk
              pagination
              small
              placeholder="Choose Action"
              options={actionSelectOptions}
              onChange={option => handleActionSelect(option.value)}
            />
          ) : null}
        </div>
      </HeaderDiv>
      <Divider />
      {searchState.businesses.map((business, index) => {
        return (
          <div key={index}>
            <BusinessLayoutsDiv key={index}>
              <div>
                <Checkbox
                  onChange={e => onToggleBusiness(business.id)}
                  checked={selectedBusinesses.includes(business.id)}
                  inputProps={{
                    'aria-label': 'secondary checkbox'
                  }}
                />
              </div>
              <div style={{ marginTop: '10px' }}>{business.display_name}</div>
              <div>
                <Select
                  pagination
                  small
                  placeholder="Select Default Layout"
                  options={templateList}
                  getOptionValue={template => template.id}
                  getOptionLabel={template => template.template_name}
                  value={templatesSelected[index]}
                  onChange={option => handleSelect(option, index)}
                />
              </div>
            </BusinessLayoutsDiv>
            <Divider />
          </div>
        );
      })}

      {stickyButtons && (
        <StickyButtons
          isVisible={true}
          handleDiscard={() => handleReset()}
          handleSave={() => handleSave()}
          title="App Feed Layout Management"
        />
      )}
      {modalState && (
        <BulkAssignmentModal
          optionSelect={templateList}
          onClose={() => setModalState(false)}
          onSave={templateId => onBulkSave(templateId)}
          numbBusiness={selectedBusinesses.length}
        />
      )}
    </Wrapper>
  );
};

export default connect(
  null,
  {
    assignTemplatesBusinesses,
    bulkAssignTemplateBusinesses,
    setPageType,
    getListAppFeedLayout
  }
)(BusinessLayoutAssigned);
