import React, { Fragment} from 'react';
import "react-image-crop/dist/ReactCrop.css";
import Tooltip from '@material-ui/core/Tooltip';
import {Wrapper,
    Title,
    ImageAndFieldWrapper,
    FieldWrapper,
    RightWrapper,
    ImageWrapper,
    HelperTextAndImageUploadButton,
    DeleteAndCropWrapper,
    Image,
    ImageError
} from './createEditImageFormStyles';
import ImageCropModal from './ImageCropModal';
import { TooltipIcon } from '../../../components/Icon';
import { Button, ButtonRow } from '../../../components/Button/Button';
import { Formik, Field} from 'formik';
import {
    RenderTextField
  } from '../../../components/FormElements/FormikElements';

const altTextDescription =
  `Alt Text is a description of an image that gets displayed if the users device can't 
   render the image or if a user is using a screen reading device to access your site. 
   Alt text can also be leveraged for SEO purposes.`;

  const CreateEditImageForm  = ({
    initialValues, 
    hideCreateImageForm,
    handleSubmit,
    isCropping,
    setIsCropping,
    crop,
    setImageDetails,
    getImageDetails,
    onImageLoaded,
    onCropComplete,
    onChangeToIncompleteCropClick,
    onCropChange,
    handleCropSubmit,
    handleCropCancel
    }) => {
    
    return (
        <Fragment>
    <Formik initialValues={initialValues} enableReinitialize={true}>
        {({ values, setFieldValue, setFieldTouched, errors}) => (
            <Wrapper>
                <Title>Upload Image</Title>
                <ImageAndFieldWrapper>
                    <ImageWrapper src={values.src}
                       onDragEnter={(e) => {e.stopPropagation(); e.preventDefault()}}
                       onDragOver={(e) => {e.stopPropagation(); e.preventDefault()}}
                       onDrop={(e) => {
                         e.stopPropagation(); 
                         e.preventDefault(); 
                         getImageDetails(e.dataTransfer);
                         }}
                    >
                        { !values.imageFile &&
                        <HelperTextAndImageUploadButton>
                            <p>Drag and Drop Image</p>
                            <p style={{margin:'0 45%'}}> or</p>
                            <input type="file" id="imageUpload" onChange={() =>{
                                getImageDetails(document.getElementById('imageUpload'))
                            }}>
                            </input>
                            <label class="button" for="imageUpload">Browse Computer</label>
                            <p style={{margin:'10px 40px 0 30px'}}> Maximum File Size: 2MB </p>
                            <p style={{margin:'0px 40px 0 30px'}}> Format: .jpg, .png, .JPEG </p>
                        </HelperTextAndImageUploadButton>
                        }
                        { values.imageFile &&
                            <Image id="imageDisplay" src={values.src} alt="" height='245' width='245'/>
                        }
                    </ImageWrapper>
                    <RightWrapper>
                    <FieldWrapper>
                        <Field
                            name='imageName'
                            component={RenderTextField}
                            type="text"
                            label='Image Name *'
                            validate={() => {
                                if(!values.imageName) return 'Required'
                                if(values.imageName && values.imageName.length > 30) {
                                  return 'Image Name cannot be more than 30 characters'
                                }
                            }}
                        />
                     </FieldWrapper>
                     <FieldWrapper>
                            <Field
                                name='imageAltText'
                                component={RenderTextField}
                                type="text"
                                label='Alt'
                            />
                            <Tooltip title={altTextDescription}>
                                <span style={{height: '10px', margin: '15px 0 0 0'}}><TooltipIcon /></span>    
                            </Tooltip>
                     </FieldWrapper>
                     </RightWrapper>
                </ImageAndFieldWrapper>
                {values.imageError && <ImageError>{values.imageError}</ImageError>}
                { values.src &&
                    <DeleteAndCropWrapper>
                        <i class="fas fa-trash-alt" 
                            onClick= {() => {
                                setImageDetails({ 
                                  imageFile: '',
                                  src:'',
                                  imageName: '',
                                  imageAltText: '' ,
                                  croppedImageDetails: '',
                                  imageRef: ''
                              })
                            }}>

                        </i>
                        <i class="fas fa-crop-alt" onClick={() => setIsCropping(true)}></i>
                    </DeleteAndCropWrapper>
                }
                <ButtonRow
                justifyContent="space-between"
                style={{
                  margin: '24px 40px 24px 40px',
                  width: 'inherit'
                }}
              >
                <Button
                  secondary
                  onClick={() => {
                     hideCreateImageForm(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={(values.imageName && values.imageFile && Object.keys(errors).length == 0) ? false : true}
                  onClick={() => {
                        handleSubmit(values)
                  }}
                >
                  Upload
                </Button>
              </ButtonRow>
            </Wrapper>
        )}
    </Formik>
     {isCropping && 
        <ImageCropModal 
            src={initialValues.src} 
            crop={crop}
            onImageLoaded={onImageLoaded}
            onCropComplete={onCropComplete}
            onChangeToIncompleteCropClick={onChangeToIncompleteCropClick}
            onCropChange={onCropChange}
            handleCropSubmit={handleCropSubmit}
            handleCropCancel={handleCropCancel}
    />}
    </Fragment>
    
  )
}

export default CreateEditImageForm;
