import {
  GET_CUSTOMERS_OF_MERCHANT_REQUEST,
  GET_CUSTOMERS_OF_MERCHANT_SUCCESS,
  GET_CUSTOMERS_OF_MERCHANT_FAILURE
} from './customerListTypes';

export default function(
  state = {
    getCustomersIsRequesting: false,
    customers: [],
    meta: []
  },
  action
) {
  switch (action.type) {
    case GET_CUSTOMERS_OF_MERCHANT_REQUEST: {
      return {
        ...state,
        getCustomersIsRequesting: true
      };
    }
    case GET_CUSTOMERS_OF_MERCHANT_SUCCESS:
      return {
        ...state,
        getCustomersIsRequesting: false,
        customers: action.customers,
        meta: action.meta
      };
    default:
      return state;
  }
}
