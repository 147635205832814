import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import Modal from './Modal';
import {ButtonRow, Button} from '../Button/Button';
import {ModalBody} from '../../components/FormElements/FormElements.js';

const LeaveFormModalPrompt = ({isBlocking, afterConfirm, saveAnyway}) => (
  <NavigationPrompt when={isBlocking} afterConfirm={afterConfirm}>
    {({onConfirm, onCancel}) => {
      const saveThenLeave = () => {
        saveAnyway();
        onConfirm();
      };
      return (
        <Modal>
          <ModalBody small={saveAnyway ? false : true} medium={saveAnyway}>
            <p style={{textAlign: 'center', marginBottom: '12px'}}>
              All unsaved data will be lost. Are you sure you want to continue?
            </p>
            <ButtonRow justifyContent="space-between">
              <Button small secondary type="button" onClick={onCancel}>
                Cancel
              </Button>
              {saveAnyway && (
                <Button tertiary small type="button" onClick={saveThenLeave}>
                  Save
                </Button>
              )}
              <Button small type="button" onClick={onConfirm}>
                Yes
              </Button>
            </ButtonRow>
          </ModalBody>
        </Modal>
      );
    }}
  </NavigationPrompt>
);

export default LeaveFormModalPrompt;
