import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import {
  RenderSelect,
  RenderTextField,
  required
} from '../../../../components/FormElements/FormikElements';
import { Button } from '../../../../components/Button/Button';
import { SmallToggleSwitch } from '../../../../components/ToggleSwitch/ToggleSwitch';
import {
  ButtonsWrapper,
  FormWrapper,
  ModalTitle,
  BusinessSelect
} from '../CreateEditModalStyle';
import { getRoles, getBusinesses } from '../../utils/utils';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { formatLocations } from '../../../../utils/childLocations/formatLocations';

const Wrapper = styled.div`
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
`;

const CreateUserModal = ({
  roles,
  toggleCreateUserModal,
  submitUser,
  businesses,
  account
}) => {
  const [isEmailState, setIsEmailState] = useState(false);

  const businessesModified = formatLocations(businesses);

  const [businessSelectAvailability, setBusinessSelectAvailability] = useState(
    account ? account.business_ids.length === businessesModified.length : true
  );

  const handleBusinessToggle = setFieldValue => {
    setBusinessSelectAvailability(!businessSelectAvailability);
    if (account) {
      if (businessSelectAvailability) {
        if (account.business_ids.length === businessesModified.length)
          setFieldValue('userBusinesses', []);
        else setFieldValue('userBusinesses', account.business_ids);
      } else {
        setFieldValue(
          'userBusinesses',
          businessesModified.map(business => business.id)
        );
      }
    }
  };

  const validateUsername = username => {
    const isEmail = new RegExp(
      '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@' +
        '[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
    );
    setIsEmailState(!isEmail.test(username));
  };

  const [isUserActive, setIsUserActive] = useState(
    account ? account.is_active : true
  );

  const toggleUser = setFieldValue => {
    setIsUserActive(!isUserActive);
    setFieldValue('userIsActive', !isUserActive);
  };

  const handleSubmit = (account, values) => {
    if (values.userBusinesses.length == 0 && businessSelectAvailability) {
      businesses = {
        business_ids: businesses.map(business => business.id)
      };
    } else {
      businesses = {
        business_ids: values.userBusinesses
      };
    }
    submitUser(account, values, businesses);
  };

  const saveButtonValidation = values => {
    return (
      !values.userUsername ||
      !values.userDisplayName ||
      !values.userRole ||
      (!values.userBusinesses.length && !businessSelectAvailability) ||
      isEmailState
    );
  };

  return (
    <Dialog open fullWidth maxWidth="sm">
      <DialogTitle>
        <ModalTitle>
          {account ? 'Edit User' : 'Invite a New User'}
          <div onClick={() => toggleCreateUserModal(null)}>
            <i className="fal fa-times" style={{ cursor: 'pointer' }}></i>
          </div>
        </ModalTitle>
      </DialogTitle>
      <DialogContent>
        <FormWrapper>
          <Formik
            initialValues={{
              userUsername: account ? account.username : '',
              userDisplayName: account ? account.display_name : '',
              userRole: account ? account.role : '',
              userBusinesses: account ? account.business_ids : [],
              userIsActive: account ? account.is_active : true,
              userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }}
          >
            {({ values, handleChange, setFieldValue }) => (
              <Fragment>
                <Field
                  type="text"
                  name="userUsername"
                  label="Email"
                  component={RenderTextField}
                  validate={required}
                  disabled={account}
                  onChange={e => {
                    handleChange(e);
                    validateUsername(e.target.value);
                  }}
                ></Field>
                {isEmailState ? (
                  <span
                    style={{
                      color: 'red',
                      fontSize: '12px',
                      marginTop: '-20px'
                    }}
                  >
                    Please put a valid email format
                  </span>
                ) : null}
                <Field
                  type="text"
                  name="userDisplayName"
                  label="Display name"
                  component={RenderTextField}
                  validate={required}
                ></Field>
                <Field
                  options={getRoles(roles)}
                  name="userRole"
                  component={RenderSelect}
                  placeholder="Select a User Role"
                  getOptionLabel={role => role.label}
                  getOptionValue={role => role.value}
                  onChange={e => setFieldValue('userRole', e.value)}
                />
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr'
                  }}
                >
                  <span>Select all businesses:</span>
                  <SmallToggleSwitch
                    onChange={() => {
                      handleBusinessToggle(setFieldValue);
                    }}
                    checked={businessSelectAvailability}
                  />
                </div>
                {!businessSelectAvailability && (
                  <BusinessSelect>
                    <Field
                      options={formatLocations(businesses)}
                      name="userBusinesses"
                      component={RenderSelect}
                      placeholder="Select Businesses"
                      isMulti
                      getOptionLabel={business => business.display_name}
                      getOptionValue={business => business.id}
                      setFieldValue={setFieldValue}
                      valueKey="id"
                      simpleValue
                    />
                  </BusinessSelect>
                )}
                {account && (
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '80px 1fr',
                      alignItems: 'center'
                    }}
                  >
                    <SmallToggleSwitch
                      checked={isUserActive}
                      onChange={() => toggleUser(setFieldValue)}
                      value="checkedB"
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <span> Make {isUserActive ? 'Inactive' : 'Active'}</span>
                  </div>
                )}
                <ButtonsWrapper>
                  <Button
                    style={{
                      background: 'none',
                      color: '#191818',
                      border: 'none'
                    }}
                    onClick={() => toggleCreateUserModal(null)}
                  >
                    Cancel
                  </Button>
                  <div />
                  <Button
                    type="submit"
                    onClick={() => handleSubmit(account, values)}
                    disabled={saveButtonValidation(values)}
                  >
                    {account ? 'Save' : 'Send Invite'}
                  </Button>
                </ButtonsWrapper>
              </Fragment>
            )}
          </Formik>
        </FormWrapper>
      </DialogContent>
    </Dialog>
  );
};

export default CreateUserModal;
