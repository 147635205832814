import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import RolesTable from './RolesTable';
import { Wrapper } from '../../../components/FormElements/FormElements';
import CreateEditUserModalContainer from './CreateEditUser/CreateEditUserModalContainer';

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const ComponentWrapper = styled(Wrapper)`
  display: grid;
  grid-template-columns: 5fr 1fr;
`;

const TitleDiv = styled.div``;

const NewUserDiv = styled.div`
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
`;

const RolesIndex = props => {
  const {
    accounts,
    roles,
    businesses,
    postResendInvitation,
    updateUser,
    patchUpdateRole,
    authUser,
    isModal,

    toggleCreateUserModal
  } = props;

  return (
    <div>
      <ComponentWrapper>
        <TitleDiv>
          <Title>Roles</Title>
          <div>View the different roles registered for your portal</div>
        </TitleDiv>
        <NewUserDiv>
          <div onClick={() => toggleCreateUserModal(null)}>
            <i className="far fa-user-plus" style={{ marginRight: '8px' }} />
            Invite a New User
          </div>
        </NewUserDiv>
      </ComponentWrapper>

      <RolesTable
        accounts={accounts}
        roles={roles}
        postResendInvitation={postResendInvitation}
        updateUser={updateUser}
        patchUpdateRole={patchUpdateRole}
        authUser={authUser}
        businesses={businesses}
        isModal={isModal}
        toggleCreateUserModal={toggleCreateUserModal}
      />
    </div>
  );
};
export default RolesIndex;
