import React from 'react';
import styled from 'styled-components';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import { Button } from '../../../../../../components/Button/Button';
import { EditIcon } from '../../../../../../components/Icon';
import ProductOverridesModalContainer from '../containers/ProductOverridesModalContainer';
import ProductBusinessesOverridesFormSummary from './ProductBusinessesOverridesFormSummary';
import { Collapse } from '@material-ui/core';
import { LoadingBlueLarge } from '../../../../../../components/Loading/Loading';

const Card = styled.div`
  background: ${props => (props.disabled ? '#ebebeb' : 'white')};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 32px 48px;
  color: ${props => (props.disabled ? '#959595' : 'initial')};
  label {
    z-index: 0;
  }
`;

const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
`;

const ButtonWrapper = styled(Button)`
  margin-top: 24px;
  margin-bottom: 23px;
`;

const NoOverridesText = styled.p`
  fontstyle: italic;
`;

const OverridesRow = styled.div`
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 60px;
  grid-gap: 50px;
`;

const VariantName = styled.div`
  color: #191818;
  margin-top: 20px;
  font-size: 14px;
`;

const BusinessName = styled.span`
  color: #191818;
  font-weight: 600;
`;

let ProductBusinessesOverridesForm = ({
  isExpanded,
  setIsExpanded,
  disabled,
  summaryData,
  isOverrideModal,
  setIsOverrideModal,
  formattedBusinesses,
  productId,
  productVariantsModified,
  productVariantsInitialValues,
  isCreate,
  setIsCreate,
  isSubmiting,
  isRequestingProductVariants
}) => {
  const summaryDataModified = summaryData
    ? summaryData.length
      ? summaryData.filter(data => data.prices.length > 0)
      : []
    : null;

  if (
    (isSubmiting || !summaryDataModified || isRequestingProductVariants) &&
    !disabled
  )
    return <LoadingBlueLarge />;
  return (
    <div>
      <Card
        disabled={disabled}
        onClick={() => !disabled && setIsExpanded(!isExpanded)}
      >
        <TitleAndCollapseButton>
          <Title>Business Overrides</Title>
          {!isExpanded ? (
            <ChevronDownIcon
              disabled={disabled}
              onClick={() => setIsExpanded(true)}
            />
          ) : (
            <ChevronUpIcon
              onClick={() => setIsExpanded(false)}
              disabled={disabled}
            />
          )}
        </TitleAndCollapseButton>

        <Collapse in={isExpanded}>
          <div>
            <ButtonWrapper
              onClick={e => {
                e.stopPropagation();
                setIsOverrideModal({
                  isShowing: true,
                  prices: null,
                  assignedBusinesses: []
                });
                setIsCreate(true);
              }}
            >
              Add Override
            </ButtonWrapper>
            {!disabled ? (
              !summaryDataModified.length ? (
                <NoOverridesText>
                  There are no overrides for this product
                </NoOverridesText>
              ) : (
                summaryDataModified.map((data, index) => {
                  return (
                    <OverridesRow key={index}>
                      <div>
                        {data.businesses.map((business, businessIndex) => {
                          return (
                            <BusinessName key={businessIndex}>
                              {business.name},{' '}
                            </BusinessName>
                          );
                        })}

                        {data.prices.map((variant, variantIndex) => {
                          return (
                            <VariantName key={variantIndex}>
                              {variant.name} - {`$${variant.price}`}
                            </VariantName>
                          );
                        })}
                      </div>
                      <div>
                        Edit
                        <EditIcon
                          onClick={e => {
                            e.stopPropagation();
                            setIsOverrideModal({
                              isShowing: true,
                              prices: summaryDataModified[index].prices,
                              assignedBusinesses:
                                summaryDataModified[index].businesses,
                              initialBusinesses:
                                summaryDataModified[index].businesses,
                              index: index
                            });
                            setIsCreate(false);
                          }}
                          style={{ cursor: 'pointer', marginLeft: '5px' }}
                        />
                      </div>
                    </OverridesRow>
                  );
                })
              )
            ) : null}
          </div>
        </Collapse>
      </Card>

      {isOverrideModal.isShowing && (
        <ProductOverridesModalContainer
          isOverrideModal={isOverrideModal}
          setIsOverrideModal={setIsOverrideModal}
          formattedBusinesses={formattedBusinesses}
          productId={productId}
          summaryData={summaryData}
          productVariantsModified={productVariantsModified}
          productVariantsInitialValues={productVariantsInitialValues}
          isCreate={isCreate}
          disabled={!summaryDataModified.length}
        />
      )}
    </div>
  );
};

export default ProductBusinessesOverridesForm;
