import store from './configureStore';
import axios from 'axios';
import {
  SESSION_EXPIRED_UNAUTH_USER,
  UPDATE_CREATED_AT_TIMER
} from './modules/Account/types';
import Moment from 'moment';

export const api = axios.create({
  baseURL: ROOT_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(request => {
  request.headers['X-SP-Admin-Token'] = localStorage.getItem('token');
  return request;
});

api.interceptors.response.use(
  response => {
    const createdAt = Moment();
    localStorage.setItem('createdAt', createdAt);
    store.dispatch({
      type: UPDATE_CREATED_AT_TIMER,
      createdAt: createdAt
    });

    return response;
  },
  error => {
    // Do something with response error
    // return Promise.reject(error);
    if (error.response.status == 401) {
      store.dispatch({
        type: SESSION_EXPIRED_UNAUTH_USER,
        reauthenticate: true
      });
      localStorage.removeItem('token');
      localStorage.removeItem('displayName');
      localStorage.removeItem('role');
      return Promise.reject(error);
    }
    if (error.response.status == 403) {
      store.dispatch({
        type: 'CREATE_ALERT',
        alert: {
          type: 'error',
          message: 'You do not have access to this route'
        }
      });
    }
    // else if (error.response.status == 400) {
    //   store.dispatch({
    //     type: 'CREATE_ALERT',
    //     alert: {
    //       type: 'error',
    //       message: 'Invalid form data. Please contact Admin.'
    //     }
    //   });
    // }
    else if (error.response.status == 500) {
      store.dispatch({
        type: 'CREATE_ALERT',
        alert: {
          type: 'error',
          message: 'Server error. Please contact Admin'
        }
      });
    }
    // else {
    //   store.dispatch({
    //     type: 'CREATE_ALERT',
    //     alert: {type: 'error', message: 'Unknown Error. Please contact Admin'}
    //   });
    // }
    return Promise.reject(error);
  }
);

export default api;
