import React from 'react';
import styled from 'styled-components';
import {StyledP} from '../../../common/commonElements';
import Collapse from '@material-ui/core/Collapse';

const SpecialHoursNotes = styled.div`
  padding: 0 0 50px 0;
`;

const NotesPragraphContainer = styled.div`
  padding: 10px 0;
`;

const ToggleNotes = styled.span`
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;
`;

const SpecialHoursFormNotes = props => {
  const {handleIsLearnMoreVisible, isLearnMoreVisible} = props;

  return (
    <SpecialHoursNotes>
      <NotesPragraphContainer>
        <StyledP style={{fontSize: '15px'}}>
          Set different hours for this location for a short period (i.e.
          holidays, long weekends, etc.). Hours can only be changed per Day of
          the Week (Monday, Tuesday, etc). After they are set, the Special Hours
          will be visible below the Regular Hours for this location.
        </StyledP>
      </NotesPragraphContainer>
      <NotesPragraphContainer>
        <StyledP style={{fontSize: '15px'}}>
          For best results, we recommend only making special hours for a date
          range less than 1 month. For a longer change of hours, please edit the
          Regular Hours for this location.
          {isLearnMoreVisible && (
            <ToggleNotes onClick={() => handleIsLearnMoreVisible(false)}>
              {' '}
              Learn More
            </ToggleNotes>
          )}
        </StyledP>
      </NotesPragraphContainer>
      <Collapse in={!isLearnMoreVisible}>
        <NotesPragraphContainer>
          <StyledP style={{fontSize: '15px'}}>
            If you have to set different hours per Day of Week, break up the
            date range into separate sets of Special Hours. After they are set,
            the Special Hours will be visible below the Regular Hours for this
            location. If you need to change the location selected, exit this
            window and change the location on the Location Hours page.
            <ToggleNotes onClick={() => handleIsLearnMoreVisible(true)}>
              {' '}
              Show Less
            </ToggleNotes>
          </StyledP>
        </NotesPragraphContainer>
      </Collapse>
    </SpecialHoursNotes>
  );
};

export default SpecialHoursFormNotes;
