import React, {Component} from 'react';
import {HorizontalBar} from 'react-chartjs-2';
import {
  NoData,
  ChartCard,
  TitleWrapper,
  Title,
  TotalWrapper,
  TitleSpan
} from '../../components/DashboardElements';
import {findMaxValue, findStepSize} from '../../utils/chart';
import Tooltip from '@material-ui/core/Tooltip';
import { TooltipIcon } from '../../../../components/Icon';

class Transactions extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {locations, order_ahead, scan_to_pay} = this.props.data;
    const label = locations;
    const orderAhead = order_ahead;
    const scanToPay = scan_to_pay;
    const barThickness = label.length < 7 ? 35 : 15;

    var suggestedMaxCurrent = findMaxValue(orderAhead);
    var stepSize = findStepSize(orderAhead);

    var suggestedMaxPrevious = findMaxValue(scanToPay);
    var stepSizePrevious = findStepSize(scanToPay);

    if (
      stepSize <= 1 &&
      stepSizePrevious <= 1 &&
      suggestedMaxCurrent <= 7 &&
      suggestedMaxPrevious <= 7
    ) {
      stepSize = 1;
      suggestedMaxCurrent = 7;
    } else {
      stepSize = '';
    }

    const data = {
      labels: label,
      datasets: [
        {
          label: 'Order Ahead',
          backgroundColor: '#296bde',
          borderColor: '#296bde',
          borderWidth: 1,
          //hoverBackgroundColor: 'rgba(75,192,192,0.4)',
          hoverBorderColor: '#296bde',
          data: orderAhead
        },
        {
          label: 'Scan To Pay',
          backgroundColor: '#00a5db',
          borderColor: '#00a5db',
          borderWidth: 1,
          //hoverBackgroundColor: 'rgba(75,192,192,0.4)',
          hoverBorderColor: '#00a5db',
          data: scanToPay
        }
      ]
    };

    return (
      <ChartCard>
        <TitleWrapper>
          <Title>
            <TitleSpan>
              Top Locations - Transactions{' '} 
              <Tooltip title="The total number of transactions happened in top locations" placement="top" arrow>
                <span> <TooltipIcon/> </span>
              </Tooltip>
            </TitleSpan> <br />
            <svg height="10" width="17.7">
              <line
                x1="0"
                y1="7"
                x2="17.7"
                y2="7"
                style={{stroke: '#296bde', strokeWidth: '8'}}
              />
            </svg>{' '}
            <span style={{color: '#296bde'}}> order ahead </span>
            &nbsp;
            <svg height="10" width="17.7">
              <line
                x1="0"
                y1="7"
                x2="17.7"
                y2="7"
                style={{stroke: '#00a5db', strokeWidth: '8'}}
              />
            </svg>{' '}
            <span style={{color: '#00a5db'}}> scan to pay </span>
          </Title>
        </TitleWrapper>
        {(!label || !label.length) && <NoData>No Data available</NoData>}
        <HorizontalBar
          data={data}
          options={{
            maintainAspectRatio: true,
            responsive: true,
            legend: {
              display: false
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  ticks: {
                    suggestedMin: 0,
                    beginAtZero: true,
                    stepSize: stepSize,
                    suggestedMax: suggestedMaxCurrent,
                    callback: function(value, index, values) {
                      return value.toLocaleString();
                    }
                  },
                  scaleLabel: {
                    display: false
                  },
                  stacked: true
                }
              ],
              yAxes: [
                {
                  display: true,
                  ticks: {
                    suggestedMin: 8000
                  },
                  barThickness: barThickness,
                  scaleLabel: {
                    display: false
                    //labelString: 'Date',
                  },
                  stacked: true
                }
              ]
            }
          }}
          // plugins= {
          //   [{
          //       beforeInit: function (chart) {
          //         chart.data.labels.forEach(function (e, i, a) {
          //           if (/\n/.test(e)) {
          //             a[i] = e.split(/\n/)
          //           }
          //         })
          //       }
          //     }]
          //   }
        />
      </ChartCard>
    );
  }
}

export default Transactions;
