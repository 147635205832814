import React, { Component } from 'react';
import Select from '../../../components/Select/Select';

class BusinessSearchSelect extends Component {
  render() {
    const {
      businessSearchManager,
      selectBusiness,
      businesses,
      onChange,
      isClearable
    } = this.props;
    const { selectedBusiness } = businessSearchManager;
    return (
      <Select
        topMod="14px"
        light
        options={businesses}
        getOptionValue={business => business.id}
        getOptionLabel={business => business.display_name}
        noOptionsMessage={'No results found'}
        isSearchable={true}
        isClearable={isClearable}
        onChange={selectedBusiness => {
          if (selectedBusiness) {
            selectBusiness(selectedBusiness);
          } else {
            selectBusiness(false);
          }
          onChange ? onChange(selectedBusiness) : null;
        }}
        value={selectedBusiness}
      />
    );
  }
}

export default BusinessSearchSelect;
