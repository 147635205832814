import React from 'react';
import styled from 'styled-components';

const NoOverridesText = styled.p`
  fontstyle: italic;
`;

const OverridesRow = styled.div`
  margin-bottom: 10px;
`;

const VariantName = styled.div`
  color: #191818;
  margin-top: 20px;
  font-size: 14px;
`;

const BusinessName = styled.span`
  color: #191818;
  font-weight: 600;
`;

const ProductBusinessesOverridesFormSummary = ({ summaryDataModified }) => {
  return !summaryDataModified.length ? (
    <NoOverridesText>There are no overrides for this product</NoOverridesText>
  ) : (
    summaryDataModified.map((data, index) => {
      return (
        <OverridesRow key={index}>
          {data.businesses.map((business, businessIndex) => {
            return (
              <BusinessName key={businessIndex}>
                {business.display_name},{' '}
              </BusinessName>
            );
          })}
        </OverridesRow>
      );
    })
  );
};

export default ProductBusinessesOverridesFormSummary;
