import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { resetCombosReducer } from './combosAction';
import CombosListContainer from './CombosList/CombosListContainer';
import CombosDetailContainer from './ComboDetail/CombosDetailContainer';

const CombosContainer = ({ currentPage, businesses, resetCombosReducer }) => {
  useEffect(() => {
    return () => resetCombosReducer();
  }, []);

  if (currentPage === 'list') {
    return <CombosListContainer businesses={businesses} />;
  } else if (currentPage === 'create') {
    return <CombosDetailContainer businesses={businesses} />;
  } else if (currentPage === 'edit') {
    return <CombosDetailContainer businesses={businesses} isEdit={true} />;
  }
};

const mapStateToProps = state => ({
  currentPage: state.combosReducer.currentPage,
  businesses: state.businessLocationsList.businessLocationsOfMerchant
});

export default connect(mapStateToProps, { resetCombosReducer })(
  CombosContainer
);
