import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import { Formik, Field } from 'formik';
import { hoursFormatter } from '../utils/helper.js';
import { comboAvailableAllTime } from '../utils/helper.js';
import { isComboAvailableAllTimeFunction } from '../utils/helper.js';
import {
  Wrapper,
  IsActiveToggleWrapper,
  TitleAndCollapseButton,
  Title,
  ButtonWrapper,
} from '../styles/style.js';
import {
  RenderCheckBox,
  RenderTextField,
  required,
} from '../../../../../../components/FormElements/FormikElements';
import AvailableDaysRow from './AvailableDaysRow';
import DatesRow from './DatesRow';
import ComboLocationsRow from './ComboLocationsRow';
import { SmallToggleSwitch } from '../../../../../../components/ToggleSwitch/ToggleSwitch';
import { Button } from '../../../../../../components/Button/Button';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import { Collapse, MenuItem } from '@material-ui/core';

const ComboAvailability = ({
  businesses,
  initValues,
  comboBusinesses,
  comboAvailabilities,
  handleComboAvailabilitiesSubmit,
  submitAvailabilitiesIsRequesting,
  disabled,
  orderTypesList,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [locationModalState, setLocationModalState] = useState(false);
  const [expiryFocusedState, setexpiryFocusedState] = useState(false);
  const [startDateFocusedState, setstartDateFocusedState] = useState(false);
  const [availabilityModalState, setAvailabilityModalState] = useState(false);

  const [comboBusinessesModified, setComboBusinessesModified] = useState(
    comboBusinesses.map((comboBusiness) => comboBusiness.business_id)
  );

  const [isComboAvailableAllTime, setIsComboAvailableAllTime] = useState(
    isComboAvailableAllTimeFunction(comboAvailabilities)
  );

  useEffect(() => {
    setIsComboAvailableAllTime(
      isComboAvailableAllTimeFunction(comboAvailabilities)
    );
  }, [comboAvailabilities]);

  const handleIsAvailableAllLocations = (checked, setFieldValue) => {
    if (checked) {
      setFieldValue(
        'comboLocations',
        businesses.map((businessId) => businessId.id)
      );
      setAvailableAllLocations(true);
    } else {
      setFieldValue('comboLocations', comboBusinessesModified);
      setAvailableAllLocations(false);
    }
    setFieldValue('comboAvailableAllLocations', checked);
  };

  const toggleLocationsModal = () => {
    setLocationModalState(!locationModalState);
  };

  useEffect(() => {
    setComboBusinessesModified(
      comboBusinesses.map((comboBusiness) => comboBusiness.business_id)
    );
  }, [comboBusinesses]);

  useEffect(() => {
    setAvailableAllLocations(
      businesses.length === comboBusinessesModified.length
    );
  }, [comboBusinessesModified]);

  const toggleAvailableHoursModal = () => {
    setAvailabilityModalState(!availabilityModalState);
  };

  const allTimeCombo = comboAvailableAllTime();

  const handleisAvailableAllTime = (checked, setFieldValue) => {
    if (checked) {
      setFieldValue('comboAvailability', allTimeCombo);
      setIsComboAvailableAllTime(true);
    } else {
      setIsComboAvailableAllTime(false);
    }
    setFieldValue('comboIsComboAvailableAllTime', checked);
  };

  const getExistingBusinesses = (businesses, comboLocations) => {
    const existingBusinesses = [];
    businesses.forEach((business) => {
      comboLocations.map((comboBusinessId) => {
        if (business.id == comboBusinessId) {
          existingBusinesses.push({
            value: business.id,
            label: business.display_name,
          });
        }
      });
    });
    return existingBusinesses;
  };

  // logic for order type toggle
  const orderTypeHandleChange = (
    checked,
    value,
    setFieldValue,
    comboOrderTypes
  ) => {
    if (checked) setFieldValue('comboOrderTypes', [...comboOrderTypes, value]);
    else
      setFieldValue(
        'comboOrderTypes',
        comboOrderTypes.filter((type) => type !== value)
      );
  };

  const [expire, setExpire] = useState(initValues.expiry_date !== null);

  const handleChangeExpiry = (checked, setFieldValue) => {
    if (checked) {
      setExpire(false);
    } else {
      setFieldValue(
        'comboExpiryDate',
        initValues.expiry_date === null ? new Date() : initValues.expiry_date
      );
      setExpire(true);
    }
    setFieldValue('comboNeverExpired', checked);
  };

  const [availableAllLocations, setAvailableAllLocations] = useState(
    businesses.length === comboBusinessesModified.length
  );

  const initialValues = {
    comboNeverExpired: initValues.expiry_date === null,
    comboExpiryDate: initValues.expiry_date,
    comboStartDate: initValues.start_date,
    comboOrderTypes: initValues.order_types,
    comboIsActive: initValues.is_active,
    comboLocations: comboBusinessesModified,
    comboAvailableAllLocations:
      businesses.length === comboBusinessesModified.length,
    comboIsComboAvailableAllTime: isComboAvailableAllTimeFunction(
      comboAvailabilities
    ),
    comboAvailability: hoursFormatter(comboAvailabilities, initValues),
  };

  return (
    <Wrapper disabled={disabled}>
      <div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validate={(values) => {
            let errors = {};
            if (!values.comboOrderTypes.length) {
              errors.comboOrderTypes = 'At least one selection is required';
            }
            return errors;
          }}
        >
          {({
            values,
            handleReset,
            dirty,
            errors,
            setFieldValue,
            resetForm,
          }) => (
            <Fragment>
              <TitleAndCollapseButton
                onClick={() => !disabled && setIsExpanded(!isExpanded)}
              >
                <Title>Combo Availabilities </Title>
                {!isExpanded ? (
                  <ChevronDownIcon
                    disabled={disabled}
                    onClick={() => setIsExpanded(true)}
                  />
                ) : (
                  <ChevronUpIcon
                    disabled={dirty}
                    onClick={() => setIsExpanded(false)}
                  />
                )}
              </TitleAndCollapseButton>

              <Collapse in={isExpanded}>
                <Fragment>
                  <div style={{ width: '50%' }}>
                    <div style={{ marginBottom: '15px' }}>Order Type*</div>
                    <Field
                      component={RenderCheckBox}
                      name="comboOrderTypes"
                      options={orderTypesList}
                      error={errors.comboOrderTypes}
                    />
                  </div>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                    }}
                  >
                    <div>
                      <AvailableDaysRow
                        values={values}
                        setFieldValue={setFieldValue}
                        isComboAvailableAllTime={isComboAvailableAllTime}
                        handleisAvailableAllTime={handleisAvailableAllTime}
                        setIsComboAvailableAllTime={setIsComboAvailableAllTime}
                        availabilityModalState={availabilityModalState}
                        toggleAvailableHoursModal={toggleAvailableHoursModal}
                        initValues={initValues}
                        handleReset={handleReset}
                      />

                      <DatesRow
                        values={values}
                        setFieldValue={setFieldValue}
                        startDateFocusedState={startDateFocusedState}
                        setstartDateFocusedState={setstartDateFocusedState}
                        expiryFocusedState={expiryFocusedState}
                        setexpiryFocusedState={setexpiryFocusedState}
                        handleChangeExpiry={handleChangeExpiry}
                        expire={expire}
                      />

                      <ComboLocationsRow
                        values={values}
                        setFieldValue={setFieldValue}
                        availableAllLocations={availableAllLocations}
                        setAvailableAllLocations={setAvailableAllLocations}
                        handleIsAvailableAllLocations={
                          handleIsAvailableAllLocations
                        }
                        getExistingBusinesses={getExistingBusinesses}
                        toggleLocationsModal={toggleLocationsModal}
                        locationModalState={locationModalState}
                        businesses={businesses}
                        initValues={initValues}
                      />

                      <IsActiveToggleWrapper>
                        <SmallToggleSwitch
                          checked={values.comboIsActive}
                          onChange={() =>
                            setFieldValue(
                              'comboIsActive',
                              !values.comboIsActive
                            )
                          }
                          value="checkedB"
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          label="Activate"
                          name="comboIsActive"
                        />
                      </IsActiveToggleWrapper>
                    </div>
                  </div>
                  <ButtonWrapper>
                    <Button
                      onClick={() => {
                        handleComboAvailabilitiesSubmit(initValues, values);
                        resetForm(values);
                      }}
                      disabled={
                        !dirty ||
                        !values.comboLocations.length ||
                        submitAvailabilitiesIsRequesting ||
                        !values.comboOrderTypes.length
                      }
                    >
                      Save
                    </Button>
                  </ButtonWrapper>
                </Fragment>
              </Collapse>
            </Fragment>
          )}
        </Formik>
      </div>
    </Wrapper>
  );
};

export default ComboAvailability;
