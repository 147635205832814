import {CREATE_ALERT, CLEAR_ALERT} from './types';
import randomId from 'random-id';

export default function(state = false, action) {
  switch (action.type) {
    case CREATE_ALERT:
      return {id: randomId(10), ...action.alert};
    case CLEAR_ALERT:
      return false;
    default:
      return state;
  }
}
