import React, { Component } from 'react';
import * as actions from './actions';
import { connect } from 'react-redux';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import Select from '../../../components/Select/Select';
import styled from 'styled-components';
import { Charts, TimePeriod } from '../components/DashboardElements';
import { LoadingBlueLarge } from '../../../components/Loading/Loading';
import SummaryBar from './components/Summary';
import ActiveUsers from './components/ActiveUsers';
import UserSignUps from './components/UserSignUps';
import CumulativeUserSignUps from './components/CumulativeUserSignUps';
import ActiveUserAge from './components/ActiveUserAge';
import ReferralStats from './components/ReferralStats';
import intervalOptions from '../utils/intervalOptions';
import { TitleArea } from '../../../components/Table/TableElements';
const Separation = styled.div`
  border-top: 1px solid #c9cbcf;
  margin: 2em 0;
`;

class UsersDashboard extends Component {
  state = {
    intervalTypeAndValue: intervalOptions[1]
  };

  componentDidMount() {
    this.getAllUserDashboardData();
    track.page('dashboard-user');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.intervalTypeAndValue !== this.state.intervalTypeAndValue) {
      this.getAllUserDashboardData();
    }
    if (!prevProps.token && this.props.token) {
      this.getAllUserDashboardData();
    }
  }

  getAllUserDashboardData = () => {
    const { intervalTypeAndValue } = this.state;
    this.props.getSummary(intervalTypeAndValue.value);
    this.props.getActiveUsers(intervalTypeAndValue.value);
    this.props.getNewUsers(intervalTypeAndValue.value);
    this.props.getReferralStats(intervalTypeAndValue.value);
    this.props.getUserAge(intervalTypeAndValue.value);
    this.props.getCumulativeUsers();
  };

  renderTitle = () => {
    return (
      <TitleArea
        pageName="Users"
        selectName="time_period"
        selectLabel="Time Period"
        selectValue={this.state.intervalTypeAndValue}
        onChange={intervalTypeAndValue =>
          this.setState({ intervalTypeAndValue })
        }
        options={intervalOptions}
      />
    );
  };

  render() {
    const {
      summaryData,
      activeUsersData,
      userSignUpsData,
      referralStatsData,
      usersAgeData,
      cumulativeUsersData,
      loading,
      innerLoading
    } = this.props.usersDashboard;
    return (
      <div>
        {this.renderTitle()}
        {summaryData && loading ? (
          <div>
            {summaryData ? (
              <SummaryBar data={summaryData} />
            ) : (
              <LoadingBlueLarge />
            )}

            <Charts>
              {activeUsersData && innerLoading ? (
                <ActiveUsers
                  activeUsersData={activeUsersData}
                  stateInterval={this.state.intervalTypeAndValue.value}
                />
              ) : (
                <LoadingBlueLarge />
              )}
              {userSignUpsData ? (
                <UserSignUps
                  userSignUpsData={userSignUpsData}
                  stateInterval={this.state.intervalTypeAndValue.value}
                />
              ) : (
                <LoadingBlueLarge />
              )}
            </Charts>

            <Charts>
              {referralStatsData ? (
                <ReferralStats referralStatsData={referralStatsData} />
              ) : (
                <LoadingBlueLarge />
              )}
              {usersAgeData ? (
                <ActiveUserAge usersAgeData={usersAgeData} />
              ) : (
                <LoadingBlueLarge />
              )}
            </Charts>

            <Separation />

            {cumulativeUsersData ? (
              <CumulativeUserSignUps
                cumulativeUsersData={cumulativeUsersData}
              />
            ) : (
              <LoadingBlueLarge />
            )}
          </div>
        ) : (
          <LoadingBlueLarge />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    usersDashboard: state.usersDashboard,
    usersDashboardForm: state.form.TimePeriod,
    token: state.accountReducer.token,
  };
}

export default connect(mapStateToProps, {...actions})(UsersDashboard);
