import React from 'react';
import styled from 'styled-components';
import {
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td
} from '../../../../../../components/Table/TableElements';
import {EditIcon, DeleteIcon} from '../../../../../../components/Icon';
import ColourBox from './ColourBox';

const TableWrapper = styled.div`
  overflow-x: auto;
  padding-left: 20px;
  padding-bottom: 20px;
`;

const TdPadding = styled(Td)`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const TdPaddingCapitalized = styled(Td)`
  text-transform: capitalize;
`;

const ActionsGrid = styled.div`
  display: grid;
  margin: 0 10px;
  grid-template-columns: 1fr 1fr;
`;

const DisplayMessage = styled.div`
  font-size: 38px;
  color: #d8d8d8;
  height: 30vh;
  margin: auto;
  padding: 12vh 0;
  text-align: center;
`;

const EditDeliveryContainer = styled.div``;
const DeleteDeliveryContainer = styled.div``;

const BusinessLocationDeliveryZonesViewTable = props => {
  const {
    deliveryZoneObjects,
    handleEditIconClick,
    handleDeleteIconClick
  } = props;

  return (
    <TableWrapper>
      <Table>
        {renderHeaders()}
        <TBody striped>
          {deliveryZoneObjects.length
            ? renderRows(
                deliveryZoneObjects,
                handleEditIconClick,
                handleDeleteIconClick
              )
            : null}
        </TBody>
      </Table>
      {!deliveryZoneObjects.length ? (
        <DisplayMessage>No Delivery Zones Found</DisplayMessage>
      ) : null}
    </TableWrapper>
  );
};

const renderHeaders = () => (
  <TrHead>
    <Th align="center">Colour</Th>
    <Th align="center">Zone Name</Th>
    <Th align="center">Shape Type</Th>
    <Th align="center">Delivery Fee</Th>
    <Th align="center">Active Status</Th>
    <Th align="center">Actions</Th>
  </TrHead>
);

const renderRows = (
  deliveryZoneObjects,
  handleEditIconClick,
  handleDeleteIconClick
) =>
  deliveryZoneObjects.map((deliveryZone, index) => (
    <Tr key={index}>
      <TdPadding align="center">
        <ColourBox colour={deliveryZone.colour} />
      </TdPadding>
      <TdPadding align="center">{deliveryZone.zoneName}</TdPadding>
      <TdPaddingCapitalized align="center">
        {deliveryZone.type}
      </TdPaddingCapitalized>
      <TdPadding align="center">${deliveryZone.zoneFee}</TdPadding>
      <TdPadding align="center">
        {deliveryZone.isActive ? 'ACTIVE' : 'INACTIVE'}
      </TdPadding>
      <TdPadding align="center">
        <ActionsGrid>
          <EditDeliveryContainer>
            <EditIcon onClick={() => handleEditIconClick(deliveryZone.id)} />
          </EditDeliveryContainer>
          <DeleteDeliveryContainer>
            <DeleteIcon
              onClick={() => handleDeleteIconClick(deliveryZone.id)}
            />
          </DeleteDeliveryContainer>
        </ActionsGrid>
      </TdPadding>
    </Tr>
  ));
export default BusinessLocationDeliveryZonesViewTable;
