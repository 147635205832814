import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../combosAction';
import ComboOverridesModal from '../components/ComboOverridesModal';

const ComboOverridesModalContainer = ({
  isOverrideModal,
  setIsOverrideModal,
  formattedBusinesses,
  comboId,
  combo,
  summaryData,
  handleSubmitComboOverrides,
  isCreate,
  disabled
}) => {
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isConfirmOverridesModal, setIsConfirmOverridesModal] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);

  return (
    <ComboOverridesModal
      isConfirmModal={isConfirmModal}
      setIsConfirmModal={setIsConfirmModal}
      isConfirmOverridesModal={isConfirmOverridesModal}
      setIsConfirmOverridesModal={setIsConfirmOverridesModal}
      isOverrideModal={isOverrideModal}
      setIsOverrideModal={setIsOverrideModal}
      formattedBusinesses={formattedBusinesses}
      comboId={comboId}
      combo={combo}
      summaryData={summaryData}
      isCreate={isCreate}
      disabled={disabled}
      handleSubmitComboOverrides={handleSubmitComboOverrides}
      selectedLocations={selectedLocations}
      setSelectedLocations={setSelectedLocations}
    />
  );
};

export default connect(null, actions)(ComboOverridesModalContainer);
