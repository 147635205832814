import React, {Component} from 'react';
import {getProducts} from '../../MenuManagement/Products/productsActions';
import * as selectors from '../../MenuManagement/Products/productsSelectors';
import {connect} from 'react-redux';

function getProductsHOC(WrappedComponent) {
  class Container extends Component {
    componentDidMount() {
      if (!this.props.allProducts && !this.props.requestingGetProducts) {
        this.props.getProducts();
      }
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.token && this.props.token) {
        this.props.getProducts();
      }
    }

    render() {
      return (
        <WrappedComponent
          requestingGetProducts={this.props.requestingGetProducts}
          allProducts={this.props.allProducts}
          {...this.props}
        />
      );
    }
  }

  function mapStateToProps(state) {
    return {
      allProducts: selectors.selectAllProducts(state),
      requestingGetProducts: selectors.selectRequestingGetProducts(state),
      token: state.accountReducer.token
    };
  }

  return connect(
    mapStateToProps,
    {getProducts}
  )(Container);
}

export default getProductsHOC;
