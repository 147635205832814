import {
  GET_COUPONS_REQUEST,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_FAILURE,
  INITIATE_CREATE_COUPON,
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAILURE,
  CANCEL_COUPON,
  INITIATE_EDIT_COUPON,
  EDIT_COUPON_REQUEST,
  EDIT_COUPON_SUCCESS,
  EDIT_COUPON_FAILURE,
  TOGGLE_IS_ACTIVE_COUPON,
  TOGGLE_IS_ACTIVE_COUPON_SUCCESS,
  TOGGLE_IS_ACTIVE_COUPON_FAILURE,
  SEARCH_CUSTOMER_REQUEST,
  SEARCH_CUSTOMER_SUCCESS,
  SEARCH_CUSTOMER_FAILURE,
  CLEAR_CUSTOMER_SEARCH,
  GET_CUSTOMER_DETAIL_REQUEST,
  GET_CUSTOMER_DETAIL_SUCCESS,
  UPDATE_SORTED_COUPONS_LIST
} from './types';
import { UPDATE_COUPONS_REQUEST_PARAMS } from '../../Pagination/types';

const initialFormValues = {
  coupon_details: {
    template_name: 'unlimited-time',
    template_values: {
      type: 'money'
    },
    is_active: true
  },
  businesses: [],
  customers: []
};

export default function(
  state = {
    requestParams: {
      page_number: 1,
      page_size: 25,
      sorting_option: ''
    },
    isFetching: false,
    requestingCreateCoupon: false,
    response: null,
    isDeleting: [],
    searchLoading: false,
    initialFormValues,
    storeLocations: []
  },
  action
) {
  switch (action.type) {
    case GET_COUPONS_REQUEST:
      return {
        ...state,
        requestingGetCoupons: true
      };
    case GET_COUPONS_SUCCESS:
      return {
        ...state,
        requestingGetCoupons: false,
        editCoupon: false,
        createCoupon: false,
        response: { data: action.payload.data.reverse(), ...action.payload }
      };
    case GET_COUPONS_FAILURE:
      return {
        ...state,
        requestingGetCoupons: false,
        editCoupon: false,
        createCoupon: false,
        error: action.error
      };
    case CREATE_COUPON_REQUEST:
      return {
        ...state,
        requestingCreateCoupon: true,
        initialFormValues
      };
    case CREATE_COUPON_SUCCESS:
      return {
        ...state,
        requestingCreateCoupon: false,
        createCoupon: false,
        customersInfo: undefined,
        customerDetail: undefined
      };
    case CREATE_COUPON_FAILURE:
      return {
        ...state,
        requestingCreateCoupon: false
      };
    case INITIATE_CREATE_COUPON:
      return {
        ...state,
        createCoupon: true,
        initialFormValues
      };
    case EDIT_COUPON_REQUEST:
      return {
        ...state,
        requestingEditCoupon: true
      };
    case EDIT_COUPON_SUCCESS:
      return {
        ...state,
        requestingEditCoupon: false,
        editCoupon: false,
        customersInfo: undefined,
        customerDetail: undefined
      };
    case EDIT_COUPON_FAILURE:
      return {
        ...state,
        editCoupon: true,
        requestingEditCoupon: false,
        error: action.error
      };
    case TOGGLE_IS_ACTIVE_COUPON:
      return {
        ...state,
        isDeleting: [...state.isDeleting, action.payload]
      };
    case TOGGLE_IS_ACTIVE_COUPON_SUCCESS:
      return {
        ...state,
        isDeleting: state.isDeleting.filter(id => id !== action.payload),
        response: {
          ...state.response,
          data: state.response.data.map(coupon =>
            coupon.coupon_details.coupon_id !==
            action.payload.coupon_details.coupon_id
              ? coupon
              : {
                  ...coupon,
                  coupon_details: {
                    ...coupon.coupon_details,
                    is_active: !coupon.coupon_details.is_active
                  }
                }
          )
        }
      };
    case TOGGLE_IS_ACTIVE_COUPON_FAILURE:
      return {
        ...state,
        isDeleting: state.isDeleting.filter(id => id !== action.payload)
      };
    case CANCEL_COUPON:
      let updatedState = {...state};
      delete updatedState.customersInfo;
      delete updatedState.customerDetail;
      return {
        ...updatedState,
        createCoupon: false,
        editCoupon: false,
        requestingCreateCoupon: false,
      };
    case INITIATE_EDIT_COUPON:
      return {
        ...state,
        editCoupon: true,
        initialFormValues: { ...action.payload.coupon /*customers: [1, 2, 3]*/ }
      };
    case UPDATE_COUPONS_REQUEST_PARAMS:
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload
        }
      };
    case SEARCH_CUSTOMER_REQUEST:
      return {
        ...state,
        searchLoading: true
      };
    case SEARCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        customersInfo: action.payload,
        searchLoading: false
      };
    case CLEAR_CUSTOMER_SEARCH:
      return {
        ...state,
        customersInfo: undefined,
        searchLoading: false
      };
    case GET_CUSTOMER_DETAIL_REQUEST:
      return {
        ...state,
        requestingGetCustomerDetail: true
      };
    case GET_CUSTOMER_DETAIL_SUCCESS:
      return {
        ...state,
        requestingGetCustomerDetail: false,
        customerDetail: action.payload
      };
    case UPDATE_SORTED_COUPONS_LIST:
      return {
        ...state,
        response:{
          ...state.response,
           data: action.couponsList
        },
        requestParams: {
          ... state.requestParams,
          sorting_option: action.sortOrder
        }
      };
  }
  return state;
}
