import {
  GET_BUSINESS_LOCATIONS_OF_MERCHANT_REQUEST,
  GET_BUSINESS_LOCATIONS_OF_MERCHANT_SUCCESS,
  GET_BUSINESS_LOCATIONS_OF_MERCHANT_FAILURE,
  FILTER_BUSINESS_LOCATIONS_OF_MERCHANT_WITH_SELECTED_BUSINESS,
} from './types';

export default function(
  state = {
    requestingGetBusinessLocationsOfMerchant: false,
    businessLocationsOfMerchant: [],
    selectedBusinessLocationsOfMerchant: []
  },
  action
) {
  switch (action.type) {
    case GET_BUSINESS_LOCATIONS_OF_MERCHANT_REQUEST:
      return {
        ...state,
        requestingGetBusinessLocationsOfMerchant: true,
        businessLocationsOfMerchant: []
      };
    case GET_BUSINESS_LOCATIONS_OF_MERCHANT_SUCCESS:
      return {
        ...state,
        requestingGetBusinessLocationsOfMerchant: false,
        businessLocationsOfMerchant: action.payload.data,
        selectedBusinessLocationsOfMerchant: action.payload.data
      };
    case FILTER_BUSINESS_LOCATIONS_OF_MERCHANT_WITH_SELECTED_BUSINESS:
      return {...state, selectedBusinessLocationsOfMerchant: [action.payload]};
    default:
      return state;
  }
}
