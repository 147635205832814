import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  getCategories,
  toggleCategory,
  initiateEditCategory,
  sortCategories,
  updateCategoriesRequestParams,
  updateSortedCategoriesList,
} from '../categoryActions';
import CategoryList from './components/CategoryList';
import { LoadingBlueLarge } from '../../../../components/Loading/Loading';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import CategoriesSortModal from './CategorySort/CategoriesSortModal';
import { sort } from '../../../../utils/sort/sort';
import _ from 'lodash';

let CategoryListContainer = ({
  getCategories,
  toggleCategory,
  categories,
  isRequestingGetCategories,
  handleCreateButtonClick,
  initiateEditCategory,
  sortCategories,
  isSortingRequested,
  requestParams,
  updateCategoriesRequestParams,
  updateSortedCategoriesList,
  allInitialCategories,
}) => {
  useEffect(() => {
    track.page('menu-management-category-list');
    window.scrollTo(0, 0);
    getCategories();
  }, []);

  const [searchString, setSearchString] = useState('');
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);

  const handleCategoriesSearch = (searchString) => {
    updateCategoriesRequestParams({
      page_number: 1,
      page_size: requestParams.page_size,
    });
    setSearchString(searchString);
  };

  const handleSort = (sortArgs) => {
    let sortOrder = '';
    let order = '';
    const currentOptionAsArray = requestParams.sorting_option.split('-');
    if (currentOptionAsArray[0] === sortArgs.value) {
      order = currentOptionAsArray[1] === 'asc' ? 'desc' : 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    } else {
      order = 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    }
    if (categories) {
      const result = sort(categories, {
        ...sortArgs,
        sortOrder: `${order}`,
      });
      updateCategoriesRequestParams({
        page_number: 1,
        page_size: requestParams.page_size,
      });
      updateSortedCategoriesList(result, sortOrder);
    }
  };

  const sliceCategories = (categories) => {
    const indexOfFirst =
      (requestParams.page_number - 1) * requestParams.page_size;
    const indexOfLast = requestParams.page_number * requestParams.page_size;

    return categories
      .filter(
        (category) =>
          category.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1
      )
      .slice(indexOfFirst, indexOfLast);
  };

  const [categoriesActiveList, setCategoriesActiveList] = useState(
    sliceCategories(categories)
  );

  useEffect(() => {
    setCategoriesActiveList(sliceCategories(categories));
  }, [requestParams, categories]);

  const allCategoriesLength = categories.filter(
    (categories) =>
      categories.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1
  ).length;

  return (
    <Fragment>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '11fr 3fr',
          alignItems: 'center',
        }}
      >
        <BreadCrumb />
      </div>
      {!isRequestingGetCategories ? (
        <CategoryList
          categories={categoriesActiveList}
          toggleCategory={toggleCategory}
          handleCreateButtonClick={handleCreateButtonClick}
          initiateEditCategory={initiateEditCategory}
          sortCategories={sortCategories}
          isSortingRequested={isSortingRequested}
          handleCategoriesSearch={handleCategoriesSearch}
          requestParams={requestParams}
          setIsSortModalOpen={setIsSortModalOpen}
          allCategoriesLength={allCategoriesLength}
          searchString={searchString}
          handleSort={handleSort}
        />
      ) : (
        <LoadingBlueLarge />
      )}
      {isSortModalOpen && (
        <CategoriesSortModal
          categories={categories}
          setIsSortModalOpen={setIsSortModalOpen}
          sortCategories={sortCategories}
          isSortingRequested={isSortingRequested}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  categories: state.categoryReducer.allCategories,
  isRequestingGetCategories: state.categoryReducer.isRequestingGetCategories,
  isSortingRequested: state.categoryReducer.isSortingRequested,
  requestParams: state.categoryReducer.requestParams,
  allInitialCategories: state.categoryReducer.allInitialCategories,
});

export default connect(mapStateToProps, {
  getCategories,
  toggleCategory,
  initiateEditCategory,
  sortCategories,
  updateCategoriesRequestParams,
  updateSortedCategoriesList,
})(CategoryListContainer);
