import { FieldArray, Formik } from 'formik';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../../components/Button/Button';
import Modal from '../../../../../components/Modal/Modal';
import RenderCategoriesList from './components/RenderCategoriesList';
import { LoadingBlueLarge } from '../../../../../components/Loading/Loading';

const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: auto 20px;
  padding: 24px 24px 10px;
  font-size: 20px;
`;

const ModelContentWrapper = styled.div`
  width: 800px;
`;

const ButtonsWrapper = styled.div`
  text-align: right;
  padding: 20px;
  box-shadow: 0 -2px 9px 0 rgba(0, 0, 0, 0.12);
`;

const ModalBody = styled.div`
  height: 60vh;
`;

const LineDivider = styled.hr`
  margin-top: 11px;
  margin-bottom: 11px;
  border: solid 1px #ebebeb;
`;

const CategoriesSortModal = ({
  categories,
  setIsSortModalOpen,
  sortCategories,
  isSortingRequested
  //   isSavingComboPositions,
  //   handleSortingComboSubmit
}) => {
  const closeCategoriesSortModal = () => {
    setIsSortModalOpen(false);
  };
  return (
    <Modal borderRadius>
      <ModelContentWrapper>
        <Formik
          initialValues={{ categories: categories }}
          enableReinitialize={true}
        >
          {({ values, handleReset, dirty, errors, setFieldValue }) => (
            <Fragment>
              <ModalTitle>
                <div>Sort Categories</div>
                <div
                  onClick={closeCategoriesSortModal}
                  style={{ cursor: 'pointer', justifySelf: 'center' }}
                >
                  <i className="fa fa-times" />
                </div>
              </ModalTitle>
              <LineDivider />
              <ModalBody>
                {isSortingRequested && <LoadingBlueLarge />}
                {!isSortingRequested && (
                  <FieldArray
                    name="categories"
                    render={arrayHelpers => (
                      <RenderCategoriesList arrayHelpers={arrayHelpers} />
                    )}
                  />
                )}
              </ModalBody>
              <ButtonsWrapper>
                <Button
                  disabled={!dirty || isSortingRequested}
                  onClick={() => {
                    sortCategories(
                      values['categories'],
                      closeCategoriesSortModal
                    );
                  }}
                >
                  Save
                </Button>
              </ButtonsWrapper>
            </Fragment>
          )}
        </Formik>
      </ModelContentWrapper>
    </Modal>
  );
};

export default CategoriesSortModal;
