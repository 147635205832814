import React, { Component } from 'react';
import { connect } from 'react-redux';
import fetchOnParamChange from '../../HOCs/fetchOnParamChange';
import * as upsellActions from './upsellActions';
import UpsellIndex from './components/UpsellIndex';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import ProductSelector from './components/ProductSelector';

class UpsellContainer extends Component {
  state = {
    productSelectorIsVisible: false
  };

  componentDidMount() {
    track.page('menu-management-upsell-list');
  }

  toggleProductSelector = () =>
    this.setState({
      productSelectorIsVisible: !this.state.productSelectorIsVisible
    });

  render() {
    const { upsellMenuDraft, addProductsToUpsellMenuDraft } = this.props;
    const { productSelectorIsVisible } = this.state;
    return (
      <div>
        <BreadCrumb pageName="Upsell" />
        {this.props.upsellMenu && (
          <UpsellIndex
            {...this.props}
            toggleProductSelector={this.toggleProductSelector}
          />
        )}
        {productSelectorIsVisible && (
          <ProductSelector
            cancel={this.toggleProductSelector}
            select={products => {
              addProductsToUpsellMenuDraft(products);
              this.toggleProductSelector();
            }}
            productsToExclude={upsellMenuDraft}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    upsellReducer: state.upsellReducer,
    upsellMenu: state.upsellReducer.upsellMenu,
    upsellMenuDraft: state.upsellReducer.upsellMenuDraft
  };
}

const initialRequestParams = {
  page_number: 1,
  page_size: 25
};

UpsellContainer = fetchOnParamChange(
  UpsellContainer,
  upsellActions.getUpsellMenu,
  initialRequestParams
);

export default connect(mapStateToProps, upsellActions)(UpsellContainer);
