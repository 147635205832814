import offersReducer from '../../../Engagement/Offers/offersReducer';

export const posDataTransform = (products, posData) => {
  products.foreach(product => {
    posData.foreach(pos => {
      if (product.productId === posData.productId) {
        product.productVariants.map(variant => {
          if (variant.variantId === posData.variantId) {
            return {
              productId: product.id,
              productName: product.name,
              productImageUrl: product.image_url,
              productIsActive: product.is_active,
              productVariants
            };
          }
        });
      }
    });
  });
};

export const discountOptionsModified = (options, type) => {
  let optionsModified = [];

  optionsModified = options.map(option => {
    if (type === 'coupon') {
      return {
        ...option,
        value: option.coupon_details.label,
        label: option.coupon_details.label
      };
    }
    if (type === 'offer') {
      return {
        ...option,
        value: option.offer_details.offer_id,
        label: option.offer_details.label
      };
    }
    if (type === 'combo') {
      return {
        ...option,
        value: option.name,
        label: option.name
      };
    }
  });

  // optionsModified.unshift({
  //   id: `ALL-${type.toUpperCase()}S`,
  //   value: `ALL ${type.toUpperCase()}S`,
  //   label: `ALL ${type.toUpperCase()}S`
  // });

  return optionsModified;
};
