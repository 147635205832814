import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
  background: ${props => props.theme.lightGray};
  display: flex;
  justify-content: space-between;
  align-self: center;
  height: 5vh;
  padding: 0 30px;
  @media print {
    display: none;
  }
`;
const StyledP = styled.p`
  align-self: center;
  text-align: ${props => props.textAlign || 'left'};
`;

const Footer = () => {
  return (
    <FooterContainer>
      <StyledP>&copy; {new Date().getFullYear()} Smooth Commerce</StyledP>
      <StyledP textAlign="right">Powered by Smooth Commerce</StyledP>
    </FooterContainer>
  );
};

export default Footer;
