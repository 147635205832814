import React, { Fragment, useState } from 'react';
import {
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td
} from '../../../components/Table/TableElements';
import {
  RenderTextField,
  required,
  RenderSelect,
  RenderToggleSwitch
} from '../../../components/FormElements/FormikElements';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { Collapse } from '@material-ui/core';
import ChevronUpIcon from '../../../components/Icon/ChevronUpIcon';
import ChevronDownIcon from '../../../components/Icon/ChevronDownIcon';
import { Button, ButtonRow } from '../../../components/Button/Button';
import ConfirmCancelModal from '../../MenuManagement/Products/ProductWizard/components/ConfirmCancelModal';

const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 30px;
  margin: 30px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
`;

const AppDetailForm = ({
  appDetailSchema,
  appDetail,
  paymentOptions,
  handleSubmit
}) => {
  const { configuration } = appDetail;
  const [isExpanded, setIsExpanded] = useState(false);
  const [
    confirmCancelModalIsVisible,
    setConfirmCancelModalIsVisible
  ] = useState(false);
  
  const appConfigToShow = [
    'is_order_ahead_enabled',
    'is_tip_enabled',
    'is_delivery_enabled',
    'upsell_cart_enabled',
    'show_rate_app',
    'preferred_delivery'
  ]

  const renderHeaders = () => {
    return (
      <TrHead>
        <Th align="left">Sr.No</Th>
        <Th align="left">Name/Label</Th>
        <Th align="left">Type</Th>
      </TrHead>
    );
  };

  const renderRowsByType = (configkey, setFieldValue) => {
    let options = [];
    let fieldData = '';
    switch (appDetailSchema[configkey]) {
      case 'array':
        configuration[configkey].map(configDetail =>
          options.push({ value: configDetail, label: configDetail })
        );
        fieldData = (
          <Td padding="20px" style={{ width: '40%' }}>
            <Field
              name={configkey}
              component={RenderSelect}
              value={options}
              placeholder={configkey}
              isMulti
              options={paymentOptions}
              style={{ marginTop: '12px' }}
            />
          </Td>
        );
        break;
      case 'boolean':
        fieldData = (
          <Td padding="20px" style={{ width: '40%' }}>
            <Field
              name={configkey}
              component={RenderToggleSwitch}
              onChange={e => setFieldValue(configkey, e.target.checked)}
            />
          </Td>
        );
        break;
      default:
        fieldData = (
          <Td padding="20px" style={{ width: '40%' }}>
            <Field
              name={configkey}
              component={RenderTextField}
              type="text"
              label={`* ${configkey}`}
              validate={required}
            />
          </Td>
        );
        break;
    }
    return fieldData;
  };
  const renderRows = setFieldValue => {
    return Object.keys(appDetailSchema)
      .filter(schema => appConfigToShow.includes(schema))
      .map((configkey, index) => {
      return (
        <Tr style={{ padding: '20px' }} key={index}>
          <Td padding="20px" style={{ width: '10%' }}>
            {index + 1}
          </Td>
          <Td padding="20px" style={{ width: '30%' }}>
            {configkey}
          </Td>
          {renderRowsByType(configkey, setFieldValue)}
          <Td padding="20px" style={{ width: '20%' }}></Td>
        </Tr>
      );
    });
  };
  return (
    <Formik initialValues={configuration} enableReinitialize={true}>
      {({ values, handleReset, dirty, setFieldValue, isValid }) => (
        <Fragment>
          <TitleAndCollapseButton>
            <Title style={{ textTransform: 'capitalize' }}>
              {appDetail.platform_name}
            </Title>
            {!isExpanded ? (
              <ChevronDownIcon
                onClick={() => {
                  setIsExpanded(true);
                }}
              />
            ) : (
              <ChevronUpIcon
                disabled={dirty}
                onClick={() => {
                  setIsExpanded(false);
                }}
              />
            )}
          </TitleAndCollapseButton>
          <Collapse in={isExpanded}>
            <Form>
              <Table noBorder>
                <thead>{renderHeaders()}</thead>
                <TBody striped style={{ padding: '10px' }}>
                  {renderRows(setFieldValue)}
                </TBody>
              </Table>
              <ButtonRow
                justifyContent="space-between"
                style={{
                  margin: '24px 40px 24px 40px',
                  width: 'inherit'
                }}
              >
                <Button
                  secondary
                  onClick={() => {
                    dirty
                      ? setConfirmCancelModalIsVisible(true)
                      : setIsExpanded(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!dirty || !isValid}
                  onClick={() => {
                    setIsExpanded(false);
                    handleSubmit({
                      app_id: appDetail.app_id,
                      platform_id: appDetail.platform_id,
                      platform_name: appDetail.platform_name,
                      configuration: values
                    });
                  }}
                >
                  Save
                </Button>
              </ButtonRow>
              {confirmCancelModalIsVisible && (
                <ConfirmCancelModal
                  onCancel={() => setConfirmCancelModalIsVisible(false)}
                  onConfirm={() => {
                    handleReset();
                    setConfirmCancelModalIsVisible(false);
                    setIsExpanded(false);
                  }}
                />
              )}
            </Form>
          </Collapse>
        </Fragment>
      )}
    </Formik>
  );
};

export default AppDetailForm;
