import React, { Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Field } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import {
  RenderTextField,
  required,
  RenderSelect
} from '../../../../../components/FormElements/FormikElements';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 40px;
`;

const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-rows: 120px auto;
`;

const Subtitle = styled.div`
  font-weight: 600;
`;

const OptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
`;

const OptionGridWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
`;

const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 50px;
  align-items: center;
`;

const BirthdayFreeItemOffer = ({
  allProducts,
  allVariants,
  setFieldValue,
  auto_redeem,
  reusable,
  apply_multiple,
  conditions
}) => {
  return (
    <Wrapper>
      {conditions.map((condition, index) => {
        return (
          <React.Fragment key={index}>
            <Subtitle style={{ margin: '10px 0', textTransform: 'capitalize' }}>
              {condition.source}
            </Subtitle>
            <ProductWrapper>
              <FieldWrapper>
                <div>
                  {/* <Subtitle>{condition.source}</Subtitle> */}
                  <Subtitle>*Eligible Products</Subtitle>
                  <div>
                    These are Products that can be free. If there are no
                    Pre-Requisite Products, the free products will be available
                    to all customers that can view it on their app.
                  </div>
                </div>
                <div>
                  <Field
                    name={`conditions[${index}].item_conditions.eligible_products`}
                    component={RenderSelect}
                    placeholder="Products"
                    isMulti
                    options={allProducts ? allProducts : []}
                    getOptionLabel={product => product.name}
                    getOptionValue={product => product.id}
                    simpleValue
                    valueKey="id"
                  />
                </div>
              </FieldWrapper>

              <div></div>
            </ProductWrapper>
            <hr />
          </React.Fragment>
        );
      })}
      <Subtitle>Free Item Options</Subtitle>
      <OptionsWrapper>
        <OptionGridWrapper>
          <Field
            name="offer_details.template_values.max_discount"
            component={RenderTextField}
            type="number"
            label="*Max Discount"
            validate={required}
            onKeyDown={evt =>
              (evt.key === 'e' || evt.key === '-' || evt.key === '+') &&
              evt.preventDefault()
            }
          />
          <Field
            name="offer_details.template_values.days_available"
            component={RenderTextField}
            type="number"
            label="*days available"
            validate={required}
            onKeyDown={evt =>
              (evt.key === 'e' || evt.key === '-' || evt.key === '+') &&
              evt.preventDefault()
            }
          />
        </OptionGridWrapper>
        <OptionGridWrapper>
          <CheckboxWrapper>
            <Checkbox
              onChange={e =>
                setFieldValue('offer_details.auto_redeem', e.target.checked)
              }
              checked={auto_redeem}
              inputProps={{
                'aria-label': 'secondary checkbox'
              }}
            />
            <div>Auto Redeem</div>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <Checkbox
              onChange={e =>
                setFieldValue('offer_details.reusable', e.target.checked)
              }
              checked={reusable}
              inputProps={{
                'aria-label': 'secondary checkbox'
              }}
            />
            <div>Reusable</div>
          </CheckboxWrapper>
          {/* <CheckboxWrapper>
            <Checkbox
              onChange={e =>
                setFieldValue(
                  'offer_details.template_values.apply_multiple',
                  e.target.checked
                )
              }
              checked={apply_multiple}
              inputProps={{
                'aria-label': 'secondary checkbox'
              }}
            />
            <div>Apply Multiple</div>
          </CheckboxWrapper> */}
        </OptionGridWrapper>
      </OptionsWrapper>
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  allProducts: state.productsReducer.allProducts,
  allVariants: state.variantsReducer.allVariants
});

export default connect(mapStateToProps)(BirthdayFreeItemOffer);
