import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Card from './components/Card';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import { Button, ButtonRow } from '../../../components/Button/Button';
import apnIcon from '../../../../public/img/MobileMessages/apn-icon.png';
import oneTimeMsgIcon from '../../../../public/img/MobileMessages/one-time-msg-icon.png';

const MobileMessagesContainer = props => {
  return (
    <div>
      <ButtonRow justifyContent="space-between" style={{ margin: 'auto' }}>
        <BreadCrumb pageName="Mobile Messages" />
      </ButtonRow>

      <Card
        logo={apnIcon}
        title="Automated Messages"
        description="Automatically inform customers about activities such as order status, offers, loyalty and more."
        onArrowClick={() =>
          props.history.push('mobile-messages/automated-messages')
        }
      />

      <Card
        logo={oneTimeMsgIcon}
        title="One-Time Messages"
        description="Create single-use messages to inform all customers (or a specific group of customers) when you have a special event or promotion."
        onArrowClick={() =>
          props.history.push('mobile-messages/one-time-messages')
        }
      />
    </div>
  );
};

export default MobileMessagesContainer;
