import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Formik, Field, FieldArray } from 'formik';
import { getCombos, submitPOSDiscountValuesCombos } from '../posAction';
import { LoadingBlueLarge } from '../../../../components/Loading/Loading';
import fetchOnParamChange from '../../../HOCs/fetchOnParamChange';
import CombosMappingTable from './components/CombosMappingTable';

const Wrapper = styled.div`
  width: 1000px;
  background: white;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  margin-top: 24px;

  @media (max-width: 1313px) {
    width: 100%;
  }
`;

let CombosMappingContainer = ({
  meta,
  combos,
  isRequestingGetCombos,
  getCombos,
  updateRequestParams,
  requestParams,
  submitPOSDiscountValuesCombos,
  type,
  selectedValuePOS,
  setSelectedValuePOS,
  allCombos,
  posDiscountsMappingFields,
  posDiscountsFieldsNum
}) => {
  useEffect(() => {
    getCombos(initialRequestParams);
  }, []);

  const initialValues = {
    combos: combos.map((combo, index) => ({
      ...combo,
      pos_mapping: combo.pos_mapping
        ? Object.keys(combo.pos_mapping).reduce((acc, key) => {
            acc[key] = combo.pos_mapping[key] || '';
            return acc;
          }, {})
        : '',
      index: index
    }))
  };

  let combosModified = combos.map((combo, index) => ({
    ...combo,
    index
  }));

  return (
    <Formik initialValues={initialValues} enableReinitialize={true}>
      {({ values, handleReset, dirty, errors, setFieldValue, handleSave }) => (
        <Fragment>
          <Wrapper>
            {!isRequestingGetCombos ? (
              <CombosMappingTable
                meta={meta}
                combos={combosModified}
                requestParams={requestParams}
                updateRequestParams={updateRequestParams}
                initialRequestParams={initialRequestParams}
                dirty={dirty}
                handleReset={handleReset}
                submitPOSDiscountValuesCombos={submitPOSDiscountValuesCombos}
                values={values}
                initialValues={initialValues}
                getCombos={getCombos}
                type={type}
                selectedValuePOS={selectedValuePOS}
                setSelectedValuePOS={setSelectedValuePOS}
                allCombos={allCombos}
                posDiscountsMappingFields={posDiscountsMappingFields}
                posDiscountsFieldsNum={posDiscountsFieldsNum}
              />
            ) : (
              <LoadingBlueLarge />
            )}
          </Wrapper>
        </Fragment>
      )}
    </Formik>
  );
};

const initialRequestParams = {
  page_number: 1,
  page_size: 25,
  searchString: ''
};

CombosMappingContainer = fetchOnParamChange(
  CombosMappingContainer,
  getCombos,
  initialRequestParams
);

const mapStateToProps = state => ({
  meta: state.posReducer.meta,
  isRequestingGetCombos: state.posReducer.isRequestingGetCombos,
  combos: state.posReducer.combos,
  allCombos: state.combosReducer.combosList,
  selectedValuePOS: state.posReducer.selectedValuePOS
});

export default connect(mapStateToProps, {
  getCombos,
  submitPOSDiscountValuesCombos
})(CombosMappingContainer);
