import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  getListAppFeedLayout,
  setPageType,
  resetAppFeedLayoutReducer
} from '../appFeedLayoutActions';
import { getBusinessLocationsOfMerchant } from '../../BusinessLocationDetails/LocationListManager/actions';
import SelectDefault from './components/SelectDefault';
import BusinessLayoutAssigned from './components/BusinessLayoutAssigned';
import { LoadingBlueLarge } from '../../../components/Loading/Loading';
import ChevronLeftIcon from '../../../components/Icon/ChevronLeftIcon';
import accountReducer from '../../Account/accountReducer';

const Wrapper = styled.div`
  width: 800px;
  margin: 0 auto;
`;

const Title = styled.p`
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.04px;
  color: #191818;
  margin-top: 50px;
  margin-bottom: 24px;
`;

const LinkBack = styled(Link)`
  width: 176px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 4px 150px;
  grid-gap: 10px;
  align-items: center;
  margin-top: 20px;
`;

const LayoutAssignmentContainer = ({
  getListAppFeedLayout,
  getBusinessLocationsOfMerchant,
  appFeedLayoutPage,
  businessesList,
  setPageType,
  resetAppFeedLayoutReducer,
  appIdentifier
}) => {
  useEffect(() => {
    getListAppFeedLayout();
    // getBusinessLocationsOfMerchant();
    return () => {
      resetAppFeedLayoutReducer();
    };
  }, []);

  return (
    <div>
      <LinkBack
        to="/app-feed"
        onClick={() => setPageType()}
        style={{ color: 'inherit ' }}
      >
        <ChevronLeftIcon />
        To App Feed
      </LinkBack>

      <Wrapper>
        <Title>Layout Assignment</Title>
        {appFeedLayoutPage.listAppFeedLayoutIsRequesting ? (
          <LoadingBlueLarge />
        ) : (
          <div>
            <SelectDefault layoutPage={appFeedLayoutPage} />
            {appIdentifier === 'sodexo' ? (
              <BusinessLayoutAssigned
                layoutPage={appFeedLayoutPage}
                businessesList={businessesList}
              />
            ) : null}
          </div>
        )}
      </Wrapper>
    </div>
  );
};

const mapStateToProps = ({
  appFeedLayoutReducer,
  businessLocationsList,
  accountReducer
}) => {
  return {
    appFeedLayoutPage: appFeedLayoutReducer,
    businessesList: businessLocationsList,
    appIdentifier: accountReducer.appIdentifier
  };
};

export default connect(mapStateToProps, {
  getListAppFeedLayout,
  getBusinessLocationsOfMerchant,
  setPageType,
  resetAppFeedLayoutReducer
})(LayoutAssignmentContainer);
