import axios from 'axios';
import { createAlert } from '../Alert/actions';
import { NEW_USER_REGISTRATION_MESSAGE } from './newUserLoginTypes';
import { softSignoutUser } from '../Account/actions';

export function postNewUser(user, token, history) {
  const password = user.password;
  const headers = {
    'Content-Type': 'application/json'
  };

  return function (dispatch) {
    return axios
      .post(
        `${ROOT_URL}/registration`,
        {
          token: token,
          password: password
        },
        { headers: headers }
      )
      .then(response => {
        dispatch(softSignoutUser());
        dispatch({
          type: NEW_USER_REGISTRATION_MESSAGE
        });
      })
      .catch(err => {
        dispatch(
          createAlert({
            type: 'error',
            message: 'An error occurred trying to login.'
          })
        );
      });
  };
}

export function validateChangePasswordFields(values) {
  const errors = {};
  if (values.password !== values.passwordConfirm) {
    errors.password = 'Confirmation password does not match';
  } else if (values.password.length < 8) {
    errors.password = 'Password should contain 8 characters at least';
  } else if (!/[0-9*@!#%&()^~{}]+/.test(values.password)) {
    errors.password = 'Password should contain 1 digit or symbol at least';
  } else if (!/[A-Z]/.test(values.password)) {
    errors.password = 'Password should contain 1 upper case character';
  } else if (!/[a-z]/.test(values.password)) {
    errors.password = 'Password should contain 1 lower case character';
  }
  return errors;
}