import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import fetchOnParamChange from '../../HOCs/fetchOnParamChange';
import exportCSVWrapper from '../../HOCs/exportCSVWrapper';
import * as actions from './actions';
import OrderHistory from './components/OrderHistory';

export class OrderHistoryContainer extends Component {
  state = {
    advancedFilterIsOpen: false,
    amountOfFiltersApplied: 0,
    filters: {}
  };

  componentDidMount() {
    track.page('customer-service-order-history-list');
  }

  handleAdvancedFilterButtonClick = () => {
    this.setState({
      advancedFilterIsOpen: !this.state.advancedFilterIsOpen
    });
  };

  handleApplyFilters = filters => {
    this.setState({ amountOfFiltersApplied: filters.length });
    let newParams = {};

    filters.forEach(filter => {
      newParams = { ...newParams, [filter.type]: filter.value };
    });

    this.setState({ filters: newParams });

    this.props.updateRequestParams({
      page_number: initialRequestParams.page_number,
      page_size: initialRequestParams.page_size,
      ...newParams
    });
  };

  handleActionButtonClick = orderId => {
    this.props.history.push(`/order-history/${orderId}`);
  };

  handleExportClick = () => {
    let params = {
      page_number: this.props.orderHistory.meta.page_number,
      page_size: this.props.orderHistory.meta.page_size,
      ...this.state.filters
    };
    let paramsCSV = '';

    Object.entries(params).forEach(([key, value]) => {
      paramsCSV += `${key}=${value}_`;
    });

    paramsCSV = paramsCSV.slice(0, -1);

    this.props.exportCSV({
      requestPath: 'orders',
      fileName: `order-history-report__${paramsCSV}`,
      requestParams: params
    });
  };

  render() {
    const { advancedFilterIsOpen, amountOfFiltersApplied } = this.state;
    const {
      orders,
      ordersOfMerchantIsRequesting,
      meta
    } = this.props.orderHistory;

    return (
      <Fragment>
        <OrderHistory
          data-test="component-OrderHistory"
          advancedFilterIsOpen={advancedFilterIsOpen}
          handleAdvancedFilterButtonClick={this.handleAdvancedFilterButtonClick}
          handleApplyFilters={this.handleApplyFilters}
          amountOfFiltersApplied={amountOfFiltersApplied}
          orders={orders}
          ordersOfMerchantIsRequesting={ordersOfMerchantIsRequesting}
          response={{
            page_number: this.props.requestParams.page_number,
            page_size: this.props.requestParams.page_size,
            count: meta.count
          }}
          requestParams={this.props.requestParams}
          updateRequestParams={this.props.updateRequestParams}
          handleActionButtonClick={this.handleActionButtonClick}
          meta={meta}
          handleExportClick={this.handleExportClick}
          requestingCSV={this.props.requestingCSV}
        />
      </Fragment>
    );
  }
}

const initialRequestParams = {
  page_number: 1,
  page_size: 25
};

let OrderHistoryContainerExport = exportCSVWrapper(OrderHistoryContainer);

let OrderHistoryContainerPagination = fetchOnParamChange(
  OrderHistoryContainerExport,
  actions.getOrderHistoryOfMerchant,
  initialRequestParams
);

function mapStateToProps(state) {
  return {
    orderHistory: state.orderHistory
  };
}

export default connect(
  mapStateToProps,
  actions
)(OrderHistoryContainerPagination);
