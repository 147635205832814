import React from 'react';
import {connect} from 'react-redux';
import nav from '../Full/_nav';
import {Route} from 'react-router-dom';
import { LoadingBlueLarge } from '../../components/Loading/Loading';
import {authorizedPathsForTiers} from '../Full/utils/filterNav';
import {Redirect} from 'react-router-dom';
import {setPromoteTierUpgradeModalIsVisible} from '../Full/actions';
const getAuthorizationList = path => {
  let authorizationList = [];
  nav.items.forEach(mainItem => {
    if (mainItem.url === path) {
      authorizationList = mainItem.authorization;
    }
    if (mainItem.children) {
      mainItem.children.forEach(childItem => {
        if (childItem.url == path) {
          authorizationList = childItem.authorization;
        }
      });
    }
    if (mainItem.notOnSidebar) {
      mainItem.notOnSidebar.map(notOnSidebarItem => {
        if (notOnSidebarItem.url == path) {
          authorizationList = notOnSidebarItem.authorization;
        }
      });
    }
  });
  return authorizationList;
};

const RouteWrapper = (props) => {
  if(authorizedPathsForTiers.length > 0) {
    if(!authorizedPathsForTiers.includes(props.path)){
      props.setPromoteTierUpgradeModalIsVisible(true);
      return  <Redirect to="/users-dashboard" />;
    }
    else{
      return getAuthorizationList(props.path).includes(
        props.accountReducer.role
      ) ? (
        <Route {...props} component={props.component} />
      ) : (
        <div>
          <h1> You don't have permission to view page! </h1>
        </div>
      );
      }
  }  
  return(<LoadingBlueLarge/>)
};
const mapStateToProps = state => ({accountReducer: state.accountReducer});
export default connect(mapStateToProps, {setPromoteTierUpgradeModalIsVisible})(RouteWrapper);
