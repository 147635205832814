import { FieldArray, Formik } from 'formik'
import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Button } from '../../../../../../components/Button/Button'
import Modal from '../../../../../../components/Modal/Modal'
import RenderSortableComboItemsList from './RenderSortableComboItemsList'
import { LoadingBlueLarge } from '../../../../../../components/Loading/Loading';

const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: auto 20px;
  padding: 24px 24px 10px;
  font-size: 20px;
`

const ModelContentWrapper = styled.div`
  width: 800px;
`

const ButtonsWrapper = styled.div`
  text-align: right;
  padding: 20px;
  box-shadow: 0 -2px 9px 0 rgba(0, 0, 0, 0.12);
`

const ModalBody = styled.div`
  height: 60vh;
`

const LineDivider = styled.hr`
  margin-top: 11px;
  margin-bottom: 11px;
  border: solid 1px #ebebeb;
`

const ComboItemsSortModal = ({
  initValues,
  comboItems,
  setIsSortComboItemsModal,
  isSavingComboItemsPositions,
  handleSortingComboItemsSubmit
}) => {

  return (
    <Modal borderRadius>
      <ModelContentWrapper>
      <Formik initialValues={{comboItems: comboItems}} enableReinitialize={true}>
        {({ values, handleReset, dirty, errors, setFieldValue }) => (
          <Fragment>
            <ModalTitle>
              <div>Sort Combo Groups</div>
              <div
                onClick={() =>  setIsSortComboItemsModal(false)}
                style={{ cursor: 'pointer', justifySelf: 'center' }}
              >
                <i className="fa fa-times" />
              </div>
            </ModalTitle>
            <LineDivider />
            <ModalBody>
              { isSavingComboItemsPositions && <LoadingBlueLarge />}
              { !isSavingComboItemsPositions &&
                <FieldArray
                  name="comboItems"
                  render={arrayHelpers => 
                    <RenderSortableComboItemsList
                      arrayHelpers={arrayHelpers}
                    />
                  }
                />
               }
            </ModalBody>
            <ButtonsWrapper>
              <Button
                disabled={!dirty || isSavingComboItemsPositions}
                onClick={() => {
                  handleSortingComboItemsSubmit(initValues, values['comboItems']);
                  setIsSortComboItemsModal(false);
                }}
              >
                Save
              </Button>
            </ButtonsWrapper>
          </Fragment>
        )}
      </Formik>
      </ModelContentWrapper>
    </Modal>
  )
}

export default ComboItemsSortModal;
