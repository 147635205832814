import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import fetchOnParamChange from '../../../HOCs/fetchOnParamChange';
import * as actions from '../offerActions';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import OffersList from './OffersList';
import { Button } from '../../../../components/Button/Button';
import {sort} from '../../../../utils/sort/sort';

let OffersListContainer = ({
  offersReducer,
  toggleOfferAvailability,
  initiateCreateOffer,
  initiateEditOffer,
  meta,
  updateRequestParams,
  updateOffersRequestParams,
  getOffers,
  updateSortedOffersList
}) => {
  const { allOffers, requestingGetOffers, requestParams } = offersReducer;

  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    getOffers();
    track.page('engagement-offers-list');
  }, []);

   const handleSort = (sortArgs) => {
     let sortOrder = '';
     let order = '';
     const currentOptionAsArray = requestParams.sorting_option.split('-');
     if (currentOptionAsArray[0] === sortArgs.value) {
      order = currentOptionAsArray[1] === 'asc' ? 'desc' : 'asc';
      sortOrder = `${sortArgs.value}-${order}`
      
     }else{
       order = 'asc'
       sortOrder = `${sortArgs.value}-${order}`;
     }
      if(allOffers){
        const result = sort(allOffers, {...sortArgs, sortOrder : `${order}`});
        updateOffersRequestParams({
          page_number: 1,
          page_size: requestParams.page_size
        });
        updateSortedOffersList(result, sortOrder);
      }
    };

  const handleOffersSearch = searchString => {
    updateOffersRequestParams({
      page_number: 1,
      page_size: requestParams.page_size
    });
    setSearchString(searchString);
  };

  const sliceOffers = offers => {
    const indexOfFirst =
      (requestParams.page_number - 1) * requestParams.page_size;
    const indexOfLast = requestParams.page_number * requestParams.page_size;

    return offers
      .filter(
        offer =>
          offer.offer_details.title
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) > -1
      )
      .slice(indexOfFirst, indexOfLast);
  };

  const [offersActiveList, setOffersActiveList] = useState(
    sliceOffers(allOffers)
  );

  useEffect(() => {
    setOffersActiveList(sliceOffers(allOffers));
  }, [requestParams, allOffers]);

  const allOffersLength = allOffers.filter(
    allOffers =>
      allOffers.offer_details.title
        .toLowerCase()
        .indexOf(searchString.toLowerCase()) > -1
  ).length;

  return (
    <Fragment>
      <BreadCrumb />
      <OffersList
        initiateEditOffer={initiateEditOffer}
        initiateCreateOffer={initiateCreateOffer}
        meta={meta}
        handleOffersSearch={handleOffersSearch}
        requestParams={requestParams}
        updateRequestParams={updateRequestParams}
        offersActiveList={offersActiveList}
        requestingGetOffers={requestingGetOffers}
        toggleOfferAvailability={toggleOfferAvailability}
        allOffersLength={allOffersLength}
        handleSort={handleSort}
      />
    </Fragment>
  );
};

// const initialRequestParams = {
//   page_number: 1,
//   page_size: '1000'
// };

// const initialRequestParams = {
//   page_number: 1,
//   page_size: 25,
//   searchString: ''
// };

// OffersListContainer = fetchOnParamChange(
//   OffersListContainer,
//   actions.getOffers,
//   initialRequestParams
// );

const mapStateToProps = state => ({
  offersReducer: state.offersReducer,
  meta: state.offersReducer.meta
});

export default connect(mapStateToProps, actions)(OffersListContainer);
