import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../productsActions';
import ProductOverridesModal from '../components/ProductOverridesModal';

const ProductOverridesModalContainer = ({
  isOverrideModal,
  setIsOverrideModal,
  formattedBusinesses,
  productId,
  summaryData,
  handleSubmitProductOverrides,
  productVariantsModified,
  productVariantsInitialValues,
  isCreate,
  disabled
}) => {
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isConfirmOverridesModal, setIsConfirmOverridesModal] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);

  return (
    <ProductOverridesModal
      isConfirmModal={isConfirmModal}
      setIsConfirmModal={setIsConfirmModal}
      isConfirmOverridesModal={isConfirmOverridesModal}
      setIsConfirmOverridesModal={setIsConfirmOverridesModal}
      isOverrideModal={isOverrideModal}
      setIsOverrideModal={setIsOverrideModal}
      formattedBusinesses={formattedBusinesses}
      productId={productId}
      summaryData={summaryData}
      isCreate={isCreate}
      disabled={disabled}
      productVariantsModified={productVariantsModified}
      productVariantsInitialValues={productVariantsInitialValues}
      handleSubmitProductOverrides={handleSubmitProductOverrides}
      selectedLocations={selectedLocations}
      setSelectedLocations={setSelectedLocations}
    />
  );
};

export default connect(null, actions)(ProductOverridesModalContainer);
