import React, {Component} from 'react';
import {connect} from 'react-redux';
import ProductsListContainer from './ProductList/ProductsListContainer';
import ProductWizardContainer from './ProductWizard/ProductWizardContainer';

class ProductsContainer extends Component {
  render() {
    const {productWizardIsVisible} = this.props;
    return !productWizardIsVisible ? (
      <ProductsListContainer />
    ) : (
      <ProductWizardContainer />
    );
  }
}

const mapStateToProps = state => ({
  productWizardIsVisible: state.productsReducer.productWizardIsVisible
});

export default connect(mapStateToProps)(ProductsContainer);
