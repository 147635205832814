import React, { useState } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import moment from 'moment';
import {
  RenderTextField,
  RenderTimePicker,
  required,
  validateTwoChar,
  bulkValidator,
  maxLengthValidationForNameField
} from '../../../../../components/FormElements/FormikElements';
import {
  MainDiv,
  TimeSelectionGrid,
  HoursField,
  ImageWrapper,
  ChooseImage,
  Image
} from './createEditFormStyles';
import MediaModal from '../../../components/MediaModal';
import { SmallToggleSwitch } from '../../../../../components/ToggleSwitch/ToggleSwitch';

const AddSubCategoryButton = styled.button`
  background: none;
  border: none;
  color: #191818;
  margin-top: 30px;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
`;

const CategoryForm = ({
  editingCategory,
  arrayHelpers,
  setFieldValue,
  scrollToBottom,
  touched
}) => {
  const [
    mediaSelectionModalIsVisible,
    setMediaSelectionModalIsVisible
  ] = useState(false);

  const handleIsCategoryAvailableAllDay = () =>
    editingCategory.categoryStartTime.format('HH:mm:ss') === '00:00:00' &&
    editingCategory.categoryEndTime.format('HH:mm:ss') === '23:59:59';

  const toggleIsCategoryAvailableAllTime = checked => {
    if (checked) {
      setFieldValue('categoryStartTime', moment('00:00:00', 'HH:mm:ss'));
      setFieldValue('categoryEndTime', moment('23:59:59', 'HH:mm:ss'));
      setIsCategoryAvailableAllDay(true);
    } else {
      setFieldValue('categoryStartTime', editingCategory.categoryStartTime);
      setFieldValue('categoryEndTime', editingCategory.categoryEndTime);
      setIsCategoryAvailableAllDay(false);
    }
  };

  const handleAddNewSubCategory = () => {
    const newSubCat = {
      id: null,
      parent_id: editingCategory.id,
      image_url: null,
      is_active: false,
      start_time: moment('00:00:00', 'HH:mm:ss'),
      end_time: moment('23:59:59', 'HH:mm:ss'),
      position: null,
      name: null
    };
    arrayHelpers.push(newSubCat);
    scrollToBottom('smooth');
  };

  const [isCategoryAvailableAllDay, setIsCategoryAvailableAllDay] = useState(
    handleIsCategoryAvailableAllDay()
  );

  return (
    <MainDiv>
      <div style={{ display: 'grid', gridRowGap: '20px' }}>
        <Field
          name="categoryName"
          component={RenderTextField}
          label="Category Name"
          validate={bulkValidator(
            validateTwoChar,
            maxLengthValidationForNameField
          )}
        />
        <SmallToggleSwitch
          checked={editingCategory.isCategoryActive}
          onChange={() =>
            setFieldValue('isCategoryActive', !editingCategory.isCategoryActive)
          }
          value="checkedB"
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          label="Activate"
        />
        <SmallToggleSwitch
          checked={isCategoryAvailableAllDay}
          onChange={({ target: { checked } }) =>
            toggleIsCategoryAvailableAllTime(checked)
          }
          value="checkedB"
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          label="Available All Day"
        />
        {!isCategoryAvailableAllDay && (
          <TimeSelectionGrid>
            <HoursField>
              Start
              <Field
                name="categoryStartTime"
                component={RenderTimePicker}
                onChange={value => {
                  setFieldValue('categoryStartTime', moment(value, 'HH:mm:ss'));
                }}
                hideDate
                max={new Date(moment('January 1, 3999 23:30:00'))}
              />
            </HoursField>

            <HoursField>
              End
              <Field
                name="categoryEndTime"
                component={RenderTimePicker}
                onChange={value => {
                  setFieldValue('categoryEndTime', moment(value, 'HH:mm:ss'));
                }}
                hideDate
              />
            </HoursField>
          </TimeSelectionGrid>
        )}
        <div>
          <AddSubCategoryButton onClick={handleAddNewSubCategory}>
            + Add a subcategory
          </AddSubCategoryButton>
        </div>
      </div>
      {/* <div>
        <ImageWrapper>
          <div
            onClick={() => setMediaSelectionModalIsVisible(true)}
            style={{ alignSelf: 'stretch' }}
            style={{ cursor: 'pointer', height: '100%' }}
          >
            {!editingCategory.categoryImageUrl ? (
              <ChooseImage>+ Choose Image</ChooseImage>
            ) : (
              <Image src={editingCategory.categoryImageUrl} />
            )}
          </div>
          <div style={{ textAlign: 'center' }}>or</div>
          <div>
            <Field
              name="categoryImageUrl"
              component={RenderTextField}
              label="Image URL"
              // error={
              //   !touched.image_url ||
              //   (touched.image_url &&
              //     !touched.image_url) ||
              //   values.productInformation.image_url
              //     ? null
              //     : 'Required'
              // }
              //validate={required}
              // value={editingCategory.categoryImageUrl}
            />
          </div>
        </ImageWrapper>
        {mediaSelectionModalIsVisible && (
          <MediaModal
            closeModal={() => setMediaSelectionModalIsVisible(false)}
            selectProduct={(id, url) => {
              setFieldValue('categoryImageUrl', url);
            }}
          />
        )}
      </div> */}
    </MainDiv>
  );
};

export default CategoryForm;
