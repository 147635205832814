import {
  ROLE_SUPER_ADMIN,
  ROLE_ADMIN,
  ROLE_BUSINESS_ADMIN,
  ROLE_MARKETING_ADMIN,
  ROLE_FINANCE_ADMIN,
  ROLE_BUSINESS_SMOOTHPAY,
  ROLE_OPERATOR
} from './rolesRepository';

export default {
  toppers: [
    ROLE_ADMIN,
    ROLE_BUSINESS_ADMIN,
    ROLE_MARKETING_ADMIN,
    ROLE_FINANCE_ADMIN
    // ROLE_BUSINESS_SMOOTHPAY
  ],
  sodexo: [
    ROLE_ADMIN,
    ROLE_BUSINESS_ADMIN,
    ROLE_MARKETING_ADMIN,
    ROLE_FINANCE_ADMIN,
    // ROLE_BUSINESS_SMOOTHPAY,
    ROLE_OPERATOR
  ],
  default: [
    ROLE_ADMIN,
    ROLE_BUSINESS_ADMIN,
    ROLE_MARKETING_ADMIN,
    ROLE_FINANCE_ADMIN
    // ROLE_BUSINESS_SMOOTHPAY
  ]
};
