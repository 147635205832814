import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import styled from 'styled-components';
import Moment from 'moment';
import {
  RenderTextField,
  RenderToggleSwitch,
  required,
  isPriceValidation,
} from '../../../../../../components/FormElements/FormikElements';
import { Button, ButtonRow } from '../../../../../../components/Button/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '../../../../../../components/Icon/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import ConfirmCancelModal from '../../../../../MenuManagement/Products/ProductWizard/components/ConfirmCancelModal';

const Card = styled.div`
  background: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-bottom: 20px;
`;

const FormWrapper = styled.div`
  padding: 20px 48px 20px 48px;
  display: grid;
  grid-gap: 20px;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
  align-items: center;
`;

const Headline = styled.p`
  font-size: 17px;
  font-weight: 600;
`;

const OrderSettingsWrapper = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr 100px;
  grid-gap: 20px;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
  margin: 40px 0 0 48px;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: center;
`;

const BusinessInformationForm = ({
  initialValues,
  currencyList,
  handleSubmit,
  externalIdIsVisible,
  is_delivery_enabled,
}) => {
  const [
    confirmCancelModalIsVisible,
    setConfirmCancelModalIsVisible,
  ] = useState(false);
  return (
    <Card>
      <Title>Business Information</Title>
      <Formik initialValues={initialValues} enableReinitialize={true}>
        {({ values, isValid, touched, setFieldValue, dirty, handleReset }) => (
          <FormWrapper>
            <FieldWrapper>
              <Field
                name="businessInformation.name"
                component={RenderTextField}
                label="Location Name *"
                validate={required}
              />
            </FieldWrapper>
            {externalIdIsVisible && (
              <GridWrapper>
                <Field
                  name="businessInformation.external_id"
                  component={RenderTextField}
                  label="External ID *"
                  validate={required}
                />
              </GridWrapper>
            )}
            <Headline>Order Settings</Headline>
            <OrderSettingsWrapper>
              <Field
                component={RenderTextField}
                style={{ position: 'relative', top: '-4px' }}
                type="text"
                name="businessInformation.currency"
                label="Currency *"
                select
                variant="outlined"
                margin="normal"
                validate={required}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {currencyList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
              <Field
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon className="fal fa-dollar-sign" />
                    </InputAdornment>
                  ),
                }}
                name="businessInformation.transaction_amount_limit"
                component={RenderTextField}
                label="Maximum Order Amount *"
                validate={isPriceValidation}
              />
            </OrderSettingsWrapper>
            <Field
              name="businessInformation.is_order_ahead_enabled"
              component={RenderToggleSwitch}
              label={
                values.businessInformation.is_order_ahead_enabled
                  ? 'Order Ahead Enabled'
                  : 'Order Ahead Disabled'
              }
              onChange={(e) =>
                setFieldValue(
                  'businessInformation.is_order_ahead_enabled',
                  e.target.checked
                )
              }
            />
            {is_delivery_enabled ? (
              <GridWrapper>
                <Field
                  name="businessInformation.is_delivery_enabled"
                  component={RenderToggleSwitch}
                  label={
                    values.businessInformation.is_delivery_enabled
                      ? 'Delivery Enabled'
                      : 'Delivery Disabled'
                  }
                  onChange={(e) =>
                    setFieldValue(
                      'businessInformation.is_delivery_enabled',
                      e.target.checked
                    )
                  }
                />
                <div>
                  {values.businessInformation.is_delivery_enabled
                    ? null
                    : 'Only Pick-up will be available for this Location'}
                </div>
              </GridWrapper>
            ) : (
              <div />
            )}
            <Field
              name="businessInformation.is_active"
              component={RenderToggleSwitch}
              label={
                values.businessInformation.is_active
                  ? 'Business Location Active'
                  : 'Business Location Inactive'
              }
              onChange={(e) =>
                setFieldValue('businessInformation.is_active', e.target.checked)
              }
            />
            {/* <Field
                    name="businessInformation.is_visible"
                    component={RenderToggleSwitch}
                    label= {values.businessInformation.is_visible ? 
                                  "Business Location Visible" : "Business Location Invisible"}
                    onChange={e => setFieldValue('businessInformation.is_visible', e.target.checked)}
                /> */}
            <ButtonRow
              justifyContent="space-between"
              style={{
                margin: '24px 40px 24px 40px',
                width: 'inherit',
              }}
            >
              <Button
                secondary
                onClick={() => {
                  dirty ? setConfirmCancelModalIsVisible(true) : '';
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!dirty || !isValid}
                onClick={() => {
                  if (!externalIdIsVisible) {
                    delete values.businessInformation.external_id;
                  }
                  handleSubmit(values);
                }}
              >
                Save
              </Button>
            </ButtonRow>
            {confirmCancelModalIsVisible && (
              <ConfirmCancelModal
                onCancel={() => setConfirmCancelModalIsVisible(false)}
                onConfirm={() => {
                  handleReset();
                  setConfirmCancelModalIsVisible(false);
                }}
              />
            )}
          </FormWrapper>
        )}
      </Formik>
    </Card>
  );
};

export default BusinessInformationForm;
