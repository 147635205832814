import styled from 'styled-components';

export const StyledP = styled.p`
  font-weight: ${props => (props.semiBold ? '600' : 'inherit')};
  font-size: ${props => props.size || 'inherit'};
  color: ${props => props.color || props.theme.darkBlue};
  opacity: ${props => props.opacity || '1'};
  align-self: ${props => (props.alignCenter ? 'center' : 'inherit')};
  cursor: ${props => (props.pointer ? 'pointer' : 'inherit')};
`;

export const ContainerDiv = styled.div`
  padding: ${props => props.padding || '0 0 0 0'};
  margin: ${props => props.margin || '0 0 0 0'};
  width: ${props => props.width || '100%'};
  text-align: ${props => props.textAlign || 'inherit'};
`;

export const StoreHoursAndOrderAheadHoursGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-right: 50px;
  padding: 50px;
  @media screen and (max-width: 1338px) {
    display: block;
  }
`;

export const SubTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  text-align: ${props => props.textAlign || 'inherit'};
`;

export const HoursSelectionContainer = styled.div`
  padding: 0 50px 50px 50px;
  border-right: ${props => (props.borderRight ? 'solid 2px #d8d8d8' : 'none')};
  @media screen and (max-width: 1338px) {
    border: none;
    padding: 0;
  }
`;
