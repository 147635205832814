import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import {
  NoData,
  ChartCard,
  TitleWrapper,
  Title,
  TotalWrapper,
  TitleSpan
} from '../../components/DashboardElements';
import { findMaxValue, findStepSize } from '../../utils/chart';
import Moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { TooltipIcon } from '../../../../components/Icon';

class Sales extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { dates, period_now, period_prev, total } = this.props.data;
    const { interval_value } = this.props.stateInterval;
    const userDatesData = dates;

    var userDates = userDatesData.slice(interval_value); //.reverse();
    if (interval_value == 90) {
      userDates = userDatesData.slice(90);
    } else if (interval_value == 1) {
      userDates = userDatesData.slice(12);
    }

    const currentSales = period_now || [];
    const previousSales = period_prev || [];

    var suggestedMaxCurrent = findMaxValue(currentSales);
    var stepSize = findStepSize(currentSales);

    var suggestedMaxPrevious = findMaxValue(previousSales);
    var stepSizePrevious = findStepSize(previousSales);

    if (stepSize < 1 && stepSizePrevious < 1) {
      stepSize = 1;
      suggestedMaxCurrent = 7;
    } else {
      stepSize = '';
    }

    const userData = {
      labels: userDates,
      datasets: [
        {
          label: 'current period',
          fill: false,
          lineTension: 0,
          backgroundColor: 'rgba(75,192,192,0.4)',
          //borderColor: 'rgba(75,192,192,1)',
          borderColor: '#296bde',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          //pointBorderColor: 'rgba(75,192,192,1)',
          pointBorderColor: '#296bde',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          //pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBackgroundColor: '#296BDE',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1.5,
          pointHitRadius: 10,
          fontWeight: 'bold',
          borderWidth: 1,
          data: currentSales
        },
        {
          label: 'previous period',
          fill: false,
          lineTension: 0,
          backgroundColor: 'rgba(75,192,192,0.4)',
          //borderColor: 'rgba(75,192,192,1)',
          borderColor: '#979eaa',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          //pointBorderColor: 'rgba(75,192,192,1)',
          pointBorderColor: '#979eaa',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          //pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBackgroundColor: '#979eaa',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1.5,
          pointHitRadius: 10,
          fontWeight: 'bold',
          borderWidth: 1,
          data: previousSales
        }
      ]
    };

    return (
      
      <ChartCard>
        <TitleWrapper>
          <Title>
            <TitleSpan>Sales {' '}
            <Tooltip title="The total revenue generated" placement="top" arrow>
              <span> <TooltipIcon/> </span>
            </Tooltip>
            </TitleSpan> <br />
            <svg height="10" width="17.7" style={{ paddingTop: '6px' }}>
              <line
                x1="0"
                y1="0"
                x2="17.7"
                y2="0"
                style={{ stroke: '#296bde', strokeWidth: '2' }}
              />
            </svg>{' '}
            <span style={{ color: '#296bde' }}> current period </span>
            &nbsp;
            <svg height="10" width="17.7" style={{ paddingTop: '6px' }}>
              <line
                x1="0"
                y1="0"
                x2="17.7"
                y2="0"
                style={{ stroke: '#979eaa', strokeWidth: '2' }}
              />
            </svg>{' '}
            <span style={{ color: '#979eaa' }}> previous period </span>
          </Title>
          <TotalWrapper>
            <span> Total </span>
            <TitleSpan>{(total || 0).toLocaleString()}</TitleSpan>
          </TotalWrapper>
        </TitleWrapper>
        {(!currentSales || currentSales.length == 0) && (
          <NoData>No Data available</NoData>
        )}
        <Line
          data={userData}
          options={{
            legend: {
              display: false,
              // position: 'top',
              // title: {
              //   position: 'top',
              //   align: 'start',
              //   textAlign: 'left',
              // },
              labels: {
                fontColor: '#296bde',
                useLineStyle: true
              }
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  gridLines: {
                    //zeroLineWidth: 2,
                    color: '#f2f2f2'
                    //zeroLineColor:"rgba(255,255,255,10)",
                  },
                  scaleLabel: {
                    display: false
                    //labelString: 'Date',
                  },
                  ticks: {
                    callback: function(value) {
                      if (interval_value == 1)
                        var day = Moment(value).format('MMM');
                      //new Date(value).toLocaleDateString('en-EN', { day:'numeric'});
                      else var day = Moment(value).format('DD MMM');
                      //const month = new Date(value).toLocaleDateString('en-EN', { month:'short'});
                      //const str= `${day}`; //`\n${month}`;
                      return day;
                    }
                  }
                }
              ],
              yAxes: [
                {
                  display: true,
                  gridLines: {
                    //zeroLineColor: '#979797',
                    //zeroLineWidth: 1,
                    //color: ["#f2f2f2"],
                  },
                  ticks: {
                    suggestedMin: 0,
                    beginAtZero: true,
                    stepSize: stepSize,
                    suggestedMax: suggestedMaxCurrent,
                    callback: function(value, index, values) {
                      return `$${value.toLocaleString()}`;
                    }
                  },
                  scaleLabel: {
                    display: false
                    //labelString: '# Of Sign Ups',
                  }
                }
              ]
            }
          }}
        />
      </ChartCard>
    );
  }
}

export default Sales;
