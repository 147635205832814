import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../optionActions';
import OptionsList from './components/OptionsList';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import { sort } from '../../../../utils/sort/sort';

let OptionsListContainer = ({
  options,
  toggleOptionIsActive,
  handleCreateButtonClick,
  requestingGetOptions,
  requestParams,
  updateOptionRequestParams,
  updateSortedOptionsList,
  editOption
}) => {
  useEffect(() => {
    track.page('menu-management-option-list');
  }, []);
  const [searchString, setSearchString] = useState('');

  const handleSort = sortArgs => {
    let sortOrder = '';
    let order = '';
    const currentOptionAsArray = requestParams.sorting_option.split('-');
    if (currentOptionAsArray[0] === sortArgs.value) {
      order = currentOptionAsArray[1] === 'asc' ? 'desc' : 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    } else {
      order = 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    }
    if (options) {
      const result = sort(options, { ...sortArgs, sortOrder: `${order}` });
      updateOptionRequestParams({
        page_number: 1,
        page_size: requestParams.page_size
      });
      updateSortedOptionsList(result, sortOrder);
    }
  };

  const handleOptionSearch = searchString => {
    updateOptionRequestParams({
      page_number: 1,
      page_size: requestParams.page_size
    });
    setSearchString(searchString);
  };

  const sliceOptions = options => {
    const indexOfFirst =
      (requestParams.page_number - 1) * requestParams.page_size;
    const indexOfLast = requestParams.page_number * requestParams.page_size;
    return options
      .filter(
        option =>
          option.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1
      )
      .slice(indexOfFirst, indexOfLast);
  };

  const [optionsActiveList, setOptionsActiveList] = useState(
    sliceOptions(options)
  );

  useEffect(() => {
    setOptionsActiveList(sliceOptions(options));
  }, [requestParams, options]);

  return (
    <Fragment>
      <BreadCrumb />
      <OptionsList
        handleCreateButtonClick={handleCreateButtonClick}
        handleOptionSearch={handleOptionSearch}
        optionsActiveList={optionsActiveList}
        optionsLength={
          options.filter(
            option =>
              option.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1
          ).length
        }
        toggleOptionIsActive={toggleOptionIsActive}
        requestingGetOptions={requestingGetOptions}
        requestParams={requestParams}
        editOption={editOption}
        handleSort={handleSort}
      />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  options: state.optionReducer.options,
  requestingGetOptions: state.optionReducer.requestingGetOptions,
  requestParams: state.optionReducer.requestParams
});

export default connect(mapStateToProps, actions)(OptionsListContainer);
