import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './actions';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import styled from 'styled-components';
import { Charts } from '../components/DashboardElements';
import { LoadingBlueLarge } from '../../../components/Loading/Loading';
import SummaryBar from './components/Summary';
import Transactions from './components/Transactions';
import Sales from './components/Sales';
import TransactionsPerHour from './components/TransactionsPerHour';
import OrderTypes from './components/OrderTypes';
import intervalOptions from '../utils/intervalOptions';
import Select from '../../../components/Select/Select';
import { TitleArea } from '../../../components/Table/TableElements';
import { formatLocations } from '../../../utils/childLocations/formatLocations';

class TransactionsDashboard extends Component {
  state = {
    intervalTypeAndValue: intervalOptions[1],
    business_ids: [],
    business_names: [],
    type: 'all'
  };

  componentDidMount() {
    this.getAllTransactionDashboardData();
    track.page('dashboard-transactions');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.getAllTransactionDashboardData();
    }
    if (!prevProps.token && this.props.token) {
      this.getAllTransactionDashboardData();
    }
  }

  getAllTransactionDashboardData = () => {
    const {
      intervalTypeAndValue,
      business_ids,

      type
    } = this.state;
    const params = {
      interval_type: intervalTypeAndValue.value.interval_type,
      interval_value: intervalTypeAndValue.value.interval_value,
      business_ids,
      type
    };
    this.props.getSummary(params);
    this.props.getTransactions(params);
    this.props.getSales(params);
    this.props.getTransactionsPerHour(params);
    this.props.getOrderTypes(params);
  };

  renderTitle = () => {
    const {
      intervalTypeAndValue,
      business_ids,
      business_names,
      type
    } = this.state;
    const { allBusinesses } = this.props;

    const paymentTypeOptions = [
      { value: 'all', label: 'All' },
      { value: 'mobile', label: 'Order Ahead' },
      { value: 'scan', label: 'Scan to Pay' }
    ];

    return (
      <TitleArea
        pageName="Transactions"
        selectName="location"
        selectLabel="Location"
        selectValue={business_names}
        onChange={data =>
          this.setState({
            business_ids: data.map(business => business.id),
            business_names: data
          })
        }
        options={formatLocations(allBusinesses)}
        getOptionLabel={business => business.display_name}
        getOptionValue={business => business.id}
        multiEnabled={true}
        secondSelectName="payment_type"
        secondSelectLabel="Order Type"
        secondSelectValue={paymentTypeOptions.filter(
          ({ value }) => value === type
        )}
        secondOnChange={({ value }) => this.setState({ type: value })}
        secondOptions={paymentTypeOptions}
        thirdSelectName="time_period"
        thirdSelectLabel="Time Period"
        thirdSelectValue={intervalTypeAndValue}
        thirdOnChange={intervalTypeAndValue =>
          this.setState({ intervalTypeAndValue })
        }
        thirdOptions={intervalOptions}
        gridTemplate={'1fr / 2fr 1fr 1fr'}
      />
    );
  };

  render() {
    const { allBusinesses, transactionsDashboard } = this.props;
    const {
      summaryData,
      transactionsData,
      salesData,
      transactionsPerHourData,
      orderTypesData,
      loading
    } = transactionsDashboard;
    let businessOptions = [{ id: 'all', name: 'All Locations' }];
    if (allBusinesses) {
      businessOptions = [...businessOptions, ...allBusinesses];
    }

    return (
      <div>
        {this.renderTitle()}

        {summaryData && loading ? (
          <div>
            {summaryData ? (
              <SummaryBar data={summaryData} />
            ) : (
              <LoadingBlueLarge />
            )}

            <Charts>
              {transactionsData ? (
                <Transactions
                  data={transactionsData}
                  stateInterval={this.state.intervalTypeAndValue.value}
                />
              ) : (
                <LoadingBlueLarge />
              )}

              {salesData ? (
                <Sales
                  data={salesData}
                  stateInterval={this.state.intervalTypeAndValue.value}
                />
              ) : (
                <LoadingBlueLarge />
              )}
            </Charts>

            <Charts>
              {transactionsPerHourData ? (
                <TransactionsPerHour data={transactionsPerHourData} />
              ) : (
                <LoadingBlueLarge />
              )}

              {orderTypesData ? (
                <OrderTypes data={orderTypesData} />
              ) : (
                <LoadingBlueLarge />
              )}
            </Charts>
          </div>
        ) : (
          <LoadingBlueLarge />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    transactionsDashboard: state.transactionsDashboard,
    transactionsForm: state.form.TransactionsFilterSelection,
    token: state.accountReducer.token,
    allBusinesses: state.businessLocationsList.businessLocationsOfMerchant
  };
}

export default connect(mapStateToProps, actions)(TransactionsDashboard);
