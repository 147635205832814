import React from "react";
import Modal from "../../../../../components/Modal/Modal";
import { Button, ButtonRow } from "../../../../../components/Button/Button";
import { FormGrid } from "../../../../../components/FormElements/FormElements";
import styled from "styled-components";
import {
  ModalContainerDiv,
  ModalStyledP,
  ModalGridWrapper
} from "../../utils/commonStyledComponents";

const StyledSpan = styled.span`
  font-weight: ${props => (props.semiBold ? "600" : "initial")};
`;

const CancelTransactionModal = props => {
  const {
    customerName,
    isOrderCancelBoxChecked,
    requestingCancelOrderDetails,
    handleVisibilityCancelOrderModal,
    handleIsOrderCancelBoxChecked,
    handleOrderCancel
  } = props;
  return (
    <Modal fullWidth maxWidth='sm'>
      <div className="modal">
        <FormGrid style={{ background: "white", padding: "20px" }}>
          <ModalStyledP style={{ textAlign: "center" }}>
            You are about to{" "}
            {<StyledSpan semiBold>Refund this Order</StyledSpan>} for{" "}
            <StyledSpan semiBold>{customerName}</StyledSpan>
          </ModalStyledP>
          <ModalContainerDiv center fontSize="18px">
            <ModalStyledP lightBlue margin="auto">
              This action cannot be undone
            </ModalStyledP>
            <ModalStyledP>
              Are you sure you want to Refund this Order?
            </ModalStyledP>
          </ModalContainerDiv>

          <ModalContainerDiv margin="12px auto" width="50%" fontSize="14px">
            <ModalContainerDiv margin="20px 0 5px 0px">
              <form style={{ display: "flex" }}>
                <label>
                  <input
                    type="checkbox"
                    defaultChecked={isOrderCancelBoxChecked}
                    onChange={() => handleIsOrderCancelBoxChecked()}
                  />
                </label>
                <ModalStyledP bold margin="0 10px" fontSize="16px">
                  Yes, I understand.
                </ModalStyledP>
              </form>
            </ModalContainerDiv>
          </ModalContainerDiv>

          <ButtonRow justifyContent="space-between">
            <Button
              secondary
              onClick={() => handleVisibilityCancelOrderModal()}
            >
              CANCEL
            </Button>
            <Button
              disabled={
                !isOrderCancelBoxChecked || requestingCancelOrderDetails
              }
              onClick={() => handleOrderCancel()}
            >
              {requestingCancelOrderDetails ? "Loading..." : "YES, REFUND"}
            </Button>
          </ButtonRow>
        </FormGrid>
      </div>
    </Modal>
  );
};

export default CancelTransactionModal;
