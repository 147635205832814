import React from 'react';
import styled from 'styled-components';
import { ChevronLeftIcon } from '../Icon';

const Button = styled.div`
  min-width: 300px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 4px auto;
  grid-gap: 10px;
  align-items: center;

  &: hover {
    color: #3051d4;
  }
`;

const BackButton = ({ pageName, onClick }) => {
  return (
    <Button onClick={onClick}>
      <ChevronLeftIcon />
      <div>{`Back to ${pageName}`}</div>
    </Button>
  );
};

export default BackButton;
