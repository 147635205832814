import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td
} from '../../../components/Table/TableElements';
import Icon from '../../../components/Icon/Icon';
import CreateEditUserModalContainer from './CreateEditUser/CreateEditUserModalContainer';
import getRoles from '../utils/utils.js';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const TdBold = styled(Td)`
  font-weight: 600;
`;

const RoleName = styled.span`
  font-weight: 600;
  text-transform: capitalize;
`;

export const MoreEllipsisIcon = styled(Icon)`
  font-size: 21px;
  color: ${props => (props.disabled ? '#cdcdcd' : 'inherit')};

  &:hover {
    opacity: 0.7;
  }
`;

const TextSearch = styled.div`
  padding: 0 37px 37px 37px;
`;

const OnHoverFeatures = styled.table`
  z-index: 9999;
  position: absolute;
  background-color: white;
  display: block;
  right: 7%;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;

  & td {
    padding-right: 10px;
  }
`;

const RolesTable = props => {
  const roles = props.roles.headers;
  const {
    accounts,
    updateUser,
    postResendInvitation,
    authUser,
    businesses,
    isModal,
    toggleCreateUserModal
  } = props;

  const [filterStates, setFilterStates] = useState({
    search: '',
    accounts: []
  });
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const beautifyAccounts = accounts => {
    if (accounts.length) {
      return accounts.map(account => ({
        ...account,
        modifiedRole: account.role
          .replace('ROLE', '')
          .replace(/_/g, ' ')
          .toLowerCase()
      }));
    } else {
      return accounts.role
        .replace('ROLE', '')
        .replace(/_/g, ' ')
        .toLowerCase();
    }
  };

  useEffect(() => {
    setFilterStates({
      search: filterStates.search,
      accounts: beautifyAccounts(props.accounts)
    });
  }, [props]);

  const handleSearch = e => {
    const sanitizedSearchString = e.target.value.toLowerCase();
    setFilterStates({
      search: e.target.value,
      accounts: beautifyAccounts(props.accounts).filter(
        account =>
          account.username.includes(sanitizedSearchString) ||
          beautifyAccounts(account).includes(sanitizedSearchString)
      )
    });
  };

  const toggleUser = account => {
    updateUser(account.id, { is_active: !account.is_active });
  };

  return (
    <div>
      <TextSearch>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon className="fal fa-search" />
              </InputAdornment>
            )
          }}
          style={{ width: '90%' }}
          variant="outlined"
          type="text"
          onChange={e => handleSearch(e)}
          placeholder="Search by user name or role"
          disabled={!props.accounts.length}
          value={filterStates.search}
        />
      </TextSearch>

      <Table style={{ borderLeft: 'none', borderRight: 'none' }}>
        <thead>
          <TrHead>
            <Th>Type</Th>
            <Th>Username</Th>
            <Th>Display Name</Th>
            <Th>Status</Th>
            <Th></Th>
          </TrHead>
        </thead>
        <TBody>
          {filterStates.accounts.map(
            (account, index) =>
              account.username !== authUser.username && (
                <Tr key={index} onMouseLeave={() => setSubMenuOpen(false)}>
                  <TdBold style={{ width: '15%' }}>
                    {/*  old version of changing role without modal
                {isEditable === account.id ? (
                  <Select
                    menuContainerStyle={{ zIndex: 999 }}
                    options={getRoles(roles)}
                    name="roles"
                    disabled
                    onChange={e => patchUpdateRole(e.value, account)}
                  />
                ) : (
                  <RoleName>{account.modifiedRole}</RoleName>
                )} */}
                    <RoleName>{account.modifiedRole}</RoleName>
                  </TdBold>
                  <TdBold align="center">{account.username}</TdBold>
                  <TdBold align="center">{account.display_name}</TdBold>
                  <TdBold align="center">
                    {account.is_active ? 'ACTIVE' : 'INACTIVE'}
                  </TdBold>
                  <TdBold align="left">
                    {subMenuOpen !== account.id && (
                      <MoreEllipsisIcon
                        onMouseEnter={() => setSubMenuOpen(account.id)}
                        className="fas fa-ellipsis-v"
                      />
                    )}
                  </TdBold>
                  {subMenuOpen === account.id && (
                    <OnHoverFeatures>
                      <tbody>
                        <tr>
                          <td align="left">
                            <Icon disabled className="fal fa-toggle-off" />
                          </td>
                          <td>
                            <span
                              onClick={() => toggleUser(account)}
                              style={{ cursor: 'pointer' }}
                            >
                              Make {account.is_active ? 'Inactive' : 'Active'}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td align="left">
                            <Icon disabled className="fal fa-user" />
                          </td>
                          <td
                            onClick={() => {
                              setSubMenuOpen(false);
                              toggleCreateUserModal(account);
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            Edit Role
                          </td>
                        </tr>
                        {account.is_password_set ? (
                          <tr>
                            <td align="center">
                              <i className="fa fa-check" />
                            </td>
                            <td>Registration Complete</td>
                          </tr>
                        ) : (
                          <tr>
                            <td align="center">
                              <Icon disabled className="fal fa-envelope" />
                            </td>
                            <td
                              onClick={() => postResendInvitation(account)}
                              style={{ cursor: 'pointer' }}
                            >
                              Resend Invitation
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </OnHoverFeatures>
                  )}
                </Tr>
              )
          )}
        </TBody>
        {isModal && (
          <CreateEditUserModalContainer
            roles={roles}
            businesses={businesses}
            toggleCreateUserModal={toggleCreateUserModal}
          />
        )}
      </Table>
    </div>
  );
};

export default RolesTable;
