import moment from 'moment';

const dayNames = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
];

export const makeSpecialHoursForForm = existingSpecialHours => {
  let specialHours = [];
  let specialHoursForForm = [];
  if (existingSpecialHours) {
    specialHours = existingSpecialHours.hours;
  }
  for (let i = 1; i < 8; i++) {
    let found = null;
    specialHours.forEach(specialHour => {
      if (specialHour.open_day_of_week == i) {
        found = specialHour;
      }
    });
    if (found) {
      specialHoursForForm.push({
        id: found.id,
        business_id: found.business_id,
        open_day_of_week: found.open_day_of_week,
        dayName: dayNames[i - 1],
        startDate: found.start_date,
        endDate: found.end_date,

        openTime: moment(found.open_time, 'HH:mm:ss'),
        closeTime: moment(found.close_time, 'HH:mm:ss'),
        isOpen: !found.is_closed,

        orderAheadIsOpen: !(
          found.order_start_time == '00:00:00' &&
          found.order_end_time == '00:00:00'
        ),
        orderAheadOpenTime: !(
          found.order_start_time == '00:00:00' &&
          found.order_end_time == '00:00:00'
        )
          ? moment(found.order_start_time, 'HH:mm:ss')
          : moment('09:00:00', 'HH:mm:ss'),
        orderAheadCloseTime: !(
          found.order_start_time == '00:00:00' &&
          found.order_end_time == '00:00:00'
        )
          ? moment(found.order_end_time, 'HH:mm:ss')
          : moment('17:00:00', 'HH:mm:ss'),
        isSpecial: true
      });
    } else {
      specialHoursForForm.push({
        open_day_of_week: i,
        dayName: dayNames[i - 1],
        openTime: moment('09:00:00', 'HH:mm:ss'),
        closeTime: moment('17:00:00', 'HH:mm:ss'),
        isOpen: true,
        orderAheadIsOpen: false,
        orderAheadOpenTime: moment('09:00:00', 'HH:mm:ss'),
        orderAheadCloseTime: moment('17:00:00', 'HH:mm:ss'),
        isSpecial: false
      });
    }
  }
  return specialHoursForForm;
};

export const getInvalidBounds = (invalidDays, startDate, endDate) => {
  let invalidBounds = {};
  if (endDate && invalidDays.length > 0) {
    let min = moment.max(invalidDays);
    for (let i = 0; i < invalidDays.length; i++) {
      let diff = endDate.diff(invalidDays[i]);
      if (diff < endDate.diff(min) && diff > 0) {
        min = invalidDays[i];
      }
    }
    invalidBounds.leftInvalid = min;
  }
  if (startDate && invalidDays.length > 0) {
    let min = moment.max(invalidDays);
    for (let i = 0; i < invalidDays.length; i++) {
      let diff = startDate.diff(invalidDays[i]);
      if (startDate.diff(min) < diff && diff < 0) {
        min = invalidDays[i];
      }
    }

    if (startDate.diff(min) >= 0) {
      min = null;
    }

    invalidBounds.rightInvalid = min;
  }
  return invalidBounds;
};

export const getGoodDays = (startDate, endDate) => {
  let from = moment(startDate, 'YYYY-MM-DD');
  let to = moment(endDate, 'YYYY-MM-DD');
  let goodDays = [];
  while (from.diff(to) <= 0) {
    goodDays.push(from.clone().format('YYYY-MM-DD'));
    from = from.add(1, 'day');
  }
  return goodDays;
};

export const getInvalidDays = (specialHours, goodDays) => {
  let invalidDays = [];
  specialHours.map(specialHour => {
    const {startDate, endDate} = specialHour.startEndDate;
    let from = moment(startDate, 'YYYY-MM-DD');
    let to = moment(endDate, 'YYYY-MM-DD');
    while (from.diff(to) <= 0) {
      if (!goodDays.includes(from.format('YYYY-MM-DD'))) {
        invalidDays.push(from.clone());
      }
      from = from.add(1, 'day');
    }
  });
  return invalidDays;
};
