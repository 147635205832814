import React, {useState, useEffect, Fragment} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux'
import * as actions from '../actions';
import styled from 'styled-components';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import BackButton from '../../../../components/BackButton';
import CouponForm from './components/CouponForm';

const BreadCrumbWrapper = styled.div`
  color: ${props => props.theme.darkBlue};
  margin: 30px;
  margin-left: 0;
  @media print {
    display: none;
  }
`;

const CreateCouponContainer = (props) => {

  const {coupons} = props;

  const [selectedSegments, setSelectedSegments] = useState([]);
  const [selectedRecipientsDetails, setSelectedRecipientsDetails] = useState([]);
  // const [prevRecipientsLength, setPrevRecipientsLength] = useState(0);
  const [isEditingCustomer, setIsEditingCustomers] = useState(false);

  const title = props.edit ? 'Edit Coupon' : 'Create Coupon';

  useEffect(() => {
    
    if (props.edit) {
      track.page('engagement-coupons-edit');
    } else {
      track.page('engagement-coupons-create');
    }

    if(props.edit && coupons.initialFormValues.customers.length > 0){
        props.getCustomerDetail(coupons.initialFormValues.coupon_details.coupon_id);
    }
      
  },[])


  useEffect(() => {
     if(typeof selectedSegments == 'object' && selectedSegments.length > 0){
        props.searchCustomers('', selectedSegments);
    }
  }, [selectedSegments]);

  useEffect(() => {
    coupons.customerDetail &&
        setSelectedRecipientsDetails(() => {
          // setPrevRecipientsLength(prevRecipient.length);
          return coupons.customerDetail;
        } )
  }, [coupons.customerDetail])

  function *filterCustomers (customers)  {

    yield customers.filter( customer => !selectedRecipientsDetails
                                        .map(selectedRecipient =>  selectedRecipient.customer_id)
                                        .includes(customer.customer_id))                 
    }

  const handleCustomerSearchChange = (searchText) => {
    const segments = (typeof selectedSegments == 'object' && 
                selectedSegments.length > 0) ? selectedSegments : null;
    if (searchText.length > 2) {
      props.searchCustomers(
        searchText,
        segments
      );
    } else if (searchText.length == 0) {
      props.clearCustomerSearch();
    }
  };


  const handleSubmitCoupon = (values) => {

    let customersId;

    if(selectedRecipientsDetails && typeof values.allUsers == 'string'){
      function *loadId(){
        yield selectedRecipientsDetails.reduce((acc,customer) => acc.concat(customer.customer_id),[]); 
      }customersId=loadId().next().value;
    }
  
    if(values.coupon_details.template_name == 'unlimited-time'){
      delete values.coupon_details.template_values.start_date;
      delete values.coupon_details.template_values.end_date;
    }

    if(typeof values.businesses == 'string'){
      values.businesses = [];
    }

    if(props.edit){
      props.editCoupon({...values, 
        coupon_id: coupons.initialFormValues.coupon_details.coupon_id, 
        customers: customersId ? customersId : []});
    }else{
      props.createCoupon({...values, customers: customersId ? customersId : []} );
    }
  }

  const handleAddAllRecipient = customersFromSegments => {

    setSelectedRecipientsDetails([...selectedRecipientsDetails, ...customersFromSegments]);
    setIsEditingCustomers(true);

  };

  const handleAddRecipient = customer => {
    setIsEditingCustomers(true);
    setSelectedRecipientsDetails(selectedRecipientsDetails.concat(customer));
  };
  
  const handleRemoveRecipient = customer => {
    setIsEditingCustomers(true);
    
    setSelectedRecipientsDetails(
                      selectedRecipientsDetails.filter(
                              selectedCustomer => selectedCustomer.customer_id != customer.customer_id
      ))
    
  
  };
  const handleRemoveAllRecipient = () => {
    setIsEditingCustomers(true);
    setSelectedRecipientsDetails([]);
    (typeof selectedSegments == 'object' && selectedSegments.length > 0) && props.searchCustomers('', selectedSegments);
    
  };

  const cancelCoupon = () => {
      props.cancelCoupon();
    
  };

  let initialValues = {coupon_details: coupons.initialFormValues.coupon_details,
                          customers: coupons.initialFormValues.customers,
                          businesses: coupons.initialFormValues.businesses,
                          allUsers: coupons.initialFormValues.customers.length > 0 ?  'select users' :  [],
                          selectedSegments: [],
                          unlimitedRedemption: coupons.initialFormValues.coupon_details.template_values.limit_redemption ? false : true
                      }

    return (
    <Fragment>
      <BreadCrumbWrapper>
          <BackButton
            pageName="Coupons List"
            onClick={() => cancelCoupon()}
          />
          <BreadCrumb style={{ margin: ' 0px' }} pageName={title} />
        </BreadCrumbWrapper>
        <CouponForm
            {...props}
            selectedSegments={selectedSegments} 
            setSelectedSegments={setSelectedSegments}
            selectedRecipientsDetails={selectedRecipientsDetails}
            requestingGetCustomerDetail={coupons.requestingGetCustomerDetail}
            handleCustomerSearchChange={handleCustomerSearchChange}
            filterCustomers={filterCustomers}
            customersFromSelectedSegments={coupons.customersInfo ? coupons.customersInfo.data : []}
            handleAddAllRecipient={handleAddAllRecipient}
            handleRemoveAllRecipient={handleRemoveAllRecipient}
            handleAddRecipient={handleAddRecipient}
            initialValues={initialValues}
            handleRemoveRecipient={handleRemoveRecipient}
            handleSubmitCoupon={handleSubmitCoupon}
            isEditingCustomer={isEditingCustomer}
            setIsEditingCustomers={setIsEditingCustomers}
            cancelCoupon={cancelCoupon}
        />
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    coupons: state.coupons,
    allBusinesses: state.businessLocationsList.businessLocationsOfMerchant,
    programType: state.accountReducer.appMetaData.configuration.program_type,
    appIdentifier: state.accountReducer.appIdentifier
  }
}
export default withRouter(connect(mapStateToProps,actions )(CreateCouponContainer));