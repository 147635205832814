import React from 'react';
import styled from 'styled-components';
import ListTitleAndAction from './ListTitleAndAction';

const HorizontalList = ({row, allOffers, ...props}) => {
  const ComponentContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px;
    padding-right: 0;
  `;

  const HorizontalScrollContainer = styled.div`
    flex: 1 0;
    display: flex;
    padding-bottom: 2px;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  `;

  const HorizontalCardWrapper = styled.div`
    flex: 0 0 90%;
    height: 100%;
    border-radius: ${props => props.theme.feedStyles.corner_radius}px;
    border-width: ${props => props.theme.feedStyles.border_width}px;
    border-style: solid;
    border-color: ${props =>
      props.theme.feedStyles.border_color
        ? props.theme.feedStyles.border_color
        : 'transparent'};
    overflow: hidden;
    margin: 0 6px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  `;

  const BackgroundImage = styled.div`
    background-image: url(${props => props.backgroundImage});
    background-size: cover;
    height: 72%;
    position: relative;
  `;

  const TitleWrapper = styled.div`
    background: ${props =>
      props.color ? props.color : props.theme.offerBorder};
    height: 28%;
    display: grid;
    align-content: center;
    padding: 0 6px;
  `;

  const CardTitle = styled.div`
    font-weight: 600;
    color: ${props => (props.color ? props.color : props.theme.white)};
    font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  `;

  const PointsBox = styled.div`
    font-weight: 600;
    background-color: ${props =>
      props.auto ? props.theme.offerAutoBox : props.theme.offerPointsBox};
    font-size: ${props => (props.fontSize ? props.fontSize : '11px')};
    color: ${props => (props.color ? props.color : props.theme.white)};
    margin: 0px 6px 9px 0px;
    padding: 2px 5px;
    position: absolute;
    bottom: 0px;
    right: 0px;
  `;

  const CardSubtitle = styled.div`
    color: ${props => (props.color ? props.color : props.theme.white)};
    font-size: ${props => (props.fontSize ? props.fontSize : '12px')};
  `;

  const HorizontalListRightBoxMarginHack = styled.div`
    flex: 0 0 6px;
  `;

  const checkTitleLength = title => {
    if (title.length > 25) {
      return title.slice(0, 25) + '...';
    }
    return title;
  };

  const checkDescription = description => {
    if (description.length > 65) {
      return description.slice(0, 65) + '...';
    }
    return description;
  };

  const renderHorizontalCards = () => {
    if (row) {
      if (row.items) {
        return row.items.map(card => {
          if (card.is_visible) {
            return (
              <HorizontalCardWrapper key={card.id}>
                <BackgroundImage backgroundImage={card.background_image_url} />
                <TitleWrapper>
                  <CardTitle>{card.title}</CardTitle>
                  <CardSubtitle>{card.subtitle}</CardSubtitle>
                </TitleWrapper>
              </HorizontalCardWrapper>
            );
          }
        });
      } else {
        return row.map(card => {
          if (card) {
            return (
              <HorizontalCardWrapper key={card.id}>
                <BackgroundImage backgroundImage={card.background_image_url} />
                <TitleWrapper>
                  <CardTitle>{card.title}</CardTitle>
                  <CardSubtitle>{card.subtitle}</CardSubtitle>
                </TitleWrapper>
              </HorizontalCardWrapper>
            );
          }
        });
      }
    } else {
      return allOffers.map(row => {
        return (
          <HorizontalCardWrapper key={row.offer_details.offer_id}>
            <BackgroundImage backgroundImage={row.offer_details.image_url}>
              {row.offer_details.auto_redeem ? (
                <PointsBox auto>AUTO</PointsBox>
              ) : row.offer_details ? (
                row.offer_details.template_values ? (
                  row.offer_details.template_values.points_cost ? (
                    <PointsBox>
                      {row.offer_details.template_values.points_cost} PTS
                    </PointsBox>
                  ) : null
                ) : null
              ) : null}
            </BackgroundImage>
            <TitleWrapper>
              <CardTitle color="white" fontSize="10px">
                {checkTitleLength(row.offer_details.title)}
              </CardTitle>
              <CardSubtitle color="white" fontSize="10px">
                {checkDescription(row.offer_details.description)}
              </CardSubtitle>
            </TitleWrapper>
          </HorizontalCardWrapper>
        );
      });
    }
  };

  return (
    <ComponentContainer>
      {row && <ListTitleAndAction row={row} margin="0 12px 6px 0px" />}
      {allOffers && (
        <ListTitleAndAction
          rowTitle={props.rowTitle}
          actionLabel={props.actionLabel}
          margin="0 12px 6px 0px"
        />
      )}
      <HorizontalScrollContainer>
        {renderHorizontalCards()}
        <HorizontalListRightBoxMarginHack />
      </HorizontalScrollContainer>
    </ComponentContainer>
  );
};

export default HorizontalList;
