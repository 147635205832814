import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Table,
  TableWithButtonRow,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
} from '../../../../../components/Table/TableElements';
import { STRING, BOOLEAN, sortByTypes } from '../../../../../utils/sort/sort';
import Icon from '../../../../../components/Icon/Icon';
import { SmallToggleSwitch } from '../../../../../components/ToggleSwitch/ToggleSwitch';
import { Button } from '../../../../../components/Button/Button';
import { LoadingBlueLarge } from '../../../../../components/Loading/Loading';
import PaginationControl from '../../../../../components/Pagination/PaginationControl';
import { TooltipIcon } from '../../../../../components/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const Wrapper = styled.div`
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  display: grid;
  grid-gap: 20px;
`;

const TableWrapper = styled.div`
  overflow-x: scroll;
`;

const TopDiv = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  justify-content: space-between;
  margin: 37px;
`;

const TextSearch = styled.div``;

const OptionsList = ({
  handleOptionSearch,
  optionsActiveList,
  toggleOptionIsActive,
  handleCreateButtonClick,
  requestingGetOptions,
  requestParams,
  editOption,
  optionsLength,
  handleSort,
}) => {
  const [showSubOptions, setShowSubOptions] = useState([]);
  const [showItems, setShowItems] = useState([]);

  // console.log(meta, options);
  const renderHeaders = () => (
    <TrHead>
      <Th
        align="left"
        onClick={() => {
          handleSort({
            value: 'name',
            sortMethod: sortByTypes,
            sortType: STRING,
          });
        }}
        value="name"
        sortingOption={requestParams.sorting_option}
        isSortable="true"
      >
        Name
      </Th>
      <Th
        align="left"
        onClick={() => {
          handleSort({ value: 'id', sortMethod: sortByTypes });
        }}
        value="id"
        sortingOption={requestParams.sorting_option}
        isSortable="true"
      >
        ID
      </Th>
      <Th align="left">
        Min
        <Tooltip
          style={{ marginRight: 'auto' }}
          title="Minimum amount of item quantity that's required"
        >
          <span>
            <TooltipIcon marginLeft />
          </span>
        </Tooltip>
      </Th>
      <Th align="left">
        Max
        <Tooltip
          style={{ marginRight: 'auto' }}
          title="Maximum amount of item quantity. Cannot go over this amount"
        >
          <span>
            <TooltipIcon marginLeft />
          </span>
        </Tooltip>
      </Th>
      <Th align="left">
        Included
        <Tooltip
          style={{ marginRight: 'auto' }}
          title="Amount of items that are free"
        >
          <span>
            <TooltipIcon marginLeft />
          </span>
        </Tooltip>
      </Th>
      {/* <Th align="left">Products Linked</Th> */}
      <Th
        align="left"
        onClick={() => {
          handleSort({
            value: 'is_active',
            sortMethod: sortByTypes,
            sortType: BOOLEAN,
          });
        }}
        value="is_active"
        sortingOption={requestParams.sorting_option}
        isSortable="true"
      >
        Active
      </Th>
    </TrHead>
  );
  const renderRows = () => {
    return (
      optionsActiveList &&
      optionsActiveList.map((option, index) => {
        if (!option.parent_id) {
          return (
            <Fragment>
              <Tr
                onClick={() => editOption(option.id)}
                key={index}
                style={{ height: '90px', color: '#191818', cursor: 'pointer' }}
              >
                <Td align="left">
                  <div>{option.name}</div>

                  {/* {(option.sub_options && option.sub_options.length !== 0 && (
                    <SubTitle
                      onClick={e => {
                        showSubOptions.includes(index)
                          ? setShowSubOptions(
                              showSubOptions.filter(option => option !== index)
                            )
                          : setShowSubOptions([...showSubOptions, index]);
                        e.stopPropagation();
                      }}
                    >
                      {option.sub_options.length} Sub-Options
                    </SubTitle>
                  )) ||
                    (option.items && option.items.length !== 0 && (
                      <SubTitle
                        onClick={e => {
                          showItems.includes(index)
                            ? setShowItems(
                                showItems.filter(item => item !== index)
                              )
                            : setShowItems([...showItems, index]);
                          e.stopPropagation();
                        }}
                      >
                        {option.items.length} Items
                      </SubTitle>
                    ))} */}
                </Td>
                <Td align="left">{option.id}</Td>
                <Td align="left">{option.minimum_pick}</Td>
                <Td align="left">{option.maximum_pick}</Td>
                <Td align="left">{option.included_pick}</Td>
                {/* <Td align="left">
                  {option.products.length
                    ? option.products.length > 1
                      ? `${option.products[0].name} and ${option.products
                          .length - 1} more`
                      : `${option.products[0].name}`
                    : null}
                </Td> */}
                <Td align="left">
                  <SmallToggleSwitch
                    checked={option.is_active}
                    onChange={() => toggleOptionIsActive(option)}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Td>
              </Tr>
              {option.sub_options &&
                option.sub_options.length !== 0 &&
                showSubOptions.includes(index) &&
                option.sub_options.map((subOption, subOptionIndex) => (
                  <Tr key={subOptionIndex} style={{ height: '90px' }}>
                    <Td align="left" style={{ padding: '5px 50px' }}>
                      {subOption.name}
                    </Td>
                    <Td align="left">{subOption.id}</Td>
                    <Td align="left">{subOption.minimum_pick}</Td>
                    <Td align="left">{subOption.maximum_pick}</Td>
                    <Td align="left">{subOption.included_pick}</Td>
                    {/* <Td align="left">
                      {subOption.products.length
                        ? subOption.products.length > 1
                          ? `${subOption.products[0].name} and ${subOption
                              .products.length - 1} more`
                          : `${subOption.products[0].name}`
                        : null}
                    </Td> */}
                    <Td align="left">
                      <SmallToggleSwitch
                        checked={subOption.is_active}
                        onChange={() => toggleOptionIsActive(subOption)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Td>
                  </Tr>
                ))}
              {option.items &&
                option.items.length !== 0 &&
                showItems.includes(index) &&
                option.items.map((item, itemIndex) => (
                  <Tr key={itemIndex} style={{ height: '90px' }}>
                    <Td align="left" style={{ padding: '5px 50px' }}>
                      {item.name}
                    </Td>
                    <Td align="left">{item.id}</Td>
                    <Td align="left" />
                    <Td align="left" />
                    <Td align="left" />
                    <Td align="left" />
                    <Td align="left">
                      <SmallToggleSwitch
                        checked={item.is_active}
                        // onChange={() => toggleOptionIsActive(subOption)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Td>
                  </Tr>
                ))}
            </Fragment>
          );
        }
      })
    );
  };

  return (
    <Wrapper>
      <TableWithButtonRow>
        <TopDiv>
          <TextSearch>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon className="fal fa-search" />
                  </InputAdornment>
                ),
              }}
              style={{ width: '100%' }}
              variant="outlined"
              type="text"
              onChange={(e) => handleOptionSearch(e.target.value)}
              placeholder="Search Options"
            />
          </TextSearch>
          <Button onClick={handleCreateButtonClick}>Create Option</Button>
        </TopDiv>
        {requestingGetOptions ? (
          <LoadingBlueLarge height="100vh" />
        ) : (
          <TableWrapper noShadow>
            <Table style={{ borderRight: 'none', borderLeft: 'none' }} noBorder>
              <TBody>
                {renderHeaders()}
                {renderRows()}
              </TBody>
            </Table>
          </TableWrapper>
        )}
      </TableWithButtonRow>
      <PaginationControl
        response={{
          ...optionsActiveList,
          page: requestParams.page_number,
          page_size: requestParams.page_size,
          count: optionsLength,
        }}
        requestParams={requestParams}
        type="options"
      />
    </Wrapper>
  );
};

export default OptionsList;
