import {
  GET_ORDER_HISTORY_OF_MERCHANT_REQUEST,
  GET_ORDER_HISTORY_OF_MERCHANT_SUCCESS,
  GET_ORDER_HISTORY_OF_MERCHANT_FAILURE
} from './types';

export default function(
  state = {
    ordersOfMerchantIsRequesting: false,
    orders: [],
    meta: []
  },
  action
) {
  switch (action.type) {
    case GET_ORDER_HISTORY_OF_MERCHANT_REQUEST:
      return {
        ...state,
        ordersOfMerchantIsRequesting: true
      };
    case GET_ORDER_HISTORY_OF_MERCHANT_SUCCESS:
      return {
        ...state,
        ordersOfMerchantIsRequesting: false,
        orders: action.orders,
        meta: action.meta
      };
    case GET_ORDER_HISTORY_OF_MERCHANT_FAILURE:
      return {
        ...state,
        ordersOfMerchantIsRequesting: false
      };
    default:
      return state;
  }
}
