import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import MobileMessagesContainer from '../Engagement/MobileMessages/MobileMessagesContainer';
import AppFeed from '../Appfeed/AppFeedContainer';
import styled from 'styled-components';
import Profile from '../Profile/Profile';
import Settlements from '../Settlements/Settlements';
import BatchDetailContainer from '../Settlements/BatchDetail/BatchDetailContainer';
import PageNotFound from './PageNotFound/PageNotFound';
import { withRouter } from 'react-router';
import ListSegment from '../Segmentation/ListSegment';
import UsersDashboard from '../Dashboard/Users/index';
import TransactionsDashboard from '../Dashboard/Transactions/index';
import TopLocationsDashboard from '../Dashboard/TopLocations/index';
import AuthenticateModal from '../../modules/Account/components/AuthenticateModal';
import ProductsContainer from '../MenuManagement/Products/ProductsContainer';
//import CategoryManager from '../MenuManagement/CategoryManager/CategoryManager';
import OptionsContainer from '../MenuManagement/OptionManager/OptionsContainer';
import UpsellContainer from '../MenuManagement/Upsell/UpsellContainer';
import Alert from '../Alert/Alert';
import RouteWrapper from '../HOCs/RouteWrapper';
import CouponPromo from '../Engagement/CouponPromo';
import OrderHistoryContainer from '../OrderManager/OrderHistoryList/OrderHistoryContainer';
import FeedbackContainer from '../FeedbackManager/FeedbackList/FeedbackContainer';
import AppFeedLayoutContainer from '../AppFeedLayout/AppFeedLayoutContainer';
import LayoutAssignmentContainer from '../AppFeedLayout/LayoutAssignment/LayoutAssignmentContainer';
import OrderDetailsContainer from '../OrderManager/OrderDetails/OrderDetailsContainer';
import MediaContainer from '../Media/MediaContainer';
import BusinessLocationsListContainer from '../BusinessLocationDetails/LocationListManager/BusinessLocationsListContainer';
import BusinessLocationsContainer from '../BusinessLocationDetails/BusinessLocationsContainer';
// import BusinessLocationDetailsContainer from '../BusinessLocationDetails/LocationDetailsManager/BusinessLocationDetailsContainer';
import DeliveryZonesViewContainer from '../BusinessLocationDetails/LocationDetailsManager/BusinessWizard/DeliveryZoneInformation/DeliveryZonesContainer';
import DeliveryZonesCreateContainer from '../BusinessLocationDetails/LocationDetailsManager/BusinessWizard/DeliveryZoneInformation/DeliveryZonesCreate/DeliveryZonesCreateContainer';
import DeliveryZonesEditContainer from '../BusinessLocationDetails/LocationDetailsManager/BusinessWizard/DeliveryZoneInformation/DeliveryZonesEdit/DeliveryZonesEditContainer';
import CustomerListContainer from '../CustomerManager/CustomerList/CustomerListContainer';
import CustomerDetailsContainer from '../CustomerManager/CustomerDetails/CustomerDetailsContainer';
import TagsContainer from '../Tags/TagsContainer';
import * as actions from './actions';
import Layout from './Layout';
import RolesAndPermissionsContainer from '../RolesManager/RolesContainer';
import OffersContainer from '../Engagement/Offers/OffersContainer';
import AppDetailContainer from '../AppDetail/AppDetailContainer';
import AppIntegrationContainer from '../AppIntegration/AppIntegrationContainer';
import PriceContainer from '../MenuManagement/PriceManager/PriceContainer';
import CombosContainer from '../MenuManagement/CombosManager/CombosContainer';
import Category from '../MenuManagement/Category/CategoryContainer';
import NutritionTemplateContainer from '../MenuManagement/NutritionTemplate/NutritionTemplateContainer';
import POSContainer from '../MenuManagement/POSMapping/POSContainer';
import TransactionReport from '../Reports/TransactionReport';
import ComboPerformanceReport from '../Reports/ComboPerformanceReport';
import LoyaltyReport from '../Reports/LoyaltyReport';
import ProductReport from '../Reports/ProductReport';
import LocationReport from '../Reports/LocationReport';
import CustomerReport from '../Reports/CustomerReport';
import Moment from 'moment';
import VariantsContainer from '../MenuManagement/Variants/VariantsContainer';
import moment from 'moment';
import OneTimeMobileMessageContainer from '../Engagement/MobileMessages/OneTimeMessages/OneTimeMobileMessageContainer';
import AutomatedMessagesContainer from '../Engagement/MobileMessages/AutomatedMessages/AutomatedMessagesContainer';
import AutomatedMessagesList from '../Engagement/MobileMessages/AutomatedMessages/components/AutomatedMessagesList';
import GenericDetailsTemplate from '../Engagement/MobileMessages/AutomatedMessages/components/GenericDetailsTemplate';

const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding: 0 30px;
  width: 100%;
  @media print {
    padding: 0 !important;
  }
`;

let timer;
class Full extends Component {
  state = {
    portalReloadIsVisible: false,
  };

  sidebarMinimize() {
    document.body.classList.add('sidebar-minimized');
    document
      .querySelectorAll('li.open')
      .forEach((li) => li.classList.remove('open'));
  }

  componentWillMount() {
    this.props.onAppLoad();

    track.boot({
      email: this.props.accountReducer.username,
      created_at: moment(),
    });

    // const { createdAt } = this.props.accountReducer;

    // if (createdAt) {
    //   const expiresIn =
    //     1 * 60000 - Moment().diff(Moment(createdAt), 'miliseconds');
    //   if (expiresIn - 0.5 * 60000 > 0) {
    //     timer = setTimeout(() => {
    //       this.props.promptReauth();
    //     }, expiresIn - 0.5 * 60000);
    //     this.props.onAppLoad();
    //   } else {
    //     this.props.softSignoutUser();
    //   }
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    const { username, createdAt } = this.props.accountReducer;
    const pathChanged =
      prevProps.location.pathname !== this.props.location.pathname;
    if (pathChanged) {
      window.scrollTo(0, 0);
    }
    if (username != prevProps.accountReducer.username) {
      this.props.onAppLoad();
    }
    if (
      prevProps.accountReducer.createdAt &&
      prevProps.accountReducer.createdAt !== createdAt
    ) {
      window.clearTimeout(timer);
      const expiresIn =
        30 * 60000 - Moment().diff(Moment(createdAt), 'miliseconds');
      timer = setTimeout(() => {
        this.props.promptReauth();
      }, expiresIn - 5 * 60000);
    }
  }

  render() {
    const {
      token,
      reauthenticate,
      shouldPromptReauth,
      appIdentifier,
      appMetaData,
    } = this.props.accountReducer;

    if (!token && !reauthenticate) {
      localStorage.setItem('redirectedFrom', this.props.location.pathname);
      return <Redirect to="/" />;
    }

    return (
      <div className="app">
        <Alert />
        <Layout
          locationPathName={this.props.location.pathname}
          appIdentifier={appIdentifier}
          handleSignoutClick={this.props.signoutUser}
          appConfig={appMetaData ? appMetaData.configuration : null}
          promoteTierUpgradeModalIsVisible={
            this.props.promoteTierUpgradeModalIsVisible
          }
          setPromoteTierUpgradeModalIsVisible={
            this.props.setPromoteTierUpgradeModalIsVisible
          }
        >
          <Container>
            <Switch>
              <RouteWrapper
                path="/users-dashboard"
                component={UsersDashboard}
              />
              <RouteWrapper
                path="/transactions-dashboard"
                component={TransactionsDashboard}
              />
              <RouteWrapper
                path="/top-locations-dashboard"
                component={TopLocationsDashboard}
              />

              <RouteWrapper
                path="/app-feed"
                component={AppFeedLayoutContainer}
              />

              <RouteWrapper
                path="/layout-assignment"
                component={LayoutAssignmentContainer}
              />

              <RouteWrapper
                exact
                path="/settlements"
                strict={false}
                component={Settlements}
              />
              <RouteWrapper
                path="/settlements/:id"
                component={BatchDetailContainer}
              />
              <RouteWrapper
                exact
                path="/order-history"
                strict={false}
                component={OrderHistoryContainer}
              />
              <RouteWrapper
                path="/order-history/:orderId"
                component={OrderDetailsContainer}
              />

              <RouteWrapper
                exact
                path="/media"
                strict={false}
                component={MediaContainer}
              />

              <RouteWrapper path="/password" component={Profile} />

              <RouteWrapper
                exact
                path="/transaction-report"
                strict={false}
                component={TransactionReport}
              />
              <RouteWrapper path="/loyalty-report" component={LoyaltyReport} />
              <RouteWrapper path="/segments" component={ListSegment} />

              <RouteWrapper
                path="/product-performance-report"
                component={ProductReport}
              />

              <RouteWrapper
                path="/customer-report"
                component={CustomerReport}
              />

              <RouteWrapper
                path="/location-transaction-report"
                component={LocationReport}
              />

              <RouteWrapper
                path="/combo-performance-report"
                component={ComboPerformanceReport}
              />

              {/*<RouteWrapper
                path="/category-manager"
                component={CategoryManager}
              />*/}

              <RouteWrapper path="/categories" component={Category} />

              <RouteWrapper path="/pos-mapping" component={POSContainer} />

              <RouteWrapper path="/products" component={ProductsContainer} />

              <RouteWrapper path="/offers" component={OffersContainer} />

              <RouteWrapper path="/combos" component={CombosContainer} />
              <RouteWrapper path="/offers" component={OffersContainer} />

              <RouteWrapper path="/variants" component={VariantsContainer} />
              {/* <RouteWrapper path="/app-detail" component={AppDetailContainer} />
              <RouteWrapper
                path="/app-integration"
                component={AppIntegrationContainer}
              /> */}
              {/* <RouteWrapper
                path="/variant-manager"
                component={variantsReducer}
              /> */}

              <RouteWrapper path="/upsell" component={UpsellContainer} />

              <RouteWrapper path="/options" component={OptionsContainer} />

              <RouteWrapper
                path="/coupon-management"
                name="Coupon Promos"
                component={CouponPromo}
              />

              <RouteWrapper
                exact
                path="/mobile-messages"
                component={MobileMessagesContainer}
              />

              <RouteWrapper
                exact
                path="/mobile-messages/one-time-messages"
                component={OneTimeMobileMessageContainer}
              />

              <RouteWrapper
                exact
                path="/mobile-messages/automated-messages"
                component={AutomatedMessagesContainer}
              />

              {/* <RouteWrapper
                exact
                path="/mobile-messages/automated-messages/:name"
                component={GenericDetailsTemplate}
              /> */}

              <RouteWrapper
                exact
                path="/locations"
                strict={false}
                component={BusinessLocationsContainer}
              />

              {/* <RouteWrapper
                exact
                path="/locations/:businessLocationId"
                component={BusinessLocationDetailsContainer}
              /> */}

              <RouteWrapper
                exact
                path="/locations/:businessLocationId/delivery-zones/view/"
                component={DeliveryZonesViewContainer}
              />

              <RouteWrapper
                exact
                path="/locations/:businessLocationId/delivery-zones/create/"
                component={DeliveryZonesCreateContainer}
              />

              <RouteWrapper
                exact
                path="/locations/:businessLocationId/delivery-zones/edit/:deliveryZoneId"
                component={DeliveryZonesEditContainer}
              />

              <RouteWrapper path="/tags" component={TagsContainer} />

              <RouteWrapper
                path="/roles"
                component={RolesAndPermissionsContainer}
              />
              <RouteWrapper
                exact
                path="/customers"
                name="Customers"
                component={CustomerListContainer}
              />

              <RouteWrapper
                exact
                path="/customers/:customerId"
                name="Customer Details"
                component={CustomerDetailsContainer}
              />

              <RouteWrapper
                exact
                path="/customer-feedback"
                name="Customer Feedback"
                component={FeedbackContainer}
              />

              <RouteWrapper
                exact
                path="/option-price"
                component={PriceContainer}
              />

              {/* <RouteWrapper
                exact
                path="/nutritional-template"
                name="Nutritional Template"
                component={NutritionTemplateContainer}
              /> */}

              <Route component={PageNotFound} />
            </Switch>
          </Container>
        </Layout>

        {(!token && reauthenticate) || shouldPromptReauth ? (
          <AuthenticateModal />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    accountReducer: state.accountReducer,
    promoteTierUpgradeModalIsVisible:
      state.fullReducer.promoteTierUpgradeModalIsVisible,
  };
}

Full = withRouter(Full);

export default connect(mapStateToProps, actions)(Full);
