import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Icon from '../../../../components/Icon/Icon';

const Wrapper = styled.div`
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  display: grid;
  grid-template-columns: 100px 1fr 50px;
  margin: 13px 0;
  padding: 20px;
  max-width: 1000px;
`;

const TextDiv = styled.div``;

const Title = styled.p`
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #191818;
  margin-bottom: 11px;
`;

const Description = styled.p`
  font-size: 17px;
  letter-spacing: 0.02px;
  color: #191818;
`;

const IconWrapper = styled(Icon)`
  align-self: center;
  text-align: center;
  font-size: 30px;
  color: #191818;
`;

const Card = ({ logo, title, description, onArrowClick }) => {
  return (
    <Wrapper>
      <img
        src={logo}
        width="50px"
        style={{ margin: '0 auto', alignSelf: 'center' }}
      />
      <TextDiv>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextDiv>
      <IconWrapper className="fal fa-angle-right" onClick={onArrowClick} />
    </Wrapper>
  );
};

export default Card;
