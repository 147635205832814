import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from './actions';
import CustomerSearchSelect from './components/CustomerSearchSelect';

class CustomerSearchManager extends Component {
  componentDidMount() {
    if (this.props.defaultValue) {
      this.props.selectCustomerByDefaultValue(this.props.defaultValue);
    } else {
      this.props.resetState();
    }
  }

  render() {
    return <CustomerSearchSelect {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {customerSearchManager: state.customerSearchManager};
}

export default connect(
  mapStateToProps,
  actions
)(CustomerSearchManager);
