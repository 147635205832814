import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../../../components/Modal/Modal';
import Select from '../../../../components/Select/Select';
import { ButtonRow, Button } from '../../../../components/Button/Button';

export const ModalTitle = styled.div`
  font-size: 20px;
  letter-spacing: 0.03px;
  color: #191818;
  margin-bottom: 24px;
`;

export const ModalContent = styled.div`
  padding: 32px;
`;

export const ModalText = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
`;

const BulkAssignmentModal = ({
  optionSelect,
  onClose,
  onSave,
  numbBusiness
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  return (
    <Modal>
      <ModalContent>
        <ModalTitle>{numbBusiness} Businesses Selected</ModalTitle>
        <ModalText>
          Select a Layout you want to assign the businesses to
        </ModalText>
        <Select
          pagination
          small
          placeholder="Choose Action"
          options={optionSelect}
          getOptionValue={template => template.id}
          getOptionLabel={template => template.template_name}
          value={selectedOption}
          onChange={option => setSelectedOption(option)}
        />
        <ButtonRow style={{ marginTop: '80px' }}>
          <Button small secondary type="button" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button small type="submit" onClick={() => onSave(selectedOption.id)}>
            Save
          </Button>
        </ButtonRow>
      </ModalContent>
    </Modal>
  );
};

export default BulkAssignmentModal;
