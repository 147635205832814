import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Formik, FieldArray, Field } from 'formik';
import {
  RenderSelect,
  RenderTextField,
  RenderToggleSwitch
} from '../../../../components/FormElements/FormikElements';
import Switch from '@material-ui/core/Switch';

const Title = styled.p`
  color: #191818;
  font-size: 17px;
  font-weight: 600;
  margin: 23px 0;
`;

const ItemVariantsDiv = styled.div`
  margin-top: 40px;
`;

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  grid-gap: 5px;
  margin-bottom: 30px;
`;

const Header = styled.p`
  font-size: 15px;
  font-weight: 600;
`;

const BodyDiv = styled.div``;

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  grid-gap: 5px;
  padding-top: 10px;
`;

const RowCol = styled.div`
  font-size: 14px;
`;

const LineDivider = styled.hr`
  border: 1px solid rgba(0, 0, 0, 0.09);
  margin: 15px 0;
`;

const NoLocationSelectedDiv = styled.p`
  color: #959595;
  font-size: 20px;
  letter-spacing: 0.03;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`;

const SelectLocationScreen = ({
  formattedBusinesses,
  item,
  selectedOption,
  priceKey,
  selectedLocations,
  setSelectedLocations,
  setFieldValue,
  singleItemOverrides,
  isPageEdit,
  editingVariant
}) => {
  const itemModified = editingVariant.item ? editingVariant.item : item;

  return (
    <div>
      <Title>Select a Location</Title>

      <Field
        name="assignedBusinesses"
        component={RenderSelect}
        options={formattedBusinesses || []}
        isMulti
        setFieldValue={setFieldValue}
        getOptionLabel={business =>
          business.isDisabled
            ? `${business.display_name} - Business is not associated`
            : business.display_name
        }
        getOptionValue={business => business.id}
        valueKey="id"
        placeholder="Search Locations"
        onChange={e => setSelectedLocations(e)}
        simpleValue
      />

      {!selectedLocations.length && !isPageEdit ? (
        <NoLocationSelectedDiv>
          Select a location to add overrides
        </NoLocationSelectedDiv>
      ) : (
        <ItemVariantsDiv>
          <HeaderWrapper>
            <Header>Variant</Header>
            <Header>
              {selectedOption.included_pick > 0 ? 'Additional Price' : 'Price'}
            </Header>
            <Header>Active</Header>
            <Header>Available</Header>
          </HeaderWrapper>

          <FieldArray
            name="item"
            render={arrayHelpers => {
              return (
                <Fragment>
                  <BodyDiv>
                    {itemModified.prices.map((variant, priceIndex) => {
                      return (
                        <div key={priceIndex}>
                          <RowWrapper>
                            <RowCol>
                              {editingVariant.item
                                ? variant.name
                                : variant.variantName}
                            </RowCol>
                            <RowCol style={{ paddingRight: '20px' }}>
                              <Field
                                id="price"
                                name={`item.prices[${priceIndex}][${priceKey}]`}
                                component={RenderTextField}
                                isPrice
                                placeholder={
                                  item.prices[priceIndex].price
                                    ? `${item.prices[priceIndex].price}`
                                    : ''
                                }
                              />
                            </RowCol>
                            <RowCol>
                              <Switch
                                checked={
                                  arrayHelpers.form.values.item.prices[
                                    priceIndex
                                  ].is_active
                                }
                                onChange={e => {
                                  setFieldValue(
                                    `item.prices[${priceIndex}].is_active`,
                                    e.target.checked
                                  );
                                }}
                                value="checkedB"
                                color="primary"
                                inputProps={{
                                  'aria-label': 'primary checkbox'
                                }}
                              />
                            </RowCol>
                            <RowCol>
                              <Switch
                                checked={
                                  arrayHelpers.form.values.item.prices[
                                    priceIndex
                                  ].is_available
                                }
                                onChange={e => {
                                  setFieldValue(
                                    `item.prices[${priceIndex}].is_available`,
                                    e.target.checked
                                  );
                                }}
                                value="checkedB"
                                color="primary"
                                inputProps={{
                                  'aria-label': 'primary checkbox'
                                }}
                              />
                            </RowCol>
                          </RowWrapper>
                          <LineDivider />
                        </div>
                      );
                      // } else return null;
                    })}
                  </BodyDiv>
                </Fragment>
              );
            }}
          />
        </ItemVariantsDiv>
      )}
    </div>
  );
};

export default SelectLocationScreen;
