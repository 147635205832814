import React, {Fragment} from 'react';
import styled from 'styled-components';
import CustomerLoyaltyPointsTable from './CustomerLoyaltyPointsTable';
import CustomerLoyaltyPointsTier from './CustomerLoyaltyPointsTier';
import {Wrapper} from '../../../../components/FormElements/FormElements';

const Title = styled.div`
  font-weight: 600;
  color: ${props => props.theme.darkBlue};
  padding: 10px 0;
`;

const BalanceContainerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'a b c';
  grid-gap: 75px;
  @media screen and (max-width: 1232px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'a c';
  }
`;

const BalanceContainer = styled.div`
  grid-area: a;
`;

const SendPoints = styled.div`
  color: ${props => props.theme.blue};
  font-weight: 600;
  padding: 10px 0;
  grid-area: c;
  cursor: pointer;
  margin-right: auto;
`;

const BalanceWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 10px;
  padding: 5px;
`;

const BalanceName = styled.div`
  font-size: 14px;
`;

const BalanceValue = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

const HorizontalLine = styled.hr`
  border: 0;
  border-top: 2px solid #d8d8d8;
  opacity: 0.4;
  margin: 50px 37px;
`;

const CustomerLoyaltyPoints = props => {
  const {
    toggleSendLoyaltyModal,
    loyaltyPoints,
    loyaltyPointsAccumulation,
    loyaltyTier
  } = props;
  return (
    <Fragment>
      <Wrapper>
        <Title data-test="title">Customer Loyalty Points</Title>
        <BalanceContainerWrapper>
          <BalanceContainer>
            <BalanceWrapper>
              <BalanceName data-test="label-currentPointsBalance">
                Current Points Balance
              </BalanceName>
              <BalanceValue data-test="value-currentPointsBalance">
                {loyaltyPoints.current_points} pts
              </BalanceValue>
            </BalanceWrapper>
            <BalanceWrapper>
              <BalanceName data-test="label-lifetimeAccumulation">
                Lifetime Accumulation
              </BalanceName>
              <BalanceValue data-test="value-lifetimeAccumulation">
                {loyaltyPoints.lifetime_points} pts
              </BalanceValue>
            </BalanceWrapper>
            {loyaltyTier ? (
              <CustomerLoyaltyPointsTier loyaltyTier={loyaltyTier} />
            ) : null}
          </BalanceContainer>
          <SendPoints
            data-test="button-sendPoints"
            onClick={toggleSendLoyaltyModal}
          >
            + SEND POINTS
          </SendPoints>
        </BalanceContainerWrapper>
      </Wrapper>
      <CustomerLoyaltyPointsTable
        loyaltyPointsAccumulation={loyaltyPointsAccumulation}
      />
      <HorizontalLine />
    </Fragment>
  );
};

export default CustomerLoyaltyPoints;
