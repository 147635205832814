import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Formik, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import { Collapse } from '@material-ui/core';
import { RenderSelect } from '../../../../../../components/FormElements/FormikElements';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import { Button, ButtonRow } from '../../../../../../components/Button/Button';
import EditIcon from '../../../../../../components/Icon/EditIcon';
import ConfirmCancelModal from '../../components/ConfirmCancelModal';
import { SmallToggleSwitch } from '../../../../../../components/ToggleSwitch/ToggleSwitch';

const Card = styled.div`
  background: ${(props) => (props.disabled ? '#ebebeb' : 'white')};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 32px 48px;
  color: ${(props) => (props.disabled ? '#959595' : 'initial')};
  label {
    z-index: 0;
  }
`;

const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
`;

const ProductTagsWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  margin-top: 40px;

  .react-select__control {
    background-color: white;
    border-radius: 6px;
  }

  .react-select__single-value {
    font-weight: 600;
    font-size: 14px;
  }

  .react-select__multi-value {
    background-color: #e6e6e6;
    border-radius: 15px;
    padding: 1px 3px;
    font-weight: 400;
    font-size: 14px;
  }
`;

let ProductBusinessSelectionFormComponent = ({
  disabled,
  isExpanded,
  setIsExpanded,
  initialValues,
  allBusinesses,
  setConfirmCancelModalIsVisible,
  confirmCancelModalIsVisible,
  putProductBusinesses,
  isAvailableAllLocations,
  setIsAvailableAllLocations,
  allInitialBusinesses,
  productId,
}) => {
  return (
    <Card
      disabled={disabled}
      onClick={() => !disabled && setIsExpanded(!isExpanded)}
    >
      <Formik initialValues={initialValues} enableReinitialize={true}>
        {({ dirty, values, setValues, setFieldValue }) => (
          <Fragment>
            <TitleAndCollapseButton>
              <Title>Assign Businesses - Optional</Title>
              {!isExpanded ? (
                <ChevronDownIcon
                  disabled={disabled}
                  onClick={() => setIsExpanded(true)}
                />
              ) : (
                <ChevronUpIcon
                  disabled={dirty}
                  onClick={() => setIsExpanded(false)}
                />
              )}
            </TitleAndCollapseButton>
            <Collapse in={isExpanded}>
              <SmallToggleSwitch
                checked={isAvailableAllLocations}
                label="All Businesses"
                name="allBusinesses"
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldValue(
                      'productSelectedBusinesses',
                      allBusinesses.map((business) => business.id)
                    );
                  }
                  setIsAvailableAllLocations(!isAvailableAllLocations);
                }}
                // onChange={e => {
                //   if (e.target.checked) {
                //     this.props.change('businesses', []);
                //   }
                //   this.setState({ isChecked: e.target.checked });
                // }}
              />
              {!isAvailableAllLocations && (
                <ProductTagsWrapper onClick={(e) => e.stopPropagation()}>
                  <Field
                    name="productSelectedBusinesses"
                    component={RenderSelect}
                    options={allBusinesses}
                    isMulti
                    setFieldValue={setFieldValue}
                    getOptionLabel={(business) => business.display_name}
                    getOptionValue={(business) => business.id}
                    valueKey="id"
                    placeholder="Select businesses"
                    required
                  />
                </ProductTagsWrapper>
              )}
              <ButtonRow
                justifyContent="space-between"
                style={{
                  marginTop: '24px',
                }}
              >
                <Button
                  secondary
                  onClick={(e) => {
                    e.stopPropagation();
                    dirty
                      ? setConfirmCancelModalIsVisible(true)
                      : setIsExpanded(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!dirty}
                  onClick={(e) => {
                    e.stopPropagation();
                    putProductBusinesses(
                      values.productSelectedBusinesses,
                      productId
                    );
                    setIsExpanded(false);
                  }}
                >
                  Save
                </Button>
              </ButtonRow>
              {confirmCancelModalIsVisible && (
                <ConfirmCancelModal
                  onCancel={() => setConfirmCancelModalIsVisible(false)}
                  onConfirm={() => {
                    setValues(initialValues);
                    setConfirmCancelModalIsVisible(false);
                    setIsExpanded(false);
                  }}
                />
              )}
            </Collapse>
          </Fragment>
        )}
      </Formik>
    </Card>
  );
};

export default ProductBusinessSelectionFormComponent;
