import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from './variantsActions';
import VariantDetailsContainer from './VariantDetails/VarianDetailsContainer';
import VariantListContainer from './VariantList/VariantListContainer';
import { LoadingBlueLarge } from '../../../components/Loading/Loading';

const VariantsContainer = ({
  componentType,
  variantToEdit,
  resetToInitialValues,
  getVariants,
  isVariantAvailable
}) => {
  useEffect(() => {
    return () => {
      resetToInitialValues();
    };
  }, []);
  return (
    <div>
      {componentType === 'list' ? (
        <VariantListContainer></VariantListContainer>
      ) : componentType === 'create' ? (
        <VariantDetailsContainer></VariantDetailsContainer>
      ) : (
        <VariantDetailsContainer
          variantToEdit={variantToEdit}
        ></VariantDetailsContainer>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  componentType: state.variantsReducer.componentType,
  variantToEdit: state.variantsReducer.variantToEdit,
  isVariantAvailable: state.variantsReducer.isVariantAvailable
});
export default connect(mapStateToProps, actions)(VariantsContainer);
