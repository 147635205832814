import styled from 'styled-components';
import Icon from '../../../../../../components/Icon/Icon';

export const Wrapper = styled.div`
  background-color: ${props => (props.disabled ? '#ebebeb' : 'white')};
  color: ${props => (props.disabled ? '#959595' : 'inherit')};
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding-top: 24px;
  padding-left: 48px;
  // margin-bottom: 24px;
  //padding-bottom: 20px;
  padding-right: 20px;
`;

export const Title = styled.div`
  font-family: 'Montserrat';
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.03px;
  // color: #191818;
`;

export const AddComboItemButton = styled.button`
  background-color: #efefef;
  border-radius: 8px;
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 600;
  color: #191818;
  //margin-top: 10px;
  margin-bottom: 32px;
  padding: 10px;
  cursor: pointer;
  border: none;
`;

export const AddProductsButton = styled(AddComboItemButton)`
  background-color: white;
  color: ${props => (props.disabled ? '#d1d1d1' : '#191818')};
  padding: 0;
  margin-bottom: 40px;
`;

export const ComboItemDiv = styled.div``;

export const ComboItemRow = styled.div`
  display: grid;
  grid-template-columns: 60px 4fr 1fr 40px 40px;
  padding-bottom: 20px;
  align-items: center;
`;

export const ComboItemProductImage = styled.img`
  max-width: 48px;
  max-height: 48px;
  border-radius: 4px;
  background-color: #d3d3d3;
`;

export const LineDivider = styled.hr`
  border: solid 1px #e1e1e1;
  margin-bottom: 12px;
`;

export const ItemNameField = styled.div`
  display: grid;
  grid-template-columns: auto 15px 15px;
  grid-gap: 20px;
  align-items: center;
  margin-top: 10px;
`;

export const ItemRequiredWrapper = styled.div`
  margin-bottom: 10px;
`;

export const ProductName = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  letter-spacing: 0.03px;
  color: #191818;
  color: ${props => (props.isActive ? 'inherit' : '#979797')};
`;

export const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 24px;
`;

export const ProductVariant = styled(ProductName);

export const ComboProductsDiv = styled.div``;

export const ShowComboItemsText = styled.div`
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 20px;
`;

export const IconEllipsis = styled(Icon)`
  color: ${props => (props.disabled ? '#d8d8d8' : 'inherit')};
`;

export const RemoveComboGroupName = styled.div`
  cursor: pointer;
  margin-left: -10px;
`;
