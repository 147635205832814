import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { Label } from '../FormElements/FormElements';

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  ${props =>
    props.vertical
      ? `grid-auto-flow: row;
  grid-gap: 4px;`
      : `
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-gap: 16px;
  `}
`;

const Switch = styled.label`
  position: relative;
  display: block;
  ${'' /* margin-left: auto; */}
  margin: 0;
  width: 64px;
  height: 32px;
  input {
    display: none;
    &:checked + span {
      ${'' /* background-color: #2196F3; */}
      background-color: ${props =>
        props.disabled ? '#ccc' : props.theme.blue};
    }
    &:checked + span:before {
      transform: translateX(32px);
    }
    &:focus + span {
      ${'' /* box-shadow: 0 0 1px #2196F3; */}
      box-shadow: 0 0 1px ${props =>
        props.disabled ? '#ccc' : props.theme.blue};
    }
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
  &:before {
    position: absolute;
    content: '';
    height: 28px;
    width: 28px;
    left: 2px;
    bottom: 2px;
    background-color: ${props => props.theme.white};
    transition: 0.4s;
    border-radius: 50%;
  }
  &::after {
    right: 20px;
  }
`;

export const SmallToggleSwitch = ({
  onChange,
  checked,
  label,
  disabled,
  onClick,
  vertical
}) => {
  return (
    <Wrapper vertical={vertical} onClick={onClick}>
      <Switch>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <Slider />
      </Switch>
      {label && <Label vertical={vertical}>{label}</Label>}
    </Wrapper>
  );
};

const ToggleSwitch = ({ onChange, name, label, vertical }) => (
  <Wrapper vertical={vertical}>
    <Switch>
      <Field
        name={name}
        component="input"
        onChange={onChange}
        type="checkbox"
        parse={Boolean}
      />
      <Slider />
    </Switch>
    {label && (
      <Label vertical={vertical} style={{ fontWeight: '600' }}>
        {label}
      </Label>
    )}
  </Wrapper>
);

export default ToggleSwitch;
