import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadCreatePage, resetOptionReducer } from './optionActions';
import OptionsListContainer from './OptionsList/OptionsListContainer';
import OptionsDetailContainer from './OptionsDetail/OptionsDetailContainer';

const OptionsContainer = ({
  currentPage,
  loadCreatePage,
  resetOptionReducer
}) => {
  useEffect(() => {
    return () => resetOptionReducer();
  }, []);
  if (currentPage === 'list') {
    return (
      <OptionsListContainer handleCreateButtonClick={() => loadCreatePage()} />
    );
  } else if (currentPage === 'create') {
    return <OptionsDetailContainer />;
  } else if (currentPage === 'edit') {
    return <OptionsDetailContainer isEdit={true} />;
  }
};

const mapStateToProps = state => ({
  currentPage: state.optionReducer.currentPage
});
export default connect(
  mapStateToProps,
  { loadCreatePage, resetOptionReducer }
)(OptionsContainer);
