import React, {Fragment} from 'react';
import styled from 'styled-components';
import {
  Table,
  TBody,
  TrHead,
  Td,
  Tr,
  Th
} from '../../../../components/Table/TableElements';
import {Link} from 'react-router-dom';

const TableWrapper = styled.div`
  overflow-x: scroll;
`;

const NoCredits = styled.div`
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  font-size: 38px;
  color: #d8d8d8;
`;

const renderHeaders = () => (
  <TrHead data-test="component-TrHead">
    <Th align="center">Date</Th>
    <Th align="center">Amount Given</Th>
    <Th align="center" style={{wordBreak: 'break-word'}}>
      Note
    </Th>
  </TrHead>
);

const renderRows = customerGlobalCredits =>
  customerGlobalCredits.map((credit, index) => (
    <Tr key={index} data-test="component-Tr">
      <Td align="center">{credit.created_at}</Td>
      <Td align="center">{credit.initial_amount}</Td>
      <Td align="center">
        {credit.comment.includes('order ID') ? (
          <div>
            {`${credit.comment.split('order ID ')[0]} order ID`}
            <Link to={`/order-history/${credit.comment.split('order ID ')[1]}`}>
              {credit.comment.split('order ID')[1]}
            </Link>
          </div>
        ) : (
          credit.comment
        )}
      </Td>
    </Tr>
  ));

const CustomerCreditsTable = props => {
  const {customerGlobalCredits} = props;
  return (
    <Fragment>
      <TableWrapper>
        <Table data-test="component-Table">
          <thead>{renderHeaders()}</thead>
          {customerGlobalCredits.length ? (
            <TBody striped>{renderRows(customerGlobalCredits)}</TBody>
          ) : null}
        </Table>
      </TableWrapper>
      {!customerGlobalCredits.length ? (
        <NoCredits data-test="component-NoCredits">
          No Credits have been sent yet
        </NoCredits>
      ) : null}
    </Fragment>
  );
};

export default CustomerCreditsTable;
