import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../../../components/Button/Button';
import { HrLine } from '../../../../../components/FormElements/FormElements';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 10px;
`;

const Title = styled.div`
  font-size: 22.5px;
  font-weight: 500;
  letter-spacing: 0.02px;
  line-height: 1.45;
`;

const Description = styled.div`
  font-size: 16.5px;
  line-height: 1.45;
  letter-spacing: 0.02px;
`;

const TokensWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const TokenHeader = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const TokenGridWrapper = styled.div`
  display: grid;
`;

const Token = styled.div``;
const Label = styled.div``;

const Disclaimer = styled.div`
  font-size: 13.5px;
  margin-top: -10px;
  line-height: 1.44;
  letter-spacing: 0.02px;
`;

const TokensTable = ({ tokens, setIsOpen }) => {
  return (
    <Wrapper>
      <Title>Insert Personalization</Title>
      <Description>
        Adding personalization allows you to customize your message for each
        customer.
      </Description>
      <div>
        <TokensWrapper>
          <TokenHeader>Token</TokenHeader>
          <TokenHeader>Description</TokenHeader>
        </TokensWrapper>
        <HrLine />
        {Object.keys(tokens).map((key, i) => (
          <TokenGridWrapper key={i}>
            <TokensWrapper>
              <Token>{key}</Token>
              <Label>{tokens[key]}</Label>
            </TokensWrapper>
            <HrLine />
          </TokenGridWrapper>
        ))}
      </div>
      <Disclaimer>
        If the customer does not have a value for this property it will be left
        blank.
      </Disclaimer>
      <Button
        secondary
        style={{ fontSize: '16px' }}
        onClick={(e) => {
          setIsOpen(false);
          e.stopPropagation();
        }}
      >
        Cancel
      </Button>
    </Wrapper>
  );
};

export default TokensTable;
