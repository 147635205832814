import React, { Fragment } from 'react';
import {
  RenderTextField,
  required,
  RenderSelect,
  requiredSelect,
} from '../../../../../../components/FormElements/FormikElements';
import { Field } from 'formik';
import { FieldWrapper, Image } from './styles';
import timezones from '../../../../../../utils/constants/timezones';
import ChooseImage from '../../../../../MediaModal/components/ChooseImage';

const DetailsForm = ({
  selectedDetails,
  setIsEditing,
  values,
  setDetails,
  setMediaSelectionModalIsVisible,
  errors,
  isEditing,
  languagesConfig,
}) => {
  return (
    <Fragment>
      <FieldWrapper grid="2fr 1fr">
        <Field
          name="details.timezone"
          component={RenderSelect}
          validate={requiredSelect}
          onChange={() => {
            setIsEditing(true);
          }}
          options={timezones.map((timezone) => ({
            label: timezone,
            value: timezone,
          }))}
          getOptionValue={(timezone) => timezone.value}
          getOptionLabel={(timezone) => timezone.label}
          valueKey="label"
          placeholder="Select Timezone*"
        />
      </FieldWrapper>
      <FieldWrapper grid="2fr 1fr">
        <Field
          name="details.language_code"
          component={RenderSelect}
          validate={requiredSelect}
          onChange={() => {
            setIsEditing(true);
          }}
          options={languagesConfig.map((language) => ({
            label: language.display_name,
            value: language.language_code,
          }))}
          getOptionValue={(language) => language.value}
          getOptionLabel={(language) => language.label}
          valueKey="value"
          placeholder="Select Language*"
        />
      </FieldWrapper>
      <FieldWrapper grid="2fr 1fr">
        <Field
          name="details.tax_number"
          component={RenderTextField}
          onChange={(e) => {
            setDetails({
              details: {
                ...selectedDetails.details,
                tax_number: e.target.value,
              },
            });
            setIsEditing(true);
          }}
          label="HST Number"
        />
      </FieldWrapper>
      <FieldWrapper grid="2fr 1fr">
        <Field
          name="details.website_url"
          component={RenderTextField}
          onChange={(e) => {
            setDetails({
              details: {
                ...selectedDetails.details,
                website_url: e.target.value,
              },
            });
            setIsEditing(true);
          }}
          label="Website URL"
        />
      </FieldWrapper>
      <ChooseImage
        imageUrl={values.details.image_url}
        setIsImageGallaryVisible={setMediaSelectionModalIsVisible}
      />
    </Fragment>
  );
};

export default DetailsForm;
