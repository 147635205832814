import React, { Fragment } from 'react';
import {
  ContainerDiv,
  StyledP,
  GridWrapper
} from '../utils/commonStyledComponents';
import {
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td
} from '../../../../components/Table/TableElements';
import styled from 'styled-components';
import moment from 'moment';
import toPriceFormat from '../../../../utils/toPriceFormat';

const TableWrapper = styled.div`
  overflow-x: scroll;
  padding: 20px 0;
`;

const renderHeaders = () => (
  <TrHead data-test="component-TrHead">
    <Th align="center">Date</Th>
    <Th align="center">Time</Th>

    <Th align="center">Payment Type</Th>
    <Th align="center">Transaction Type</Th>
    <Th align="center">Amount</Th>
    <Th align="center">Status</Th>
  </TrHead>
);

const renderRows = transactions =>
  transactions.map((transaction, index) => (
    <Tr data-test="component-Tr" key={index}>
      <Td align="center">
        {moment(transaction.created_at, 'YYYY-MM-DD HH:mm:ss').format(
          'YYYY-MM-DD'
        )}
      </Td>
      <Td align="center">
        {moment(transaction.created_at, 'YYYY-MM-DD HH:mm:ss').format(
          'hh:mm:ss a'
        )}
      </Td>

      <Td align="center">
        {transaction.payment_type
          ? transaction.payment_type == 'credit_card'
            ? transaction.payment_type.replace('_', ' ').toUpperCase() +
              ` (${transaction.payment_method.card_type.toUpperCase()})`
            : transaction.payment_type.replace('_', ' ').toUpperCase()
          : transaction.is_loyalty
          ? 'LOYALTY'
          : transaction.payment_gateway.replace('_', ' ').toUpperCase()}
      </Td>
      <Td align="center">{transaction.type.replace('_', ' ').toUpperCase()}</Td>
      <Td align="center">{transaction.amount}</Td>
      <Td align="center">{transaction.status.toUpperCase()}</Td>
    </Tr>
  ));

export const TransactionHistoryTable = props => {
  const {
    transactions,
    subtotal,
    discount,
    loyalty,
    tip,
    tax,
    total,
    creditsUsed,
    additionalFees
  } = props;
  return (
    <Fragment>
      <ContainerDiv padding={'15px 0px 0px 0px'}>
        <StyledP semiBold>Transaction History</StyledP>
        <TableWrapper>
          <Table data-test="component-Table">
            <thead>{renderHeaders()}</thead>
            <TBody striped>{renderRows(transactions)}</TBody>
          </Table>
        </TableWrapper>

        <ContainerDiv margin="0 auto" width="50%">
          <GridWrapper
            templateColumns="1fr auto"
            amountOfTransactionDetail
            collapseGap
            gap="10px"
            padding="0 10px"
            grayBackground
          >
            <StyledP collapseRight>Subtotal: </StyledP>
            <StyledP data-test="subtotal" semiBold collapseLeft>
              {subtotal}
            </StyledP>
          </GridWrapper>
          <GridWrapper
            templateColumns="1fr auto"
            amountOfTransactionDetail
            collapseGap
            gap="10px"
            padding="0 10px"
          >
            <StyledP collapseRight>Discount: </StyledP>
            <StyledP data-test="discount" semiBold collapseLeft>
              {discount}
            </StyledP>
          </GridWrapper>

          <GridWrapper
            templateColumns="1fr auto"
            amountOfTransactionDetail
            collapseGap
            gap="10px"
            padding="0 10px"
            grayBackground
          >
            <StyledP collapseRight>Credits Applied: </StyledP>
            <StyledP data-test="creditsUsed" semiBold collapseLeft>
              - {creditsUsed}
            </StyledP>
          </GridWrapper>

          <GridWrapper
            templateColumns="1fr auto"
            amountOfTransactionDetail
            collapseGap
            gap="10px"
            padding="0 10px"
          >
            <StyledP collapseRight>Total After Credits Applied: </StyledP>
            <StyledP data-test="totalAfterCreditsApplied" semiBold collapseLeft>
              {toPriceFormat(
                Math.round(
                  (parseFloat(subtotal.substring(1)) -
                    parseFloat(creditsUsed.substring(1))) *
                    100
                ) / 100
              )}
            </StyledP>
          </GridWrapper>

          <GridWrapper
            templateColumns="1fr auto"
            amountOfTransactionDetail
            collapseGap
            gap="10px"
            padding="0 10px"
            grayBackground
          >
            <StyledP collapseRight>Additional Fees: </StyledP>
            <StyledP semiBold collapseLeft>
              {additionalFees}
            </StyledP>
          </GridWrapper>

          <GridWrapper
            templateColumns="1fr auto"
            amountOfTransactionDetail
            collapseGap
            gap="10px"
            padding="0 10px"
          >
            <StyledP collapseRight>Tax: </StyledP>
            <StyledP data-test="tax" semiBold collapseLeft>
              {tax}
            </StyledP>
          </GridWrapper>

          <GridWrapper
            templateColumns="1fr auto"
            amountOfTransactionDetail
            collapseGap
            gap="10px"
            padding="0 10px"
            grayBackground
          >
            <StyledP collapseRight>Tip: </StyledP>
            <StyledP data-test="tip" semiBold collapseLeft>
              {tip}
            </StyledP>
          </GridWrapper>

          <GridWrapper
            templateColumns="1fr auto"
            margin="20px 0 0 0"
            collapseGap
            gap="10px"
            padding="0 10px"
            grayBackground
          >
            <StyledP semiBold collapseRight>
              Total:
            </StyledP>
            <StyledP data-test="total" semiBold collapseLeft>
              {total}
            </StyledP>
          </GridWrapper>
          {/* <GridWrapper
            templateColumns="1fr auto"
            margin="20px 0 0 0"
            collapseGap
            gap="10px"
            padding="0 10px"
          >
            <StyledP semiBold collapseRight>
              Loyalty Earned:
            </StyledP>
            <StyledP data-test="total" semiBold collapseLeft>
              {loyalty}
            </StyledP>
          </GridWrapper> */}
        </ContainerDiv>
      </ContainerDiv>
    </Fragment>
  );
};

export default TransactionHistoryTable;
