import React from 'react';
import { Formik, Field } from 'formik';
import styled from 'styled-components';
import Moment from 'moment';
import {
  RenderTextField,
  RenderTimePicker,
  RenderCheckBox,
  maxLengthValidationForNameField,
  required,
} from '../../../../../../components/FormElements/FormikElements';
import { Button, ButtonRow } from '../../../../../../components/Button/Button';
import { SmallToggleSwitch } from '../../../../../../components/ToggleSwitch/ToggleSwitch';
import MediaModal from '../../../../components/MediaModal';
import Collapse from '@material-ui/core/Collapse';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import MediaModalContainer from '../../../../../MediaModal/MediaModalContainer';
import ChooseImage from '../../../../../MediaModal/components/ChooseImage';

const Card = styled.div`
  background: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const FormWrapper = styled.div`
  padding: 48px;
  display: grid;
  grid-gap: 32px;
`;

const NameAndPreview = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 20px;
`;

const TaxCategoryWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 20px;
  align-items: center;
  grid-gap: 10px;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  display: grid;
  grid-template: 100px / 150px auto 1fr;
  grid-gap: 48px;
  align-items: center;
  height: 100px;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
`;

const TimeWidgetsWrapper = styled.div`
  display: grid !important;
  grid-gap: 32px;
  margin-top: 32px;
`;

const TimeWrapper = styled.div`
  display: grid;
  grid-template-columns: 86px 100px;
  grid-gap: 32px;
  align-items: center;
  font-weight: 600;
  .rw-widget-input {
    border: 0.5px solid rgba(0, 0, 0, 0.24);
  }
  .rw-input {
    background-color: #fff;
  }
  .rw-select-bordered {
    background-color: #fff;
    border: 0.5px solid rgba(0, 0, 0, 0.24);
    border-left: none;
  }
`;

const PreviewButton = styled.div`
  font-weight: 600;
  cursor: pointer;
  justify-self: flex-end;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 16px;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  color: ${(props) => (props.disabled ? props.theme.gray : 'inherit')};
`;

const OrderTypeLabel = styled.div`
  font-weight: 700;
`;
const OrderTypeSub = styled.div`
  font-weight: 200;
  font-size: 13px;
`;

const CheckboxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  margin-top: 15px;
`;

let ProductInformationForm = (props) => {
  const {
    setMediaSelectionModalIsVisible,

    setIsAvailableAllDay,
    isAvailableAllDay,
    initialValues,
    handleSubmit,
    mediaSelectionModalIsVisible,
    setProductPreviewIsVisible,
    productPreviewIsDisabled,
    isRequesting,
    programType,
    taxCategoryList,
    orderTypesList,
    showTaxCategory,
    orderTypes,
  } = props;

  return (
    <Card>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={(values) => {
          let errors = {};
          if (!values.productInformation.name) {
            errors.name = 'Required';
          }
          if (!values.productInformation.user_description) {
            errors.user_description = 'Required';
          } else if (values.productInformation.user_description.length > 1024) {
            errors.user_description = 'Cannot be greater than 1024 characters';
          } else if (
            values.productInformation.user_description.split(' ').length > 150
          ) {
            errors.user_description = 'Cannot be greater than 150 words';
          }
          if (!values.productInformation.image_url) {
            errors.image_url = 'Required';
          }
          if (values.productInformation.orderType.length < 1) {
            errors.orderType = 'At least one selection is required';
          }
          return errors;
        }}
      >
        {({ values, isValid, dirty, touched, setFieldValue, errors }) => (
          <FormWrapper>
            <NameAndPreview>
              <Field
                name="productInformation.name"
                component={RenderTextField}
                label="Product Name"
                error={
                  !touched.productInformation ||
                  (touched.productInformation &&
                    !touched.productInformation.name) ||
                  values.productInformation.name
                    ? null
                    : 'Required'
                }
                required
                validate={maxLengthValidationForNameField}
              />
              {showTaxCategory ? (
                <TaxCategoryWrapper>
                  <Field
                    component={RenderTextField}
                    style={{ position: 'relative', top: '-3px' }}
                    type="text"
                    name="productInformation.taxCategoryId"
                    label="Tax Rate Product Type *"
                    select
                    variant="outlined"
                    margin="normal"
                    validate={required}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {taxCategoryList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <Tooltip
                    interactive
                    title={
                      <React.Fragment>
                        For more information regarding the types of taxable
                        supplies &nbsp;
                        <a
                          rel="shortcut icon"
                          target="_blank"
                          style={{
                            color: 'white',
                            textDecoration: 'underline',
                          }}
                          href="https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/gst-hst-businesses/charge-collect-which-rate.html"
                        >
                          go to the CRA website
                        </a>
                      </React.Fragment>
                    }
                  >
                    <div style={{ cursor: 'pointer' }}>
                      <i
                        className="fal fa-info-circle"
                        style={{ fontSize: '16px' }}
                      ></i>
                    </div>
                  </Tooltip>
                </TaxCategoryWrapper>
              ) : null}
              {/* <PreviewButton
                disabled={productPreviewIsDisabled}
                onClick={() => setProductPreviewIsVisible(true)}
              >
                <Icon
                  disabled={productPreviewIsDisabled}
                  className="fas fa-eye"
                />
                Preview
              </PreviewButton> */}
            </NameAndPreview>
            <Field
              name="productInformation.user_description"
              component={RenderTextField}
              required
              rows={4}
              label="Product Description"
              error={
                touched.productInformation &&
                touched.productInformation.user_description
                  ? values.productInformation.user_description
                    ? values.productInformation.user_description.length <= 1024
                      ? values.productInformation.user_description.split(' ')
                          .length <= 150
                        ? null
                        : 'Cannot be more than 150 words'
                      : 'Cannot be greater than 1024 characters'
                    : 'Required'
                  : null
              }
            />
            <ChooseImage
              imageUrl={values.productInformation.image_url}
              setIsImageGallaryVisible={setMediaSelectionModalIsVisible}
            />
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
              <div>
                <SmallToggleSwitch
                  checked={isAvailableAllDay}
                  onChange={() => {
                    if (!isAvailableAllDay) {
                      setFieldValue(
                        'productInformation.start_time',
                        moment('00:00:00', 'HH:mm:ss')
                      );
                      setFieldValue(
                        'productInformation.end_time',
                        moment('23:59:59', 'HH:mm:ss')
                      );
                    }
                    setIsAvailableAllDay(!isAvailableAllDay);
                  }}
                  label="Available All Day"
                />
                <div style={{ marginTop: '10px' }}>
                  <SmallToggleSwitch
                    name="productInformation.is_combo_only"
                    checked={values.productInformation.is_combo_only}
                    onChange={(e) =>
                      setFieldValue(
                        'productInformation.is_combo_only',
                        e.target.checked
                      )
                    }
                    label="Combo Only"
                  />
                </div>
                <div style={{ marginTop: '10px' }}>
                  <SmallToggleSwitch
                    name="productInformation.is_alcoholic"
                    checked={values.productInformation.is_alcoholic}
                    onChange={(e) =>
                      setFieldValue(
                        'productInformation.is_alcoholic',
                        e.target.checked
                      )
                    }
                    label="Alcoholic Product"
                  />
                </div>
                <Collapse in={!isAvailableAllDay}>
                  <TimeWidgetsWrapper>
                    <TimeWrapper>
                      <div>Start Time</div>
                      <Field
                        name="productInformation.start_time"
                        component={RenderTimePicker}
                        onChange={(value) =>
                          setFieldValue('productInformation.start_time', value)
                        }
                        hideDate
                        max={new Date('January 1, 3999 23:30:00')}
                      />
                    </TimeWrapper>
                    <TimeWrapper>
                      <div>End Time</div>
                      <Field
                        name="productInformation.end_time"
                        component={RenderTimePicker}
                        onChange={(value) =>
                          setFieldValue('productInformation.end_time', value)
                        }
                        min={
                          new Date(
                            Moment(values.productInformation.start_time).add(
                              '30',
                              'm'
                            )
                          )
                        }
                        hideDate
                      />
                    </TimeWrapper>
                  </TimeWidgetsWrapper>
                </Collapse>
                <div style={{ marginTop: '30px' }}>
                  {programType === 'points' ? (
                    <SmallToggleSwitch
                      name="productInformation.is_points_enabled"
                      checked={values.productInformation.is_points_enabled}
                      onChange={(e) =>
                        setFieldValue(
                          'productInformation.is_points_enabled',
                          e.target.checked
                        )
                      }
                      label="Points is enabled"
                    />
                  ) : null}
                </div>
              </div>

              {/* <div>
                <SmallToggleSwitch
                  checked={isForAllBusinesses}
                  onChange={e => {
                    if (e.target.checked) {
                      setFieldValue(
                        'productBusinesses',
                        allBusinesses.map(business => business.id)
                      );
                    } else {
                      setFieldValue(
                        'productBusinesses',
                        initialValues.productBusinesses
                      );
                    }
                    setIsForAllBusinesses(!isForAllBusinesses);
                  }}
                  label="All Businesses"
                />
                <Collapse in={!isForAllBusinesses}>
                  <ReactSelectWrapper>
                    <Field
                      name="productBusinesses"
                      component={RenderSelect}
                      isMulti
                      options={allBusinesses}
                      getOptionLabel={business => business.display_name}
                      getOptionValue={business => business.id}
                      valueKey="id"
                      placeholder="Select Businesses"
                      setFieldValue={setFieldValue}
                    />
                  </ReactSelectWrapper>
                </Collapse>
              </div> */}
            </div>
            <div>
              <OrderTypeLabel>
                Order Type*
                <OrderTypeSub>Select all that apply</OrderTypeSub>
              </OrderTypeLabel>
              <CheckboxWrapper>
                <Field
                  name="productInformation.orderType"
                  component={RenderCheckBox}
                  options={orderTypesList}
                  error={errors.orderType}
                />
              </CheckboxWrapper>
            </div>
            <ButtonRow style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => handleSubmit(values)}
                disabled={Object.keys(errors).length || isRequesting}
              >
                Save
              </Button>
            </ButtonRow>
            {mediaSelectionModalIsVisible && (
              <MediaModalContainer
                closeModal={() => setMediaSelectionModalIsVisible(false)}
                selectProduct={(id, url) => {
                  setFieldValue('productInformation.image_url', url);
                }}
              />
            )}
          </FormWrapper>
        )}
      </Formik>
    </Card>
  );
};

export default ProductInformationForm;
