import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../appFeedLayoutActions';
import AppFeedLayoutList from './AppFeedLayoutList';
import { withRouter } from 'react-router-dom';

const AppFeedLayoutListContainer = ({
  initiateCreateLayout,
  getListAppFeedLayout,
  listAppFeed,
  editAppFeedLayout,
  deleteAppFeedLayout,
  deepDuplicateFeed,
  setSearchResult,
  search,
  appIdentifier,
  history
}) => {
  useEffect(() => {
    getListAppFeedLayout();
  }, []);

  return (
    <AppFeedLayoutList
      initiateCreateLayout={initiateCreateLayout}
      listAppFeed={listAppFeed}
      editAppFeedLayout={editAppFeedLayout}
      setSearchResult={setSearchResult}
      deepDuplicateFeed={deepDuplicateFeed}
      deleteAppFeedLayout={deleteAppFeedLayout}
      search={search}
      appIdentifier={appIdentifier}
      history={history}
    />
  );
};

const mapStateToProps = ({ appFeedLayoutReducer, accountReducer }) => {
  return {
    listAppFeed: appFeedLayoutReducer,
    search: appFeedLayoutReducer.search,
    appIdentifier: accountReducer.appIdentifier
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(AppFeedLayoutListContainer)
);
