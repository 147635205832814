import React from "react";
import styled from "styled-components";
import Icon from "../../../../../../components/Icon/Icon";
import { SmallToggleSwitch } from "../../../../../../components/ToggleSwitch/ToggleSwitch";

const Card = styled.div`
  background: ${props => (props.disabled ? "#ebebeb" : "white")};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 32px 48px;
  color: ${props => (props.disabled ? "#959595" : "initial")};
  display: grid;
  grid-gap: 26px;
`;

const PreviewButton = styled.div`
  font-weight: 600;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 8px;
  margin-bottom: 6px;
`;

const ToggleWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 28px;
  align-items: center;
`;

const ProductTogglesForm = ({
  is_featured,
  is_available,
  is_active,
  patchProductIsFeatured,
  patchProductIsAvailable,
  patchProductIsActive,
  disabled
}) => (
  <Card disabled={disabled}>
    {/* <PreviewButton>
      <Icon disabled={disabled} className="fas fa-eye" />
      Preview
    </PreviewButton> */}
    <ToggleWrapper>
      <SmallToggleSwitch
        disabled={disabled}
        checked={is_featured}
        onChange={e => patchProductIsFeatured(e.target.checked)}
      />
      <div>Mark as Featured</div>
    </ToggleWrapper>
    <ToggleWrapper>
      <SmallToggleSwitch
        disabled={disabled}
        checked={is_available}
        onChange={e => patchProductIsAvailable(e.target.checked)}
      />
      <div>Product is Available</div>
    </ToggleWrapper>
    <ToggleWrapper>
      <SmallToggleSwitch
        disabled={disabled}
        checked={is_active}
        onChange={e => patchProductIsActive(e.target.checked)}
      />
      <div>Product is Active</div>
    </ToggleWrapper>
  </Card>
);

export default ProductTogglesForm;
