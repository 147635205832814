import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../productsActions';
import ProductBusinessSelectionFormComponent from '../components/ProductBusinessSelectionFormComponent';

const ProductBusinessSelectionFormContainer = props => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [
    confirmCancelModalIsVisible,
    setConfirmCancelModalIsVisible
  ] = useState(false);

  const [allBusinesses, setAllBusinesses] = useState([]);
  const [isAvailableAllLocations, setIsAvailableAllLocations] = useState('');

  useEffect(() => {
    if (props.allBusinesses.length) {
      let businessesModified = [];
      props.allBusinesses.forEach(business => {
        if (business.sub_businesses.length) {
          business.sub_businesses.forEach(subBusiness => {
            if (subBusiness.is_active) {
              businessesModified.push(subBusiness);
            }
          });
        } else {
          if (business.is_active) {
            businessesModified.push(business);
          }
        }
      });
      setAllBusinesses(businessesModified);
    }
  }, []);

  useEffect(() => {
    setIsAvailableAllLocations(
      allBusinesses.length <=
        props.productSelectedBusinessesInitialValues.length
    );
  }, [allBusinesses, props.productSelectedBusinessesInitialValues]);

  return (
    <ProductBusinessSelectionFormComponent
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      disabled={!props.productId}
      initialValues={{
        productSelectedBusinesses: props.productSelectedBusinessesInitialValues
      }}
      confirmCancelModalIsVisible={confirmCancelModalIsVisible}
      setConfirmCancelModalIsVisible={setConfirmCancelModalIsVisible}
      allBusinesses={allBusinesses}
      putProductBusinesses={props.putProductBusinesses}
      businessesAssigned={props.productSelectedBusinesses}
      isAvailableAllLocations={isAvailableAllLocations}
      setIsAvailableAllLocations={setIsAvailableAllLocations}
      allInitialBusinesses={props.allBusinesses}
      productId={props.productId}
      getProductAssignedBusinesses={props.getProductAssignedBusinesses}
    />
  );
};

const mapStateToProps = state => ({
  productId: state.productsReducer.productId,
  productSelectedBusinessesInitialValues:
    state.productsReducer.productSelectedBusinessesInitialValues,
  allBusinesses: state.businessLocationsList.businessLocationsOfMerchant
});

export default connect(
  mapStateToProps,
  actions
)(ProductBusinessSelectionFormContainer);
