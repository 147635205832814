import React from 'react';
import Modal from './Modal';
import { ButtonRow, Button, LoadingButton } from '../Button/Button';
import { LoadingWhite } from '../Loading/Loading';
import { ModalBody } from '../../components/FormElements/FormElements.js';

const ConfirmDeleteModal = ({
  disable,
  requestingDelete,
  cancel,
  deleteAction,
  deleteSuccess
}) => {
  if (deleteSuccess) {
    cancel();
  }
  return (
    <Modal>
      <ModalBody small>
        <p>
          This action is permanent and cannot be undone. Are you sure you want
          to continue?
        </p>
        <ButtonRow justifyContent="space-between">
          <Button
            small
            type="button"
            secondary
            onClick={e => {
              cancel && cancel();
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            Cancel
          </Button>
          {requestingDelete ? (
            <LoadingButton whiteSpinner small type="button" danger>
              Yes, Delete
            </LoadingButton>
          ) : (
            <Button
              disabled={disable}
              small
              type="button"
              onClick={e => {
                deleteAction && deleteAction();
                e.preventDefault();
                e.stopPropagation();
              }}
              danger
            >
              Yes, Delete
            </Button>
          )}
        </ButtonRow>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmDeleteModal;
