import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import {
  renderTimePicker,
  renderCheckbox
} from '../../../../../../components/FormElements/ReduxForm';
import Moment from 'moment';

const HoursSelectionGrid = styled.div`
  display: grid;
  grid-template-columns:1fr 90px  4fr;
  grid-column-gap: 10px;
  min-height: 80px;
`;

const TimeSelectionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  align-self: center;
`;

const DayName = styled.p`
  font-weight: 600;
  align-self: center;
  text-transform: capitalize;
  font-size: 14px !important;
`;

const HoursCheckbox = styled.div`
  align-self: center;
  text-align: center;
`;

const HoursField = styled.div`
  text-align: left;
  font-size: 12px;
`;

const StyledTextHourField = styled(Field)`
  font-size: 16px;
  background-color: ${props => props.theme.lightGray};
  border: none;
  border-radius: none;
  height: 40px;
  width: 128px;
  padding: 10px;
`;

const ClosedText = styled.div`
  align-self: center;
  font-weight: 600;
  color: #979eaa;
  font-size: 14px;
`;

const SPECIAL_HOURS = 'specialHours';
const REGULAR_HOURS = 'regularHours';

const StoreHoursPanel = props => {
  const { storeHours, handleHourIsOpenChange, formFieldName } = props;
  return (
    <div>
      {storeHours.map((storeHour, index) => {
        if (
          (formFieldName == SPECIAL_HOURS && storeHour.isSpecial) ||
          formFieldName == REGULAR_HOURS
        ) {
          if (storeHour.isOpen) {
            return (
              <HoursSelectionGrid key={index}>
                <HoursCheckbox>
                  <Field
                    label=""
                    component={renderCheckbox}
                    name={`${formFieldName}[${index}].isOpen`}
                    onChange={() => handleHourIsOpenChange(index)}
                  />
                </HoursCheckbox>
                <DayName>{storeHour.dayName}</DayName>
                <TimeSelectionGrid>
                  <HoursField>
                    Open
                    <StyledTextHourField
                      name={`${formFieldName}[${index}].openTime`}
                      component={renderTimePicker}
                      hideDate
                      max={new Date('January 1, 3999 23:30:00')}
                    />
                  </HoursField>
                  <HoursField>
                    Close
                    <StyledTextHourField
                      name={`${formFieldName}[${index}].closeTime`}
                      component={renderTimePicker}
                      // min={new Date(Moment(storeHour.openTime).add('30', 'm'))}
                      hideDate
                    />
                  </HoursField>
                </TimeSelectionGrid>
              </HoursSelectionGrid>
            );
          } else {
            return (
              <HoursSelectionGrid key={index}>
                <HoursCheckbox>
                  <Field
                    label=""
                    component={renderCheckbox}
                    name={`${formFieldName}[${index}].isOpen`}
                  />
                </HoursCheckbox>
                <DayName>{storeHour.dayName}</DayName>
                <ClosedText>Closed</ClosedText>
              </HoursSelectionGrid>
            );
          }
        }
      })}
    </div>
  );
};

export default StoreHoursPanel;
