import React, { useEffect, Fragment, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actions from '../variantsActions';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import { Button } from '../../../../components/Button/Button';
import VariantList from './components/VariantList';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '../../../../components/Icon/Icon';
import {sort} from '../../../../utils/sort/sort';

const Wrapper =  styled.div`
background-color: white;
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
border-radius: 12px;
display: grid;
grid-gap: 20px;
`;

const TopDiv = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  justify-content: space-between;
  margin: 37px;
`;

const TextSearch = styled.div`
  // padding: 20px;
`;

let VariantListPage = ({
  allVariants,
  meta,
  requestParams,
  isGetVariantRequest,
  updateRequestParams,
  changeComponentType,
  toggleIsActive,
  isVariantAvailable,
  setEditVariant,
  variantIsSaving,
  updateVariantsRequestParams,
  updateSortedVariantsList
}) => {

  useEffect(() => {
    track.page('menu-management-variant-list');
    window.scrollTo(0, 0);
  }, []);


  const [searchString, setSearchString] = useState('');

  const handleVariantsSearch = searchString => {
    updateVariantsRequestParams({
      page_number: 1,
      page_size: requestParams.page_size
    });
    setSearchString(searchString);
  };

  const handleSort = (sortArgs) => {
    let sortOrder = '';
    let order = '';
    const currentOptionAsArray = requestParams.sorting_option.split('-');
    if (currentOptionAsArray[0] === sortArgs.value) {
     order = currentOptionAsArray[1] === 'asc' ? 'desc' : 'asc';
     sortOrder = `${sortArgs.value}-${order}`
     
    }else{
      order = 'asc'
      sortOrder = `${sortArgs.value}-${order}`;
    }
     if(allVariants){
       const result = sort(allVariants, {...sortArgs, sortOrder : `${order}`});
       updateVariantsRequestParams({
         page_number: 1,
         page_size: requestParams.page_size
       });
       updateSortedVariantsList(result, sortOrder);
     }
   };

  return (
    <Fragment>
        <BreadCrumb />
      <Wrapper>
        <TopDiv>
          <TextSearch>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon className="fal fa-search" />
                    </InputAdornment>
                  )
                }}
                style={{ width: '100%' }}
                variant="outlined"
                type="text"
                onChange={e => handleVariantsSearch(e.target.value)}
                placeholder="Search variants"
              />
          </TextSearch>
          <Button onClick={() => changeComponentType('create')}>
            Create Variant
          </Button>
        </TopDiv> 

        <VariantList
          variantIsSaving={variantIsSaving}
          allVariants={allVariants}
          requestParams={requestParams}
          isGetVariantRequest={isGetVariantRequest}
          toggleIsActive={toggleIsActive}
          setEditVariant={setEditVariant}
          isVariantAvailable={isVariantAvailable}
          searchString={searchString}
          handleSort={handleSort}
        />
      </Wrapper>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  allVariants: state.variantsReducer.allVariants,
  requestParams: state.variantsReducer.requestParams,
  isGetVariantRequest: state.variantsReducer.isGetVariantRequest,
  isVariantAvailable: state.variantsReducer.isVariantAvailable,
  variantIsSaving: state.variantsReducer.variantIsSaving
});
export default connect(mapStateToProps, actions)(VariantListPage);
