import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import theme from './theme';
import { ThemeProvider, injectGlobal } from 'styled-components';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Analytics from 'react-router-ga';
import store from './configureStore';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import 'react-widgets/dist/css/react-widgets.css';
import Full from './modules/Full/Full';
import Login from './modules/Account/Login';
import Signout from './modules/Account/Signout';
import NewUserLoginContainer from './modules/NewUserLoginPage/NewUserLoginContainer';
import Firebase, { FirebaseContext } from './modules/Firebase';
import './utils/css/icons.css';

import ResetPasswordRequest from './modules/Account/ResetPasswordRequest';
import ChangePassword from './modules/Account/ChangePassword';

const trackingId = ENV === 'production' ? 'UA-116579211-3' : 'UA-116579211-2';

const styledColors = theme();

const muiTheme = createMuiTheme({
  palette: {
    textColor: styledColors.darkBlue,
    primary: { main: styledColors.blue },
    secondary: { main: styledColors.darkBlue },
    tertiary: { main: styledColors.tertiaryBlue }
  },
  textField: {
    hintColor: styledColors.darkBlue,
    borderColor: styledColors.darkBlue,
    focusColor: styledColors.darkBlue
  },
  overrides: {
    MuiRadio: {
      '&$checked': {
        color: `styledColors.checkBlue !important;` //without adding important, this color is overridden by theme secondary color, which is really gross as this is the overrides object
      }
    }
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    useNextVariants: true
  }
});

injectGlobal`
  html, body {
    font-size: 16px;
    color: ${styledColors.darkBlue};
    font-family: 'Montserrat', sans-serif;
    background-color: ${styledColors.lightGray};
  }
  input[type="text"], textarea {
    -webkit-appearance: textfield;
  }
  h1, h2, h3, h4, h5, h6 {
    color: ${styledColors.darkBlue};
  }

  #intercom-container {
    display: none !important;
  }
  .intercom-lightweight-app {
    display: none! important;
  }
`;

if (top.location.href == self.location.href) {
  ReactDOM.render(
    <Provider store={store}>
      <FirebaseContext.Provider value={new Firebase()}>
        <ThemeProvider theme={theme(store.getState().appFeed.feedStyles)}>
          <MuiThemeProvider theme={muiTheme}>
            <BrowserRouter>
              <Analytics id={trackingId}>
                <Switch>
                  <Route exact path="/" component={Login} />
                  <Route path="/login" component={Login} />
                  <Route path="/signout" component={Signout} />
                  <Route path="/request-password-reset" component={ResetPasswordRequest} />
                  <Route exact path="/reset-password/:token" component={ChangePassword} />
                  <Route
                    exact
                    path="/new-user-login/:token"
                    name="New User Login"
                    component={NewUserLoginContainer}
                  />
                  <Full />
                </Switch>
              </Analytics>
            </BrowserRouter>
          </MuiThemeProvider>
        </ThemeProvider>
      </FirebaseContext.Provider>
    </Provider>,
    document.getElementById('root')
  );
}
