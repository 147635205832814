import {
  GET_CUSTOMERS_OF_MERCHANT_REQUEST,
  GET_CUSTOMERS_OF_MERCHANT_SUCCESS
} from './customerListTypes';

import api from '../../../api';
import renderQueryString from '../../../utils/renderQueryString';

export function getCustomersOfMerchant(params) {
  return function(dispatch) {
    dispatch({
      type: GET_CUSTOMERS_OF_MERCHANT_REQUEST
    });
    return api.get(`/customers${renderQueryString(params)}`).then(response => {
      dispatch({
        type: GET_CUSTOMERS_OF_MERCHANT_SUCCESS,
        customers: response.data.data,
        meta: response.data.meta
      });
    });
  };
}
