import {
  GET_OFFERS_REQUEST,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_FAILURE,
  TOGGLE_OFFER_AVAILABILITY_SUCCESS,
  INITIATE_CREATE_OFFER,
  CREATE_OFFER_REQUEST,
  CREATE_OFFER_SUCCESS,
  CREATE_OFFER_FAILURE,
  INITIATE_EDIT_OFFER,
  EDIT_OFFER_REQUEST,
  EDIT_OFFER_SUCCESS,
  EDIT_OFFER_FAILURE,
  RESET_OFFER_STATE,
  UPDATE_SORTED_OFFERS_LIST
} from './offerTypes';

const initialState = {
  allOffers: [],
  requestingGetOffers: true,
  isCreatingOffer: false,
  isEditingOffer: false,
  requestingCreateOffer: false,
  requestingEditOffer: false,
  editingOffer: null,
  editingOfferId: null,
  meta: { page_number: 1, page_size: 25 },
  options: null,
  requestParams: {
    page_number: 1,
    page_size: '25',
    sorting_option: 'title-asc'
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_OFFERS_REQUEST:
      return { ...state, requestingGetOffers: true };

    case GET_OFFERS_SUCCESS:
      return {
        ...state,
        requestingGetOffers: false,
        allOffers: action.allOffers,
        options: action.options.sources.map((item, index) => ({
          label: item,
          value: item
        })),
        meta: action.meta
      };
    case GET_OFFERS_FAILURE:
      return {
        ...state,
        requestingGetOffers: false,
        error: action.error,
        allOffers: initialState.allOffers
      };
    case TOGGLE_OFFER_AVAILABILITY_SUCCESS:
      return {
        ...state,
        allOffers: state.allOffers.map(offer =>
          offer.offer_details.offer_id === action.offer.offer_details.offer_id
            ? {
                ...offer,
                offer_details: {
                  ...offer.offer_details,
                  is_active: !offer.offer_details.is_active
                }
              }
            : offer
        )
      };
    case INITIATE_CREATE_OFFER:
      return {
        ...state,
        isCreatingOffer: true,
        isEditingOffer: false,
        requestingCreateOffer: false,
        editingOffer: null,
        editingOfferId: null
      };
    case CREATE_OFFER_REQUEST:
      return {
        ...state,
        requestingCreateOffer: true
      };
    case CREATE_OFFER_SUCCESS:
      return {
        ...state,
        requestingCreateOffer: false,
        isCreatingOffer: false,
        isEditingOffer: false,
        editingOffer: null,
        editingOfferId: null
      };
    case CREATE_OFFER_FAILURE:
      return {
        ...state,
        requestingCreateOffer: false
      };
    case INITIATE_EDIT_OFFER:
      return {
        ...state,
        isEditingOffer: true,
        editingOffer: action.editingOffer,
        editingOfferId: action.editingOfferId
      };
    case EDIT_OFFER_REQUEST:
      return {
        ...state,
        requestingEditOffer: true
      };
    case EDIT_OFFER_SUCCESS:
      return {
        ...state,
        requestingCreateOffer: false,
        requestingEditOffer: false,
        isCreatingOffer: false,
        isEditingOffer: false,
        editingOffer: null,
        editingOfferId: null
      };
    case EDIT_OFFER_FAILURE:
      return {
        ...state,
        requestingEditOffer: false
      };
    case 'UPDATE_OFFERS_REQUEST_PARAMS':
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload
        }
      };
    case RESET_OFFER_STATE:
      return initialState;
    case UPDATE_SORTED_OFFERS_LIST:
      return {
        ...state,
        allOffers: action.offersList,
        requestParams: {
          ... state.requestParams,
          sorting_option: action.sortOrder
        }
      };
    default:
      return state;
  }
}
