import {
  GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_REQUEST,
  GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_SUCCESS,
  GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_FAILURE,
  PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_REQUEST,
  PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_SUCCESS,
  PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_FAILURE,
  IS_DELETE_SPECIAL_HOURS_CONFIRM_MODAL_OPEN,
  SPECIAL_HOURS_TO_DELETE,
  DELETE_SPECIAL_HOURS_REQUEST,
  DELETE_SPECIAL_HOURS_SUCCESS,
  IS_CREATE_AND_EDIT_SPECIAL_HOURS_MODAL_OPEN,
  SPECIAL_HOURS_TO_EDIT,
  UPDATE_SPECIAL_HOURS_REQUEST,
  UPDATE_SPECIAL_HOURS_SUCCESS,
  UPDATE_SPECIAL_HOURS_FAILURE,
  RESET_BUSINESS_LOCATION_DETAILS_REDUCER_STATE,
  SET_BUSINESS_WIZARD_IS_VISIBLE,
  CREATE_BUSINESS_LOCATION_REQUEST,
  CREATE_BUSINESS_SUCCESS,
  POST_BUSINESS_ADDRESS_AND_DETAILS_SUCCESS,
  PUT_REGULAR_HOURS_SUCCESS,
  INITIATE_CREATE_LOCATION,
  INITIATE_EDIT_LOCATION,
  UPDATE_BUSINESS_SUCCESS,
  PUT_REGULAR_HOURS_FAILURE,
  GET_PAYMENT_INTEGRATION_SCHEMA_SUCESS,
  GET_PAYMENT_INTEGRATION_SUCESS,
  UPDATE_PAYMENT_INTEGRATION_SUCESS
} from './types';

const defaultState = {
  shouldComponentBeLoading: false,
  businessLocationDetails: false,
  isDeleteSpecialHoursConfirmModalOpen: false,
  specialHoursToDelete: [],
  isDeleteSpecialHoursRequesting: false,
  isCreateAndEditSpecialHoursModalOpen: false,
  specialHoursToEdit: null,
  isFormSubmitting: false,
  currencyList: ['CAD','USD'],
  countryList: ['CANADA', 'USA'],
  isCreatingLocation: false,
  isEditingLocation: false,
  isEditingAddress: false,
  businessInformationInitialValues: {},
  addressInformationInitialValues: {},
  regularHours: [],
  specialHours: [],
  isrequestingCreateBusiness: false,
  businessId: '',
  paymentIntegration: {}
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_REQUEST:
      return {...state, shouldComponentBeLoading: true};
    case GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_SUCCESS:
      return {
        ...state,
        shouldComponentBeLoading: false,
        ...action.payload
      };
    case PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_REQUEST:
      return {...state, shouldComponentBeLoading: true};
    case PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_SUCCESS:
      return {...state, 
              shouldComponentBeLoading: false,
              addressInformationInitialValues: {...action.data}
            };
    case PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_FAILURE:
      return {
        ...state,
        shouldComponentBeLoading: false
      };
    case GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_FAILURE:
      return {
        ...state,
        shouldComponentBeLoading: false,
        businessLocationDetails: false
      };
    case IS_DELETE_SPECIAL_HOURS_CONFIRM_MODAL_OPEN:
      return {
        ...state,
        isDeleteSpecialHoursConfirmModalOpen:
          action.isDeleteSpecialHoursConfirmModalOpen
      };
    case SPECIAL_HOURS_TO_DELETE:
      return {
        ...state,
        specialHoursToDelete: action.specialHoursToDelete
      };
    case DELETE_SPECIAL_HOURS_REQUEST:
      return {
        ...state,
        isDeleteSpecialHoursRequesting: true
      };
    case DELETE_SPECIAL_HOURS_SUCCESS:
      return {
        ...state,
        isDeleteSpecialHoursRequesting: false,
        isDeleteSpecialHoursConfirmModalOpen: false,
        specialHoursToDelete: []
      };
    case IS_CREATE_AND_EDIT_SPECIAL_HOURS_MODAL_OPEN:
      return {
        ...state,
        isCreateAndEditSpecialHoursModalOpen:
          action.isCreateAndEditSpecialHoursModalOpen
      };
    case SPECIAL_HOURS_TO_EDIT:
      return {...state, specialHoursToEdit: action.specialHoursToEdit};
    case UPDATE_SPECIAL_HOURS_REQUEST:
      return {
        ...state,
        isFormSubmitting: true
      };
    case UPDATE_SPECIAL_HOURS_SUCCESS:
      return {
        ...state,
        isFormSubmitting: false,
        isCreateAndEditSpecialHoursModalOpen: false
      };
    case UPDATE_SPECIAL_HOURS_FAILURE:
      return {
        ...state,
        isFormSubmitting: false
      };
    case RESET_BUSINESS_LOCATION_DETAILS_REDUCER_STATE:
      return defaultState;
    case INITIATE_CREATE_LOCATION:
      return {
        ...state,
        businessInformationInitialValues: {
          ...action.data.businessInformationInitialValues
        },
        addressInformationInitialValues: {
          ...action.data.addressInformationInitialValues
        },
        isCreatingLocation: true,
        isEditingLocation: false
      };
    case INITIATE_EDIT_LOCATION:
      return {
        ...state,
        isEditingLocation: true,
        isCreatingLocation: false,
        businessId: action.businessId,
        shouldComponentBeLoading: true
      }
    case CREATE_BUSINESS_LOCATION_REQUEST:
      return{
        ...state,
        isrequestingCreateBusiness: true
      }
    case CREATE_BUSINESS_SUCCESS:
      return{
        ...state,
        businessId: action.businessId,
        businessInformationInitialValues: action.businessInformation,
        isrequestingCreateBusiness: false,
        businessLocationDetails:{
          ... state.businessLocationDetails,
         business_id : action.businessId
        }
      }
    case POST_BUSINESS_ADDRESS_AND_DETAILS_SUCCESS:
    return{
      ...state,
      addressInformationInitialValues: action.data,
      isEditingAddress: true
    }
    case PUT_REGULAR_HOURS_SUCCESS:
      return{
        ...state,
        regularHours: action.regularHours
      }
    case PUT_REGULAR_HOURS_FAILURE:
      return{
        ...state
      }
    case UPDATE_BUSINESS_SUCCESS:
      return{
        ...state,
        businessInformationInitialValues: action.businessInformation
      }
    case GET_PAYMENT_INTEGRATION_SCHEMA_SUCESS:
      return{
        ...state,
        paymentIntegrationSchema: action.schema
      }
    case GET_PAYMENT_INTEGRATION_SUCESS:
      return{
        ...state,
        paymentIntegration: action.paymentIntegration
      }
    case UPDATE_PAYMENT_INTEGRATION_SUCESS:
      return{
        ...state,
        paymentIntegration: action.paymentIntegration
      }
    default:
      return state;
  }
}
