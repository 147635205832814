import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  loadCreateCategoryPage,
  resetCategoryReduxState
} from './categoryActions';
import CategoryListContainer from './CategoryList/CategoryListContainer';
import CategoryDetailContainer from './CategoryDetail/CategoryDetailContainer';

const CategoryContainer = ({
  currentPage,
  loadCreateCategoryPage,
  resetCategoryReduxState
}) => {
  useEffect(() => {
    return () => {
      resetCategoryReduxState();
    };
  }, []);
  if (currentPage === 'list') {
    return (
      <CategoryListContainer
        handleCreateButtonClick={() => loadCreateCategoryPage()}
      />
    );
  } else if (currentPage === 'create') {
    return <CategoryDetailContainer />;
  } else if (currentPage === 'edit') {
    return <CategoryDetailContainer isEdit={true} />;
  }
};

const mapStateToProps = state => ({
  currentPage: state.categoryReducer.currentPage
});

export default connect(mapStateToProps, {
  loadCreateCategoryPage,
  resetCategoryReduxState
})(CategoryContainer);
