import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import {
  RenderTextField,
  required,
  RenderSelect
} from '../../../../../components/FormElements/FormikElements';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 7.5%;
  align-items: center;
  margin-top: 10px;
`;

const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 50px;
  align-items: center;
`;

const FirstTimeOffer = ({ auto_redeem, setFieldValue }) => {
  return (
    <div>
      <Subtitle>Offer Details</Subtitle>
      <Wrapper>
        <Field
          name='offer_details.template_values.total_amount'
          component={RenderTextField}
          isPrice={true}
          label='*Dollar Amount'
          validate={required}
          onKeyDown={evt =>
            (evt.key === 'e' || evt.key === '-' || evt.key === '+') &&
            evt.preventDefault()
          }
        />
        <CheckboxWrapper>
          {/* <Checkbox
            onChange={e =>
              setFieldValue('offer_details.auto_redeem', e.target.checked)
            }
            checked={auto_redeem}
            inputProps={{
              'aria-label': 'secondary checkbox'
            }}
          />
          <div>Auto Redeem</div> */}
        </CheckboxWrapper>
      </Wrapper>
    </div>
  );
};

export default FirstTimeOffer;
