import {
  GET_OPTION_PRICES_SUCCESS,
  FILTER_OPTIONS_BY_VARIANTS,
  PUT_OPTION_PRICES_REQUEST,
  PUT_OPTION_PRICES_SUCCESS,
  RESET_PRICE_REDUX_STATE,
  GET_ITEM_OVERRIDES_REQUEST,
  GET_ITEMS_OVERRIDES,
  GET_FORMATTED_BUSINESSES,
  GET_OPTION_PRICES_REQUEST,
  SET_IS_OPTION_OVERRIDES_MODAL
} from './priceTypes';

const initialState = {
  optionPrices: null,
  items: null,
  isRequesting: false,
  itemOverrides: null,
  formattedBusinesses: null,
  isRequestingItemOverrides: false,
  isOptionOverridesModal: {
    isShowing: false,
    item: null,
    itemIndex: null
  }
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_OPTION_PRICES_REQUEST:
      return {
        ...state,
        isRequesting: true,
        items: null
      };
    case GET_OPTION_PRICES_SUCCESS:
      return {
        ...state,
        optionPrices: action.optionPrices,
        isRequesting: false
      };
    case FILTER_OPTIONS_BY_VARIANTS:
      return {
        ...state,
        items: action.items
      };
    case PUT_OPTION_PRICES_REQUEST:
      return {
        ...state,
        isRequesting: true
      };
    case PUT_OPTION_PRICES_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: action.items
      };
    case GET_ITEMS_OVERRIDES:
      return {
        ...state,
        itemOverrides: action.payload,
        isRequestingItemOverrides: false
        // items: state.items.map((item, index) => {
        //   const overridesData = action.payload[index];
        //   const set = new Set();
        //   overridesData.forEach(data => set.add(data.business_id));

        //   return {
        //     ...item,
        //     overrides: set.size()
        //   };
        // })
      };
    case GET_FORMATTED_BUSINESSES:
      return {
        ...state,
        formattedBusinesses: action.payload
      };
    case GET_ITEM_OVERRIDES_REQUEST:
      return {
        ...state,
        isRequestingItemOverrides: true
      };
    case SET_IS_OPTION_OVERRIDES_MODAL:
      return {
        ...state,
        isOptionOverridesModal: {
          isShowing: action.payload.isShowing,
          item: action.payload.item,
          itemIndex: action.payload.itemIndex
        }
      };
    case RESET_PRICE_REDUX_STATE:
      return initialState;
    default:
      return state;
  }
}
