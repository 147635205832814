import React from 'react';
import Modal from '../../../components/Modal/Modal';
import { Button} from '../../../components/Button/Button';
import styled from 'styled-components';
import {Redirect} from 'react-router-dom';

const Wrapper = styled.h2`
  display: grid;
  grid-gap: 20px;
  margin: 40px 20px 40px 60px;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 2fr 1fr;
`;

const PageHeader = styled.h2`
  font-weight: 600;
  font-size: 30px;
`;
const Content = styled.h2`
  font-size: 15px;
  display: grid;
  grid-gap: 40px;
  width: 400px;
`;

const LockScreenImage = styled.div`
  width: 130px;
  heigt: 130px;
`;

const CloseButton = styled.div`
  margin: 20px;
  float: right;
  cursor: pointer;

`;

const PromoteTierUpgrade = ({
  setPromoteTierUpgradeModalIsVisible
}) => {
  return (
    <Modal>
        <CloseButton onClick= {() => setPromoteTierUpgradeModalIsVisible(false)}> 
            <i className="fas fa-times-circle" style={{fontSize: '30px', color:'#242424'}}></i> 
        </CloseButton>
        <Wrapper>
            <PageHeader>Upgrade to access this feature</PageHeader>
            <ContentWrapper>
            <Content>
                <p>The feature you are trying to access is part of our enhanced package. 
                    Please contact us to learn more about your options.
                </p>
                <Button 
                  onClick={() =>  window.open('https://smoothcommerce.tech/contact-us/', '_blank')}
                  style={{width:'20%'}}>
                  Contact Us
                </Button>
            </Content>
            <LockScreenImage>
              <img 
                style={{width: 'inherit'}}
                src='https://s3.amazonaws.com/assets.smoothpay.com/web/portal-assets/illustration-copy%403x.png'
                alt='lock screen image'
                />
            </LockScreenImage>
          </ContentWrapper>
        </Wrapper>
    </Modal>
  );
};

export default PromoteTierUpgrade;
