import React from 'react';
import { Field } from 'formik';
import {
  RenderTextField,
  required,
} from '../../../../../../components/FormElements/FormikElements';
import {
  AddProductsButton,
  ComboItemDiv,
  ItemNameField,
  ShowComboItemsText,
  IconEllipsis,
  RemoveComboGroupName,
  ItemRequiredWrapper,
} from '../styles/style';
import AddProductsModal from './AddProductsModal';
import ComboOverridesModal from './ComboOverridesModal';
import SingleComboProductsDiv from './SingleComboProductsDiv';
import Icon from '../../../../../../components/Icon/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { Switch } from '@material-ui/core';

const SingleComboItem = ({
  comboItems,
  arrayHelpers,
  setCombosOverrideModalState,
  toggleCombosOverridesModal,
  isCombosOverridesModal,
  combosOverrideModalState,
  toggleShowProductsState,
  showProductsState,
  beautifyProductVariantNames,
  toggleChargeExtraModal,
  removeProductVariant,
  toggleProductModal,
  isChargeExtraModal,
  isProductModal,
  chargeExtraModalState,
  setChargeExtraModalState,
  initValues,
  setFieldValue,
  handleReset,
  values,
  productsModalState,
  setProductsModalState,
  getProductsCategories,
  productsCategories,
  getProductsVariants,
  productsVariants,
  isRequestingGetProductsVariants,
  allProducts,
  getAllProducts,
  getCombosOptionsOverrides,
  combosOptionsOverride,
  isRequestingGetCombosOptionsOverrides,
  options,
  initialValues,
  isComboItemsSubmitSuccess,
  isRequestingGetAllProducts,
}) => {
  const activeComboItems = comboItems.filter(
    (comboItem) => comboItem.is_active
  );
  return (
    <div>
      {activeComboItems.length !== 0 ? (
        <div>
          <ComboItemDiv>
            {comboItems.map((comboItem, index) => {
              return (
                comboItem.is_active && (
                  <div key={`comboItems_${index}`}>
                    <ItemNameField>
                      <Field
                        name={`comboItems[${index}].label`}
                        component={RenderTextField}
                        label="Combo Group Name"
                        error={
                          arrayHelpers.form.touched.comboItems &&
                          arrayHelpers.form.touched.comboItems[index] &&
                          arrayHelpers.form.touched.comboItems[index].label &&
                          !arrayHelpers.form.values.comboItems[index].label
                            ? 'Required'
                            : null
                        }
                        validate={required}
                      />
                      <div>
                        <Tooltip title="Edit Customization Rules">
                          <span>
                            <IconEllipsis
                              className="fas fa-ellipsis-v"
                              style={{ fontSize: '20px' }}
                              onClick={() => {
                                setCombosOverrideModalState({
                                  comboItemId: comboItem.id,
                                });
                                toggleCombosOverridesModal();
                              }}
                              disabled={!comboItem.productVariants.length}
                            />
                          </span>
                        </Tooltip>

                        {isCombosOverridesModal &&
                          combosOverrideModalState.comboItemId ===
                            comboItem.id && (
                            <ComboOverridesModal
                              toggleCombosOverridesModal={
                                toggleCombosOverridesModal
                              }
                              comboItem={comboItem}
                              getCombosOptionsOverrides={
                                getCombosOptionsOverrides
                              }
                              combosOptionsOverride={combosOptionsOverride}
                              isRequestingGetCombosOptionsOverrides={
                                isRequestingGetCombosOptionsOverrides
                              }
                              options={options}
                              comboItemIndex={index}
                              comboItems={comboItems}
                              handleReset={handleReset}
                              initialValues={initialValues}
                              initValues={initValues}
                              setFieldValue={setFieldValue}
                              comboItemFormik={
                                arrayHelpers.form.values.comboItems[index]
                              }
                              arrayHelpers={arrayHelpers}
                              // onSave={values => {
                              //   setFieldValue(
                              //     `comboItems[${index}].combosOptionsOverride`,
                              //     values
                              //   );
                              // }}
                            />
                          )}
                      </div>
                      {
                        <RemoveComboGroupName
                          onClick={() => {
                            if (comboItem.id) {
                              setFieldValue(
                                `comboItems[${index}].is_active`,
                                false
                              );
                            } else {
                              arrayHelpers.remove(index);
                            }
                          }}
                        >
                          <i className="fal fa-trash-alt" />
                        </RemoveComboGroupName>
                      }
                    </ItemNameField>
                    <ItemRequiredWrapper>
                      <span>Combo Group is Required</span>
                      <Switch
                        checked={comboItem.is_required}
                        onChange={() =>
                          setFieldValue(
                            `comboItems[${index}].is_required`,
                            !comboItem.is_required
                          )
                        }
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </ItemRequiredWrapper>
                    <div>
                      {!comboItem.productVariants.length ? (
                        <p
                          style={{
                            fontStyle: 'italic',
                            marginBottom: '20px',
                            fontSize: '14px',
                          }}
                        >
                          There are no customization for this combo group
                        </p>
                      ) : (
                        <div>
                          <ShowComboItemsText
                            onClick={() => {
                              toggleShowProductsState(comboItem.id);
                            }}
                          >
                            {showProductsState.includes(comboItem.id)
                              ? 'Hide'
                              : 'Show'}{' '}
                            {comboItem.productVariants.length} customization(s)
                            included
                            <i
                              style={{ marginLeft: '15px' }}
                              className={`fas ${
                                showProductsState.includes(comboItem.id)
                                  ? 'fa-sort-up'
                                  : 'fa-sort-down'
                              }`}
                            ></i>
                          </ShowComboItemsText>
                          {showProductsState.includes(comboItem.id) &&
                            comboItem.productVariants.map(
                              (productVariant, productVariantIndex) => (
                                <SingleComboProductsDiv
                                  key={`comboItem_${comboItems}_${productVariantIndex}`}
                                  productVariant={productVariant}
                                  productVariantIndex={productVariantIndex}
                                  comboItem={comboItem}
                                  index={index}
                                  arrayHelpers={arrayHelpers}
                                  beautifyProductVariantNames={
                                    beautifyProductVariantNames
                                  }
                                  toggleChargeExtraModal={
                                    toggleChargeExtraModal
                                  }
                                  removeProductVariant={removeProductVariant}
                                  isChargeExtraModal={isChargeExtraModal}
                                  chargeExtraModalState={chargeExtraModalState}
                                  setChargeExtraModalState={
                                    setChargeExtraModalState
                                  }
                                  initValues={initValues}
                                  setFieldValue={setFieldValue}
                                  handleReset={handleReset}
                                  values={values}
                                />
                              )
                            )}
                        </div>
                      )}

                      <AddProductsButton
                        onClick={() => {
                          setProductsModalState({
                            comboItemId: comboItem.id,
                            productVariants: comboItem.productVariants,
                            itemLabel: comboItem.label,
                          });
                          toggleProductModal();
                        }}
                        disabled={
                          !comboItem.id &&
                          !isComboItemsSubmitSuccess &&
                          !comboItem.productVariants.length
                        }
                      >
                        + Add Products
                        {!comboItem.id && !isComboItemsSubmitSuccess && (
                          <Tooltip title="Combo Group should be saved before adding products">
                            <Icon
                              className="fal fa-info-circle"
                              style={{
                                cursor: 'inherit',
                                marginLeft: '10px',
                                fontSize: '16px',
                              }}
                            />
                          </Tooltip>
                        )}
                      </AddProductsButton>

                      {isProductModal &&
                        productsModalState.comboItemId === comboItem.id && (
                          <AddProductsModal
                            toggleProductModal={toggleProductModal}
                            productsModalState={productsModalState}
                            getProductsCategories={getProductsCategories}
                            productsCategories={productsCategories}
                            getProductsVariants={getProductsVariants}
                            productsVariants={productsVariants}
                            isRequestingGetProductsVariants={
                              isRequestingGetProductsVariants
                            }
                            comboItem={comboItem}
                            allProducts={allProducts}
                            getAllProducts={getAllProducts}
                            index={index}
                            setFieldValue={setFieldValue}
                            arrayHelpers={arrayHelpers}
                            isRequestingGetAllProducts={
                              isRequestingGetAllProducts
                            }
                          />
                        )}
                    </div>
                  </div>
                )
              );
            })}
          </ComboItemDiv>
        </div>
      ) : (
        <div style={{ paddingBottom: '15px' }}>
          There are no Combo Items to show
        </div>
      )}
    </div>
  );
};

export default SingleComboItem;
