import {
  REQUEST_REPORT_DATA,
  SET_REPORT_DATA,
  UPDATE_REPORT_INITIAL_PARAMS,
  CACHE_REPORT_DATA,
  SET_CACHE_DATA,
  RESET_REPORT_TABLE_REDUCER,
} from './ReportTableTypes';
import Moment from 'moment';

const defaultState = {
  isRequestingData: true,
  filters: null,
  rows: [],
  columns: [],
  columnNames: [],
  meta: null,
  initialParams: {
    page_number: 1,
    page_size: 10,
    export: false,
    start_datetime: `${Moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD')} 00:00:00`,
    end_datetime: `${Moment().format('YYYY-MM-DD')} 23:59:59`,
  },
  cache: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_REPORT_DATA:
      return {
        ...state,
        isRequestingData: true,
      };
    case SET_REPORT_DATA:
      return {
        ...state,
        isRequestingData: false,
        rows: action.payload ? action.payload.rows : [],
        columns: action.payload ? action.payload.columns : [],
        columnNames: action.payload ? action.payload.columnNames : [],
        meta: action.payload ? action.payload.meta : null,
        initialParams: action.payload.initialParams
          ? action.payload.initialParams
          : state.initialParams,
      };
    case UPDATE_REPORT_INITIAL_PARAMS:
      return {
        ...state,
        initialParams: {
          ...state.initialParams,
          ...action.params,
        },
      };
    case CACHE_REPORT_DATA:
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.key]: action.payload,
        },
      };
    case SET_CACHE_DATA:
      return {
        ...state,
        cache: action.cache ? action.cache : state.cache,
      };
    case RESET_REPORT_TABLE_REDUCER:
      return defaultState;
    default:
      return state;
  }
};
