import React from 'react';
import styled from 'styled-components';
import Drawers from './components/Drawers';
import CalorieString from './components/CalorieString';
import { PrimaryButton, ButtonRow } from './components/Buttons';
import { Title1, Body } from './components/Typography';
import Modal from '../../../../../../components/Modal/Modal';
import toPriceFormat from '../../../../../../utils/toPriceFormat';

const Wrapper = styled.div`
  position: relative;
  max-width: 786px;
  margin: auto;
  overflow: scroll;
  @media (max-width: 786px) {
    -webkit-overflow-scrolling: touch;
  }
`;

const InfoWrapper = styled.div`
  padding: 48px;
  @media (max-width: 786px) {
    padding: 20px;
    padding-bottom: 80px;
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  background-color: #d3d3d3;
  @media (max-width: 786px) {
    height: inherit;
  }
`;

const ProductTitle1 = styled(Title1)`
  margin-bottom: 24px;
`;

const Category = styled.div`
  font-size: 14px;
  font-weight: 900;

  text-transform: uppercase;
`;

const Price = styled(Body)``;

const CalPriceRow = styled.div`
  display: flex;
  justify-content: ${props => (props.cals ? 'space-between' : 'flex-end')};
  align-self: flex-end;
  margin-top: 24px;
`;

// const Label = styled.div`
//   font-size: 14px;
//   font-weight: 900;
//   color: ${props => props.theme.darkGray};
// `;

const Cancel = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  background-color: rgba(81, 78, 89, 0.63);
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const CartButton = styled(PrimaryButton)`
  cursor: default;
  @media (max-width: 786px) {
    background-color: ${props => '#2440ae'};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60;
  }
`;

const ProductCustomizer = ({
  productCustomizerReducer,
  handleAddToCart,
  addToCartString,
  onCancel
}) => {
  const { flatProduct, singlePrice } = productCustomizerReducer;

  return (
    <Modal bodyScroll>
      <Wrapper id="productCustomizerContainer">
        <Cancel onClick={onCancel}>
          <i className="fal fa-times" />
        </Cancel>
        <ProductImage src={flatProduct.image_url} />
        <InfoWrapper>
          <Category>{flatProduct.category}</Category>
          <ProductTitle1>{flatProduct.name}</ProductTitle1>
          <Body>{flatProduct.user_description}</Body>
          <CalPriceRow cals>
            {flatProduct.calories ? (
              <CalorieString
                calories={flatProduct.calories.find(
                  calorie => calorie.variant_id === flatProduct.variant_id
                )}
              />
            ) : (
              <div />
            )}
            <Price>{toPriceFormat(singlePrice)}</Price>
          </CalPriceRow>
          <Drawers flatProduct={flatProduct} />
          <ButtonRow justifyContent="flex-end">
            <CartButton onClick={handleAddToCart} disabled>
              ADD TO CART
            </CartButton>
          </ButtonRow>
        </InfoWrapper>
      </Wrapper>
    </Modal>
  );
};

export default ProductCustomizer;
