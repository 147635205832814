export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_MAPPING_FIELDS = 'GET_PRODUCTS_MAPPING_FIELDS';
export const GET_CURRENT_PRODUCT_POS_MAPPING_VALUES =
  'GET_CURRENT_PRODUCT_POS_MAPPING_VALUES';
export const SET_IS_REQUESTING_PRODUCT_POS_MAPPING_VALUES =
  'SET_IS_REQUESTING_PRODUCT_POS_MAPPING_VALUES';
export const GET_PRODUCTS_CATEGORIES = 'GET_PRODUCTS_CATEGORIES';
export const GET_PRODUCTS_FILTERED_CATEGORY = 'GET_PRODUCTS_FILTERED_CATEGORY';
export const GET_POS_COMBOS = 'GET_POS_COMBOS';
export const GET_POS_OFFERS = 'GET_POS_OFFERS';
export const GET_POS_COUPONS = 'GET_POS_COUPONS';
export const SET_SELECTED_VALUE_POS = 'SET_SELECTED_VALUE_POS';
export const GET_ALL_COUPONS_POS = 'GET_ALL_COUPONS_POS';
export const GET_DISCOUNT_MAPPING_FIELDS = 'GET_DISCOUNT_MAPPING_FIELDS';
export const RESET_SEARCH_VALUE = 'RESET_SEARCH_VALUE';
export const POS_DISCOUNT_MAPPING_FIELDS_NOT_AVAILABLE =
  'POS_DISCOUNT_MAPPING_FIELDS_NOT_AVAILABLE';
export const GET_DISCOUNT_MAPPING_FIELDS_REQUEST =
  'GET_DISCOUNT_MAPPING_FIELDS_REQUEST';

export const GET_ITEMS_MAPPING_FIELDS_REQUEST =
  'GET_ITEMS_MAPPING_FIELDS_REQUEST';
export const GET_ITEMS_MAPPING_FIELDS_SUCCESS =
  'GET_ITEMS_MAPPING_FIELDS_SUCCESS';
export const GET_ITEMS_POS_VALUES_REQUEST = 'GET_ITEMS_POS_VALUES_REQUEST';
export const GET_ITEMS_POS_VALUES_SUCCESS = 'GET_ITEMS_POS_VALUES_SUCCESS';
