import {
  SET_PROMOTE_TIER_UPGRADE_MODAL_IS_VISIBLE,
  GET_ORDER_TYPES_LIST,
  GET_TAX_CATEGORY_LIST_SUCCESS,
  GET_LANGUAGES_CONFIG_SUCCESS,
} from './fullTypes';

export default function (
  state = {
    promoteTierUpgradeModalIsVisible: false,
    orderTypesList: [],
    taxCategoryList: [],
    languagesConfig: [],
  },
  action
) {
  switch (action.type) {
    case SET_PROMOTE_TIER_UPGRADE_MODAL_IS_VISIBLE:
      return {
        ...state,
        promoteTierUpgradeModalIsVisible: action.isVisible,
      };
    case GET_ORDER_TYPES_LIST:
      return {
        ...state,
        orderTypesList: action.orderTypesList,
      };
    case GET_TAX_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        taxCategoryList: action.taxCategoryList,
      };
    case GET_LANGUAGES_CONFIG_SUCCESS:
      return {
        ...state,
        languagesConfig: action.languagesConfig,
      };
    default:
      return state;
  }
  return state;
}
