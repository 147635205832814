import React from 'react';
import styled from 'styled-components';
import {
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td
} from '../../../../components/Table/TableElements';
import logo from '../../../../../public/img/smooth-commerce-logo.png';
import Moment from 'moment';
import { BatchDetailPage } from '../../../../components/FormElements/FormElements';
import { TransactionsTitle } from '../../../../components/FormElements/FormElements';

const Report = styled.div`
  background: ${props => props.theme.white};
  padding: 16px 16px 8px 16px;
  font-size: 14px;
`;

// font-size: 1.25rem was it's original size with bootstrap

const Logo = styled.img`
  height: 50px;
  margin-bottom: 8px;
`;

const StyledP = styled.p`
  margin: 0;
  word-break: break-word;
  @media print {
    font-size: 14px;
    padding: ${props => props.printPadding || 'inherit'};
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'space-between'};
`;

const toPriceFormat = intPrice => {
  return new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: 'USD'
  }).format(Math.abs(intPrice));
};

const TransactionDetailsTable = props => {
  const {
    transactions,
    batchDate,
    batchSettlementId,
    batchAmount,
    businessesIncluded,
    transactionPeriodBegin,
    transactionPeriodEnd
  } = props;
  const TransactionsHeader = () => {
    return (
      <div>
        <Row>
          <Logo src={logo} alt="Smooth Commerce" />
          {Moment(batchDate).format('MM/DD/YYYY')}
        </Row>
        <Row justifyContent="flex-end">
          <StyledP>Batch ID: {batchSettlementId}</StyledP>
        </Row>
        <Row>
          <StyledP>Locations Included:</StyledP>
          <StyledP>Batch Amount: {toPriceFormat(batchAmount)}</StyledP>
        </Row>
        <Row>
          <div>
            {businessesIncluded.map((business, index) => (
              <StyledP key={index}>{business.display_name}</StyledP>
            ))}
          </div>
          <StyledP>
            Transaction Period:&nbsp;
            {Moment(transactionPeriodBegin).format('MM/DD/YYYY')}
            &nbsp;-&nbsp;
            <div>{Moment(transactionPeriodEnd).format('MM/DD/YYYY')}</div>
          </StyledP>
        </Row>
        <TransactionsTitle>Transaction Details</TransactionsTitle>
      </div>
    );
  };
  const renderTransactions = () => {
    return transactions.map(transaction => {
      return (
        <Tr key={transaction.transaction_id}>
          <Td align="left">{transaction.transaction_id}</Td>
          <Td align="left">{transaction.order_id}</Td>
          <Td align="left">
            <div> {Moment(transaction.time_created).format('MM/DD/YY')}</div>
            <div> {Moment(transaction.time_created).format('h:mm a')}</div>
          </Td>
          <Td align="center">
            {transaction.is_gift_card ? (
              <i className="fa fa-check" aria-hidden="true" />
            ) : (
              'No'
            )}
          </Td>
          <Td align="center">
            {toPriceFormat(transaction.transaction_amount)}
          </Td>
        </Tr>
      );
    });
  };

  return (
    <BatchDetailPage>
      <Report>
        <TransactionsHeader />
        <div style={{ overflowX: 'auto' }}>
          <Table>
            <thead>
              <TrHead>
                <Th align="left">Transaction</Th>
                <Th align="left">Order</Th>
                <Th align="left">Date</Th>
                <Th align="left">Gift Card</Th>
                <Th align="left">Transaction Amount</Th>
              </TrHead>
            </thead>
            <TBody striped>{renderTransactions()}</TBody>
          </Table>
        </div>
      </Report>
    </BatchDetailPage>
  );
};

export default TransactionDetailsTable;
