import {REQUEST_SETTLEMENTS, RECEIVE_SETTLEMENTS} from './types';

export default function(
  state = {
    isFetchingSettlements: false,
    response: null
  },
  action
) {
  switch (action.type) {
    case REQUEST_SETTLEMENTS:
      return {...state, isFetchingSettlements: true};
    case RECEIVE_SETTLEMENTS:
      return {...state, isFetchingSettlements: false, response: action.payload};
    default:
      return state;
  }
}
