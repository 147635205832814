import React, { Component } from 'react';
import * as actions from './actions';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { renderTextField } from '../../components/FormElements/ReduxForm';
import {
  Button,
  ButtonRow,
  LoadingButton
} from '../../components/Button/Button';
import { LoadingWhite } from '../../components/Loading/Loading';
import styled from 'styled-components';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import Icon from '../../components/Icon/Icon';

const ComponentCard = styled.div`
  background: ${props => props.theme.white};
  //box-shadow: 0px 0px 4px #888888;
  box-shadow: 0px 0px 4px #d6cece;
  padding: 15px;
  width: 50%;
`;

const AdjustedButtonRow = ButtonRow.extend`
  margin-top: 30px;
`;

const PasswordRequirements = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-left: 3px;
  margin-bottom: 10px;

  & > span {
    font-size: 12px;
    font-family: 'Montserrat';
    color: #959595;
    letter-spacing: -0.02px;
    margin-bottom: 8px;
  }
`;

const RegexTextSpan = styled.span`
  color: ${props => (props.isValid ? '#127428 !important' : 'inherit')};
`;

class Profile extends Component {
  componentDidMount() {
    track.page('profile');
  }
  componentDidUpdate(prevProps) {
    let successfulUpdate =
      !prevProps.profile.updatePasswordSuccess &&
      this.props.profile.updatePasswordSuccess;

    successfulUpdate ? this.props.reset() : undefined;
  }

  renderSubmitButton() {
    if (this.props.profile.isUpdating) {
      return (
        <LoadingButton whiteSpinner disabled>
          Updating
        </LoadingButton>
      );
    }
    return (
      <Button
        type="submit"
        disabled={
          !this.props.valid ||
          !this.state.eight ||
          !this.state.upperLower ||
          !this.state.digitSymbol
        }
      >
        Submit
      </Button>
    );
  }

  validatePassword = password => {
    const minEightRegex = new RegExp('^(?=.{8,})').test(password);
    const upperAndLowerCaseRegex = new RegExp('^(?=.*[A-Z])^(?=.*[a-z])').test(
      password
    );
    const digitOrSymbolRegex = new RegExp('^(?=.*[^a-zA-Z])').test(password);

    this.setState({
      eight: minEightRegex,
      upperLower: upperAndLowerCaseRegex,
      digitSymbol: digitOrSymbolRegex
    });
  };

  state = {
    eight: false,
    upperLower: false,
    digitSymbol: false
  };

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <div>
        <BreadCrumb pageName="Change Password" />
        <ComponentCard style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <div style={{ marginBottom: '15px' }}>Change Password Form</div>
          <form
            name="updatePasswordForm"
            onSubmit={handleSubmit(values => this.props.updatePassword(values))}
          >
            <Field
              name="currentPassword"
              label="Current Password*"
              component={renderTextField}
              type="password"
            />
            <Field
              name="newPassword"
              label="New Password*"
              component={renderTextField}
              type="password"
              onChange={e => this.validatePassword(e.target.value)}
            />
            <div>
              <PasswordRequirements>
                <RegexTextSpan isValid={this.state.eight}>
                  Minimum 8 characters{' '}
                  {this.state.eight && <Icon className="fal fa-check" />}
                </RegexTextSpan>

                <RegexTextSpan isValid={this.state.upperLower}>
                  At least 1 uppercase and 1 lowercase character{' '}
                  {this.state.upperLower && <Icon className="fal fa-check" />}
                </RegexTextSpan>

                <RegexTextSpan isValid={this.state.digitSymbol}>
                  At least 1 digit or symbol{' '}
                  {this.state.digitSymbol && <Icon className="fal fa-check" />}
                </RegexTextSpan>
              </PasswordRequirements>
            </div>
            <Field
              name="confirmNewPassword"
              label="Confirm New Password*"
              component={renderTextField}
              type="password"
            />
            <AdjustedButtonRow justifyContent="flex-end">
              {this.renderSubmitButton()}
            </AdjustedButtonRow>
          </form>
        </ComponentCard>
        {/* {this.props.authUser.role === 'ROLE_SUPER_ADMIN' ||
        this.props.authUser.role === 'ROLE_ADMIN' ? (
          <EditInformationCardContainer authUser={this.props.authUser} />
        ) : null} */}
      </div>
    );
  }
}

const validate = values => {
  const errors = {};
  const requiredFields = [
    'currentPassword',
    'newPassword',
    'confirmNewPassword'
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  if (values.newPassword !== values.confirmNewPassword) {
    errors.confirmNewPassword = 'Passwords do not match.';
  }
  if (values.currentPassword === values.newPassword) {
    errors.newPassword = 'New password cannot be same as current password.';
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    profile: state.profile,
    alerts: state.alerts,
    authUser: state.accountReducer
  };
}

Profile = reduxForm({
  form: 'updatePasswordForm',
  validate
})(Profile);

export default connect(mapStateToProps, actions)(Profile);
