import {
  GET_CUSTOMER_DETAILS_REQUEST,
  GET_CUSTOMER_DETAILS_SUCCESS,
  SEND_REWARDS_MODAL_IS_OPEN,
  SEND_CREDITS_MODAL_IS_OPEN,
  POST_SEND_REWARDS_REQUEST,
  POST_SEND_REWARDS_SUCCESS,
  POST_SEND_REWARDS_FAILURE,
  POST_SEND_CREDITS_REQUEST,
  POST_SEND_CREDITS_SUCCESS,
  POST_SEND_CREDITS_FAILURE,
  ACCOUNT_ACTIVATION_CONFIRM_MODAL_TOGGLE_VISIBILITY,
  PATCH_ACCOUNT_IS_ACTIVE_REQUEST,
  PATCH_ACCOUNT_IS_ACTIVE_SUCCESS,
  PATCH_ACCOUNT_INFORMATION_FAILURE,
  ACCOUNT_EDIT_MODAL_TOGGLE_VISIBILITY,
  PATCH_ACCOUNT_INFORMATION_REQUEST,
  PATCH_ACCOUNT_INFORMATION_SUCCESS,
  GET_CUSTOMER_LOYALTY_INFORMATION_REQUEST,
  GET_CUSTOMER_LOYALTY_INFORMATION_SUCCESS,
  GET_CUSTOMER_GLOBAL_CREDITS_REQUEST,
  GET_CUSTOMER_GLOBAL_CREDITS_SUCCESS,
  RESET_CUSTOMER_DETAILS_REDUCER_STATE
} from './customerTypes';

import getCurrentCreditsBalance from './utils/getCurrentCreditsBalance';
import getLifetimeCreditsBalance from './utils/getLifetimeCreditsBalance';
import formatCustomerGlobalCredits from './utils/formatCustomerGlobalCredits';
import formatCustomerLoyaltyPointsAccumulation from './utils/formatCustomerLoyaltyPointsAccumulation';
import moment from "moment";
import api from '../../../api';
import { createAlert } from '../../Alert/actions';

export function getCustomerDetails(customerId) {
  return function (dispatch) {
    dispatch(getCustomerAccountDetails(customerId));
    dispatch(getCustomerLoyaltyInformation(customerId));
    dispatch(getCustomerGlobalCredits(customerId));
  };
}

export function getCustomerAccountDetails(customerId) {
  return function (dispatch) {
    dispatch({
      type: GET_CUSTOMER_DETAILS_REQUEST
    });
    return api.get(`customers/${customerId}`).then(response => {
      dispatch({
        type: GET_CUSTOMER_DETAILS_SUCCESS,
        customerDetails: {
          ...response.data.data,
          customerName: function () {
            return `${this.first_name} ${this.last_name}`;
          }
        }
      });
    });
  };
}

export function getCustomerLoyaltyInformation(customerId) {
  return function (dispatch, getState) {
    const appMetaData = getState().accountReducer.appMetaData;
    dispatch({ type: GET_CUSTOMER_LOYALTY_INFORMATION_REQUEST });
    let promises = [null, null, null];
    if (
      appMetaData.configuration.program_type &&
      appMetaData.configuration.program_type == 'points' &&
      appMetaData.configuration.is_tiers_enabled
    ) {
      promises[0] = getCustomerLoyaltyTier(customerId);
    }
    if (
      appMetaData.configuration.program_type &&
      appMetaData.configuration.program_type == 'points'
    ) {
      promises[1] = getCustomerLoyaltyPoints(customerId);
      promises[2] = getCustomerLoyaltyPointsAccumulation(customerId);
    }
    return Promise.all(promises).then(responses => {
      dispatch({
        type: GET_CUSTOMER_LOYALTY_INFORMATION_SUCCESS,
        loyaltyTier: responses[0] ? responses[0].data.data : null,
        loyaltyPoints: responses[1]
          ? responses[1].data.data.length
            ? responses[1].data.data[0]
            : { current_points: 0, lifetime_points: 0 }
          : null,
        loyaltyPointsAccumulation: responses[2]
          ? formatCustomerLoyaltyPointsAccumulation(responses[2].data.data)
          : null
      });
    });
  };
}

function getCustomerLoyaltyTier(customerId) {
  return api.get(`customers/${customerId}/loyalty-tier`);
}

function getCustomerLoyaltyPoints(customerId) {
  return api.get(`customers/${customerId}/points`);
}

function getCustomerLoyaltyPointsAccumulation(customerId) {
  return api.get(`customers/${customerId}/points-accumulation`);
}

export function sendLoyaltyModalIsOpen(sendLoyaltyModalIsOpen) {
  return function (dispatch) {
    dispatch({ type: SEND_REWARDS_MODAL_IS_OPEN, sendLoyaltyModalIsOpen });
  };
}

export function SendCreditsModalIsOpen(SendCreditsModalIsOpen) {
  return function (dispatch) {
    dispatch({
      type: SEND_CREDITS_MODAL_IS_OPEN,
      SendCreditsModalIsOpen
    });
  };
}

export function postSendRewards(customerId, body) {
  return function (dispatch) {
    dispatch({ type: POST_SEND_REWARDS_REQUEST });
    return api
      .post(`customers/${customerId}/points`, body)
      .then(response => {
        dispatch({ type: POST_SEND_REWARDS_SUCCESS });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Customer received reward successfully'
          })
        );
        dispatch(getCustomerLoyaltyInformation(customerId));
      })
      .catch(error => {
        dispatch({ type: POST_SEND_REWARDS_FAILURE });
      });
  };
}

export function postSendCredits(customerId, body) {
  return function (dispatch) {
    dispatch({ type: POST_SEND_CREDITS_REQUEST });
    return api
      .post(`customers/${customerId}/global-credits`, body)
      .then(response => {
        dispatch({ type: POST_SEND_CREDITS_SUCCESS });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Customer received credits successfully'
          })
        );
        dispatch(getCustomerGlobalCredits(customerId));
      })

      .catch(error => {
        dispatch({ type: POST_SEND_CREDITS_FAILURE });
      });
  };
}

export function confirmAccountPhoneNumber(customerId) {
  return function (dispatch) {
    return api
      .patch(`customers/${customerId}`, { is_sms_confirmed: true })
      .then(response => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'Phone number confirmed successfully'
          })
        );
        dispatch(getCustomerAccountDetails(customerId));
      });
  };
}

export function accountActivationConfirmModalToggleVisibility() {
  return function (dispatch) {
    dispatch({ type: ACCOUNT_ACTIVATION_CONFIRM_MODAL_TOGGLE_VISIBILITY });
  };
}

export function patchAccountIsActive(customerId, accountShouldBeActive) {
  return function (dispatch) {
    dispatch({
      type: PATCH_ACCOUNT_IS_ACTIVE_REQUEST
    });
    return api
      .patch(`/customers/${customerId}`, { is_active: accountShouldBeActive })
      .then(response => {
        dispatch({
          type: PATCH_ACCOUNT_IS_ACTIVE_SUCCESS
        });
        dispatch(
          createAlert({
            type: 'success',
            message: `Account ${accountShouldBeActive ? 'Activated' : 'Deactivated'
              } Successfully`
          })
        );
        dispatch(getCustomerAccountDetails(customerId));
      });
  };
}

export function accountInformationEditModalToggleVisibility() {
  return function (dispatch) {
    dispatch({ type: ACCOUNT_EDIT_MODAL_TOGGLE_VISIBILITY });
  };
}

export function patchAccountInformation(customerId, data) {

  return function (dispatch, getState) {
    dispatch({ type: PATCH_ACCOUNT_INFORMATION_REQUEST });
    return api
      .patch(`/customers/${customerId}`, data)
      .then(response => {
        dispatch({ type: PATCH_ACCOUNT_INFORMATION_SUCCESS });
        dispatch(
          createAlert({
            type: 'success',
            message: `Account Successfully Updated`
          })
        );
        dispatch(getCustomerAccountDetails(customerId));
      })
      .catch(error => {
        if (error.response.status == 409) {
          // conflict with phone number or
          // conflict with email
          dispatch({ type: PATCH_ACCOUNT_INFORMATION_FAILURE });
          dispatch(
            createAlert({
              type: 'error',
              message: 'Phone number or email already taken, please use another one'
            })
          );
        }
      });
  };
}

export function getCustomerGlobalCredits(customerId) {
  return function (dispatch) {
    dispatch({ type: GET_CUSTOMER_GLOBAL_CREDITS_REQUEST });
    return api.get(`/customers/${customerId}/global-credits`).then(response => {
      dispatch({
        type: GET_CUSTOMER_GLOBAL_CREDITS_SUCCESS,
        customerGlobalCredits: formatCustomerGlobalCredits(response.data.data),
        currentCreditsBalance: getCurrentCreditsBalance(response.data.data),
        lifetimeCreditsBalance: getLifetimeCreditsBalance(response.data.data)
      });
    });
  };
}

export function errorAlert(message) {
  return function (dispatch) {
    dispatch(
      createAlert({
        type: 'error',
        message: message
      })
    );
  };
}

export function resetCustomerDetailsReducerState() {
  return {
    type: RESET_CUSTOMER_DETAILS_REDUCER_STATE
  };
}
