import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from './customerListActions';
import fetchOnParamChange from '../../HOCs/fetchOnParamChange';
import CustomerList from './components/CustomerList';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';

/**
 * Class Component that handle's how components should work
 */
class CustomerListContainer extends Component {
  state = {
    searchInput: ''
  };
  componentDidMount() {
    track.page('customer-service-customer-list');
  }
  /**
   * If value is 3 or more, call API to get new set of data
   * if value is 0, show all data
   * @param {string} value - input value for searching
   */
  handleSearchInput = value => {
    if (value.length > 2) {
      this.props.updateRequestParams({
        page_number: initialRequestParams.page_number,
        page_size: initialRequestParams.page_size,
        search_string: encodeURIComponent(value)
      });
    } else if (value.length == 0) {
      this.props.updateRequestParams({
        page_number: initialRequestParams.page_number,
        page_size: initialRequestParams.page_size
      });
    }
    this.setState({ searchInput: value });
  };

  /**
   * Redirect user to customer detail page
   */
  handleEditIconClick = customerId => {
    this.props.history.push(`/customers/${customerId}`);
  };

  render() {
    const {
      getCustomersIsRequesting,
      customers,
      meta
    } = this.props.customerListReducer;
    const { requestParams, updateRequestParams } = this.props;
    const { searchInput } = this.state;
    const pageName = 'Customers List';
    return (
      <Fragment>
        <BreadCrumb pageName={pageName} />
        <CustomerList
          getCustomersIsRequesting={getCustomersIsRequesting}
          customers={customers}
          handleSearchInput={this.handleSearchInput}
          searchInput={searchInput}
          meta={meta}
          requestParams={requestParams}
          updateRequestParams={updateRequestParams}
          handleEditIconClick={this.handleEditIconClick}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  customerListReducer: state.customerListReducer
});

const initialRequestParams = {
  page_number: 1,
  page_size: 25
};

CustomerListContainer = fetchOnParamChange(
  CustomerListContainer,
  actions.getCustomersOfMerchant,
  initialRequestParams
);

export default connect(mapStateToProps, actions)(CustomerListContainer);
