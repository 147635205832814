import React from 'react';
import styled from 'styled-components';
import StoreHoursPanel from './StoreHoursPanel';
import OrderAheadHoursPanel from './OrderAheadHoursPanel';
import {ContainerDiv, SubTitle} from '../../../common/commonElements';

export const StoreHoursAndOrderAheadHoursGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px 20px;
  grid-gap: 10px;
  @media screen and (max-width: 1340px) {
    grid-template-columns: 1fr;
  }
`;

export const HoursSelectionContainer = styled.div`
  padding-right: 50px;
  max-width: 500px;
  border-right: ${props => (props.borderRight ? 'solid 2px #d8d8d8' : 'none')};
  @media screen and (max-width: 1340px) {
    border: none;
    padding: 0;
    margin: auto;
  }
`;

const StoreHoursAndOrderAheadHours = props => {
  const {storeHours, handleHourIsOpenChange, formFieldName} = props;
  return (
    <StoreHoursAndOrderAheadHoursGrid>
      <HoursSelectionContainer borderRight style={{marginLeft: 'auto'}}>
        <ContainerDiv  >
          <SubTitle textAlign="left" style={{fontSize:'15px'}}>Regular Hours</SubTitle>
        </ContainerDiv>
        <StoreHoursPanel
          storeHours={storeHours}
          handleHourIsOpenChange={handleHourIsOpenChange}
          formFieldName={formFieldName}
        />
      </HoursSelectionContainer>
      <HoursSelectionContainer>
        <ContainerDiv>
          <SubTitle textAlign="left" style={{fontSize:'15px'}}>Order Ahead Hours</SubTitle>
        </ContainerDiv>
        <OrderAheadHoursPanel
          storeHours={storeHours}
          formFieldName={formFieldName}
        />
      </HoursSelectionContainer>
    </StoreHoursAndOrderAheadHoursGrid>
  );
};

export default StoreHoursAndOrderAheadHours;
