import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as actions from '../offerActions';
import OfferDetails from './OfferDetails';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import BackButton from '../../../../components/BackButton';

const BackText = styled.div`
  margin-bottom: 15px;
  margin-right: auto;

  &: hover {
    color: #3051d4;
  }
`;
const BreadCrumbWrapper = styled.div`
  color: ${props => props.theme.darkBlue};
  margin: 30px;
  margin-left: 0;
  @media print {
    display: none;
  }
`;
const OfferDetailsContainer = ({
  createOffer,
  requestingCreateOffer,
  editingOffer,
  editOffer,
  requestingEditOffer,
  resetOfferStates,
  appMetaData
}) => {
  useEffect(() => {
    if (editingOffer) {
      track.page('engagement-offers-edit');
    } else {
      track.page('engagement-offers-create');
    }
    return () => resetOfferStates();
  }, []);
  const handleSubmit = offer => {
    editingOffer ? editOffer(offer) : createOffer(offer);
  };

  const isPointsEnabled =
    appMetaData.configuration.program_type &&
    appMetaData.configuration.program_type == 'points';
  return (
    <Fragment>
      <BreadCrumbWrapper>
        <BackButton pageName="Offers List" onClick={() => resetOfferStates()} />
        {/* <BackText>
          <div style={{ cursor: 'pointer' }} onClick={() => resetOfferStates()}>
            &lt; Back To Offers List
          </div>
        </BackText> */}
        <BreadCrumb
          style={{ margin: ' 0px' }}
          pageName={editingOffer ? 'Edit Offer' : 'Create Offer'}
        />
      </BreadCrumbWrapper>

      <OfferDetails
        handleSubmit={handleSubmit}
        isRequesting={requestingCreateOffer || requestingEditOffer}
        editingOffer={editingOffer}
        isPointsEnabled={isPointsEnabled}
      />
    </Fragment>
  );
};
const mapStateToProps = state => ({
  requestingEditOffer: state.offersReducer.requestingEditOffer,
  requestingCreateOffer: state.offersReducer.requestingCreateOffer,
  editingOffer: state.offersReducer.editingOffer,
  appMetaData: state.accountReducer.appMetaData
});
export default connect(mapStateToProps, actions)(OfferDetailsContainer);
