import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import {
  RenderTextField,
  required
} from '../../../components/FormElements/FormikElements';
import Icon from '../../../components/Icon/Icon';
import { Button } from '../../../components/Button/Button';
import logo from '../../../../public/img/logo-invite-user-page.png';
import { InputAdornment } from '@material-ui/core';
import { validateChangePasswordFields } from "../newUserLoginAction";
import PasswordVerifier from '../../PasswordVerifier/PasswordVerifier';
import { connect } from "react-redux";

const Content = styled.div`
  padding: 48px 95px 40px 95px;
  width: 550px;
  height: 780px;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  background-color: white;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-size: 21px;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: 0.03px;
  color: #191818;
`;

const Subtitle = styled.div`
  font-size: 16px;
  font-weight: normal;
  line-height: 1.29;
  letter-spacing: 0.03px;
  color: #191818;
  margin-top: 16px;
  margin-bottom: 32px;
`;

const PasswordMismatchSpan = styled.span`
  font-size: 12px;
  font-family: 'Montserrat';
  color: red;
  letter-spacing: -0.02px;
  margin-bottom: 8px;
  margin-top: -20px;
`;

const FormWrapper = styled.div`
  display: grid;
  grid-gap: 32px;
`;

const ButtonDiv = styled.div`
  margin-top: 35px;
  margin-left: 190px;
`;

const Logo = styled.img`
  height: 50px;
  margin-bottom: 8px;
`;

const NewUserLogin = ({ postNewUser, params, history }) => {

  const [state, setState] = useState({
    passwordMaskState: true,
    passwordConfirmMaskState: true,
    isRegisterButtonDisabled: true
  });

  const token = params.token;

  return (
    <Content>
      <Logo src={logo} alt="Smooth Commerce"></Logo>
      <Title>Welcome to Portal</Title>
      <Subtitle>
        Please complete your profile information to get started
      </Subtitle>
      <FormWrapper>
        <Formik
          validate={values => {
            const err = validateChangePasswordFields(values)
            if (Object.keys(err).length === 0) {
              setState({
                ...state,
                isRegisterButtonDisabled: false,
              })
            } else {
              setState({
                ...state,
                isRegisterButtonDisabled: true,
              })
            }
            return err
          }
          }
        >
          {({ values }) => (
            <Fragment>
              <Field
                type={state.passwordMaskState ? 'password' : 'text'}
                name="password"
                label="Choose a password"
                component={RenderTextField}
                validate={required}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon
                        className={state.passwordMaskState ? "fas fa-eye" : "fas fa-eye-slash"}
                        onClick={() => setState({
                          ...state,
                          passwordMaskState: !state.passwordMaskState
                        })}
                      />
                    </InputAdornment>
                  )
                }}
              />
              <div>
                <PasswordVerifier password={values.password} passwordConfirm={values.passwordConfirm} />
              </div>
              <Field
                type={state.passwordConfirmMaskState ? 'password' : 'text'}
                name="passwordConfirm"
                label="Confirm password"
                component={RenderTextField}
                validate={required}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon
                        className={state.passwordConfirmMaskState ? "fas fa-eye" : "fas fa-eye-slash"}
                        onClick={() => setState({
                          ...state,
                          passwordConfirmMaskState: !state.passwordConfirmMaskState
                        })}
                      />
                    </InputAdornment>
                  )
                }}
              />
              <ButtonDiv>
                <Button
                  onClick={() => postNewUser(values, token, history)}
                  disabled={state.isRegisterButtonDisabled}
                >
                  Register
                </Button>
              </ButtonDiv>
            </Fragment>
          )}
        </Formik>
      </FormWrapper>
    </Content>
  );
};

const mapStateToProps = state => ({
  passwordVerifier: state.passwordVerifierReducer
})

export default connect(mapStateToProps, null)(NewUserLogin);
