import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
} from '../../../../../components/Table/TableElements';
import { SmallToggleSwitch } from '../../../../../components/ToggleSwitch/ToggleSwitch';
import { LoadingBlueLarge } from '../../../../../components/Loading/Loading';
import PaginationControl from '../../../../../components/Pagination/PaginationControl';
import { TooltipIcon } from '../../../../../components/Icon';
import { sortByTypes } from '../../../../../utils/sort/sort';
import { STRING, BOOLEAN } from '../../../../../utils/sort/sort';
import Tooltip from '@material-ui/core/Tooltip';

const TableWrapper = styled.div`
  overflow-x: scroll;
  border-radius: 12px;
`;

const renderList = (
  allVariants,
  toggleIsActive,
  variantIsSaving,
  setEditVariant,
  requestParams,
  searchString,
  handleSort
) => {
  return allVariants && allVariants.length !== 0 ? (
    <TableWrapper>
      <Table style={{ border: 'none' }}>
        <TBody>
          {renderTableHeader(requestParams, handleSort)}
          {allVariants.length &&
            requestParams &&
            renderTableRows(
              allVariants,
              toggleIsActive,
              variantIsSaving,
              setEditVariant,
              requestParams,
              searchString
            )}
        </TBody>
      </Table>
    </TableWrapper>
  ) : (
    <h1 style={{ textAlign: 'center' }}>No more variants data </h1>
  );
};

const renderTableHeader = (requestParams, handleSort) => {
  return (
    <TrHead>
      <Th
        align="left"
        onClick={() => {
          handleSort({
            value: 'name',
            sortMethod: sortByTypes,
            sortType: STRING,
          });
        }}
        value="name"
        sortingOption={requestParams.sorting_option}
        isSortable="true"
      >
        Name
      </Th>
      <Th align="left">
        Label
        <Tooltip
          style={{ marginRight: 'auto' }}
          title="This is an internal label to help you identify the type of variant.This will not appear to your customers"
        >
          <span>
            <TooltipIcon marginLeft />
          </span>
        </Tooltip>
      </Th>
      <Th
        align="left"
        onClick={() => {
          handleSort({
            value: 'is_active',
            sortMethod: sortByTypes,
            sortType: BOOLEAN,
          });
        }}
        value="is_active"
        sortingOption={requestParams.sorting_option}
        isSortable="true"
      >
        Active
      </Th>
    </TrHead>
  );
};
const renderTableRows = (
  allVariants,
  toggleIsActive,
  variantIsSaving,
  setEditVariant,
  requestParams,
  searchString
) => {
  const sliceVariants = (variants) => {
    const indexOfFirst =
      (requestParams.page_number - 1) * requestParams.page_size;
    const indexOfLast = requestParams.page_number * requestParams.page_size;

    return variants
      .filter(
        (variant) =>
          variant.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1
      )
      .slice(indexOfFirst, indexOfLast);
  };
  const [variantActiveList, setVariantActiveList] = useState(
    sliceVariants(allVariants)
  );

  useEffect(() => {
    setVariantActiveList(sliceVariants(allVariants));
  }, [requestParams, allVariants]);

  const onToggleActive = (variant) => {
    toggleIsActive(variant);
    const newVariantActiveList = variantActiveList.map((v) =>
      v.id === variant.id ? { ...v, is_active: !variant.is_active } : v
    );

    setVariantActiveList(sliceVariants(newVariantActiveList));
  };

  return variantActiveList.length > 0 ? (
    variantActiveList.map((variant, index) => (
      <Tr
        key={index}
        style={{ height: '75px', color: '#191818', cursor: 'pointer' }}
        onClick={() => !variantIsSaving && setEditVariant(variant)}
      >
        <Td>{variant.name}</Td>
        <Td>{variant.label}</Td>
        <Td>
          <SmallToggleSwitch
            disabled={variantIsSaving}
            checked={variant.is_active}
            onChange={(e) => onToggleActive(variant)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Td>
      </Tr>
    ))
  ) : (
    <Tr>
      <Td
        colSpan="3"
        style={{
          padding: '50px 0px',
          color: '#191818',
          cursor: 'pointer',
          textAlign: 'center',
        }}
      >
        No matching data
      </Td>
    </Tr>
  );
};

const VariantList = ({
  allVariants,
  meta,
  requestParams,
  isGetVariantRequest,
  toggleIsActive,
  isVariantAvailable,
  setEditVariant,
  variantIsSaving,
  searchString,
  handleSort,
}) => {
  return !isGetVariantRequest ? (
    <Fragment>
      {renderList(
        allVariants,
        toggleIsActive,
        variantIsSaving,
        setEditVariant,
        requestParams,
        searchString,
        handleSort
      )}
      <PaginationControl
        response={{
          ...allVariants,
          page: requestParams.page_number,
          page_size: requestParams.page_size,
          count: allVariants.length,
        }}
        requestParams={requestParams}
        type="variants"
      />
    </Fragment>
  ) : (
    <LoadingBlueLarge height="80vh" />
  );
};

export default VariantList;
