import React, { Fragment } from 'react';
import styled from 'styled-components';
import Icon, { EditIcon } from '../../../../components/Icon';
import moment from 'moment';

const TitleGridWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
`;

const Title = styled.p`
  font-weight: 600;
  color: ${(props) => props.theme.darkBlue};
`;

const IconWrapper = styled.div``;

const CustomerIsActiveWrapper = styled.div`
  padding-top: 16px;
  align-self: center;
  margin-right: auto;
`;

const CustomerFieldRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 75px;
  padding: 10px 0 20px 0;
`;

const StyledP = styled.p``;

const CustomerFieldWrapper = styled.div`
  min-width: 150px;
  word-break: break-word;
`;

const ButtonP = styled.p`
  color: ${(props) => (props.disabled ? '#c1bfbf' : props.theme.blue)};
  cursor: pointer;
  font-weight: 600;
`;

const PhoneVerificationWrapper = styled.div`
  padding-top: 16px;
  align-self: center;
  margin-right: auto;
`;

const Wrapper = styled.div`
  padding: 37px 37px 0 37px;
`;

const NonField = styled.div`
  font-size: 16px !important;
  border: none;
  border-radius: none;
  height: 40px;
  width: 100%;
  padding: 10px 10px 10px 0px;
  font-weight: 600;
`;

const HorizontalLine = styled.hr`
  border: 0;
  border-top: 2px solid #d8d8d8;
  opacity: 0.4;
  margin: 50px 37px;
`;

const AccountInformation = (props) => {
  const {
    customerDetails,
    accountActivationConfirmModalToggleVisibility,
    accountInformationEditModalToggleVisibility,
    handleConfirmPhoneNumber,
  } = props;

  return (
    <Fragment>
      <Wrapper>
        <TitleGridWrapper>
          <Title data-test="component-AccountInformationTitle">
            Account Information
          </Title>
          <IconWrapper>
            <EditIcon onClick={accountInformationEditModalToggleVisibility} />
          </IconWrapper>
        </TitleGridWrapper>
        <CustomerFieldRow>
          <CustomerFieldWrapper>
            <StyledP>Customer First Name</StyledP>
            <NonField>{customerDetails.first_name}</NonField>
          </CustomerFieldWrapper>
          <CustomerFieldWrapper>
            <StyledP>Customer Last Name</StyledP>
            <NonField>{customerDetails.last_name}</NonField>
          </CustomerFieldWrapper>
        </CustomerFieldRow>
        <CustomerFieldRow>
          <CustomerFieldWrapper>
            <StyledP>Customer Email</StyledP>
            <NonField>{customerDetails.email}</NonField>
          </CustomerFieldWrapper>
          <CustomerFieldWrapper>
            <StyledP>Customer Phone Number</StyledP>
            <NonField>{customerDetails.detail.mobile_phone}</NonField>
          </CustomerFieldWrapper>
        </CustomerFieldRow>
        <CustomerFieldRow>
          <CustomerFieldWrapper>
            <StyledP>Customer Birthday</StyledP>
            <NonField>
              {customerDetails.detail.birthdate !== null
                ? moment(customerDetails.detail.birthdate).format('MM/DD/YYYY')
                : 'Not Provided'}
            </NonField>
          </CustomerFieldWrapper>
        </CustomerFieldRow>
        <CustomerFieldRow>
          <PhoneVerificationWrapper>
            {!customerDetails.is_sms_confirmed ? (
              <ButtonP onClick={handleConfirmPhoneNumber}>
                Confirm phone number
              </ButtonP>
            ) : (
              <ButtonP disabled>
                SMS Confirmed <Icon className="fas fa-check-circle" />
              </ButtonP>
            )}
          </PhoneVerificationWrapper>
          <CustomerIsActiveWrapper>
            <ButtonP onClick={accountActivationConfirmModalToggleVisibility}>
              {customerDetails.is_active
                ? 'Deactivate this account'
                : 'Activate this account'}
            </ButtonP>
          </CustomerIsActiveWrapper>
        </CustomerFieldRow>
      </Wrapper>
      <HorizontalLine />
    </Fragment>
  );
};

export default AccountInformation;
