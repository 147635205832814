import Moment from 'moment';
import { toPriceFormat } from '../../../../utils/toPriceFormat';
import store from '../../../../configureStore';

export const formatTransactionDetails = (transactionDetails) => {
  let formattedTransactionDetails = {};

  // must have
  formattedTransactionDetails.orderId = transactionDetails.order_id;
  formattedTransactionDetails.orderDate = Moment(
    transactionDetails.order_date.split(' ')[0],
    'YYYY-MM-DD'
  ).format('DD/MM/YYYY');
  formattedTransactionDetails.orderTime = Moment(
    transactionDetails.order_date.split(' ')[1],
    'HH:mm:ss'
  ).format('hh:mma');
  formattedTransactionDetails.subtotal = toPriceFormat(
    transactionDetails.subtotal
  );
  formattedTransactionDetails.loyalty = transactionDetails.loyalty;

  formattedTransactionDetails.total = toPriceFormat(transactionDetails.total);
  formattedTransactionDetails.tax = toPriceFormat(transactionDetails.tax);
  formattedTransactionDetails.tip = toPriceFormat(transactionDetails.tip);
  formattedTransactionDetails.discount = transactionDetails.discount
    ? toPriceFormat(transactionDetails.discount)
    : '$0';
  formattedTransactionDetails.creditsUsed = toPriceFormat(
    transactionDetails.total_credit_applied
  );
  formattedTransactionDetails.products = transactionDetails.products
    ? formatProducts(transactionDetails.products)
    : null;

  formattedTransactionDetails.customerName = transactionDetails.customer
    ? `${transactionDetails.customer.first_name} ${transactionDetails.customer.last_name}`
    : 'Unknown Name';

  formattedTransactionDetails.customerEmail = transactionDetails.customer
    ? transactionDetails.customer.email
    : 'Unknown Email';

  formattedTransactionDetails.customerId =
    transactionDetails.customer.customer_id;

  formattedTransactionDetails.orderType = transactionDetails.order_type
    ? transactionDetails.order_type
    : 'Unknown';

  formattedTransactionDetails.orderingFrom = transactionDetails.customer_address
    ? formatAddress(transactionDetails.customer_address)
    : formatAddress(transactionDetails.business_address);

  formattedTransactionDetails.businessAddress = formatAddress(
    transactionDetails.business_address
  );
  formattedTransactionDetails.businessName = transactionDetails.business_name;

  // will always be able to refund

  formattedTransactionDetails.refundIsDisabled = false
    ? {
        disabled: true,
        reason: 'Cannot refund order with less than $1 gift card spent',
      }
    : { disabled: false, reason: null };

  formattedTransactionDetails.transactions = transactionDetails.transactions
    ? transactionDetails.transactions.map((transaction) => {
        return { ...transaction, amount: toPriceFormat(transaction.amount) };
      })
    : [];

  if (transactionDetails.fees !== undefined) {
    formattedTransactionDetails.additionalFees = toPriceFormat(
      transactionDetails.fees
    );
  }

  // options and must check

  return formattedTransactionDetails;
};

const formatProducts = (products) => {
  return products.map((product) => {
    let formattedProduct = {};
    formattedProduct.name = `${product.name} (x${product.quantity})`;
    formattedProduct.price = `${toPriceFormat(product.prices[0].price)} ea`;
    formattedProduct.imageUrl = formatProductUrl(product.image_url);
    formattedProduct.calories =
      product.calories && product.calories.length
        ? product.calories[0].max_calories
        : null;
    formattedProduct.options = formatProductOptions(product.options);

    return formattedProduct;
  });
};

const formatAddress = (address) => {
  let formattedAddress = {
    line1: address.line2 ? address.line2 + '-' + address.line1 : address.line1,
    line2:
      address.city && address.state
        ? address.city + ', ' + address.state + ','
        : address.city
        ? address.city + ', '
        : address.state
        ? address.state + ', '
        : '',
    line3: address.zip ? address.zip + ', ' : '',
    line4: address.country ? address.country : '',
  };
  return formattedAddress;
};

const formatProductUrl = (url) => {
  if (!url.includes('http://')) {
    return url.replace(/http:\//g, 'http://');
  }
  return url;
};

const formatProductOptions = (options) => {
  return options
    .map((option) => {
      if (option.sub_options.length) {
        const optionsToReturn = {
          ...option,
          sub_options: option.sub_options
            .map((subOption) => mapOption(subOption))
            .filter((subOption) => subOption),
        };
        if (optionsToReturn.sub_options.length) {
          return optionsToReturn;
        }
      } else {
        return mapOption(option);
      }
    })
    .filter((option) => option);
};

const mapOption = (option) => {
  const optionsToReturn = {
    ...option,
    items: option.items
      .map((item) => {
        if (item.sub_items && item.sub_items.length) {
          const itemToReturn = {
            ...item,
            sub_items: item.sub_items
              .map((subItem) => {
                if (subItem.quantity) {
                  return subItem;
                }
              })
              .filter((subItem) => subItem),
          };
          if (itemToReturn.sub_items.length) {
            return itemToReturn;
          }
        } else if (item.quantity) {
          return item;
        }
      })
      .filter((item) => item),
  };
  if (optionsToReturn.items.length) {
    return optionsToReturn;
  }
};

export const selectedItemsToString = (option) => {
  let itemsString = '';

  option.items.forEach((item) => {
    const addCommaAndSpace = itemsString ? ', ' : '';
    if (item.sub_items && item.sub_items.length) {
      item.sub_items.forEach((subItem) => {
        // const showQuantity = subItem.maximum_pick !== 1;
        const quantityString = ` x${subItem.quantity}`;
        if (subItem.quantity) {
          itemsString += `${addCommaAndSpace}${item.name} (${subItem.name}${quantityString})`;
        }
      });
    } else {
      // const showQuantity = item.maximum_pick !== 1;
      const quantityString = ` x${item.quantity}`;
      if (item.quantity) {
        itemsString += `${addCommaAndSpace}${item.name}${quantityString}`;
      }
    }
  });
  return itemsString;
};
