import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import { SCAN_AND_GO } from '../../../productsTypes';
import {
  RenderTextField,
  RenderSelect,
} from '../../../../../../components/FormElements/FormikElements';
import { SortableCardRow } from '../../../../components/MenuManagementElements';
import toPriceFormat from '../../../../../../utils/toPriceFormat';
import Icon from '../../../../../../components/Icon/Icon';
import Select from '../../../../../../components/Select/Select';
import NutritionInfoModalContainer from '../../../../NutritionTemplate/NutritionInfoModal/NutritionInfoModalContainer';
import { SmallToggleSwitch } from '../../../../../../components/ToggleSwitch/ToggleSwitch';
//import NutritionInfoModal from '../../../../NutritionTemplate/components/NutritionInfoModal';

const SortableCardRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 16px 600px auto;
  grid-gap: 24px;
  align-items: center;

  .react-select__control {
    background-color: white;
    border-radius: 6px;
  }

  .react-select__single-value {
    font-weight: 600;
    font-size: 14px;
  }

  .react-select__multi-value {
    background-color: #e6e6e6;
    border-radius: 15px;
    padding: 1px 3px;
    font-weight: 400;
    font-size: 14px;
  }
`;

const AddNewVariant = styled.div`
  font-weight: 600;
  color: ${(props) => props.disabled && '#6f6f76'};
  margin: 24px auto 24px 0;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  cursor: pointer;
`;

const ReactSelectWrapper = styled.div`
  width: 229px;

  .react-select__control {
    background-color: white;
    border-radius: 6px;
  }

  .react-select__single-value {
    font-weight: 600;
    font-size: 14px;
  }

  .react-select__multi-value {
    background-color: #e6e6e6;
    border-radius: 15px;
    padding: 1px 3px;
    font-weight: 400;
    font-size: 14px;
  }
`;

const SelectDefaultVariant = styled.div`
  font-weight: 600;
`;

const getVariantName = (allVariants, findVariantId) => {
  let foundVariant = allVariants.find(
    (variant) => variant.id === findVariantId
  );
  return foundVariant
    ? `${foundVariant.name} - ${foundVariant.label}`
    : `Variant Doesn't Exist`;
};

const AddNutritionFactButton = styled.button`
  background: none;
  border: none;
  letter-spacing: 0.02px;
  color: #231f20;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;

  & span {
    margin-left: 8px;
  }
`;

const ToggleWrapper = styled.div`
  justify-self: end;
`;

const SortableItem = SortableElement(
  ({
    productVariant,
    productVariantsAreEditable,
    variantIndex,
    allVariants,
    arrayHelpers,
    appConfig,
    //productId
    posMappingValues,
    orderType,
  }) => {
    const [nutritionInfoModal, setNutritionInfoModal] = useState(false);
    const toggleNutritionInfoModal = () => {
      setNutritionInfoModal(!nutritionInfoModal);
    };

    let isToggleDisplayed =
      appConfig && !productVariant.hasOwnProperty('is_new');
    let isToggleDisabled = !appConfig;
    if (appConfig) {
      if (appConfig.pos_integration_enabled) {
        if (posMappingValues.hasOwnProperty('mappings')) {
          const isPOSMapped = Object.values(posMappingValues.mappings).some(
            (mapping) =>
              mapping.product_variant_id === productVariant.product_variant_id
          );
          if (!isPOSMapped) {
            isToggleDisabled = true;
          }
        }
      } else {
        isToggleDisplayed = true;
      }
    }

    return (
      <SortableCardRowWrapper onClick={(e) => e.stopPropagation()}>
        {productVariantsAreEditable ? (
          <Icon
            className="fas fa-minus-circle"
            onClick={() => arrayHelpers.remove(variantIndex)}
          />
        ) : (
          <Icon className="far fa-equals" style={{ cursor: 'move' }} />
        )}
        <SortableCardRow
          style={{
            gridTemplateColumns: '5fr 2fr 5fr 2fr',
            gridGap: '20px',
            width: '600px',
            cursor: productVariantsAreEditable ? 'default' : 'move',
          }}
        >
          {productVariantsAreEditable ? (
            <Field
              name={`productVariants[${variantIndex}].product_variant_id`}
              component={RenderSelect}
              options={allVariants.filter(
                (globalVariant) =>
                  !arrayHelpers.form.values.productVariants
                    .map((productVariant) => productVariant.product_variant_id)
                    .includes(globalVariant.id) ||
                  arrayHelpers.form.values.productVariants[variantIndex]
                    .product_variant_id === globalVariant.id
              )}
              setFieldValue={arrayHelpers.form.setFieldValue}
              getOptionLabel={(variant) => `${variant.name} - ${variant.label}`}
              getOptionValue={(variant) => variant.id}
              valueKey="id"
              placeholder="Select Variant*"
              required
            />
          ) : (
            <div>
              {getVariantName(allVariants, productVariant.product_variant_id)}
            </div>
          )}
          {productVariantsAreEditable ? (
            <Field
              name={`productVariants[${variantIndex}].price`}
              component={RenderTextField}
              label="Price"
              required
              error={
                arrayHelpers.form.touched.productVariants &&
                arrayHelpers.form.touched.productVariants[variantIndex] &&
                arrayHelpers.form.touched.productVariants[variantIndex].price
                  ? arrayHelpers.form.values.productVariants[variantIndex]
                      .price === ''
                    ? 'Required'
                    : isNaN(
                        arrayHelpers.form.values.productVariants[variantIndex]
                          .price
                      )
                    ? 'Must be a number'
                    : null
                  : null
              }
            />
          ) : (
            <div>{toPriceFormat(productVariant.price)}</div>
          )}
          {productVariantsAreEditable && orderType ? (
            orderType.includes(SCAN_AND_GO) ? (
              <Field
                name={`productVariants[${variantIndex}].barcode`}
                component={RenderTextField}
                label="Barcode"
                required
                error={
                  arrayHelpers.form.touched.productVariants &&
                  arrayHelpers.form.touched.productVariants[variantIndex] &&
                  arrayHelpers.form.touched.productVariants[variantIndex]
                    .barcode
                    ? arrayHelpers.form.values.productVariants[variantIndex]
                        .barcode === ''
                      ? 'Required'
                      : isNaN(
                          arrayHelpers.form.values.productVariants[variantIndex]
                            .barcode
                        )
                      ? 'Must be a number'
                      : null
                    : null
                }
              />
            ) : null
          ) : orderType.includes(SCAN_AND_GO) ? (
            <div>{productVariant.barcode}</div>
          ) : null}
          {/* {
            <div>
              <AddNutritionFactButton
                onClick={() => toggleNutritionInfoModal()}
                style={{ visibility: 'visible' }}
              >
                <Icon className="fal fa-plus" />
                <span>Add Nutrition Info</span>
              </AddNutritionFactButton>
              {nutritionInfoModal && (
                <NutritionInfoModalContainer
                  toggleNutritionInfoModal={toggleNutritionInfoModal}
                  productId={productVariant.product_id}
                  productVariantId={productVariant.product_variant_id}
                />
              )}
            </div>
          } */}
          {isToggleDisplayed && (
            <ToggleWrapper>
              <SmallToggleSwitch
                checked={!isToggleDisabled && productVariant.is_active}
                onChange={(e) =>
                  arrayHelpers.form.setFieldValue(
                    `productVariants[${variantIndex}].is_active`,
                    e.target.checked
                  )
                }
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                label="Activate"
                vertical={true}
                disabled={isToggleDisabled}
              />
            </ToggleWrapper>
          )}
        </SortableCardRow>
        {productVariant.is_default && <div>Default</div>}
      </SortableCardRowWrapper>
    );
  }
);

const SortableList = SortableContainer(
  ({
    productVariants,
    productVariantsAreEditable,
    allVariants,
    arrayHelpers,
    productId,
    appConfig,
    posMappingValues,
    orderType,
  }) => {
    return (
      <div
        style={{
          display: 'grid',
          gridGap: '24px',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {productVariants.map((productVariant, index) => (
          <SortableItem
            key={`productVariant-${index}`}
            index={index}
            variantIndex={index}
            disabled={productVariantsAreEditable}
            productVariant={productVariant}
            productVariantsAreEditable={productVariantsAreEditable}
            allVariants={allVariants}
            arrayHelpers={arrayHelpers}
            productId={productId}
            appConfig={appConfig}
            posMappingValues={posMappingValues}
            orderType={orderType}
          />
        ))}
      </div>
    );
  }
);

const RenderProductVariantsFieldArray = ({
  arrayHelpers,
  productVariantsAreEditable,
  allVariants,
  productId,
  appConfig,
  posMappingValues,
  orderType,
}) => {
  return (
    <Fragment>
      {arrayHelpers.form.values.productVariants.length !== 0 && (
        <SortableList
          productVariants={arrayHelpers.form.values.productVariants}
          productVariantsAreEditable={productVariantsAreEditable}
          allVariants={allVariants}
          arrayHelpers={arrayHelpers}
          onSortEnd={({ oldIndex, newIndex }) => {
            arrayHelpers.move(oldIndex, newIndex);
          }}
          productId={productId}
          appConfig={appConfig}
          posMappingValues={posMappingValues}
          onClick={(e) => e.stopPropagation()}
          orderType={orderType}
        />
      )}
      <AddNewVariant
        disabled={!productVariantsAreEditable}
        onClick={(e) => {
          e.stopPropagation();
          arrayHelpers.push({
            id: 2000 + arrayHelpers.form.values.productVariants.length,
            position: arrayHelpers.form.values.productVariants.length + 1,
            is_active: false,
            is_default: false,
            product_variant_id: null,
            price: '',
            calories: '',
            is_new: true,
          });
        }}
      >
        + Add New Variant
      </AddNewVariant>
      {arrayHelpers.form.values.productVariants.length !== 0 && (
        <ReactSelectWrapper onClick={(e) => e.stopPropagation()}>
          <SelectDefaultVariant>Select Default Variant</SelectDefaultVariant>
          <Select
            value={arrayHelpers.form.values.productVariants.filter(
              (field) => field.is_default
            )}
            zIndex={0}
            pagination={true}
            // isDisabled={editingProductVariant !== null}
            options={arrayHelpers.form.values.productVariants}
            getOptionLabel={(productVariant) =>
              getVariantName(allVariants, productVariant.product_variant_id)
            }
            getOptionValue={(field) => field.product_variant_id}
            simpleValue
            valueKey="id"
            placeholder="Select Default Variant*"
            onChange={(selectedProductVariant) => {
              arrayHelpers.form.values.productVariants.forEach(
                (productVariant, index) => {
                  selectedProductVariant.product_variant_id ===
                  productVariant.product_variant_id
                    ? arrayHelpers.form.setFieldValue(
                        `productVariants[${index}].is_default`,
                        true
                      )
                    : arrayHelpers.form.setFieldValue(
                        `productVariants[${index}].is_default`,
                        false
                      );
                }
              );
            }}
          />
        </ReactSelectWrapper>
      )}
    </Fragment>
  );
};

export default RenderProductVariantsFieldArray;
