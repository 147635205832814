import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import BusinessInformationForm from './components/BusinessInformationForm';

const BusinessInformationFormContainer = ({
  businessLocationDetailsReducer,
  createBusinessInformation,
  updateBusinessInformation,
  appIdentifier,
  is_delivery_enabled,
}) => {
  const {
    businessInformationInitialValues,
    currencyList,
    businessId,
    isCreatingLocation,
  } = businessLocationDetailsReducer;

  const handleSubmit = ({ businessInformation }) => {
    if (isCreatingLocation && !businessId) {
      createBusinessInformation({
        ...businessInformation,
        transaction_amount_limit: parseInt(
          businessInformation.transaction_amount_limit
        ),
      });
    } else {
      updateBusinessInformation(
        {
          ...businessInformation,
          transaction_amount_limit: parseInt(
            businessInformation.transaction_amount_limit
          ),
        },
        businessId,
        businessInformationInitialValues
      );
    }
  };

  const externalIdIsVisible = appIdentifier === 'wilson';

  return (
    <Fragment>
      <BusinessInformationForm
        initialValues={{
          businessInformation: businessInformationInitialValues,
        }}
        currencyList={currencyList.map((currency) => ({
          value: currency,
          label: currency,
        }))}
        handleSubmit={handleSubmit}
        externalIdIsVisible={externalIdIsVisible}
        is_delivery_enabled={is_delivery_enabled}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  businessLocationDetailsReducer: state.businessLocationDetails,
  appIdentifier: state.accountReducer.appIdentifier,
  is_delivery_enabled:
    state.accountReducer.appMetaData.configuration.is_delivery_enabled,
});

export default connect(
  mapStateToProps,
  actions
)(BusinessInformationFormContainer);
