import {
  GET_OPTIONS_REQUEST,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_FAILURE,
  TOGGLE_OPTION_IS_ACTIVE_SUCCESS,
  CREATE_OPTION_SUCCESS,
  LOAD_CREATE_OPTION_PAGE,
  LOAD_EDIT_OPTION_PAGE,
  LOAD_LIST_OPTION_PAGE,
  EDIT_OPTION_SUCCESS,
  GET_OPTIONS_SUCCESS_NORMALIZED,
  RESET_OPTION_REDUCER,
  UPDATE_SORTED_OPTIONS_LIST
} from './optionTypes';

const initialState = {
  currentPage: 'list',
  requestingGetOptions: false,
  options: [],
  meta: { page_number: 1, page_size: 25 },
  editingOption: null,
  entities: null,
  result: null,
  requestParams: {
    page_number: 1,
    page_size: '25',
    sorting_option: 'title-asc'
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_OPTIONS_REQUEST:
      return {
        ...state,
        requestingGetOptions: true
      };
    case GET_OPTIONS_SUCCESS:
      return {
        ...state,
        requestingGetOptions: false,
        options: action.options,
        meta: action.meta
      };
    case GET_OPTIONS_FAILURE:
      return {
        ...state,
        requestingGetOptions: false
      };
    case TOGGLE_OPTION_IS_ACTIVE_SUCCESS:
      return {
        ...state,
        options: state.options.map(option =>
          option.id === action.optionId
            ? { ...option, is_active: !option.is_active }
            : option
        )
      };
    case CREATE_OPTION_SUCCESS:
      return {
        ...state,
        currentPage: 'list'
      };
    case LOAD_CREATE_OPTION_PAGE:
      return { ...state, currentPage: 'create' };
    case LOAD_EDIT_OPTION_PAGE:
      return {
        ...state,
        currentPage: 'edit',
        editingOption: action.editingOption
      };
    case LOAD_LIST_OPTION_PAGE:
      return {
        ...state,
        currentPage: 'list',
        editingOption: null
      };
    case EDIT_OPTION_SUCCESS:
      return {
        ...state,
        currentPage: 'list',
        editingOption: null
      };
    case GET_OPTIONS_SUCCESS_NORMALIZED:
      return {
        ...state,
        entities: action.response.entities,
        result: action.response.result
      };
    case RESET_OPTION_REDUCER:
      return {
        ...initialState,
        options: state.options,
        meta: state.meta,
        entities: state.entities,
        result: state.result
      };
    case 'UPDATE_OPTIONS_REQUEST_PARAMS':
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload
        }
      };
    case UPDATE_SORTED_OPTIONS_LIST:
      return {
        ...state,
        options: action.optionsList,
        requestParams: {
          ...state.requestParams,
          sorting_option: action.sortOrder
        }
      };
    default:
      return state;
  }
}
