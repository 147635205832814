import React, { useEffect } from 'react';
import ReportTable from '../ReportTable/ReportTable';
import { BatchDetailPage } from '../../components/FormElements/FormElements';
import moment from 'moment';
import { connect } from 'react-redux';

function LocationReport(props) {
  useEffect(() => {
    track.page('location-report');
  }, []);

  const columnNames = [
    'Location Name',

    'Pickup Orders Paid in App #',
    'Pickup Orders Paid on Arrival #',

    'Delivery Orders Paid in App #',
    'Delivery Orders Paid on Arrival #',

    'Scan to Pay Orders #',
    'Loyalty Only Orders #',

    'Scan and Go Orders #',

    'Drop-off Orders #',

    'Products Sold #',

    'Combos Sold #',

    'Gift Card Orders #',

    'CC Orders #',

    'AVG Transaction Basket Size',
    'Gross Sales',
    'Discounts',
    'Net Sales',
    'Customer Paid $',
  ];

  const columnSort = [
    'business_name',

    'num_orders_pickup_paid_in_app',
    'num_orders_pickup_paid_on_arrival',

    'num_orders_delivery_paid_in_app',
    'num_orders_delivery_paid_on_arrival',

    'num_orders_in_store_paid_in_app',
    'num_orders_in_store_paid_in_person',

    'num_orders_scan_and_go',

    'num_orders_drop_off',

    'num_products_sold',

    'num_combos_sold',

    'num_orders_gift_card',

    'num_orders_credit_card',

    'avg_basket_size',
    'gross_sales',
    'discounts',
    'net_sales',
    'customer_paid',
  ];

  const initialParams = {
    page_number: 1,
    page_size: 10,
    export: false,
    start_date: `${moment().subtract(1, 'days').format('YYYY-MM-DD')}`,
    end_date: `${moment().format('YYYY-MM-DD')}`,
    sort_by: 'business_name',
    sort_direction: 'asc',
  };

  const notSortColumns = ['num_of_orders', 'customer_paid'];

  return (
    <BatchDetailPage>
      <ReportTable
        url="/report/business/order-summary"
        columnNames={columnNames}
        title="Location Transaction Report"
        csvRequestPath="report/business/order-summary"
        filters={['date', 'location']}
        initialParams={initialParams}
        emptyMessage={
          'There Are No Location Reports To Show Between The Currently Selected Dates'
        }
        notSortColumns={notSortColumns}
        columnSort={columnSort}
      />
    </BatchDetailPage>
  );
}

const mapStateToProps = (state) => ({
  appSettings: state.accountReducer.appMetaData.configuration,
});

export default connect(mapStateToProps, null)(LocationReport);
