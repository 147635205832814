import styled from 'styled-components';
import {ComponentCard as Card} from '../../../../../../components/FormElements/FormElements';

export const ComponentCard = styled(Card)``;

export const ControlShowShapesContainer = styled.div`
  padding: 22px 0;
`;

export const ShapeNameGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  margin: 3px 0;
`;

export const ShapeName = styled.div`
  margin: auto 0;
`;

export const HorizontalLine = styled.hr`
  border: none;
  border-top: solid 2px #c9cbcf;
  opacity: 0.5;
`;

export const SubHeaderWrapper = styled.div`
  padding-bottom: 20px;
`;

export const InteractWithGoogleMapsGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
`;

export const StyledP = styled.p`
  color: ${props =>
    props.isVisible == undefined
      ? props.theme.darkBlue
      : props.isVisible
      ? props.isEditable
        ? '#296bde'
        : props.theme.darkBlue
      : '#979eaa'};
  font-weight: ${props => (props.semiBold ? '600' : 'inherit')};
  padding: ${props => props.padding || ' 0 0 0 0'};
  align-self: ${props => props.align || 'inherit'};
  font-size: ${props => props.size || 'inherit'};
`;

export const ContainerDiv = styled.div`
  margin: ${props => props.margin || '0 0 0 0'};
  text-align: ${props => props.align || 'inherit'};
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const FieldWrapper = styled.div`
  margin: ${props => props.margin || '0 0 0 0'};
`;

export const ToggleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 5px;
`;
