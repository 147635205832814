import React, { useState } from 'react';
import {
  MessageTokensWrapper,
  MessageTokenDiv,
  MessageTokenIcon,
} from '../styles/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import TokensTable from './TokensTable';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white',
    padding: '16px 12px 14px 18px',
    borderRadius: '1.5px',
    boxShadow: '0 3px 11px 0 rgba(0, 0, 0, 0.09)',
    width: 320,
    fontSize: '12px',
    fontFamily: 'Montserrat',
    color: '#000000',
    pointerEvents: 'auto !important',
  },
}))(Tooltip);

const MessageTokens = ({ tokens }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MessageTokensWrapper
      onClick={() => {
        setIsOpen(true);
      }}
    >
      <MessageTokenDiv>
        <HtmlTooltip
          PopperProps={{ style: { zIndex: '1' } }}
          open={isOpen}
          title={<TokensTable tokens={tokens} setIsOpen={setIsOpen} />}
        >
          <MessageTokenIcon className="fal fa-user" />
        </HtmlTooltip>
      </MessageTokenDiv>
    </MessageTokensWrapper>
  );
};

export default MessageTokens;
